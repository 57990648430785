import { ReactNode } from 'react';

export enum SectionMode {
  Closed,
  Opened,
  Edited,
}

export type SectionProps = {
  order: number;
  buttonName: ReactNode;
  title?: string;
  subtitle?: string;
  expanded?: boolean;
  mode?: SectionMode;
  setSectionMode?: (val: SectionMode) => void;
  fieldHasValue?: boolean;
  isError?: boolean;
  sectionBottomPadding?: number;
  showSocialIcons?: boolean;
  ariaLabelledby?: string;
  ariaLabelSaveButton?: string;
  showContent?: boolean;
};
