import { styled } from '@material-ui/core';

const SOCIAL_MEDIA_SIZE = 52;

export const StyledContainer = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingTop: 16,

  '& svg': {
    height: SOCIAL_MEDIA_SIZE,
    width: SOCIAL_MEDIA_SIZE,
  },
  '& > a': {
    paddingLeft: spacing(1),
  },
}));
