import React, { useState } from 'react';
import { useMutation } from 'react-fetching-library';

import { createSendEmail } from 'api/actions/email/email.actions';
import { RestoreUploadLinkForm } from './form/RestoreUploadLinkForm';
import { RestoreUploadFormSuccess } from './succcess/RestoreUploadFormSuccess';
import { handleOnSubmit } from 'shared/utils/handleOnSubmit/handleOnSubmit';

export const RestoreUploadLinkContainer = () => {
  const [submitSucceeded, setSubmitSucceeded] = useState(false);
  const { mutate } = useMutation(createSendEmail);

  const onSubmit = handleOnSubmit({
    action: values => mutate(values),
    successCallback: () => setSubmitSucceeded(true),
  });

  const handleGoBack = () => setSubmitSucceeded(false);

  if (submitSucceeded) {
    return <RestoreUploadFormSuccess goBack={handleGoBack} />;
  }

  return <RestoreUploadLinkForm onSubmit={onSubmit} />;
};
