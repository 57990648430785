import React from 'react';

import { Typography } from 'shared/components';
import { useParticipantData } from 'contexts/participantData/participantDataContext';
import {
  Container,
  InformationText,
  GreetingText,
  ImageContainer,
  Name,
  TextContainer,
  useStyles,
} from './GraduateSection.styles';
import { GraduateSectionProps } from './GraduateSection.types';

export const GraduateSection = ({ title, titleSize = 80, description1, description2, image }: GraduateSectionProps) => {
  const { fullName } = useParticipantData();
  const styles = useStyles({ titleSize });

  return (
    <Container>
      <TextContainer>
        <GreetingText>
          <Name color="primary" display="inline" variant="h1">
            {fullName}
          </Name>
        </GreetingText>
        <Typography variant="h2" className={styles.title}>
          {title}
        </Typography>
        <Typography variant="body1">{description1}</Typography>
        <InformationText variant="body1">{description2}</InformationText>
      </TextContainer>
      <ImageContainer>{image}</ImageContainer>
    </Container>
  );
};
