import { withStyles } from '@material-ui/core';

import { Container } from 'shared/components/container/Container';

export const StyledContainer = withStyles(({ breakpoints }) => ({
  root: {
    paddingTop: 72,

    [breakpoints.down('sm')]: {
      paddingTop: 36,
    },
  },
}))(Container);
