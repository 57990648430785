/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import Cropper from 'react-easy-crop';

import { SectionMode } from 'shared/components/expandingSection/Section.types';
import { Image as Img } from 'shared/components/image/Image';
import * as Sentry from 'shared/utils/sentry/sentry';
import { hash } from 'shared/utils/hash/hash';
import { Area, BlobImageProps } from './BlobImage.types';
import { useCreateImage } from './useCreateImage';

const IMAGE_PREVIEW_HEIGHT = 300;
const IMAGE_MIN_RESOLUTION = 700;

export const BlobImage = ({
  src,
  orientation = 0,
  resize,
  resetRotation,
  resetResize,
  onCrop,
  onLoad,
  scale,
  sectionMode,
  isError,
  ...props
}: BlobImageProps) => {
  const [blob, setBlob] = useState<string | ArrayBuffer | null>(null);
  const [previewError, setPreviewError] = useState<string | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [scrollZoom, setScrollZoom] = useState(1);
  const [pixels, setPixels] = useState();
  const [image, setImage] = useState<any>();
  const [enhancedImage, setEnhancedImage] = useState<any>();
  const [original, setOriginal] = useState<File | null>(null);
  const { getCroppedImg } = useCreateImage();
  const [imgElement, setImgElement] = useState();
  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(image, pixels, orientation);

      if (croppedImage) {
        setEnhancedImage(croppedImage);
      }
    } catch (e) {
      setPreviewError(e);
    }
  };

  useEffect(() => {
    if (original !== src) {
      // TODO: reset all values on newly uploaded file
      setOriginal(src);
      setPixels(null);
      setCrop({ x: 0, y: 0 });
      setZoom(1);
      setPreviewError(null);

      if (resetRotation) resetRotation();

      if (resetResize) resetResize();

      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImage(reader.result);
        setEnhancedImage(reader.result);
        setBlob(reader.result);

        if (typeof reader.result === 'string') {
          const newImage = document.createElement('img');
          newImage.src = reader.result;
          setImgElement(newImage);
        }
      });

      if (src instanceof Blob) {
        try {
          reader.readAsDataURL(src);
        } catch (e) {
          //   TODO: use message from translations or find a way to prevent this error from happening
          setPreviewError('Preview is unavailable for this file');
          Sentry.withScope(scope => {
            scope.setExtra('src', src);
            scope.setExtra('isBlob', src instanceof Blob);
            scope.setExtra('isFile', src instanceof File);
            scope.setExtra('isNull', src === null);
            Sentry.captureException(e);
          });
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(src)]);

  useEffect(() => {
    if (sectionMode === SectionMode.Edited) {
      showCroppedImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionMode]);

  const cropComplete = (_: Area, croppedAreaPixels: Area) => {
    setPixels(croppedAreaPixels);
    onCrop(croppedAreaPixels);
  };

  const cropChange = (c: any) => {
    setCrop(c);
  };

  useEffect(() => {
    if (!scale) {
      return;
    }

    const minDimension = Math.min(imgElement?.width, imgElement?.height);
    const maxScale = minDimension / IMAGE_MIN_RESOLUTION;
    const ratio = (maxScale - 1) / 100;

    setZoom(1 + scale * ratio);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scale, scrollZoom]);

  useEffect(() => {
    const minDimension = Math.min(imgElement?.width, imgElement?.height);
    const maxScale = minDimension / IMAGE_MIN_RESOLUTION;

    if (scrollZoom <= maxScale) setZoom(scrollZoom);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollZoom]);

  if (previewError) {
    return <span>{previewError}</span>;
  }

  if (!blob || !(typeof blob === 'string') || isError) {
    return null;
  }

  return (
    <>
      {sectionMode === SectionMode.Opened && (
        <div style={{ position: 'relative', width: '100%', height: `${IMAGE_PREVIEW_HEIGHT}px` }}>
          <Cropper
            image={blob}
            crop={crop}
            rotation={orientation}
            zoom={zoom}
            aspect={1}
            onCropChange={c => cropChange(c)}
            onCropComplete={cropComplete}
            onZoomChange={setScrollZoom}
          />
        </div>
      )}
      {(sectionMode === SectionMode.Edited || (sectionMode === SectionMode.Closed && image)) && (
        <Img {...props} src={enhancedImage || image} />
      )}
    </>
  );
};
