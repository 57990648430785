import React from 'react';

import { ScrollToTop } from 'shared/components/scrollToTop/ScrollToTop';
import { Wrapper } from './EmailTamplate.styles';

export const EmailTemplate: React.FC = ({ children }) => {
  return (
    <Wrapper>
      <ScrollToTop />
      {children}
    </Wrapper>
  );
};
