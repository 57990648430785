import {
  CreateFetchAVUserUpload,
  GetAVUserData,
  ConfirmAVUserUpload,
  AvUserUploadExternal,
  AvUserUploadExternalLink,
} from './avUser.types';

export const getAVUserData: GetAVUserData = id => ({
  endpoint: `/live-event/${id}`,
  method: 'GET',
});

export const createFetchAVUserUpload: CreateFetchAVUserUpload = uploadDetails => (fileExtension, fileName) => ({
  endpoint: `/live-event/${uploadDetails.eventId}/user/${uploadDetails.avUserId}/upload-meta?ext=${fileExtension}&fileName=${fileName}`,
  method: 'GET',
});

export const confirmAVUserUpload: ConfirmAVUserUpload = ({ avUserId, ...body }) => ({
  endpoint: `/live-event/${avUserId}`,
  method: 'POST',
  body,
});

export const avUserUploadExternal: AvUserUploadExternalLink = ({ jobId, avUserId }) => ({
  endpoint: `/live-event/upload-video-from-external/${jobId}?avuserId=${avUserId}`,
  method: 'GET',
});

export const uploadExternalVideos: AvUserUploadExternal = ({ avuserId, ...body }) => ({
  endpoint: `/live-event/upload-video-from-external/${avuserId}`,
  method: 'POST',
  body,
});
