import React from 'react';

import { Alert } from './alert/Alert';
import { Snapcode } from './Snapcode';
import { useParticipantData } from 'contexts/participantData/participantDataContext';

export const SnapcodeContainer = () => {
  const { snapchat, snapchatUrl, snapcode } = useParticipantData();

  if (snapchat) {
    if (snapchatUrl && snapcode) {
      return <Snapcode snapchatUrl={snapchatUrl} snapcode={snapcode} />;
    }

    return <Alert />;
  }

  return null;
};
