import React from 'react';
import { useQuery, useParameterizedQuery, Action } from 'react-fetching-library';

import { TextToSpeech } from './TextToSpeech';

const getTextToSpeechToken: Action = {
  endpoint: `https://eastus.api.cognitive.microsoft.com/sts/v1.0/issueToken`,
  method: 'POST',
  headers: {
    'Ocp-Apim-Subscription-Key': '550adb858c614af99f62a1486f2ed83f',
  },
};

const getVoicesList: (authorization: string) => Action = authorization => ({
  endpoint: `https://eastus.tts.speech.microsoft.com/cognitiveservices/voices/list`,
  method: 'GET',
  headers: {
    Authorization: authorization,
  },
});

export const TextToSpeechContainer = () => {
  const { payload } = useQuery(getTextToSpeechToken);
  const { payload: voices, query } = useParameterizedQuery(getVoicesList);

  return <TextToSpeech token={payload} voices={voices} getVoices={query} />;
};
