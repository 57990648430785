import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    borderRadius: spacing(1.5),
    backgroundColor: palette.green.contrastText,
    width: '100%',
    paddingLeft: spacing(0),
    paddingRight: '0px !important',
    boxShadow: 'none',
  },
}));
