import React, { useState } from 'react';
import { useMutation } from 'react-fetching-library';

import { uploadExternalVideos } from 'api/actions/avUser/avUser.actions';
import { AvUserFileUploadStreamZone } from './AvUserFileUploadStreamZone';
import { handleOnSubmit } from 'shared/utils/handleOnSubmit/handleOnSubmit';
import { useAVUserQueryData } from 'contexts/avUserQueryData/avUserQueryDataContext';
import { AvUserFileUploadStreamZoneContainerProps } from './AvUserFileUploadStreamZone.type';

export const AvUserFileUploadStreamZoneContainer = ({
  eventDetails,
  setCollaboratorPage,
  setVideoFileInfo,
}: AvUserFileUploadStreamZoneContainerProps) => {
  const { avUserId } = useAVUserQueryData();
  const { mutate } = useMutation(uploadExternalVideos);
  const [id, setjobId] = useState('');

  const onSubmit = handleOnSubmit({
    action: values => {
      const body = {
        videoUrl: values.videoUrl,
        eventId: eventDetails?.id || '',
        avuserId: avUserId,
      };

      return mutate(body);
    },
    successCallback: payload => {
      setjobId(payload);
    },
  });

  return (
    <AvUserFileUploadStreamZone
      onSubmit={onSubmit}
      id={id}
      setCollaboratorPage={setCollaboratorPage}
      eventDetails={eventDetails}
      setVideoFileInfo={setVideoFileInfo}
    />
  );
};
