import React from 'react';

import { GridContainer, GridItem } from 'shared/components/grid';
import { SimplePageIcon } from './icon/SimplePageIcon';
import { SimplePageHeader } from './header/SimplePageHeader';
import { SimplePageContent } from './content/SimplePageContent';
import { Wrapper, StyledContainer } from './SimplePage.styles';
import { SimplePageProps } from './SimplePage.types';

export const SimplePage: React.FC<SimplePageProps> & {
  Icon: typeof SimplePageIcon;
  Header: typeof SimplePageHeader;
  Content: typeof SimplePageContent;
} = ({ icon, header, content, children }) => {
  return (
    <StyledContainer>
      <GridContainer fullHeight alignItems="center" justify="center" spacing={4} direction="column">
        <GridItem md={4} sm={12}>
          <Wrapper>
            {!!icon && <SimplePageIcon icon={icon} />}
            {!!header && <SimplePageHeader>{header}</SimplePageHeader>}
            {!!content && <SimplePageContent>{content}</SimplePageContent>}
            {children}
          </Wrapper>
        </GridItem>
      </GridContainer>
    </StyledContainer>
  );
};

SimplePage.Icon = SimplePageIcon;
SimplePage.Header = SimplePageHeader;
SimplePage.Content = SimplePageContent;
