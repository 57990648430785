import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { useIntl } from 'react-intl';

import { Typography, Loader } from 'shared/components';
import { useStyles } from './StudentNameEditConfirm.styles';
import { StudentLoginProps } from './StudentNameEditConfirm.types';

export const StudentNameEditConfirmModal = ({ open, handleClose, updateParticipantLoading }: StudentLoginProps) => {
  const styles = useStyles();
  const { formatMessage } = useIntl();

  return (
    <>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogContent dividers className={styles.container}>
          {updateParticipantLoading ? (
            <Loader />
          ) : (
            <>
              <div className={styles.iconContainer}>
                <span role="img" className={styles.icon} aria-label="party popper">
                  🎉🎉🎉
                </span>
              </div>
              <Typography className={styles.title}>
                {formatMessage({ id: 'av_user.student_Name_Listener.confirmation_title' })}
              </Typography>
              <Typography className={styles.subTitle}>
                {formatMessage({ id: 'av_user.student_Name_Listener.confirmation_message' })}
              </Typography>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
