import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
  stepContainer: {
    position: 'relative',
    backgroundColor: palette.black.light,
    height: spacing(71.625),
    width: '100%',
    [breakpoints.down('sm')]: {
      height: spacing(85),
    },
  },
  raw: {
    width: '100%',
  },
  column: {
    width: '100vw',
  },
  columnImg: {
    position: 'absolute',
    left: spacing(10.375),
    top: spacing(18.75),
    height: spacing(17.125),
    [breakpoints.down('sm')]: {
      postion: 'static',
      left: '5vw',
      marginTop: spacing(2),
      marginLeft: spacing(0),
      width: spacing(37.5),
      height: spacing(25),
      top: spacing(0),
    },
  },
  cardTopic: {
    fontFamily: 'Roboto',
    position: 'absolute',
    left: spacing(10),
    bottom: spacing(10),
    color: palette.white.main,
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  links: {
    position: 'absolute',
    fontFamily: 'Roboto',
    left: '30vw',
    top: '20vh',
    fontSize: spacing(2.25),
    color: palette.white.main,
    [breakpoints.down('md')]: {
      left: '30vw',
    },
    [breakpoints.down('sm')]: {
      left: '18vw',
      top: '30vh',
    },
  },
  categoryLeft: {
    position: 'absolute',
    left: spacing(22.5),
    textAlign: 'left',
    width: spacing(22.5),
    lineHeight: spacing(0.2),
    [breakpoints.down('sm')]: {
      left: '11vw',
      top: spacing(12.5),
    },
  },
  categoryRight: {
    position: 'absolute',
    textAlign: 'left',
    width: spacing(26.75),
    marginLeft: '37vw',
    lineHeight: spacing(0.2),
    [breakpoints.down('md')]: {
      marginLeft: '38vw',
    },
    [breakpoints.down('sm')]: {
      marginLeft: spacing(0),
      left: '11vw',
      top: spacing(31),
    },
  },
  link: {
    color: palette.white.main,
    display: 'block',
  },
}));
