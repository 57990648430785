import { styled, withStyles, IconButton, fade } from '@material-ui/core';

import { Typography } from '../typography/Typography';

export const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
});

export const Subtitle = withStyles({
  root: {
    opacity: 0.6,
  },
})(Typography);

export const Controls = styled('div')({});

export const PlayButton = withStyles(({ palette }) => ({
  root: {
    background: palette.green.main,
    margin: '0 5px',
    color: '#fff',
    '&:hover': {
      background: fade(palette.green.main, 0.5),
    },
    '&$disabled': {
      backgroundColor: fade(palette.green.main, 0.2),
    },
  },
  disabled: {},
}))(IconButton);

export const Visualization = styled('div')({
  position: 'relative',
  height: 45,
});

export const Bars = styled('div')({
  display: 'inline-flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
});

export const Progress = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  overflow: 'hidden',
  width: 0,
});

export const Bar = styled('div')(({ theme: { palette } }) => ({
  width: 4,
  maxHeight: 45,
  background: palette.gray.main,
  margin: '0 3px 0 0',
  borderRadius: 16,
}));

export const BarFilled = styled(Bar)(({ theme: { palette } }) => ({ background: palette.green.main }));

export const Times = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export const Time = withStyles(({ palette }) => ({
  root: {
    color: palette.gray.main,
  },
}))(Typography);
