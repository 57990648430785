import { makeStyles } from '@material-ui/core/styles';
import { Container, styled, Typography } from '@material-ui/core';

export const Cards = styled(Container)(({ theme: { breakpoints } }) => ({
  paddingTop: '5%',
  [breakpoints.up('lg')]: {
    paddingRight: '10%',
    paddingLeft: '10%',
    paddingBottom: '10%',
  },
  [breakpoints.down('md')]: {
    paddingRight: '0%',
    paddingLeft: '2%',
    paddingBottom: '5%',
  },
}));

export const Title = styled(Typography)(({ theme: { breakpoints, typography, spacing } }) => ({
  ...typography.h2,
  display: 'flex',
  fontWeight: 'bold',
  paddingBottom: spacing(4),
  fontFamily: 'Open Sans',
  [breakpoints.down('sm')]: {
    fontSize: spacing(3.2),
    alignContent: 'center',
    justifyContent: 'center',
  },
  [breakpoints.up('lg')]: {
    fontSize: spacing(3.75),
  },
}));

export const Topic = styled(Typography)(({ theme: { breakpoints, typography, spacing } }) => ({
  ...typography.h4,
  fontWeight: 'bold',
  fontFamily: 'Open Sans',
  [breakpoints.down('sm')]: {
    fontSize: spacing(1.75),
  },
  [breakpoints.up('lg')]: {
    fontSize: spacing(2),
  },
}));

export const SubTopic = styled(Typography)(({ theme: { breakpoints, spacing, palette } }) => ({
  fontFamily: 'Open Sans',
  color: palette.black.main,
  [breakpoints.down('sm')]: {
    fontSize: spacing(1.5),
  },
  [breakpoints.up('lg')]: {
    fontSize: spacing(2),
  },
}));
export const SubTopic1 = styled(Typography)(({ theme: { breakpoints, spacing, palette } }) => ({
  fontFamily: 'Open Sans',
  fontWeight: 'bold',
  color: palette.gray.dark,
  [breakpoints.down('sm')]: {
    fontSize: spacing(3),
  },
  [breakpoints.up('lg')]: {
    fontSize: spacing(2.5),
  },
}));

export const SubTopicFail = styled(Typography)(({ theme: { breakpoints, spacing, palette } }) => ({
  fontFamily: 'Open Sans',
  color: palette.red.light,
  fontWeight: 'bold',
  [breakpoints.down('sm')]: {
    fontSize: spacing(1.5),
  },
  [breakpoints.up('lg')]: {
    fontSize: spacing(2),
  },
}));

export const Uploads = styled(Typography)(({ theme: { breakpoints, spacing, palette } }) => ({
  color: palette.black.main,
  fontWeight: 'bold',
  fontFamily: 'Open Sans',
  fontStyle: 'unset',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  [breakpoints.down('sm')]: {
    fontSize: spacing(1.5),
  },
  [breakpoints.up('lg')]: {
    fontSize: spacing(2),
    marginRight: '10%',
  },
}));
export const UploadApprove = styled(Typography)(({ theme: { breakpoints, spacing, palette } }) => ({
  color: palette.green.main,
  cursor: 'pointer',
  fontWeight: 'bold',
  fontFamily: 'Open Sans',
  [breakpoints.down('sm')]: {
    fontSize: spacing(1.5),
  },
  [breakpoints.up('lg')]: {
    fontSize: spacing(2),
  },
}));
export const UploadFail = styled(Typography)(({ theme: { breakpoints, spacing, palette } }) => ({
  color: palette.red.light,
  fontWeight: 'bold',
  fontFamily: 'Open Sans',
  [breakpoints.down('sm')]: {
    fontSize: spacing(1.5),
  },
  [breakpoints.up('lg')]: {
    fontSize: spacing(2),
  },
}));

export const Image = styled('div')(({ theme: { breakpoints, spacing } }) => ({
  borderRadius: spacing(0.5),
  [breakpoints.down('sm')]: {
    width: spacing(9),
    height: spacing(6),
  },
  [breakpoints.up('md')]: {
    width: spacing(1),
    height: spacing(5),
  },
}));

export const UpcomingEventStyle = makeStyles(({ breakpoints, spacing, palette }) => ({
  image: {
    borderRadius: spacing(0.5),
    [breakpoints.down('sm')]: {
      width: '70%',
      height: spacing(15),
    },
    [breakpoints.up('md')]: {
      width: spacing(10.5),
      height: spacing(7.5),
    },
    display: 'flex',
    fill: 'cover',
  },
  imageDiv: {
    backgroundColor: palette.black.dark,
    borderRadius: spacing(0.5),
    [breakpoints.down('sm')]: {
      width: '70%',
      height: spacing(15),
    },
    [breakpoints.up('md')]: {
      width: spacing(10.5),
      height: spacing(7.5),
    },
    display: 'flex',
  },
  uploadIcon: {
    margin: 'auto',
    width: spacing(2.5),
    height: spacing(2.25),
  },
  failedIcon: {
    margin: 'auto',
    width: spacing(2.5),
    height: spacing(2.25),
    filter: 'invert(80%) sepia(90%) saturate(5607%) hue-rotate(338deg) brightness(88%) contrast(97%)',
  },

  upload: {
    backgroundColor: palette.black.dark,
    borderRadius: spacing(0.5),
    [breakpoints.down('sm')]: {
      width: '70%',
      height: spacing(15),
    },
    [breakpoints.up('md')]: {
      marginTop: '2%',
      width: spacing(10.5),
      height: spacing(7.5),
    },
    display: 'flex',
  },

  root: {
    paddingRight: '0%',
    paddingLeft: '0%',
    paddingTop: '5%',
    paddingBottom: '5%',
    textAlign: 'center',
    width: '100%',
  },
  grid: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '1%',
    backgroundColor: palette.red.dark,
    [breakpoints.down('md')]: {
      width: '100%',
    },
    [breakpoints.up('lg')]: {
      width: '100%',
    },
  },
  uploadContent: {
    fontFamily: 'Open Sans',
    fontSize: spacing(3),
    paddingBottom: '5%',
    color: palette.black.main,
  },
  ErrorLogo: {
    height: '30%',
    width: '30%',
    color: palette.red.main,
    paddingBottom: '5%',
    paddingTop: '10%',
  },
  uploads: {
    paddingRight: '30%',
    paddingLeft: '30%',
    backgroundColor: palette.red.dark,
  },
  availableCard: {
    marginBottom: '3%',
    marginLeft: '1%',
    marginRight: '1%',
  },
  available: {
    [breakpoints.up('md')]: {
      flexDirection: 'row',
      display: 'flex',
      marginTop: '2%',
      paddingLeft: '3%',
    },
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      display: 'flex',
      padding: '2%',
    },
  },
  availableImage: {
    marginRight: '3%',
    [breakpoints.up('md')]: {
      height: spacing(7),
      width: spacing(12.5),
    },
    [breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
  },
  content: {
    flexDirection: 'column',
    width: '100%',
  },
  topic: {
    [breakpoints.up('md')]: {
      minHeight: spacing(4.4),
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      width: '80%',
    },
    [breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  subTopic: {
    [breakpoints.up('md')]: {
      height: spacing(5),
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    [breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  subTopic1: {
    [breakpoints.up('md')]: {
      height: spacing(3),
      display: 'flex',
      marginLeft: '3%',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    [breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  state: {
    [breakpoints.up('md')]: {
      width: '20%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '5%',
      marginBottom: '4%',
    },
    [breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  side: {
    [breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  errorDiv: {
    paddingBottom: '10%',
  },
  searchBarContainer: {
    display: 'flex',
    width: '100%',
    padding: spacing(0, 1, 4, 1),
  },
  searchBar: {
    width: '100%',
    '& div': {
      '& input': {
        padding: spacing(1.5, 1.5, 1.5, 0),
      },
    },
  },
  searchIcon: {
    display: 'flex',
    paddingRight: spacing(1),
  },
}));
