import React from 'react';
import clsx from 'clsx';

import { useFormControlState } from '../useFormControlState/useFormControlState';

import { withBorderProps } from './withBorder.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withBorder = <ControlProps extends any>(Control: React.ComponentType<ControlProps>) => {
  type Props = withBorderProps & ControlProps;

  return ({ classes, className, ...props }: Props) => {
    const { focused, disabled, error } = useFormControlState();

    const classNames = clsx(classes.root, className, {
      [classes.focused]: focused,
      [classes.error]: error,
      [classes.disabled]: disabled,
    });

    return (
      <div className={classNames}>
        <Control {...(props as ControlProps)} />
      </div>
    );
  };
};
