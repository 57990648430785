import { makeStyles } from '@material-ui/core';

const controlsWidth = 303;

export const useStyles = makeStyles(({ breakpoints }) => ({
  input: {
    [breakpoints.up('md')]: {
      width: controlsWidth,
    },
  },
  button: {
    [breakpoints.up('md')]: {
      width: controlsWidth,
    },
  },
  recaptcha: {
    '& iframe': {
      [breakpoints.up('md')]: {
        width: controlsWidth,
      },
    },
  },
}));
