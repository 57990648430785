import { styled, makeStyles } from '@material-ui/core';

import { Image } from 'shared/components/image/Image';

export const Container = styled('div')(({ theme: { breakpoints } }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [breakpoints.down('sm')]: {
    padding: '40px 0',
  },
}));

export const useStyles = makeStyles(({ breakpoints }) => ({
  [breakpoints.down('sm')]: {
    hideOnSM: {
      display: 'none',
    },
  },
}));

export const GraduationLogoContainer = styled('div')({
  height: 350,
});

export const GraduationLogoBox = styled('div')({
  position: 'relative',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
});

export const GraduationLogo = styled(Image)(({ theme: { breakpoints } }) => ({
  objectFit: 'contain',
  [breakpoints.down('sm')]: {
    height: 280,
  },
}));

export const ClientLogoContainer = styled('div')(({ theme: { breakpoints } }) => ({
  width: 270,
  height: 300,
  flexDirection: 'column',
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& img': {
    maxHeight: 80,
    maxWidth: '75%',
    marginBottom: 20,
  },
  [breakpoints.down('sm')]: {
    width: 220,
    height: 240,
    '& img': {
      maxWidth: '70%',
    },
  },
}));

export const Hashtag = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#bc9350',
  fontSize: '20px',
});
