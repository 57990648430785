import { styled, withStyles } from '@material-ui/core';

import { GridItem } from 'shared/components';
import { Container } from 'shared/components/container/Container';
import { Typography } from 'shared/components/typography/Typography';

export const StyledContainer = withStyles(({ breakpoints }) => ({
  root: {
    paddingTop: 72,
    paddingBottom: 236,
    [breakpoints.down('sm')]: {
      paddingTop: 36,
      paddingBottom: 36,
    },
  },
}))(Container);

export const FooterText = styled(Typography)(({ theme: { palette: { scorpionGray } } }) => ({
  textAlign: 'center',
  color: scorpionGray.main,
}));

export const LogoImage = styled('img')(({ theme: { breakpoints } }) => ({
  textAlign: 'center',
  maxHeight: 75,
  [breakpoints.down('sm')]: {
    maxHeight: 50,
  },
}));

export const LogoWrapper = styled(GridItem)(({ theme: { palette } }) => ({
  textAlign: 'center',
  color: palette.gray.main,
}));
