import { updatableContextFactory } from 'shared/utils/contextFactory/updatableContextFactory';
import { AVUserQueryContext } from './avUserQueryDataContext.types';

const [AVUserQueryDataProvider, useAVUserQueryDataContext, useUpdateAVUserQueryData] = updatableContextFactory<
  AVUserQueryContext
>({} as AVUserQueryContext);

export const useAVUserQueryData = () => {
  const context = useAVUserQueryDataContext();

  if (context === undefined) {
    throw new Error('useAVUserQueryData must be used within an AVUserQueryDataProvider');
  }

  return context;
};

export { AVUserQueryDataProvider, useUpdateAVUserQueryData };
