/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
// eslint-disable-next-line import/order
import 'react-h5-audio-player/lib/styles.css';
import { RHAP_UI } from 'react-h5-audio-player';
import { Button } from '@material-ui/core';

import { Icon } from 'shared/components';
import { RecordButton, AudioPlayerStyled } from './AudioRecorder.styles';
import { AudioPlaceholderProps } from './AudioRecoder.types';

export const AudioRecorder = ({
  mediaBlobUrl,
  status,
  pronunciationAudioURI,
  pronunciationAudio,
  startRecording,
  stopRecording,
  handleDelete,
}: AudioPlaceholderProps) => {
  return (
    <>
      {!pronunciationAudioURI && !pronunciationAudio && !mediaBlobUrl && (
        <div>
          {status !== 'recording' && (
            <RecordButton aria-label="delete" color="primary" onClick={startRecording}>
              <Icon icon="MicNone" />
            </RecordButton>
          )}
          {status === 'recording' && (
            <RecordButton aria-label="delete" color="primary" onClick={stopRecording}>
              <Icon icon="Stop" />
            </RecordButton>
          )}
        </div>
      )}
      {(!!pronunciationAudioURI || pronunciationAudio || mediaBlobUrl) && (
        <>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          {/* <audio controls src={mediaBlobUrl || pronunciationAudio || ''} /> */}
          <AudioPlayerStyled
            src={mediaBlobUrl || pronunciationAudioURI || pronunciationAudio || ''}
            showJumpControls={false}
            customAdditionalControls={[RHAP_UI.MAIN_CONTROLS]}
            customVolumeControls={[
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={handleDelete}
                // disabled={disabled}
              >
                <Icon icon="Delete" /> Delete
              </Button>,
            ]}
            customControlsSection={[
              RHAP_UI.MAIN_CONTROLS,
              <Button variant="contained" color="secondary" size="small" onClick={handleDelete}>
                <Icon icon="Delete" /> Delete
              </Button>,
            ]}
          />
        </>
      )}
    </>
  );
};
