import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles(({ breakpoints, spacing, palette }) => ({
  gridDetails: {
    [breakpoints.down('md')]: {
      paddingTop: spacing(1.9),
      width: '100%',
    },
    [breakpoints.up('lg')]: {
      paddingTop: spacing(6.25),
      width: '30%',
    },
    display: 'flex',
    flexDirection: 'column',
  },
  gridVideo: {
    [breakpoints.down('md')]: {
      width: '100%',
    },
    [breakpoints.up('lg')]: {
      paddingTop: spacing(0.13),
      width: '60%',
    },
    display: 'flex',
    flexDirection: 'column',
  },
  root1: {
    paddingRight: '8%',
    paddingLeft: '8%',
    paddingTop: '2%',
    width: '100%',
  },
  cardStyle1: {
    width: '100%',
  },
  rootLogo: {
    color: palette.black.main,
    cursor: 'pointer',
    marginTop: '35%',
    fontSize: spacing(4.25),
    display: 'flex',
    alignContent: 'flex-end',
    alignItem: 'flex-end',
  },
  rootText: {
    cursor: 'pointer',
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    fontSize: spacing(4.25),
    color: palette.black.main,
    marginTop: '0.5%',
    width: '100%',
  },
  root: {
    paddingRight: '10%',
    paddingLeft: '10%',
    paddingTop: '1%',
  },
  root3: {
    paddingRight: '0%',
    paddingLeft: '4%',
    paddingTop: '0%',
    paddingBottom: '20%',
  },
  grid1: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 'auto',
    [breakpoints.down('md')]: {
      paddingTop: '1%',
      paddingBottom: '5%',
    },
    [breakpoints.up('lg')]: {
      paddingTop: spacing(7.5),
      paddingBottom: '20%',
    },
  },
  grid2: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '0%',
    paddingLeft: '0%',
  },
  fontColored: {
    color: palette.green.dark,
    fontSize: spacing(2.7),
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
  },
  fontColoredError: {
    color: palette.green.dark,
    fontSize: spacing(2.7),
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
  },
  fontColorless: {
    display: 'flex',
    alignContent: 'left',
    justifyContent: 'left',
    color: palette.black.main,
    fontSize: spacing(2.7),
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
  },
  colorlessCircle: {
    display: 'flex',
    width: spacing(3),
    height: spacing(3),
    marginTop: spacing(0.63),
    backgroundColor: palette.gray.main,
    borderRadius: '50%',
  },
  colorCircle: {
    display: 'flex',
    width: spacing(3),
    height: spacing(3),
    marginTop: spacing(0.8),
    backgroundColor: palette.green.dark,
    borderRadius: '50%',
  },
  colorCircleError: {
    display: 'flex',
    width: spacing(3),
    height: spacing(3),
    marginTop: spacing(0.8),
    backgroundColor: palette.green.dark,
    borderRadius: '50%',
  },
  gride4: {
    [breakpoints.down('md')]: {
      paddingTop: spacing(1.3),
    },
    [breakpoints.up('lg')]: {
      paddingTop: spacing(6.3),
    },
  },
  buttonText: {
    color: palette.white.main,
    margin: 'auto',
  },
  videoText: {
    display: 'flex',
    fontSize: spacing(1.75),
    marginTop: spacing(1.25),
    fontFamily: 'Open Sans',
    marginLeft: spacing(1),
    flexDirection: 'row',
    gap: spacing(1.25),
  },
  videoName: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  videoSize: {
    width: '50%',
    fontWeight: 'bold',
  },
  videoText1: {
    fontSize: spacing(1.75),
    marginTop: spacing(1.75),
    fontFamily: 'Open Sans',
    marginLeft: spacing(1),
    gap: spacing(1.25),
  },
  doneButton: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    textTransform: 'none',
    backgroundColor: palette.green.dark,
    color: palette.white.dark,
    height: spacing(5),
    width: '100%',
    [breakpoints.down('md')]: {
      fontSize: spacing(1.9),
    },
    [breakpoints.up('lg')]: {
      fontSize: spacing(2),
    },
  },
  retryButton: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    textTransform: 'none',
    backgroundColor: palette.red.light,
    color: palette.white.main,
    height: spacing(5),
    width: '100%',
    [breakpoints.down('md')]: {
      fontSize: spacing(1.9),
    },
    [breakpoints.up('lg')]: {
      fontSize: spacing(2),
    },
  },
  box: {
    width: '80%',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'space-between',
  },
  buttonGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardStyle: {
    backgroundColor: palette.white.light,
  },
  gridStyle: {
    paddingBottom: '5%',
  },
}));
