export const regular = 400;
export const bold = 600;

export const typography = {
  fontFamily: '"Source Sans Pro", "Helvetica", "Arial", sans-serif',
  // h_44
  h1: {
    fontSize: 44,
    fontWeight: bold,
    lineHeight: '64px',
  },
  // h_32
  h2: {
    fontSize: 32,
    fontWeight: bold,
    lineHeight: '42px',
  },
  // h_24
  h3: {
    fontSize: 24,
    fontWeight: bold,
    lineHeight: '150%',
  },
  // sh_18
  h4: {
    fontSize: 18,
    fontWeight: bold,
    lineHeight: '27px',
  },
  // txt
  body1: {
    fontSize: 16,
    fontWeight: regular,
    lineHeight: '150%',
  },
  // MENU
  body2: {
    fontSize: 12,
    fontWeight: bold,
    lineHeight: '16px',
  },
  // button
  button: {
    fontSize: 16,
    fontWeight: bold,
    lineHeight: '32px',
    letterSpacing: '0.1em',
  },
  caption: {
    fontSize: 12,
    fontWeight: regular,
    lineHeight: '1.29',
    letterSpacing: 0.47,
  },
  // help
  subtitle: {
    fontSize: 11,
    lineHeight: '16px',
  },
};
