import { styled } from '@material-ui/core';

import { Paper } from 'shared/components/paper/Paper';

import { GridItem } from '..';

const padding = 28;
export const StyledPaper = styled(Paper)({
  padding,
});

export const Header = styled('div')({
  padding: `0 ${padding}px`,
});

export const Left = styled(GridItem)(({ theme: { breakpoints } }) => ({
  display: 'flex',
  alignItems: 'center',
  [breakpoints.down('xs')]: {
    flexDirection: 'column',
  },
}));

export const Right = styled(GridItem)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
});
