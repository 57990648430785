import { useContext } from 'react';

import { LocaleContext } from 'contexts/locale/localeContext/localeContext';

export const useLocale = () => {
  const context = useContext(LocaleContext);

  if (context === undefined) {
    throw new Error('useLocale must be used within an LocaleContext');
  }

  return context;
};
