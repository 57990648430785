import React, { useRef, useState } from 'react';
import { useField, FormSpy } from 'react-final-form';
import { useIntl } from 'react-intl';

import {
  Buttons,
  Details,
  DetailsIcon,
  EnhancementButtons,
  IconButton,
  Left,
  PhotoDetails,
  Right,
} from '../photo/Photo.styles';
import { ORIENTATIONS_COUNT } from '../photo/Photo.utils';

import { SectionMode } from 'shared/components/expandingSection/Section.types';
import { Button, Icon, Typography } from 'shared/components';
import { ExpandingSection } from 'shared/components/expandingSection/Section';
import { GridContainer, GridItem } from 'shared/components/grid';
import { Separator } from 'shared/components/separator/Separator';
import { AzureFileUpload } from 'shared/components/controls';
import { useParticipantQueryData } from 'contexts/participantQueryData/participantQueryDataContext';
import { useParticipantData } from 'contexts/participantData/participantDataContext';
import { createFetchUploadMeta } from 'api/actions/participant/participant.actions';
import { VideoRotation } from 'shared/components/videoRotation/VideoRotation';
import { VideoRotationProps } from 'shared/components/videoRotation/VideoRotation.types';
import { VideoProps } from './Video.types';
import { EditHeader } from './Video.styles';

const allowedExtensions = ['mp4', 'mov'];
export const VIDEO_INPUT_NAME = 'video';

const VideoPreview = (props: VideoRotationProps) => {
  const {
    input: { onChange },
  } = useField('rotation.video');

  return <VideoRotation {...props} onRotate={onChange} />;
};

const previews = {
  video: VideoPreview,
};

export const Video = ({ validate = [], setFileInfo, order, fieldHasValue }: VideoProps) => {
  const { formatMessage } = useIntl();
  const participantData = useParticipantData();
  const { participantId } = useParticipantQueryData();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [rotation, setRotation] = useState(0);
  const [sectionMode, setSectionMode] = useState<SectionMode>(SectionMode.Closed);
  const disabled = false;

  const handleRotation = (direction: 1 | -1) => {
    setRotation((prev: number) => {
      const next = prev + direction >= 0 ? prev + direction : ORIENTATIONS_COUNT - 1;

      return next % ORIENTATIONS_COUNT;
    });
  };

  const {
    input: { onChange },
  } = useField<File>('file', {
    subscription: { value: true, valid: true },
  });

  const handleDelete = () => {
    const input = fileInputRef.current;

    if (input) {
      input.value = '';
    }
    onChange('');
  };

  const handlePickNew = () => {
    const input = fileInputRef.current;

    if (input) {
      input.click();

      // if (onFocus) {
      //   onFocus();
      // }
    }
  };

  return (
    <FormSpy>
      {({ errors }) => (
        <ExpandingSection
          title="virtual_graduation.upload.video.title"
          subtitle="virtual_graduation.upload.video.subtitle"
          order={order}
          buttonName={fieldHasValue ? 'Edit' : 'Upload'}
          fieldHasValue={fieldHasValue}
          mode={sectionMode}
          setSectionMode={setSectionMode}
          isError={!!errors.file}
          showSocialIcons
          ariaLabelledby={VIDEO_INPUT_NAME}
          ariaLabelSaveButton={formatMessage({ id: 'virtual_graduation.upload.video.aria_label.save' })}
        >
          {sectionMode === SectionMode.Opened && <Separator height={25} />}
          <GridContainer>
            {/* TODO - align left image */}
            {/* TODO - video is not working in 3 width */}
            <Left xs={12} md={sectionMode === SectionMode.Edited || sectionMode === SectionMode.Closed ? 4 : 7}>
              <AzureFileUpload
                fetchUploadMetaAction={createFetchUploadMeta(participantId)}
                name="file"
                accept={allowedExtensions}
                maxSize="100 MB"
                maxDuration={participantData.fileDurationLimit}
                validate={validate}
                previews={previews}
                setFileInfo={setFileInfo}
                fileInputRef={fileInputRef}
                rotation={rotation}
                resetRotation={() => setRotation(0)}
                previewMode={sectionMode}
                type="video"
              />
              {sectionMode === SectionMode.Opened && (
                <PhotoDetails>
                  <Details>
                    <DetailsIcon icon="Movie" /> <span>File type: mp4, mov</span>
                  </Details>
                  <Details>
                    <DetailsIcon icon="Crop" /> <span>100 MB</span>
                  </Details>
                </PhotoDetails>
              )}
            </Left>
            <Right xs={12} md={5}>
              {sectionMode === SectionMode.Opened && (
                <GridContainer>
                  <GridItem xs={12}>
                    <EditHeader>Editing options</EditHeader>
                  </GridItem>
                  <GridItem xs={12}>
                    <Typography variant="subtitle1">Rotate</Typography>
                    <EnhancementButtons>
                      <IconButton
                        onClick={() => handleRotation(-1)}
                        disabled={!fieldHasValue}
                        aria-label={formatMessage({ id: 'virtual_graduation.upload.video.aria_label.rotate_left' })}
                      >
                        <Icon icon="RotateLeft" />
                      </IconButton>
                      <IconButton
                        onClick={() => handleRotation(1)}
                        disabled={!fieldHasValue}
                        aria-label={formatMessage({ id: 'virtual_graduation.upload.video.aria_label.rotate_right' })}
                      >
                        <Icon icon="RotateRight" />
                      </IconButton>
                      {/* <IconButton> */}
                      {/*  <Icon icon="SwapVert" /> */}
                      {/* </IconButton> */}
                      {/* <IconButton> */}
                      {/*  <Icon icon="SwapHoriz" /> */}
                      {/* </IconButton> */}
                    </EnhancementButtons>
                  </GridItem>
                  <GridItem xs={12}>
                    <Buttons>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={handlePickNew}
                        disabled={disabled}
                        aria-label={formatMessage({ id: 'virtual_graduation.upload.video.aria_label.select' })}
                      >
                        <Icon icon="Movie" /> Select new
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={handleDelete}
                        disabled={!fieldHasValue}
                        aria-label={formatMessage({ id: 'virtual_graduation.upload.video.aria_label.delete' })}
                      >
                        <Icon icon="Delete" /> Delete
                      </Button>
                    </Buttons>
                  </GridItem>
                </GridContainer>
              )}
            </Right>
          </GridContainer>
        </ExpandingSection>
      )}
    </FormSpy>
  );
};
