export const SET_LOADING = 'SET_LOADING';
export const SET_FILE_URI = 'SET_FILE_URI';
export const SET_ERROR = 'SET_ERROR';
export const SET_PROGRESS = 'SET_PROGRESS';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_VIDEO_URI = 'SET_VIDEO_URI';

type SetLoading = () => {
  type: typeof SET_LOADING;
};

type SetError<Payload = string> = (
  payload: Payload,
) => {
  type: typeof SET_ERROR;
  payload: Payload;
};

type SetProgress<Progress = number> = (
  progress: Progress,
) => {
  type: typeof SET_PROGRESS;
  payload: Progress;
};

type SetFileURI<URI = string> = (
  uri: URI,
) => {
  type: typeof SET_FILE_URI;
  payload: URI;
};

type SetVideoURI<URI = string> = (
  uri: URI,
) => {
  type: typeof SET_VIDEO_URI;
  payload: URI;
};

export const setLoading: SetLoading = () => ({
  type: SET_LOADING,
});

export const setError: SetError = payload => ({
  type: SET_ERROR,
  payload,
});

export const setProgress: SetProgress = progress => ({
  type: 'SET_PROGRESS',
  payload: progress,
});

export const setFileUri: SetFileURI = uri => ({
  type: SET_FILE_URI,
  payload: uri,
});

export const setVideoUri: SetVideoURI = uri => ({
  type: SET_VIDEO_URI,
  payload: uri,
});

export type Actions =
  | ReturnType<typeof setLoading>
  | ReturnType<typeof setError>
  | ReturnType<typeof setProgress>
  | ReturnType<typeof setVideoUri>
  | ReturnType<typeof setFileUri>;
