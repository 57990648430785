import { makeStyles } from '@material-ui/core';

// import { palette } from 'app/core/theme/palette';

export const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  card: {
    margin: 'auto',
    width: spacing(72),
    backgroundColor: palette.black.light,
    borderRadius: spacing(1.25),
    padding: spacing(2),
    [breakpoints.down('sm')]: {
      // Adjust styles for smaller screens
      width: '100%', // Set the width to 100% for smaller screens
    },
  },
  cardView: {
    margin: 'auto',
    width: spacing(72),
    height: spacing(36),
    backgroundColor: palette.black.light,
    borderRadius: spacing(1.25),
  },
  cardInnerParticipantName: {
    color: palette.white.main,
    fontSize: spacing(4),
    fontFamily: 'OpenSans',
    textAlign: 'center',
  },
  cardInnerParticipantRole: {
    color: palette.white.main,
    textAlign: 'center',
    fontSize: spacing(3),
    fontFamily: 'Roboto',
    fontStyle: 'italic',
  },
  Title: {
    fontSize: spacing(6),
    fontWeight: 'bold',
    lineHeight: 1.5,
    textAlign: 'center',
    color: palette.green.dark,
    margin: spacing(2, 0, 0, 0),
    // color: var(--fill-success-base);
    [breakpoints.down('sm')]: {
      fontSize: spacing(4),
      margin: spacing(1),
    },
  },
  rootText: {
    margin: spacing(3, 15.75, 0, 15.75),
    fontSize: spacing(6),
    fontWeight: spacing(87.5),
    // fontStretch: 'normal',
    fontStyle: 'OpenSans',
    // letterSpacing: 'normal',
    textAlign: 'center',
  },
  rootTextSub: {
    fontSize: spacing(6),
    fontWeight: spacing(87.5),
    // fontStretch: 'normal',
    fontStyle: 'OpenSans',
    // letterSpacing: 'normal',
    textAlign: 'center',
  },
  listenButtonContainer: {
    display: 'flex',
    fontFamily: 'Open Sans',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
    margin: spacing(2, 0, 0, 0),
  },
  listenButton: {
    marginTop: spacing(1),
    backgroundColor: palette.red.light,
    color: palette.white.main,
    textTransform: 'none',
    width: 'auto',
    '&:hover': {
      backgroundColor: palette.red.light,
    },
  },
  confirmButton: {
    marginTop: spacing(1),
    backgroundColor: palette.green.dark,
    color: palette.white.main,
    width: spacing(15),
    textTransform: 'none',
    margin: spacing(2),
    '&:hover': {
      backgroundColor: palette.green.dark,
    },
  },
  editButton: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    color: palette.green.dark,
    textTransform: 'none',
    marginTop: spacing(2),
    width: 'auto',
    borderColor: palette.black.light,
  },
  infoTextCard: {
    borderRadius: spacing(1.37),
    textAlign: 'center',
    backgroundColor: palette.gray.light,
    margin: 'auto',
    opacity: 0.5,
    padding: spacing(1.5),
    [breakpoints.down('sm')]: {
      borderRadius: spacing(1),
      padding: spacing(1),
    },
  },
  cardInnerInfoText: {
    color: palette.green.dark,
    textAlign: 'center',
    fontSize: spacing(3),
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    [breakpoints.down('sm')]: {
      fontSize: spacing(2.5),
    },
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: spacing(9),
    [breakpoints.down('sm')]: {
      marginBottom: spacing(5),
    },
  },
  clientText: {
    margin: spacing(3, 15.75, 3, 15.75),
    fontSize: spacing(4),
    fontWeight: spacing(87.5),
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    [breakpoints.down('sm')]: {
      fontSize: spacing(3),
      margin: spacing(2),
    },
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: spacing(4),
    justifyContent: 'center',
    margin: spacing(1, 4, 8, 4),
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      margin: spacing(1, 2, 4, 2),
    },
  },
  images: {
    margin: spacing(0, 6, 0, 6),
    [breakpoints.down('sm')]: {
      margin: spacing(2, 0),
    },
  },
  footer: {
    display: 'flex',
    position: 'absolute',
  },
  mainContainer: {
    // display: 'flex',
    position: 'relative',
  },
  listenButtonIcon: {
    marginRight: spacing(1),
    textTransform: 'none',
    color: palette.white.main,
  },
  editButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: spacing(1, 2, 1, 1),
  },
  editlistenButton: {
    // marginTop: spacing(1),
    backgroundColor: palette.red.light,
    color: palette.white.main,
    marginRight: spacing(1),
    fontFamily: 'Roboto',
    textTransform: 'none',
    width: 'auto',
    '&:hover': {
      backgroundColor: palette.red.light,
    },
  },
  editConfirmButton: {
    backgroundColor: palette.green.dark,
    color: palette.white.main,
    textTransform: 'none',
    width: 'auto',
    '&:hover': {
      backgroundColor: palette.green.dark,
    },
  },
  dividerLine: {
    border: 'solid 0.5px #e6e6e6',
    margin: spacing(0, 1, 0, 1),
  },
  dividerLineFirst: {
    border: 'solid 0.5px #e6e6e6',
    margin: spacing(1, 1, 0, 1),
  },
  editCardInnerParticipantRole: {
    justifyContent: 'flex-start',
    backgroundColor: palette.white.main,
    fontSize: spacing(2),
    margin: spacing(1, 1.5, 2, 1.5),
  },
  editCardInnerSelectLanguage: {
    justifyContent: 'flex-start',
    fontSize: spacing(2),
    backgroundColor: palette.white.main,
    margin: spacing(1, 1.5, 2, 1.5),
    '&: label': {
      backgroundColor: palette.white.main,
      color: palette.white.main,
    },
  },
  cardContainer: {
    margin: spacing(4, 0, 4, 0),
  },
  editCardInnerSelectLanguageMethod: {
    justifyContent: 'flex-start',
    fontSize: spacing(2),
    color: palette.white.main,
    margin: spacing(1, 1.5, 2, 1.5),
  },
  editCardInnerParticipantName: {
    color: palette.white.main,
    fontSize: spacing(4),
    fontFamily: 'OpenSans',
    textAlign: 'center',
  },
  formControl: {
    width: 'auto',
    color: palette.white.main,
    margin: spacing(1, 3.5, 2, 1.5),
  },
  editCardInnerParticipantRoleOne: {
    color: palette.white.main,
    fontSize: spacing(3),
    fontFamily: 'Roboto',
    textAlign: 'center',
    fontStyle: 'italic',
  },
  recorder: {
    color: palette.white.main,
    fontSize: spacing(3),
    fontFamily: 'Roboto',
    textAlign: 'center',
    fontStyle: 'italic',
    margin: spacing(6, 0, 8, 0),
  },
  content: {
    display: 'flex',
    flex: 1,
    margin: 20,
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  footerContainer: {
    '& footer': {
      position: 'relative',
    },
  },
  buttonMainContainer: {
    margin: spacing(3, 0, 1, 0),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: spacing(2),
  },
  labelTop: {
    marginLeft: spacing(1.5),
    fontFamily: 'Roboto',
    fontSize: spacing(2),
  },
  switchWithLabel: {
    display: 'flex',
    justifyContent: 'center',
    margin: spacing(1, 46, 0, 0),
    [breakpoints.down('sm')]: {
      margin: spacing(1, 2, 0, 2),
    },
  },
  audioContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: spacing(4),
  },
  audioRecorderConfirmButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  voiceRecordingText: {
    color: palette.white.main,
    fontSize: spacing(3),
    fontFamily: 'Roboto',
    textAlign: 'center',
  },
  isDisabled: {
    pointerEvents: 'none',
    opacity: 0.6,
    backgroundColor: palette.green.dark,
    color: palette.white.main,
  },
  phoneticLabel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  phoneticName: {
    marginLeft: '0.5rem',
  },
  phoneticText: {
    color: palette.white.main,
    fontSize: spacing(3),
    fontFamily: 'Roboto',
    textAlign: 'center',
    fontStyle: 'italic',
    [breakpoints.down('sm')]: {
      fontSize: spacing(3),
    },
  },
}));
