import React, { createContext, useEffect, useState } from 'react';
import { useQuery } from 'react-fetching-library';
import { Redirect } from 'react-router-dom';
import { hotjar } from 'react-hotjar';

import { UploadContainer } from '../upload/UploadContainer';

import { createFetchParticipantData } from 'api/actions/participant/participant.actions';
import { useParticipantQueryData } from 'contexts/participantQueryData/participantQueryDataContext';
import { useParticipantData, useUpdateParticipantData } from 'contexts/participantData/participantDataContext';
import { Loader } from 'shared/components/loader/Loader';
import { ParticipantDataResponse } from './Root.types';
import { IntroContainer } from './intro/IntroContainer';

export const SectionsContext = createContext<
  { openedSection: number; setOpenedSection: React.Dispatch<React.SetStateAction<number>> } | undefined
>(undefined);

export const Root = () => {
  const { participantId, edit, editToken } = useParticipantQueryData();
  const updateParticipantData = useUpdateParticipantData();
  const { loading, payload, error, query } = useQuery<ParticipantDataResponse>(
    createFetchParticipantData(participantId),
  );
  const shouldRedirectToUploaded = !(edit || editToken);
  const [openedSection, setOpenedSection] = useState(0);
  const { institution, attendance } = useParticipantData();

  useEffect(() => {
    if (process.env.REACT_APP_HOTJAR_ID) {
      hotjar.initialize(+process.env.REACT_APP_HOTJAR_ID, 6);
    }
  }, []);

  useEffect(() => {
    if (!loading && !error && !!payload) {
      updateParticipantData(payload);
    }
  }, [error, loading, payload, updateParticipantData]);

  if (loading) {
    return <Loader fullScreen withBackground />;
  }

  if (error || !payload) {
    return <Redirect to="/something-went-wrong" />;
  }

  if (shouldRedirectToUploaded && (payload.file || payload.message || payload.image)) {
    return <Redirect to="/uploaded" />;
  }

  if (!payload.active) {
    return <Redirect to="/expired" />;
  }

  return (
    <SectionsContext.Provider value={{ openedSection, setOpenedSection }}>
      <main>
        <IntroContainer refreshParticipant={() => query()} />
        {(!institution?.attendanceConfirmation || attendance !== null) && <UploadContainer />}
      </main>
    </SectionsContext.Provider>
  );
};
