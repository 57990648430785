import React from 'react';
import { FormattedMessage } from 'react-intl';
import throttle from 'lodash/throttle';

import { getFileType } from 'shared/utils';
import { DurationValidatorOptions } from './duration.validator.types';

const createHash = (file: File) => `${file.name}:${file.type}:${file.size}`;

const storage: { [key: string]: string } = {};

// create cache to stop creating new assets during validation
const getObjectURL = (file: File) => {
  const hash = createHash(file);

  if (!storage[hash]) {
    storage[hash] = URL.createObjectURL(file);
  }

  return storage[hash];
};

const validate = (element: HTMLVideoElement, config: DurationValidatorOptions, resolve: Function) => {
  const { duration: unsupportedVideoDuration, maxDuration } = config;
  const { duration: videoDuration } = element;
  const duration = videoDuration || unsupportedVideoDuration;

  if (duration && duration > maxDuration + 1) {
    return resolve(<FormattedMessage id="validation.duration" values={{ maxDuration: `${maxDuration}s` }} />);
  }

  return resolve();
};

export const duration = throttle(
  (config: DurationValidatorOptions) => (value: File) => {
    if (value && value instanceof File) {
      const fileType = getFileType(value);

      if (fileType && fileType === 'video') {
        const element = document.createElement('video');
        element.preload = 'metadata';

        const timeout: { id: ReturnType<typeof setTimeout> | undefined } = { id: undefined };

        const validator = new Promise(resolve => {
          element.onloadedmetadata = () => {
            if (timeout.id) {
              clearTimeout(timeout.id);
              timeout.id = undefined;
            }

            return validate(element, config, resolve);
          };
        });
        const fallback = new Promise(resolve => {
          timeout.id = setTimeout(() => validate(element, config, resolve), 600);
        });

        element.src = getObjectURL(value);

        // it is a fix for dangling validator promise which is not resolved properly (mainly for Edge browser)
        return Promise.race([validator, fallback]);
      }
    }

    return undefined;
  },
  1000,
);
