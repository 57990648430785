import { AVUserDataResponse } from 'app/avUserRoot/AVUserRoot.types';
import { updatableContextFactory } from 'shared/utils/contextFactory/updatableContextFactory';

const [AVUserDataProvider, useAvUserDataContext, useUpdateAVUserData] = updatableContextFactory<AVUserDataResponse>(
  {} as AVUserDataResponse,
);

export const useAvUserData = () => {
  const context = useAvUserDataContext();

  if (context === undefined) {
    throw new Error('useAVUserData must be used within an AVUserDataProvider');
  }

  return context;
};

export { AVUserDataProvider, useUpdateAVUserData };
