import { makeStyles, styled } from '@material-ui/core/styles';
import AudioPlayer from 'react-h5-audio-player';

import { IconButton } from 'shared/components/iconButton/IconButton';
import { StyledPaper } from 'shared/components/expandingSection/Section.styles';

export const useStyles = makeStyles(({ spacing, palette: { white, error }, shadows }) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: spacing(4, 0),
    height: spacing(16.25),
    color: white.main,
    borderRadius: spacing(0.75),
    boxShadow: shadows[2],
  },
  recordButton: {
    width: spacing(10.5),
    height: spacing(10.5),
    backgroundColor: error.main,
    color: white.main,
    '&:hover': {
      backgroundColor: 'rgb(223, 22, 66, 0.75)',
    },
  },
  //   audioPlayer: {
  //     '&.rhap_container': {
  //       boxShadow: 'none',
  //     },
  //     '& .rhap_progress-indicator': {
  //       background: greenishBlue.main,
  //     },
  //     '& .rhap_progress-filled': {
  //       background: greenishBlue.dark,
  //     },
  //     '& .rhap_controls-section': {
  //       display: 'flex',
  //       justifyContent: 'space-between',
  //     },
  //   },
}));

export const RecordButton = styled(IconButton)({
  width: 84,
  height: 84,
  backgroundColor: '#df1642',
  color: 'white',
  //  TODO - pozmieniac kolorki
  '&:hover': {
    backgroundColor: 'rgb(223, 22, 66, 0.75)',
  },
});

export const RecordNameContainer = styled(StyledPaper)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: 20,
});

export const AudioPlayerStyled = styled(AudioPlayer)({
  '&.rhap_container': {
    boxShadow: 'none',
  },
  '& .rhap_progress-indicator': {
    background: '#28abb9',
  },
  '& .rhap_progress-filled': {
    background: '#227b84',
  },
  '& .rhap_controls-section': {
    display: 'flex',
    justifyContent: 'space-between',
  },
});
