import React from 'react';
import { useIntl } from 'react-intl';

import { LocaleContext } from '../localeContext/localeContext';
import { Locale } from '../localeContext/localeContext.types';

import { LocaleContextProviderProps } from './LocaleContextProvider.types';

// TODO: how to fix locale;
export const LocaleContextProvider: React.FC<LocaleContextProviderProps> = ({ children, updateLocale }) => {
  const { locale, formatMessage } = useIntl();

  return (
    <LocaleContext.Provider
      value={{
        // @ts-ignore
        locale: Locale[locale],
        updateLocale,
        formatMessage,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};
