import React from 'react';

import { Icon } from 'shared/components';
import { InputAdornment } from 'shared/components/controls/inputAdornment/InputAdornment';
import { EndAdornmentProps } from './EndAdornment.types';

export const EndAdornment = ({ error, success }: EndAdornmentProps) => {
  const showEndAdornment = error || success;
  const adornmentProps = {
    success,
    error,
  };

  if (showEndAdornment === false) {
    return null;
  }

  return (
    <InputAdornment position="end" {...adornmentProps}>
      {error && <Icon icon="Info" />}
      {success && <Icon icon="CheckCircle" />}
    </InputAdornment>
  );
};
