import React from 'react';

import { Separator } from 'shared/components/separator/Separator';
import { Icon } from './SimplePageIcon.styles';
import { SimplePageIconProps } from './SimplePageIcon.types';

export const SimplePageIcon = ({ icon }: SimplePageIconProps) => {
  return (
    <>
      <Icon alt="" src={icon} />
      <Separator height={30} />
    </>
  );
};
