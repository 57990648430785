import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-fetching-library';
import { useIntl } from 'react-intl';

import { AVUserDataResponse, EventInformation } from '../AVUserRoot.types';

import { confirmAVUserUpload, getAVUserData } from 'api/actions/avUser/avUser.actions';
import { useAVUserQueryData } from 'contexts/avUserQueryData/avUserQueryDataContext';
import { useUpdateNotifications } from 'shared/components/notifications/notifications.context';
import { CollaboratorUpload } from './CollaboratorUpload';
import { CollaboratorPages, VideoFileInfo } from './CollaboratorUpload.types';
import { useActivityTrack } from 'shared/hooks/useActivityTrack/useActivityTrack';
import { ActivityTypes, EventVideoActivityCode } from 'api/activityTrack/activityTrack.types';

export const CollaboratorUploadContainer = () => {
  const updateNotifications = useUpdateNotifications();

  const [collaboratorPage, setCollaboratorPage] = useState<CollaboratorPages>(CollaboratorPages.upcomingEvent);
  const [eventDetails, setEventDetails] = useState<EventInformation | null>(null);
  const { avUserId } = useAVUserQueryData();
  const { payload, query } = useQuery<AVUserDataResponse>(getAVUserData(avUserId));
  const [videoFileInfo, setVideoFileInfo] = useState<VideoFileInfo | null>(null);
  const upcomingEvents = payload?.events;
  const [isDisable, setIsDisable] = useState(false);
  const { mutate } = useMutation(confirmAVUserUpload);
  const { onActivityTrack } = useActivityTrack();
  const { formatMessage } = useIntl();
  const [success, setSuccess] = useState(true);
  const isMediaUploadFailed = true;
  const [retryStep, setRetryStep] = useState(0);

  useEffect(() => {
    if (eventDetails) {
      const filteredData = payload?.events.filter(event => event.id === eventDetails.id);

      if (filteredData?.length) {
        setEventDetails(filteredData[0]);
      }
    }
  }, [eventDetails, payload]);

  // eslint-disable-next-line consistent-return
  const handleEventVideos = async () => {
    if (!eventDetails && !videoFileInfo) {
      return null;
    }
    let videoSize: string | undefined = '';

    if (videoFileInfo?.size !== undefined) {
      videoSize = videoFileInfo?.size;
    }
    const body = {
      eventId: eventDetails?.id || '',
      url: videoFileInfo?.fileUrl || '',
      name: videoFileInfo?.fileName || '',
      size: videoSize || '',
      length: videoFileInfo?.duration || '',
      avUserId,
      fileInfo: videoFileInfo?.fileInfo,
    };
    const response = await mutate(body);

    if (response.status === 200) {
      updateNotifications([
        {
          id: avUserId,
          message: formatMessage({ id: 'av_user.notification_message.upload_success' }),
          severity: 'success',
          open: true,
        },
      ]);
      const description = videoFileInfo?.fileName;
      onActivityTrack(
        ActivityTypes.EventVideo,
        EventVideoActivityCode.ProducerVideoUploaded,
        formatMessage({ id: 'av_user.activity_track_description.video_uploaded' }, { media: description }),
        eventDetails?.institution.name || '',
      );
      setRetryStep(0);
      await query();
      setIsDisable(true);
    } else {
      if (retryStep === 0) {
        setRetryStep(1);
      } else if (retryStep === 1) {
        setRetryStep(2);
      } else if (retryStep === 2) {
        setRetryStep(3);
      } else {
        setSuccess(false);
      }
      updateNotifications([
        {
          id: avUserId,
          message: formatMessage({ id: 'av_user.notification_message.unable_to_save' }),
          severity: 'error',
          open: true,
        },
      ]);
      setIsDisable(false);
    }
  };

  useEffect(() => {
    if (retryStep === 1 || retryStep === 2) {
      handleEventVideos();
    }
  }, [retryStep]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CollaboratorUpload
      isMediaUploadFailed={isMediaUploadFailed}
      collaboratorPage={collaboratorPage}
      setCollaboratorPage={setCollaboratorPage}
      avUserDetails={upcomingEvents}
      setEventDetails={setEventDetails}
      eventDetails={eventDetails}
      setVideoFileInfo={setVideoFileInfo}
      videoFileInfo={videoFileInfo}
      handleEventVideos={handleEventVideos}
      isDisable={isDisable}
      setIsDisable={setIsDisable}
      success={success}
    />
  );
};
