import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette, spacing }) => ({
  formContainer: {
    margin: 'auto',
    width: spacing(65),
    height: spacing(36),
    backgroundColor: palette.white.main,
    borderRadius: spacing(1.25),
    justifyContent: 'center',
    display: 'flex',
  },
  title: {
    fontSize: spacing(4),
    textAlign: 'center',
    fontWeight: spacing(87.5),
    color: palette.black.main,
    fontFamily: 'Open Sans',
  },
  subTitle: {
    fontSize: spacing(2),
    fontFamily: 'Roboto',
    textAlign: 'center',
    marginTop: spacing(2),
  },
  box: {
    position: 'absolute',
    width: 'auto',
    transform: 'scale(1.1)',
    backgroundColor: palette.white.main,
    border: '2px solid currentColor',
    boxShadow: spacing(0, 0.1, 1, 0),
  },
  dialogContainer: {
    padding: spacing(6),
  },
  icon: {
    fontFamily: 'AppleColorEmoji',
    height: spacing(5),
    fontSize: spacing(4),
    // display: 'flex',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    padding: spacing(5),
  },
}));
