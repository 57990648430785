import { withStyles } from '@material-ui/core';

import { Alert } from 'shared/components';

export const PageAlert = withStyles(({ palette, spacing }) => ({
  root: {
    padding: 28,
  },
  standardSuccess: {
    color: palette.green.main,
    fontSize: 16,
    padding: spacing(3),
    border: `1px solid ${palette.success.main}`,
  },
  standardWarning: {
    color: palette.black.main,
    fontSize: 16,
    padding: spacing(3),
    border: `1px solid ${palette.warning.main}`,
  },
  message: {
    display: 'inline',
  },
}))(Alert);
