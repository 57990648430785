import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  icon: {
    marginRight: '8px',
  },
});
