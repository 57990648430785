import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette, spacing, breakpoints, typography: { fontWeightBold, h3 } }) => ({
  card: {
    marginTop: spacing(2),
    width: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'left',
  },
  header: {
    fontSize: spacing(4.25),
    color: palette.black.main,
    fontWeight: spacing(87.5),
    fontFamily: 'Open Sans',
    width: 'auto',
    marginBottom: spacing(4),

    [breakpoints.only('sm')]: {
      fontSize: spacing(3),
      marginBottom: spacing(2),
    },

    [breakpoints.down('lg')]: {
      fontSize: h3.fontSize,
      lineHeight: h3.lineHeight,
    },
  },

  studentNameWrapper: {
    textAlign: 'center',
  },

  link: {
    fontWeight: fontWeightBold,
    display: 'inline-block',
    marginTop: spacing(1),
  },
  footerContainer: {
    '& footer': {
      position: 'relative',
    },
  },
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  rootText: {
    margin: spacing(3, 15.75, 0, 15.75),
    fontSize: spacing(4.25),
    fontWeight: spacing(87.5),
    fontFamily: 'Open Sans',
    textAlign: 'center',
    [breakpoints.down('sm')]: {
      fontSize: spacing(3),
      margin: spacing(2),
    },
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rootTextSub: {
    fontSize: spacing(4.25),
    fontWeight: spacing(87.5),
    fontFamily: 'Open Sans',
    textAlign: 'center',
    [breakpoints.down('sm')]: {
      fontSize: spacing(2.5),
    },
  },
  logoImage: {
    marginTop: spacing(3),
    height: spacing(20),
    [breakpoints.up('md')]: {
      marginLeft: spacing(0),
    },
    [breakpoints.down('sm')]: {
      marginTop: spacing(1.25),
      marginLeft: spacing(0),
    },
    textAlign: 'center',
  },
}));
