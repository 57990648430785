import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Container, CopyRightText } from './CopyRight.style';

export const CopyRight = () => {
  const { formatMessage } = useIntl();
  const year = useMemo(() => new Date().getFullYear(), []);

  return (
    <Container>
      <CopyRightText>{formatMessage({ id: 'virtual_graduation.footer.copyright' }, { year })} </CopyRightText>
      <CopyRightText>virtual_graduation.footer.all_rights</CopyRightText>
    </Container>
  );
};
