import { Card, Link, Typography } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';

import { useStyles } from './FaqSection.styles';

export const FaqSection = () => {
  const styles = useStyles();
  const { formatMessage } = useIntl();

  return (
    <>
      <Typography className={styles.title}>{formatMessage({ id: 'main_page.faqSection_page.title' })}</Typography>
      <div className={styles.cardSet}>
        <div className={styles.raw}>
          <div className={styles.column}>
            <Card className={styles.card}>
              <div className={styles.cardContent}>
                <div className={styles.cardRaw}>
                  <Typography className={styles.value}>
                    {formatMessage({ id: 'main_page.faqSection_page.value' })}
                  </Typography>
                </div>
                <Typography className={styles.passage}>
                  {formatMessage({ id: 'main_page.faqSection_page.passage' })}
                </Typography>
              </div>
            </Card>

            <Card className={styles.card}>
              <div className={styles.cardContent}>
                <div className={styles.cardRaw}>
                  <Typography className={styles.value}>
                    {formatMessage({ id: 'main_page.faqSection_page.value1' })}{' '}
                  </Typography>
                </div>
                <Typography className={styles.passage}>
                  {formatMessage({ id: 'main_page.faqSection_page.passage1' })}
                  <Typography
                    className={styles.talkstageclip}
                    onClick={() => {
                      window.location.href = 'mailto:talk@stageclip.com';
                    }}
                  >
                    {formatMessage({ id: 'main_page.faqSection_page.passage1Link' })}
                  </Typography>
                </Typography>
              </div>
            </Card>
          </div>

          <div className={styles.column}>
            <Card className={styles.cardTwo}>
              <div className={styles.cardContent}>
                <div className={styles.cardRaw}>
                  <Typography className={styles.valueTwo}>
                    {formatMessage({ id: 'main_page.faqSection_page.value2' })}
                  </Typography>
                </div>
                <Typography className={styles.passageTwo}>
                  {formatMessage({ id: 'main_page.faqSection_page.passage2' })}
                </Typography>
              </div>
            </Card>

            <Card className={styles.cardTwo}>
              <div className={styles.cardContent}>
                <div className={styles.cardRaw}>
                  <Typography className={styles.valueTwo}>
                    {formatMessage({ id: 'main_page.faqSection_page.value3' })}
                  </Typography>
                </div>
                <Typography className={styles.passageTwo}>
                  {formatMessage({ id: 'main_page.faqSection_page.passage3' })}
                </Typography>
              </div>
            </Card>
          </div>

          <div className={styles.column}>
            <Card className={styles.cardThree}>
              <div className={styles.cardContent}>
                <div className={styles.cardRaw}>
                  <Typography className={styles.value}>
                    {formatMessage({ id: 'main_page.faqSection_page.value4' })}
                  </Typography>
                </div>
                <Typography className={styles.passage}>
                  {formatMessage({ id: 'main_page.faqSection_page.passage4' })}
                </Typography>
              </div>
            </Card>

            <Card className={styles.cardThree}>
              <div className={styles.cardContent}>
                <div className={styles.cardRaw}>
                  <Typography className={styles.value}>
                    {formatMessage({ id: 'main_page.faqSection_page.value5' })}
                  </Typography>
                </div>
                <Typography className={styles.passage}>
                  {formatMessage({ id: 'main_page.faqSection_page.passage5' })}
                  <Link className={styles.talkstageclip} href="https://stageclip.com/privacy-policy/" target="_blank">
                    {formatMessage({ id: 'main_page.faqSection_page.passage5Link' })}
                  </Link>
                </Typography>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};
