import React from 'react';
import { Card, Typography } from '@material-ui/core';

import { Image } from 'shared/components/image/Image';
import uploadIcon from 'assets/icons/Upload-Icon1.svg';
import { Loader } from 'shared/components/loader/Loader';
import { useStyles } from './AvUserFileUploadLinkLoading.styles';
import { AvUserFileUploadLinkLoadingProps } from './AvUserFileUploadLinkLoading.types';

export const AvUserFileUploadLinkLoading = ({ status }: AvUserFileUploadLinkLoadingProps) => {
  const styles = useStyles();

  return (
    <div>
      <Card className={styles.root}>
        <Image src={uploadIcon} alt="" />
        <Typography>{status}</Typography>
        <Loader />
      </Card>
    </div>
  );
};
