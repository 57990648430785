import React, { useRef, useState } from 'react';
import { useField, FormSpy } from 'react-final-form';
import { useIntl } from 'react-intl';

import { ExpandingSection } from 'shared/components/expandingSection/Section';
import { Separator } from 'shared/components/separator/Separator';
import { AzureFileUpload } from 'shared/components/controls';
import { useParticipantQueryData } from 'contexts/participantQueryData/participantQueryDataContext';
import { createFetchUploadMeta } from 'api/actions/participant/participant.actions';
import { BlobImageRotation } from 'shared/components/blobImageRotation/BlobImageRotation';
import { BlobImageRotationProps } from 'shared/components/blobImageRotation/BlobImageRotation.types';
import { SlideProps } from './Photo.types';
import 'react-image-crop/dist/ReactCrop.css';
import {
  Buttons,
  Details,
  DetailsIcon,
  EnhancementButtons,
  IconButton,
  Left,
  PhotoDetails,
  PrettoSlider,
  SliderWrapper,
  Right,
  Scale,
  EditHeader,
} from './Photo.styles';
import { GridContainer, GridItem } from 'shared/components/grid';
import { Button, Icon, Typography } from 'shared/components';
import { ORIENTATIONS_COUNT, PHOTO_INPUT_NAME } from './Photo.utils';
import { SectionMode } from 'shared/components/expandingSection/Section.types';
import { useDebounce } from './useDebounce';

const allowedExtensions = ['jpg', 'png', 'jpeg'];

const ImagePreview = (props: BlobImageRotationProps) => {
  const {
    input: { onChange },
  } = useField('rotation.image');

  const {
    input: { onChange: onCropChange },
  } = useField('crop.image');

  return <BlobImageRotation {...props} onRotate={onChange} onCrop={onCropChange} />;
};

const previews = {
  image: ImagePreview,
};

export const Photo = ({ validate, order, fieldHasValue }: SlideProps) => {
  const { formatMessage } = useIntl();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { participantId } = useParticipantQueryData();
  const [scale, setScale] = useState<number>(0);
  const [rotation, setRotation] = useState(0);
  const [sectionMode, setSectionMode] = useState<SectionMode>(SectionMode.Closed);
  const disabled = false;
  const debouncedScale = useDebounce(scale, 300);

  const handleRotation = (direction: 1 | -1) => {
    setRotation((prev: number) => {
      const next = prev + direction >= 0 ? prev + direction : ORIENTATIONS_COUNT - 1;

      return next % ORIENTATIONS_COUNT;
    });
  };

  const {
    input: { onChange },
  } = useField<File>('image', {
    subscription: { value: true, valid: true },
  });

  const handleDelete = () => {
    const input = fileInputRef.current;

    if (input) {
      input.value = '';
    }
    setScale(0);
    onChange('');
  };

  const handlePickNew = () => {
    const input = fileInputRef.current;

    if (input) {
      input.click();
    }
  };

  return (
    <FormSpy>
      {({ errors }) => (
        <ExpandingSection
          title="virtual_graduation.upload.photo.title"
          subtitle="virtual_graduation.upload.photo.subtitle"
          order={order}
          buttonName={fieldHasValue ? 'Edit' : 'Upload'}
          fieldHasValue={fieldHasValue}
          mode={sectionMode}
          setSectionMode={setSectionMode}
          isError={!!errors.image}
          // onSave={() => setIsSaved(val => !val)}
          ariaLabelledby={PHOTO_INPUT_NAME}
          ariaLabelSaveButton={formatMessage({ id: 'virtual_graduation.upload.photo.aria_label.save' })}
        >
          {sectionMode === SectionMode.Opened && <Separator height={25} />}
          <GridContainer>
            {/* <GridContainer justify="center" spacing={5}> */}
            {/* TODO - align left image */}
            <Left xs={12} md={sectionMode === SectionMode.Edited || sectionMode === SectionMode.Closed ? 4 : 7}>
              <AzureFileUpload
                fetchUploadMetaAction={createFetchUploadMeta(participantId)}
                name="image"
                accept={allowedExtensions}
                maxSize="20 MB"
                validate={validate}
                previews={previews}
                fileInputRef={fileInputRef}
                rotation={rotation}
                resetRotation={() => setRotation(0)}
                previewMode={sectionMode}
                scale={debouncedScale}
                resolution={{ width: 700, height: 700 }}
                type="photo"
              />
              {sectionMode === SectionMode.Opened && (
                <PhotoDetails>
                  <Details>
                    <DetailsIcon icon="Image" /> <span>File type: jpg, jpeg, png</span>
                  </Details>
                  <Details>
                    <DetailsIcon icon="Crop" /> <span>20MB</span>
                  </Details>
                </PhotoDetails>
              )}
            </Left>
            <Right xs={12} md={5}>
              {sectionMode === SectionMode.Opened && (
                <GridContainer>
                  <GridItem xs={12}>
                    <EditHeader>Editing options</EditHeader>
                  </GridItem>
                  <SliderWrapper xs={12}>
                    <Scale>
                      <Typography variant="subtitle1">Scale</Typography>
                      <Typography variant="subtitle1" id="pretto-slider" aria-valuetext={String(scale)}>
                        {scale}%
                      </Typography>
                    </Scale>
                    <PrettoSlider
                      defaultValue={scale}
                      aria-label={formatMessage({
                        id: 'virtual_graduation.upload.photo.aria_label.slider_thumb_aria_label',
                      })}
                      aria-labelledby="pretto-slider"
                      onChange={(_, value) => setScale(+value)}
                      value={scale}
                    />
                  </SliderWrapper>
                  <GridItem xs={12}>
                    <Typography variant="subtitle1">Rotate</Typography>
                    <EnhancementButtons>
                      <IconButton
                        onClick={() => handleRotation(-1)}
                        disabled={!fieldHasValue}
                        aria-label={formatMessage({ id: 'virtual_graduation.upload.photo.aria_label.rotate_left' })}
                      >
                        <Icon icon="RotateLeft" />
                      </IconButton>
                      <IconButton
                        onClick={() => handleRotation(1)}
                        disabled={!fieldHasValue}
                        aria-label={formatMessage({ id: 'virtual_graduation.upload.photo.aria_label.rotate_right' })}
                      >
                        <Icon icon="RotateRight" />
                      </IconButton>
                      {/* <IconButton> */}
                      {/*  <Icon icon="SwapVert" /> */}
                      {/* </IconButton> */}
                      {/* <IconButton> */}
                      {/*  <Icon icon="SwapHoriz" /> */}
                      {/* </IconButton> */}
                    </EnhancementButtons>
                  </GridItem>
                  <GridItem xs={12}>
                    <Buttons>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={handlePickNew}
                        disabled={disabled}
                        aria-label={formatMessage({ id: 'virtual_graduation.upload.photo.aria_label.select' })}
                      >
                        <Icon icon="Image" /> Select new
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={handleDelete}
                        disabled={!fieldHasValue}
                        aria-label={formatMessage({ id: 'virtual_graduation.upload.photo.aria_label.delete' })}
                      >
                        <Icon icon="Delete" /> Delete
                      </Button>
                    </Buttons>
                  </GridItem>
                </GridContainer>
              )}
            </Right>
          </GridContainer>
        </ExpandingSection>
      )}
    </FormSpy>
  );
};
