import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import isAfter from 'date-fns/isAfter';
import parseISO from 'date-fns/parseISO';
import { FormSpy } from 'react-final-form';

import { NameRecordings, ProviderType } from 'app/root/Root.types';
import logo from 'assets/clipping_platform_logo.png';
import { StyledContainer, FooterText, LogoImage, LogoWrapper } from './Upload.styles';
import { useParticipantQueryData } from 'contexts/participantQueryData/participantQueryDataContext';
import { useParticipantData } from 'contexts/participantData/participantDataContext';
import { SnapcodeContainer } from './snapcode/SnapcodeContainer';
import { Video } from './video/Video';
import { Separator } from 'shared/components/separator/Separator';
import { GridContainer, GridItem } from 'shared/components/grid';
import { Form } from 'shared/components/form/Form';
import { Photo } from './photo/Photo';
import { CreateUploadParticipantDataBody } from 'api/actions/participant/participant.types';
import { UploadProps } from './Upload.types';
import { Message } from './message/Message';
import { PlayName } from './playName/PlayName';
import { Terms } from './terms/Terms';
import { RecordName } from './recordName/RecordName';

export const Upload = ({ onSubmit }: UploadProps) => {
  const { formatMessage } = useIntl();
  const {
    slide,
    message,
    file,
    image,
    deadlineDate,
    fullName,
    producerLanguageInfo,
    pronunciationName,
    languageId,
    voiceActorId,
    pronunciationAudio,
    providerType,
    nameRecordings,
  } = useParticipantData();
  const { participantId, editToken } = useParticipantQueryData();
  const [fileInfo, setFileInfo] = useState();
  const validateDeadline = useCallback(() => {
    if (isAfter(new Date(), parseISO(deadlineDate)) && !editToken) {
      return formatMessage({ id: 'validation.upload_forbidden' });
    }

    return undefined;
  }, [deadlineDate, formatMessage, editToken]);

  return (
    <>
      <SnapcodeContainer />
      <StyledContainer>
        <Form<CreateUploadParticipantDataBody>
          onSubmit={onSubmit(fileInfo)}
          subscription={{}}
          initialValues={{
            participantId,
            file,
            ...(slide ? { message, image } : {}),
            terms: false,
            pronunciationName: pronunciationName || fullName,
            languageId: languageId || (producerLanguageInfo && producerLanguageInfo[0]?.languageId),
            voiceActorId: voiceActorId || undefined,
            pronunciationAudio,
            playNameEdited: false,
          }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} id="upload-form">
              <FormSpy>
                {({ values }) => (
                  <>
                    {slide && <Photo validate={[validateDeadline]} order={1} fieldHasValue={!!values.image} />}
                    <Video
                      validate={[validateDeadline]}
                      setFileInfo={setFileInfo}
                      order={slide ? 2 : 1}
                      fieldHasValue={!!values.file}
                    />
                    {slide && <Message validate={[validateDeadline]} order={3} fieldHasValue={!!values.message} />}
                    {nameRecordings === NameRecordings.AudioAi && providerType === ProviderType.StageClip && (
                      <PlayName validate={[validateDeadline]} order={slide ? 4 : 2} isEdited />
                    )}
                    {nameRecordings === NameRecordings.MarchingOrder && (
                      <RecordName order={slide ? 4 : 2} fieldHasValue={!!values.pronunciationAudio} />
                    )}
                    <Terms />
                  </>
                )}
              </FormSpy>
              <Separator height={30} />
              <GridContainer justify="center">
                <GridItem xs={12} md={8}>
                  <FooterText variant="body2">virtual_graduation.upload.disclaimer</FooterText>
                </GridItem>
                <GridItem xs={12} md={8}>
                  <Separator height={50} />
                  <FooterText variant="body2">Powered by</FooterText>
                </GridItem>
                <LogoWrapper xs={12} md={8}>
                  <LogoImage src={logo} alt="" />
                </LogoWrapper>
              </GridContainer>
            </form>
          )}
        />
      </StyledContainer>
    </>
  );
};
