import React, { useState } from 'react';
import { useMutation } from 'react-fetching-library';
import { useHistory } from 'react-router';
import { Snackbar } from '@material-ui/core';

import { useLogin } from 'api/actions/login/login.actions';
import { StudentLoginForm } from './studentLoginForm';
import { handleOnSubmit } from 'shared/utils/handleOnSubmit/handleOnSubmit';
import { AppRoute } from 'app/routing/AppRoute.enum';
import { LoginResponse } from 'api/actions/login/login.types';
import { useStyles } from './studentLoginForm.styles';
import { useUpdateParticipantQueryData } from 'contexts/participantQueryData/participantQueryDataContext';

export const StudentLoginFormContainer = () => {
  const { mutate } = useMutation(useLogin);
  const { push } = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const styles = useStyles();

  const updateParticipantQueryData = useUpdateParticipantQueryData();

  const handleSuccessCallback = (value: LoginResponse) => {
    if (value.isParticipantFound) {
      updateParticipantQueryData({
        participantId: value.participantId,
        secret: value.token,
        editToken: value.editToken,
        edit: true,
      });
      push(AppRoute.studentNameListenerEdit);
    } else if (value.message) {
      setIsOpen(true);
      setMessage(value?.message);
    }
    setIsOpen(true);
  };

  const onSubmit = handleOnSubmit({
    action: values => mutate(values),
    successCallback: value => handleSuccessCallback(value),
  });

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <StudentLoginForm onSubmit={onSubmit} />
      <Snackbar
        open={isOpen}
        onClose={handleClose}
        autoHideDuration={3000}
        message={`${message || `Try using the email address you received this link at`}`}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        className={styles.snackBar}
      />
    </>
  );
};
