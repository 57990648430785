import { styled, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { luminance } from './ColorPicker.utils';

const showBorder = (rawColor: string) => {
  const color = rawColor.toLowerCase();

  return color === '' ? true : luminance(rawColor) > 0.85;
};

export const Preview = styled('div')({
  display: 'flex',
});

export const PreviewHex = styled('div')({
  marginLeft: 10,
  textTransform: 'uppercase',
  cursor: 'pointer',
  fontSize: 13,
});

export const PreviewHexEmpty = styled(PreviewHex)({
  textTransform: 'none',
});

const size = 19;
export const PreviewColor = styled('div')<Theme, { color: string }>(({ theme: { palette, transitions }, color }) => ({
  width: size,
  height: size,
  borderRadius: '100%',
  background: color,
  border: `1px solid ${showBorder(color) ? palette.gray.main : 'transparent'}`,
  cursor: 'pointer',
  transition: transitions.create(['border']),
}));

export const ColorPickerWrapper = styled('div')({
  margin: '0 5px',
});

export const useStyles = makeStyles(({ palette }) => ({
  open: {
    borderColor: palette.green.main,
  },
}));
