import { styled } from '@material-ui/core';

import { Container } from 'shared/components/container/Container';

export const StyledContainer = styled(Container)({
  paddingTop: 72,
  paddingBottom: 72,
});

export const Wrapper = styled('div')(({ theme: { breakpoints } }) => ({
  display: 'flex',
  [breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));
