import React from 'react';
import { MessageDescriptor } from 'react-intl';
import { PrimitiveType } from 'intl-messageformat';

export enum Locale {
  en = 'en',
  fr = 'fr',
}

export type UpdateLocale = (locale: Locale) => void;

export type Values = Record<string, PrimitiveType | React.ReactElement>;

export type FormatMessage = (descriptor: MessageDescriptor, values?: Values) => string | React.ReactNode;

export type LocaleContextType = {
  formatMessage: FormatMessage;
  locale: Locale;
  updateLocale: UpdateLocale;
};
