import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-fetching-library';

import { avUserUploadExternal } from 'api/actions/avUser/avUser.actions';
import { UploadExternalStatus } from 'api/actions/avUser/avUser.types';
import { useAVUserQueryData } from 'contexts/avUserQueryData/avUserQueryDataContext';
import { AvUserFileUploadLinkLoadingContainerProps } from './AvUserFileUploadLinkLoading.types';
import { AvUserFileUploadLinkLoading } from './AvUserFileUploadLinkLoading';

export const AvUserFileUploadLinkLoadingContainer = ({
  jobid,
  setSuccess,
  setVideoFileInfo,
  setstop,
}: AvUserFileUploadLinkLoadingContainerProps) => {
  const { avUserId } = useAVUserQueryData();
  const body = { jobId: jobid, avUserId };
  const { payload, query } = useQuery(avUserUploadExternal(body));
  const [status, setStatus] = useState('Initiating...');

  const filterStatus = (initialstatus: UploadExternalStatus) => {
    switch (initialstatus) {
      case UploadExternalStatus.InQueue:
        return 'Initiating..';
      case UploadExternalStatus.Downloading:
        return 'Downloading..';
      case UploadExternalStatus.Downloaded:
        return 'Downloaded';
      case UploadExternalStatus.Uploading:
        return 'Uploading..';
      case UploadExternalStatus.Uploaded:
        return 'Completed';
      default:
        return '';
    }
  };
  useEffect(() => {
    if (payload) {
      const filteredStatus = filterStatus(payload?.externalVideoUploadingStatus);
      setStatus(filteredStatus);
    }

    if (!payload || payload?.externalVideoUploadingStatus !== UploadExternalStatus.Uploaded) {
      setTimeout(() => {
        query();
      }, 10000);
    }

    if (payload?.externalVideoUploadingStatus === UploadExternalStatus.Uploaded) {
      setSuccess(true);
      setstop(true);
      setVideoFileInfo({
        duration: payload.data.length,
        fileName: payload.name,
        fileUrl: 'true',
        size: payload.data.size,
        videoUrl: payload.url,
      });
    }
  }, [payload, query, setSuccess, setVideoFileInfo, setstop]);

  return <AvUserFileUploadLinkLoading status={status} />;
};
