import React from 'react';
import Grid from '@material-ui/core/Grid';

import { useParticipantData } from 'contexts/participantData/participantDataContext';
import { HeaderContainer, Logo, useStyles, ContainerGrid } from './Header.styles';
import clippingPlatformLogo from 'assets/clipping_platform_logo.png';

// const defaultNav = <Nav link={Link} select={LanguageSelect} />;

export const Header = () => {
  const classes = useStyles();
  const { logo, institution } = useParticipantData();

  return (
    <HeaderContainer component="header">
      <ContainerGrid container>
        <Grid item xs={12} className={classes.logo}>
          <Logo
            alt={institution?.logoUrl && institution?.name ? institution.name : 'StageClip'}
            src={institution?.logoUrl || logo || clippingPlatformLogo}
          />
        </Grid>
      </ContainerGrid>
    </HeaderContainer>
  );
};
