import React from 'react';
import { Typography, Button, Grid, Card } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import { useIntl } from 'react-intl';

import { CollaboratorPages } from '../../CollaboratorUpload.types';

import { useStyles, BorderLinearProgress, BorderLinearProgressFail } from './MediaUploading.style';
import { MediaUploadingProps } from './MediaUploading.types';
import uploadIcon from 'assets/icons/Upload-Icon1.svg';

export const MediaUploading = ({
  isMediaUploadFailed,
  progress,
  fileDetails,
  setCollaboratorPage,
}: MediaUploadingProps) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  window.onbeforeunload = () => {
    return formatMessage({ id: 'av_user.notification_message.notification_message' });
  };

  return (
    <Card elevation={0} className={classes.root}>
      <Grid container className={classes.grid}>
        {isMediaUploadFailed ? (
          <Card elevation={1} className={classes.uploadFail}>
            <div className={classes.divStyle}>
              <ErrorOutline className={classes.ErrorLogo} />
              <Typography className={classes.uploadContent}>
                {formatMessage({ id: 'av_user.upload_media.media_uploading.upload_failed' })}
              </Typography>
              <BorderLinearProgressFail variant="determinate" value={100} />
              <Typography className={classes.videoInfo}>{fileDetails?.name}</Typography>
              <Button
                type="button"
                variant="contained"
                className={classes.button}
                onClick={() => {
                  setCollaboratorPage(CollaboratorPages.upcomingEvent);
                }}
              >
                {formatMessage({ id: 'av_user.confirm.retry' })}
              </Button>
            </div>
          </Card>
        ) : (
          <Card elevation={1} className={classes.upload}>
            <img src={uploadIcon} alt="icon" className={classes.uploadLogo} />
            <Typography className={classes.uploadContent}>
              {progress?.toFixed(0) || 0}
              {formatMessage({ id: 'av_user.upload_media.media_uploading.uploaded' })}
            </Typography>
            <BorderLinearProgress variant="determinate" value={progress} classes={{ bar: 'bg-primary' }} />
            <Typography className={classes.videoInfo}>{fileDetails?.name}</Typography>
          </Card>
        )}
      </Grid>
    </Card>
  );
};
