import { Action } from 'api/types';
import { FileInfo } from 'shared/components/controls/azureFileUpload/AzureFileUpload.types';

export type AVUserDataBody = {
  events?: eventDetails[];
  user_id?: string;

  file?: File | string | null;
  fileURI?: string | null;
  fileInfo?: FileInfo;
};

export type EventVideos = {
  url: string;
  thumbnailUrl: string | null;
  id: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  data: string;
};

type eventDetails = {
  avUserId: string;
  email: string;
  eventVideos: EventVideos[];
  name: string | null;
  state: string | null;
  startTime: string;
  institution: Institution;
  liveAndHybridEvent: LiveAndHybridEvent;
};

export type Institution = {
  id: string;
  name: string;
};

export type LiveAndHybridEvent = {
  address: string;
  id: string;
  location: string;
};

export type GetAVUserData = (avUserId: string) => Action;

export type CreateFetchAVUserUpload = (
  uploadDetails: UploadDetails,
) => (fileExtension: string, fileName: string) => Action;

export type UploadDetails = {
  avUserId: string;
  eventId: string;
};

export type AVUserUploadBody = {
  eventId: string;
  url: string;
  name: string;
  avUserId: string;
  size: string;
  length: string;
};

export type AvUserUploadExternalBody = {
  videoUrl: string;
  eventId: string;
  avuserId: string;
};
export type AvUserUploadExternalLinkBody = {
  jobId: string;
  avUserId: string;
};
export enum UploadExternalStatus {
  InQueue = 'queue',
  Downloading = 'downloading',
  Downloaded = 'downloaded',
  Uploading = 'uploading',
  Uploaded = 'uploaded',
}
export type AvUserUploadExternalLink = (body: AvUserUploadExternalLinkBody) => Action;

export type AvUserUploadExternal = (body: AvUserUploadExternalBody) => Action;

export enum EventState {
  WaitingForAssets = 'Waiting for Assets',
  ReadyToClip = 'Ready to Clip',
  ClippingInProgress = 'Clipping in Progress',
  ReviewNeeded = 'Review Needed',
  Complete = 'Complete',
  Cancelled = 'Cancelled',
}

export type ConfirmAVUserUpload = (body: AVUserUploadBody) => Action;
