import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
  row: {
    display: 'flex',
    flex: 'raw',
    height: '60vh',
    width: '100%',
    [breakpoints.down('sm')]: {
      textAlign: 'center',
      flexDirection: 'column',
      marginLeft: 'auto',
      marginRight: 'auto',
      height: '90vh',
    },
  },
  column: {
    width: '50%',
    textAlign: 'left',
    [breakpoints.down('sm')]: {
      width: '90%',
      marginTop: '3vh',
      marginLeft: '3vw',
    },
  },
  video: {
    width: '35vw',
    height: '45vh',
    marginLeft: '10vw',
    [breakpoints.down('sm')]: {
      width: '92vw',
      height: '40vh',
      marginLeft: '1vw',
    },
  },
  topic: {
    fontFamily: ' Open Sans',
    fontSize: spacing(4.25),
    fontWeight: 'bold',
    marginBottom: '5vh',
    marginTop: '5vh',
    [breakpoints.down('sm')]: {
      width: '90vw',
      height: spacing(12),
      marginLeft: spacing(0.625),
    },
  },
  content: {
    width: '34vw',
    height: spacing(12),
    fontFamily: ' Roboto ',
    fontSize: spacing(2),
    [breakpoints.down('md')]: {
      width: '50vw',
    },
    [breakpoints.down('sm')]: {
      fontSize: spacing(1.75),
      width: '90vw',
      height: spacing(18),
      marginLeft: spacing(0.625),
    },
  },
  buttonupload: {
    fontFamily: 'Roboto bold',
    backgroundColor: palette.green.dark,
    color: palette.white.main,
    fontWeight: 'bold',
    marginTop: '3vh',
    borderRadius: spacing(0.5),
    height: spacing(6),
    textTransform: 'initial',
    [breakpoints.down('sm')]: {
      Left: spacing(2),
      marginTop: spacing(0),
      marginLeft: spacing(0.625),
    },
    '&:hover': {
      backgroundColor: palette.scorpionGray.dark,
    },
  },
}));
