import { makeStyles } from '@material-ui/core/styles';

export const HeaderStyle = makeStyles(({ breakpoints, spacing, palette }) => ({
  divRoot: {
    flexGrow: 1,
  },
  root: {
    backgroundColor: palette.white.main,
    [breakpoints.down('xs')]: {
      height: spacing(7),
    },
    [breakpoints.up('sm')]: {
      height: spacing(8),
    },
    [breakpoints.up('md')]: {
      height: spacing(10),
    },
  },
  logo: {
    display: 'flex',
    [breakpoints.down('xs')]: {
      maxHeight: spacing(25),
      maxWidth: spacing(15),
    },
    [breakpoints.up('sm')]: {
      maxHeight: spacing(30),
      maxWidth: spacing(20),
    },
    [breakpoints.up('md')]: {
      maxHeight: '100%',
      maxWidth: spacing(25),
    },
  },
  headerImage: {
    height: 'auto',
    width: '100%',
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  headerImagexs: {
    height: 'auto',
    width: '100%',
    [breakpoints.up('xs')]: {
      display: 'none',
    },
  },
  headerImageMobile: {
    height: 'auto',
    width: '100%',
    [breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolBar: {
    padding: spacing(2),
  },
}));
