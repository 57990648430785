import React from 'react';
import clsx from 'clsx';

import { ExternalLink } from 'shared/components/externalLink/ExternalLink';
import { SocialMediaIconProps } from './SocialMediaIcon.types';
import { useStyles } from './SocialMediaIcon.styles';

export const SocialMediaIcon = ({ icon: Icon, inverse = false, href }: SocialMediaIconProps) => {
  const styles = useStyles();

  const classNames = clsx(styles.icon, {
    [styles.inverse]: inverse,
  });

  return (
    <ExternalLink href={href} target="_blank">
      <Icon className={classNames} />
    </ExternalLink>
  );
};
