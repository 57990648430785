import React from 'react';

import { Header } from './header/Header';
import { CollaboratorPages, CollaboratorUploadProps } from './CollaboratorUpload.types';
import { UpcomingEvent } from './upcomingEvent/UpcomingEvent';
import { Confirm } from './confirm/Confirm';
import { UploadMedia } from './uploadMedia/UploadMedia';
import { ConfirmFromExternal } from './confrimFromExternal/ConfirmFromExternal';

export const CollaboratorUpload = ({
  collaboratorPage,
  setCollaboratorPage,
  isDisable,
  setIsDisable,
  success,
  isMediaUploadFailed,
  ...props
}: CollaboratorUploadProps) => {
  return (
    <>
      <Header />
      {collaboratorPage === CollaboratorPages.upcomingEvent && (
        <UpcomingEvent setCollaboratorPage={setCollaboratorPage} {...props} />
      )}
      {collaboratorPage === CollaboratorPages.uploadMedia && (
        <UploadMedia
          isMediaUploadFailed={isMediaUploadFailed}
          setCollaboratorPage={setCollaboratorPage}
          isDisable={isDisable}
          setIsDisable={setIsDisable}
          {...props}
        />
      )}
      {collaboratorPage === CollaboratorPages.confirmMedia && (
        <Confirm
          setCollaboratorPage={setCollaboratorPage}
          isDisable={isDisable}
          setIsDisable={setIsDisable}
          success={success}
          {...props}
        />
      )}
      {collaboratorPage === CollaboratorPages.confirmLinkMedia && (
        <ConfirmFromExternal setCollaboratorPage={setCollaboratorPage} {...props} />
      )}
    </>
  );
};
