import React from 'react';
import { Switch as MaterialSwitch, SwitchProps as DefaultSwitchProps } from '@material-ui/core';

type SwitchProps = Omit<DefaultSwitchProps, 'onChange'> & { onChange?: (checked: boolean) => void };

export const Switch = ({ onChange, color = 'primary', ...props }: SwitchProps) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (onChange) {
      onChange(checked);
    }
  };

  return <MaterialSwitch {...props} onChange={handleOnChange} color={color} />;
};
