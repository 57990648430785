/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { useField, useFormState } from 'react-final-form';
import * as MP4Box from 'mp4box';
import getBlobDuration from 'get-blob-duration';
import { useIntl } from 'react-intl';

import { AVUserFileDropZoneField } from '../avUserFileDropZone/AVUserFileDropZoneField';

import { useAzureUpload } from 'shared/hooks/useAzureUpload/useAzureUpload';
import { hash } from 'shared/utils/hash/hash';
import { AVUserAzureFileUploadProps, Mp4BoxFileInfo, FileInfo } from './AVUserAzureFileUpload.types';
import { CollaboratorPages } from 'app/avUserRoot/collabloratorUpload/CollaboratorUpload.types';
import { useUpdateNotifications } from 'shared/components/notifications/notifications.context';

export const AVUserAzureFileUpload: React.FC<AVUserAzureFileUploadProps> = ({
  name,
  fetchUploadMetaAction,
  accept,
  maxSize,
  maxDuration,
  validate,
  previews,
  setFileInfo,
  fileInputRef,
  rotation,
  previewMode,
  scale,
  resolution,
  resetRotation,
  type,
  setVideoFileInfo,
  setCollaboratorPage,
  eventDetails,
}) => {
  const uriFieldName = `${name}URI`;
  const { loading, upload, progress, error, fileURI, fileName, fileSize, videoBlobFile, videoURI } = useAzureUpload({
    fetchUploadMetaAction,
    institutionName: eventDetails?.institution.name || '',
  });
  const updateNotifications = useUpdateNotifications();
  const fileHash = useRef<string>('');
  const formState = useFormState({ subscription: { validating: true } });
  const [durationTime, setDurationTime] = useState('');
  const { formatMessage } = useIntl();
  const mp4boxFile = MP4Box.createFile();
  const [details, setDetails] = useState<FileInfo>();

  // current file field
  const {
    input: { value: file },
    meta: { valid: isFileValid },
  } = useField<File>(name, {
    subscription: { value: true, valid: true },
    validateFields: [uriFieldName, name],
  });

  // Re-Upload
  const reUpload = () => {
    if (videoBlobFile) {
      upload(videoBlobFile);
    } else {
      updateNotifications([
        {
          id: file.name,
          message: formatMessage({ id: 'av_user.notification_message.re_upload' }),
          severity: 'error',
          open: true,
        },
      ]);
    }
  };

  // field with file URI after upload
  const {
    input: { value: fileURIValue, onChange: changeURI },
  } = useField(uriFieldName, {
    subscription: { value: true },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    validate: (value, allValues: any) => {
      if (allValues[name] instanceof File && !value) {
        return 'validation.not_uploaded';
      }

      return undefined;
    },
  });

  const getVideoDuration = async (blobFile: File) => {
    const duration = await getBlobDuration(blobFile);
    const hours = Math.floor(duration / 3600); // get hours
    const minutes = Math.floor((duration - hours * 3600) / 60); // get minutes
    const seconds = duration - hours * 3600 - minutes * 60; //  get seconds
    const videoTime = `${hours}${hours > 1 ? 'hrs' : 'hr'} ${minutes}${minutes > 1 ? 'mins' : 'min'} ${seconds.toFixed(
      0,
    )}s`;
    setDurationTime(videoTime);
  };

  useEffect(() => {
    if (
      file &&
      file instanceof File &&
      isFileValid &&
      !formState.validating &&
      !loading &&
      fileHash.current !== hash(file)
    ) {
      changeURI(null);
      fileHash.current = hash(file);
      upload(file);

      if (setFileInfo) {
        mp4boxFile.onReady = ({ brands, mime }: Mp4BoxFileInfo) => {
          setDetails({ brands, mime });
        };
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.addEventListener('loadend', (event: any) => {
          const buffer = event.currentTarget.result;
          buffer.fileStart = 0;
          mp4boxFile.appendBuffer(buffer);
        });
        getVideoDuration(file);
      }
    }

    return mp4boxFile.flush();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(file), isFileValid, formState.validating]);

  // clear when delete file
  useEffect(() => {
    if (fileURIValue && !file) {
      fileHash.current = '';
      changeURI('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  // change file URI when uploaded with success
  useEffect(() => {
    if (fileURI && !error) {
      changeURI(fileURI);
      setVideoFileInfo({
        duration: durationTime,
        fileName,
        fileUrl: fileURI,
        size: fileSize,
        videoUrl: videoURI || '',
        fileInfo: details,
      });
      setCollaboratorPage(CollaboratorPages.confirmMedia);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileURI]);

  return (
    <AVUserFileDropZoneField
      name={name}
      accept={accept}
      maxSize={maxSize}
      maxDuration={maxDuration}
      duration={durationTime}
      validate={validate}
      disabled={loading}
      hideDeleteButton={false}
      allowBlank
      previews={previews}
      fileInputRef={fileInputRef}
      rotation={rotation}
      resetRotation={resetRotation}
      previewMode={previewMode}
      scale={scale}
      resolution={resolution}
      type={type}
      progress={progress}
      fileDetails={file}
      setCollaboratorPage={setCollaboratorPage}
      errors={error}
      reUpload={reUpload}
      avError={error}
      eventDetails={eventDetails}
      setVideoFileInfo={setVideoFileInfo}
    />
  );
};
