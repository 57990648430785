import { styled } from '@material-ui/core';

import { FOOTER_GAP } from '../Footer.styles';

import { bold } from 'app/core/theme/typography';
import { Typography } from 'shared/components/typography/Typography';

export const Container = styled('div')(({ theme: { breakpoints } }) => ({
  display: 'flex',
  marginRight: FOOTER_GAP,
  [breakpoints.down('sm')]: {
    flexDirection: 'column',
    marginRight: 0,
  },
}));

export const CopyRightText = styled(Typography)(({ theme: { palette, breakpoints } }) => ({
  fontWeight: bold,
  fontSize: 14,
  color: palette.white.main,
  [breakpoints.down('sm')]: {
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
}));
