import React from 'react';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import { BrowserRouter as Router } from 'react-router-dom';

import { Theme, Providers } from 'app/core';
import { ErrorBoundary } from 'app/core/errorBoundary/ErrorBoundary';
import { AppRoutes } from './routing/AppRoutes';
import { ParticipantQueryDataProvider } from 'contexts/participantQueryData/participantQueryDataContext';
import { ParticipantDataProvider } from 'contexts/participantData/participantDataContext';
import { AVUserQueryDataProvider } from 'contexts/avUserQueryData/avUserQueryDataContext';
import { AVUserDataProvider } from 'contexts/avUserData/avUserDataContext';
import { Notifications } from 'shared/components/notifications/Notifications';

const App = () => {
  return (
    <Router>
      <ErrorBoundary>
        <ParticipantQueryDataProvider>
          <AVUserQueryDataProvider>
            <ParticipantDataProvider>
              <AVUserDataProvider>
                <Providers>
                  <Theme>
                    <AppRoutes />
                    <Notifications />
                  </Theme>
                </Providers>
              </AVUserDataProvider>
            </ParticipantDataProvider>
          </AVUserQueryDataProvider>
        </ParticipantQueryDataProvider>
      </ErrorBoundary>
    </Router>
  );
};

export default App;
