/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef } from 'react';
import {
  HashLink as RouterHashLink,
  NavHashLink as RouterNavHashLink,
  HashLinkProps,
  NavHashLinkProps,
} from 'react-router-hash-link';

/*
TODO: Improve or replace react-router-hash-link package
react-router-hash-link package doesn't support ref so we need to block it from passing to the component.
This should be only a temporary solution
*/
const skipRef = <Props extends {}>(Component: React.ComponentType<Props>) =>
  // eslint-disable-next-line no-unused-vars
  forwardRef<any, Props>((props: Props, ref) => <Component {...props} />);

export const HashLink = skipRef<HashLinkProps>(RouterHashLink);
export const NavHashLink = skipRef<NavHashLinkProps>(RouterNavHashLink);
