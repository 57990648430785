import React from 'react';
import Validators, {
  required as requiredValidator,
  email as emailValidator,
  length as lengthValidator,
  file as fileValidator,
  url as urlValidator,
  acceptance as acceptanceValidator,
  DefaultValidatorOptions,
  EmailValidatorOptions,
  LengthValidatorOptions,
  UrlValidatorOptions,
  Validator,
} from 'redux-form-validators';
import { FormattedMessage } from 'react-intl';

Validators.messages = {
  ...Validators.messages,
  file: {
    // @ts-ignore
    ...Validators.messages.file,
    id: 'validation.file.file',
  },
  fileTooBig: {
    // @ts-ignore
    ...Validators.messages.fileTooBig,
    id: 'validation.file.too_big',
  },
};

// @ts-ignore
Validators.formatMessage = msg => <FormattedMessage {...(msg.props || msg)} />;

const overWriteConfig = (id: string, config?: DefaultValidatorOptions) => ({
  ...(config || {}),
  message: {
    id,
  },
});

const overWriteValidator = (validator: Validator, id: string, config?: DefaultValidatorOptions) =>
  validator(overWriteConfig(id, config));

export const required = (config?: DefaultValidatorOptions) =>
  overWriteValidator(requiredValidator, 'validation.required', config);

export const studentIdRequired = (config?: DefaultValidatorOptions) =>
  overWriteValidator(requiredValidator, 'validation.required_studentId', config);

export const email = (config?: EmailValidatorOptions) =>
  overWriteValidator(emailValidator, 'validation.invalid_email', config);

export const length = (config?: LengthValidatorOptions) =>
  overWriteValidator(lengthValidator, 'validation.invalid_length', config);

export const file = fileValidator;

export const url = (config?: UrlValidatorOptions) => overWriteValidator(urlValidator, 'validation.invalid_url', config);

export const acceptance = acceptanceValidator;

export * from './resolution/resolution.validator';
export * from './duration/duration.validator';
