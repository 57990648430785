import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { ClientContextProvider, createClient } from 'react-fetching-library';

import { requestSecretInterceptor, requestHostInterceptor, requestBodyInterceptor } from 'api/interceptors';
import { useParticipantQueryData } from 'contexts/participantQueryData/participantQueryDataContext';
import { useAVUserQueryData } from 'contexts/avUserQueryData/avUserQueryDataContext';

export const ClientContextController: React.FC = ({ children }) => {
  const { secret, editToken } = useParticipantQueryData();
  const { secret: AvuserSecret } = useAVUserQueryData();
  const { pathname } = useLocation();
  const secretKey = pathname.includes('avuser') ? AvuserSecret : secret;

  const client = useMemo(() => {
    return createClient({
      requestInterceptors: [
        requestSecretInterceptor(secretKey, editToken),
        requestHostInterceptor,
        requestBodyInterceptor,
      ],
    });
  }, [secretKey, editToken]);

  return <ClientContextProvider client={client}>{children}</ClientContextProvider>;
};
