import React from 'react';
import { useIntl } from 'react-intl';

import { GraduateSection } from '../root/intro/graduateSection/GraduateSection';
import { RestoreUploadLinkContainer } from '../RestoreUploadLink/RestoreUploadLinkContainer';

import { Typography } from 'shared/components';
import { StyledContainer, EmailRejectImage } from './Error.styles';
import { ExternalLink } from 'shared/components/externalLink/ExternalLink';

export const Error = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <StyledContainer>
        <GraduateSection
          title="virtual_graduation.something_went_wrong.title"
          description1={
            <div>
              <Typography variant="h2">virtual_graduation.something_went_wrong.subtitle</Typography>
              <Typography>
                {formatMessage(
                  { id: 'virtual_graduation.something_went_wrong.description' },
                  {
                    link: (
                      <ExternalLink href="https://support.stageclip.com" target="_blank">
                        {formatMessage({ id: 'virtual_graduation.something_went_wrong.description_link' })}
                      </ExternalLink>
                    ),
                  },
                )}
              </Typography>
            </div>
          }
          description2={<RestoreUploadLinkContainer />}
          image={<EmailRejectImage />}
        />
      </StyledContainer>
    </>
  );
};
