/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { useField, useFormState } from 'react-final-form';
import { useIntl } from 'react-intl';
import * as MP4Box from 'mp4box';

import { FileDropZoneField } from '../fileDropZone/FileDropZoneField';

import { useAzureUpload } from 'shared/hooks/useAzureUpload/useAzureUpload';
import { hash } from 'shared/utils/hash/hash';
import { StyledLinearProgress, StyledFormHelperText } from './AzureFileUpload.styles';
import { AzureFileUploadProps, Mp4BoxFileInfo } from './AzureFileUpload.types';

export const AzureFileUpload: React.FC<AzureFileUploadProps> = ({
  name,
  fetchUploadMetaAction,
  accept,
  maxSize,
  maxDuration,
  validate,
  previews,
  setFileInfo,
  fileInputRef,
  rotation,
  previewMode,
  scale,
  resolution,
  resetRotation,
  type,
}) => {
  const uriFieldName = `${name}URI`;
  const { loading, upload, progress, error, fileURI } = useAzureUpload({ fetchUploadMetaAction });
  const { formatMessage } = useIntl();
  const fileHash = useRef<string>('');
  const formState = useFormState({ subscription: { validating: true } });
  const [durationTime, setDurationTime] = useState(0);

  const mp4boxFile = MP4Box.createFile();

  // current file field
  const {
    input: { value: file },
    meta: { valid: isFileValid },
  } = useField<File>(name, {
    subscription: { value: true, valid: true },
    validateFields: [uriFieldName, name],
  });

  // field with file URI after upload
  const {
    input: { value: fileURIValue, onChange: changeURI },
  } = useField(uriFieldName, {
    subscription: { value: true },
    validate: (value, allValues: any) => {
      if (allValues[name] instanceof File && !value) {
        return 'validation.not_uploaded';
      }

      return undefined;
    },
  });

  useEffect(() => {
    if (
      file &&
      file instanceof File &&
      isFileValid &&
      !formState.validating &&
      !loading &&
      fileHash.current !== hash(file)
    ) {
      changeURI(null);
      fileHash.current = hash(file);
      upload(file);

      if (setFileInfo) {
        mp4boxFile.onReady = ({ brands, mime, duration, timescale }: Mp4BoxFileInfo) => {
          setDurationTime(duration / timescale);
          setFileInfo({ brands, mime });
        };

        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.addEventListener('loadend', (event: any) => {
          const buffer = event.currentTarget.result;
          buffer.fileStart = 0;
          mp4boxFile.appendBuffer(buffer);
        });
      }
    }

    return mp4boxFile.flush();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(file), isFileValid, formState.validating]);

  // clear when delete file
  useEffect(() => {
    if (fileURIValue && !file) {
      fileHash.current = '';
      changeURI('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  // change file URI when uploaded with success
  useEffect(() => {
    if (fileURI && !error) {
      changeURI(fileURI);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileURI]);

  return (
    <>
      <FileDropZoneField
        name={name}
        accept={accept}
        maxSize={maxSize}
        maxDuration={maxDuration}
        duration={durationTime}
        validate={validate}
        disabled={loading}
        hideDeleteButton={false}
        allowBlank
        previews={previews}
        fileInputRef={fileInputRef}
        rotation={rotation}
        resetRotation={resetRotation}
        previewMode={previewMode}
        scale={scale}
        resolution={resolution}
        type={type}
      />
      {loading && <StyledLinearProgress variant="determinate" color="secondary" value={progress} />}
      {error && (
        <StyledFormHelperText role="alert" aria-live="assertive">
          {formatMessage({ id: error })}
        </StyledFormHelperText>
      )}
    </>
  );
};
