import { useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useWindowResize = (callback: () => void, deps: any[] = []) => {
  useEffect(() => {
    window.addEventListener('resize', callback);

    return () => window.removeEventListener('resize', callback);
  }, [callback, deps]);
};
