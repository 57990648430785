/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect } from 'react';
import { Typography, Grid, CardContent, Card, Button, Box } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import { useIntl } from 'react-intl';

import { CollaboratorPages } from '../CollaboratorUpload.types';

import { useStyle } from './Confirm.style';
import { ConfirmProps } from './Confirm.types';

export const Confirm = ({
  setCollaboratorPage,
  handleEventVideos,
  videoFileInfo,
  eventDetails,
  isDisable,
  success,
  setIsDisable,
}: ConfirmProps) => {
  const classes = useStyle();
  const { formatMessage } = useIntl();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      handleEventVideos();
    }, 200);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return videoFileInfo ? (
    <>
      <Grid container spacing={0} className={classes.root1}>
        <Card elevation={0} className={classes.cardStyle1}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs="auto" direction="column">
                <ArrowBackIos
                  onClick={() => {
                    setIsDisable(false);
                    setCollaboratorPage(CollaboratorPages.uploadMedia);
                  }}
                  className={classes.rootLogo}
                />
              </Grid>
              <Grid item xs={10} direction="column">
                <Typography
                  className={classes.rootText}
                  onClick={() => {
                    setIsDisable(false);
                    setCollaboratorPage(CollaboratorPages.uploadMedia);
                  }}
                >
                  {eventDetails?.name}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <div className={classes.root3}>
        <Card elevation={0}>
          <CardContent>
            <Grid container spacing={0} justify="center">
              <Grid className={classes.gridVideo}>
                <Card elevation={0}>
                  <CardContent>
                    <video
                      key={videoFileInfo.videoUrl}
                      width="100%"
                      height="100%"
                      controlsList="nodownload"
                      disablePictureInPicture
                      controls
                      playsInline
                    >
                      <source src={videoFileInfo.videoUrl} type="video/mp4" />
                    </video>
                  </CardContent>
                </Card>
              </Grid>
              <Grid className={classes.gridDetails}>
                <Card elevation={0} className={classes.cardStyle}>
                  <CardContent>
                    <Grid container spacing={0} className={classes.grid1}>
                      <Grid container spacing={0} className={classes.gridStyle}>
                        <Grid item xs={2} direction="column">
                          <div className={classes.colorlessCircle}>
                            <Typography className={classes.buttonText}>
                              {formatMessage({ id: 'av_user.confirm.1' })}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={4} direction="column">
                          <Typography className={classes.fontColorless}>
                            {formatMessage({ id: 'av_user.confirm.upload' })}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container spacing={1}>
                        {success ? (
                          <>
                            <Grid item xs={2} direction="column">
                              <div className={classes.colorCircle}>
                                <Typography className={classes.buttonText}>
                                  {formatMessage({ id: 'av_user.confirm.2' })}
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item xs={6} direction="column">
                              <Typography className={classes.fontColored}>
                                {formatMessage({ id: 'av_user.confirm.confirm' })}
                              </Typography>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item xs={2} direction="column">
                              <div className={classes.colorCircleError}>
                                <Typography className={classes.buttonText}>
                                  {formatMessage({ id: 'av_user.confirm.2' })}
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item xs={6} direction="column">
                              <Typography className={classes.fontColoredError}>
                                {formatMessage({ id: 'av_user.confirm.upload_error' })}
                              </Typography>
                            </Grid>
                          </>
                        )}
                        <Grid container spacing={0}>
                          <Grid item xs={2} direction="column" />
                          <Grid item xs={8} direction="column">
                            <Typography className={classes.videoText}>
                              <Typography className={classes.videoName}>{videoFileInfo.fileName}</Typography>
                              <Typography className={classes.videoSize}>{videoFileInfo.size}</Typography>
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={0}>
                          <Grid item xs={2} direction="column" />
                          <Grid item xs={8} direction="column">
                            <Typography className={classes.videoText1}>
                              {formatMessage({ id: 'av_user.confirm.runtime' })} {videoFileInfo.duration}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {success ? (
                      <Grid container>
                        <Grid item xs={12} />
                        <Grid item xs={12} sm={12} className={classes.buttonGrid}>
                          <Box className={classes.box}>
                            <Button
                              disabled={!isDisable}
                              type="submit"
                              onClick={() => {
                                setCollaboratorPage(CollaboratorPages.upcomingEvent);
                              }}
                              variant="contained"
                              className={classes.doneButton}
                            >
                              {formatMessage({ id: 'av_user.confirm.done' })}
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container>
                        <Grid item xs={12} />
                        <Grid item xs={12} sm={12} className={classes.buttonGrid}>
                          <Box className={classes.box}>
                            <Button
                              type="submit"
                              onClick={() => {
                                handleEventVideos();
                              }}
                              variant="contained"
                              className={classes.retryButton}
                            >
                              {formatMessage({ id: 'av_user.confirm.retry' })}
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </>
  ) : null;
};
