import { FileValidatorOptions } from 'redux-form-validators';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

import { connectControl } from '../connectControl/connectControl';
import { ConnectControlProps } from '../connectControl/connectControl.types';

import { file, resolution as resolutionValidator, duration as durationValidator } from 'shared/validators';
import { AVUserFileDropZoneFieldProps } from './AVUserFileDropZone.types';
import { getExtension, defaultExtensions } from './AVUserFileDropZone.utils';
import { AVUserFileDropZone } from './AVUserFileDropZone';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fileValidator = (options?: FileValidatorOptions) => (value: any, allValues?: any, props?: any) => {
  if (typeof value === 'string' || typeof value === 'undefined') {
    return undefined;
  }

  return file(options)(value, allValues, props);
};

const getValidators = (props: ConnectControlProps<AVUserFileDropZoneFieldProps>) => {
  const {
    validate = [],
    maxSize = '100 GB',
    accept = defaultExtensions,
    resolution,
    duration,
    maxDuration,
    allowBlank = false,
  } = props;
  const extensions = getExtension(accept);

  const validators = [...validate];

  // size validation
  if ([maxSize, extensions].some(value => typeof value !== 'undefined')) {
    const config = {
      accept: extensions,
      maxSize,
      allowBlank,
    };

    validators.push(fileValidator(omitBy(config, isNil)));
  }

  // resolution validation
  if (resolution) {
    validators.push(resolutionValidator(resolution));
  }

  // duration validator
  if (maxDuration) {
    validators.push(durationValidator({ duration, maxDuration }));
  }

  return { validate: validators };
};

export const AVUserFileDropZoneField = connectControl<AVUserFileDropZoneFieldProps>(AVUserFileDropZone, props => ({
  ...props,
  ...getValidators(props),
}));
