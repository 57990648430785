import { useActivityTrackClient } from '../useActivityTrackClient/useActivityTrackClient';

import { postActivity } from 'api/activityTrack/activityTrack';
import { ActivityTypes, ParticipantActivityCode, EventVideoActivityCode } from 'api/activityTrack/activityTrack.types';
import { useParticipantData } from 'contexts/participantData/participantDataContext';
import { useParticipantQueryData } from 'contexts/participantQueryData/participantQueryDataContext';
import { useAvUserData } from 'contexts/avUserData/avUserDataContext';

export const useActivityTrack = () => {
  const activityTrackClient = useActivityTrackClient();
  const { participantId } = useParticipantQueryData();
  const { fullName, institution } = useParticipantData();
  const { email } = useAvUserData();

  const onActivityTrack = async (
    activityType: ActivityTypes,
    activityCode: ParticipantActivityCode | EventVideoActivityCode,
    description: string | undefined,
    institutionName?: string,
  ) => {
    const body = {
      activityCode,
      email: '',
      participantId: participantId || '',
      channel: 'PRODUCER',
      institution: [institution?.name || institutionName || ''],
      activityType,
      userName: fullName || email || '',
      data: {
        description,
      },
    };

    postActivity({ activityTrackClient, body });
  };

  return { onActivityTrack };
};
