import { styled, Link as MaterialLink, makeStyles, Theme } from '@material-ui/core';

import { Typography } from 'shared/components/typography/Typography';

export const Container = styled('div')(({ theme: { breakpoints } }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',

  [breakpoints.down('sm')]: {
    padding: 0,
    width: 'unset',
    textAlign: 'center',
    flexDirection: 'column',
  },
}));

export const useStyles = makeStyles<Theme, { titleSize: number }>(({ palette, breakpoints, typography }) => ({
  title: {
    margin: '8px 0 24px 0',
    color: palette.blue.dark,
    fontWeight: 900,

    [breakpoints.up('sm')]: {
      fontSize: ({ titleSize }) => titleSize,
      lineHeight: ({ titleSize }) => `${titleSize}px`,
    },

    [breakpoints.down('sm')]: {
      ...typography.h1,
      marginTop: 0,
      marginBottom: 16,
      textAlign: 'center',
      lineHeight: '52px',
      fontSize: 50,
    },
  },
}));

export const Description = styled(Typography)(({ theme: { breakpoints, typography } }) => ({
  marginBottom: 8,
  [breakpoints.down('sm')]: {
    ...typography.h4,
  },
}));

export const InformationText = styled(Typography)(({ theme: { breakpoints } }) => ({
  marginBottom: 40,
  marginTop: 20,
  [breakpoints.down('sm')]: {
    whiteSpace: 'unset',
    textAlign: 'center',
  },
}));

export const GreetingText = styled('div')(({ theme: { breakpoints } }) => ({
  [breakpoints.down('sm')]: {
    textAlign: 'center',
  },
}));

export const Link = styled(MaterialLink)(({ theme: { palette, breakpoints, typography } }) => ({
  ...typography.button,
  textTransform: 'initial',
  backgroundColor: palette.primary.main,
  color: palette.white.main,
  fontWeight: 'bold',
  padding: '10px 54px',
  marginTop: '100px',
  borderRadius: 4,
  [breakpoints.down('sm')]: {
    padding: '10px 37px',
  },
}));

export const TextContainer = styled('div')({
  flex: 3,
});

export const ImageContainer = styled('div')(({ theme: { breakpoints } }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  flex: 4,

  [breakpoints.only('sm')]: {
    '& svg': {
      maxWidth: 400,
      maxHeight: 300,
    },
  },
  [breakpoints.down('sm')]: {
    '& svg': {
      maxWidth: 300,
      maxHeight: 300,
    },
    justifyContent: 'center',
  },
}));

export const Name = styled(Typography)(({ theme: { typography: { h3 } } }) => ({
  ...h3,
  fontWeight: 900,
}));
