import { LinearProgress, withStyles } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import styled from '@material-ui/core/styles/styled';

import { darkBlueLight } from 'app/core/theme/palette';

export const BorderLinearProgressFail = withStyles(({ palette }) => ({
  root: {
    height: 10,
    borderRadius: 12,
    paddingRight: '30%',
    paddingLeft: '30%',
  },
  colorPrimary: {
    backgroundColor: palette.red.main,
  },
  bar: {
    borderRadius: 12,
    backgroundColor: palette.red.main,
  },
}))(LinearProgress);

export const Wrapper = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: spacing(30),
  width: '100%',
}));

export const ImageStyle = styled('div')(({ theme: { spacing } }) => ({
  paddingTop: spacing(4),
  paddingBottom: spacing(5.25),
}));

export const DropZone = styled('div')(({ theme: { breakpoints, spacing } }) => ({
  alignItems: 'center',
  borderRadius: 8,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  textAlign: 'center',
  boxSizing: 'content-box',
  position: 'relative',
  '& > img': {
    maxHeight: spacing(21.25),
    [breakpoints.down('xs')]: {
      maxHeight: spacing(35),
    },
    objectFit: 'contain',
  },
  '& > video': {
    maxHeight: '50vh',
  },
}));

export const TextWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Input = styled('input')({
  display: 'none',
  opacity: 0,
});

export const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
  active: {
    '& > *': {
      pointerEvents: 'none',
    },
  },
  inArea: {
    borderColor: palette.green.main,
  },
  error: {
    padding: spacing(1),
  },
  disabled: {
    '&, & *': {
      'pointer-events': 'none',
    },
  },
  uploadFail: {
    backgroundColor: palette.red.dark,
    [breakpoints.down('sm')]: {
      paddingRight: '10%',
      paddingLeft: '10%',
    },
    [breakpoints.up('md')]: {
      paddingRight: '30%',
      paddingLeft: '30%',
    },
  },
  ErrorLogo: {
    height: '30%',
    width: '30%',
    color: palette.red.main,
    paddingBottom: '5%',
    paddingTop: '10%',
  },
  uploadContent: {
    fontFamily: 'Open Sans',
    fontSize: spacing(2),
    paddingBottom: '5%',
    color: palette.black.main,
  },
  buttonErr: {
    borderRadius: 5,
    backgroundColor: palette.red.light,
    color: 'black',
    fontFamily: 'Open Sans',
    fontSize: spacing(2),
    fontWeight: 'bold',
    marginTop: spacing(1.25),
  },
  dropZoneWrapper: {
    outlineColor: palette.green.main,
    '&:hover': {
      cursor: 'pointer',
      borderColor: palette.green.main,
    },
  },
  dashed: {
    border: `dashed ${palette.green.light}`,
  },
  preview: {
    [breakpoints.up('sm')]: {
      alignItems: 'start',
    },
  },
  button: {
    color: palette.green.dark,
  },
  errorStyle: {
    paddingBottom: '10%',
  },
  urlLinkContainer: {
    padding: spacing(0, 0, 0, 3),
    '& > div': {
      boxShadow: 'none',
    },
  },
  box: {
    padding: spacing(0, 0, 0, 3),
    height: '100%',

    '& > div': {
      boxShadow: 'none',
    },
  },

  cardStyle1: {
    width: '100%',
    '& .MuiCardContent-root': {
      padding: spacing(0),
    },
  },
  root1: {
    paddingRight: '18%',
    paddingLeft: '18%',
    paddingTop: '12%',
    width: '100%',
  },

  subtitle: {
    justifyContent: 'center',
    textAlign: 'center',
    padding: spacing(0, 2, 4),
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    fontSize: spacing(2.25),
    color: palette.black.main,
  },
  divider: {
    margin: 'auto',
  },
  infoText: {
    fontSize: spacing(1.8),
    color: palette.white.main,
    textAlign: 'left',
  },
  infoicon: {
    color: palette.white.main,
    marginRight: spacing(1),
  },
  infoContainer: {
    backgroundColor: `${darkBlueLight} !important`,
    BorderColor: darkBlueLight,
    borderRadius: spacing(1),
    padding: spacing(1),
    marginBottom: spacing(2),
    display: 'flex',
  },
}));
