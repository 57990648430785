import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
  header: {
    color: palette.black.main,
    lineHeight: spacing(0.15),
    fontSize: spacing(6),

    fontWeight: 'bold',
  },

  title: {
    color: palette.gray.main,
    lineHeight: spacing(0.15),
    fontSize: spacing(3.25),

    fontWeight: 'bold',
  },

  subtitle: {
    color: palette.black.main,
    textAlign: 'center',
    lineHeight: spacing(0.18),
    fontSize: spacing(3.25),
    margin: spacing(4.625),
    fontWeight: 'bold',
    marginTop: spacing(3.5625),
  },
  description: {
    color: palette.black.main,
    lineHeight: spacing(0.15),
    fontSize: spacing(2),
    textAlign: 'center',
    fontFamily: 'Roboto',
    letterSpacing: spacing(0.05),
    marginTop: spacing(5),
    margin: spacing(1.625),
  },
  label: {
    fontSize: spacing(2),
    fontFamily: 'Roboto',
    float: 'left',
  },
  TextField: {
    height: spacing(0.25),
    float: 'left',
  },

  buttonLabel: {
    backgroundColor: palette.blue.light,
    color: palette.white.main,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: spacing(2),
    margin: 'auto',
    marginTop: spacing(6.75),
    textTransform: 'initial',
    borderRadius: spacing(0.5),
    height: spacing(5),
    width: spacing(42.5),
    [breakpoints.down('sm')]: {
      marginTop: spacing(3),
      marginLeft: spacing(0),
      width: spacing(32.5),
    },
  },

  card: {
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: spacing(3),
    width: spacing(48.5),
    height: spacing(40),
    boxShadow: ` ${spacing(0, 0.5, 1, 0)}  ${palette.info.main}`,
    [breakpoints.down('sm')]: {
      width: spacing(37.5),
      marginTop: spacing(3),
    },
  },
}));
