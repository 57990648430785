import React from 'react';
import { Typography, Grid, CardContent, Card } from '@material-ui/core';
import { useIntl } from 'react-intl';

import { useStyles } from './UploadMediaSteps.styles';

export const UploadMediaSteps = () => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  return (
    <Grid container spacing={0} className={classes.grid1}>
      <Card elevation={0}>
        <CardContent>
          <Grid container spacing={3} justify="center">
            <Grid item xs={2} direction="column">
              <div className={classes.colorCircle}>
                <Typography className={classes.text}>
                  {formatMessage({ id: 'av_user.upload_media.media_uploading_steps.1' })}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={3} direction="column">
              <Typography className={classes.fontColored}>
                {formatMessage({ id: 'av_user.upload_media.media_uploading_steps.upload' })}
              </Typography>
            </Grid>
            <Grid item xs={1} direction="column" />
            <Grid item xs={2} direction="column">
              <div className={classes.colorlessCircle}>
                <Typography className={classes.text}>
                  {formatMessage({ id: 'av_user.upload_media.media_uploading_steps.2' })}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={3} direction="column">
              <Typography className={classes.fontColorless}>
                {formatMessage({ id: 'av_user.upload_media.media_uploading_steps.confirm' })}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
