import React, { createRef, useState, useEffect, useMemo } from 'react';
import { IconButton } from '@material-ui/core';
import format from 'date-fns/format';

import { Typography } from 'shared/components/typography/Typography';
import { Separator } from 'shared/components/separator/Separator';
import { Icon } from 'shared/components/icon/Icon';
import { Alert } from 'shared/components/alert/Alert';
import { PlayerProps } from './Player.types';
import {
  Wrapper,
  Subtitle,
  Controls,
  PlayButton,
  Visualization,
  Bars,
  Bar,
  Progress,
  BarFilled,
  Times,
  Time,
} from './Player.styles';

const DEFAULT_SONG = 0;
const BAR_MAX_HEIGHT = 45;
const BAR_MIN_HEIGHT = 1;
const NEXT_SONG = 1;
const PREV_SONG = -1;

const formatDuration = (duration: string | number) => {
  if (typeof duration === 'string') {
    return duration;
  }

  return format(new Date(0, 0, 0, 0, 0, duration), 'mm:ss');
};

const getBarSize = () => Math.floor(Math.random() * BAR_MAX_HEIGHT) + BAR_MIN_HEIGHT;

const getDurationInPercent = (currentTime: string | number, duration: string | number) => {
  if (typeof currentTime === 'string' || typeof duration === 'string') {
    return 0;
  }

  return (currentTime / duration) * 100;
};

export const Player: React.FC<PlayerProps> = ({ playlist, track, onChange }) => {
  const playerRef = createRef<HTMLAudioElement>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [song, setSong] = useState(typeof track !== 'undefined' ? track : DEFAULT_SONG);
  const [duration, setDuration] = useState<number | string>('--:--');
  const [currentTime, setCurrentTime] = useState<number | string>('--:--');
  const [error, setError] = useState<null | string>(null);

  const handlePause = () => {
    const player = playerRef.current;

    if (player) {
      player.pause();
    }
  };

  const handlePlay = () => {
    const player = playerRef.current;

    if (player) {
      player.play();
    }
  };

  const handlePlayClick = () => {
    if (isPlaying) {
      handlePause();
    } else {
      handlePlay();
    }
  };

  const handleSongChange = (dir: typeof NEXT_SONG | typeof PREV_SONG) => {
    const nextSong = song + dir;

    if (dir === PREV_SONG) {
      if (nextSong >= 0) {
        setSong(nextSong);
      }
    }

    if (dir === NEXT_SONG) {
      if (nextSong < playlist.length) {
        setSong(nextSong);
      }
    }
  };

  const handleLoadedMetadata = () => {
    const player = playerRef.current;

    if (player) {
      setDuration(player.duration);
    }
  };

  const handleTimeUpdate = (e: React.SyntheticEvent<HTMLAudioElement>) => setCurrentTime(e.currentTarget.currentTime);

  const handleErrorInfo = () => {
    setError('clip_template.audio.player.error');
  };

  const { src, title, subtitle } = playlist[song] || {};

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const bars = useMemo(() => new Array(40).fill(null).map(() => getBarSize()), [song]);

  useEffect(() => {
    if (playlist.length === 0) {
      setError('clip_template.audio.player.playlist_empty');
    }
  }, [playlist.length]);

  useEffect(() => {
    setIsPlaying(false);

    const player = playerRef.current;

    if (player) {
      player.load();
    }

    if (onChange) {
      onChange(song);
    }

    if (error !== null) {
      setError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [song]);

  useEffect(() => {
    if (typeof track === 'number') {
      setSong(track);
    }
  }, [track]);

  const isPlaylistEmpty = playlist.length === 0;
  const isPlayButtonDisabled = !!error;
  const isPrevSongDisabled = song === 0 || isPlaylistEmpty;
  const isNextSongDisabled = song === playlist.length - 1 || isPlaylistEmpty;

  return (
    <Wrapper>
      {!!error && (
        <>
          <Alert severity="error">{error}</Alert>
          <Separator height={8} />
        </>
      )}
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio
        src={src}
        ref={playerRef}
        onPause={() => setIsPlaying(false)}
        onPlay={() => setIsPlaying(true)}
        onEnded={() => setIsPlaying(false)}
        onLoadedMetadata={handleLoadedMetadata}
        onTimeUpdate={handleTimeUpdate}
        onError={handleErrorInfo}
      >
        Your browser does not support the <code>audio</code> element.
      </audio>
      <div>
        <Typography variant="h3" data-testid="song-title">
          {title}
        </Typography>

        {!!subtitle && (
          <>
            <Separator height={18} />
            <Subtitle variant="caption">{subtitle}</Subtitle>
          </>
        )}
      </div>
      <Separator height={35} />
      <div>
        <Visualization>
          <Bars>
            {bars.map((size, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Bar key={index} style={{ height: size }} />
            ))}
          </Bars>

          <Progress style={{ width: `${getDurationInPercent(currentTime, duration)}%` }}>
            <Bars>
              {bars.map((size, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <BarFilled key={index} style={{ height: size }} />
              ))}
            </Bars>
          </Progress>
          <Separator height={14} />
          <Times>
            <Time variant="caption">{formatDuration(currentTime)}</Time>
            <Time variant="caption">{formatDuration(duration)}</Time>
          </Times>
        </Visualization>
      </div>
      <Separator height={19} />
      <Controls>
        <IconButton
          size="small"
          onClick={() => handleSongChange(PREV_SONG)}
          disabled={isPrevSongDisabled}
          data-testid="prev"
        >
          <Icon icon="SkipPrevious" />
        </IconButton>
        <PlayButton onClick={handlePlayClick} disabled={isPlayButtonDisabled} data-testid="play">
          <Icon icon={isPlaying ? 'Pause' : 'PlayArrow'} />
        </PlayButton>
        <IconButton
          size="small"
          onClick={() => handleSongChange(NEXT_SONG)}
          disabled={isNextSongDisabled}
          data-testid="next"
        >
          <Icon icon="SkipNext" />
        </IconButton>
      </Controls>
    </Wrapper>
  );
};
