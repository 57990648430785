import { makeStyles } from '@material-ui/core';

const controlsWidth = 303;

export const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  evalidateform: {
    marginTop: spacing(5),
  },
  textField: {
    height: spacing(3.125),
    float: 'left',
    marginRight: spacing(6.875),
    marginLeft: spacing(3.25),
    width: spacing(42.5),

    [breakpoints.down('sm')]: {
      width: spacing(30.625),
      marginRight: spacing(0),
      marginLeft: spacing(2.25),
      padding: spacing(2.5),
    },
  },

  textFieldRoot: {
    '& label+*': {
      marginTop: '0 !important',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      marginLeft: spacing(1.5),
      [breakpoints.down('sm')]: {
        marginLeft: spacing(0.75),
      },
    },
    '& input': {
      [breakpoints.down('sm')]: {
        marginTop: spacing(-0.5),
      },
    },
  },

  buttonNext: {
    backgroundColor: palette.green.dark,
    color: palette.white.main,
    fontFamily: 'Roboto bold',
    fontWeight: 'bold',
    float: 'right',
    margin: 'auto',
    marginTop: spacing(3),
    marginBottom: spacing(6.875),
    marginRight: spacing(3.125),
    borderRadius: spacing(0.5),
    textTransform: 'initial',
    width: spacing(42.5),
    height: spacing(5),
    [breakpoints.down('sm')]: {
      width: spacing(30.625),
      marginRight: spacing(2),
    },
  },
  recaptcha: {
    marginLeft: spacing(5.625),
    marginTop: spacing(1.5),
    transform: 'scale(1.1)',
    '& iframe': {
      [breakpoints.down('md')]: {
        width: controlsWidth,
      },
      [breakpoints.down('sm')]: {
        width: controlsWidth,
        marginLeft: 0,
        marginRight: spacing(2),
      },
    },
    '& p': {
      padding: `0 !important`,
      textAlign: 'left',
      width: spacing(25),
    },
    [breakpoints.down('sm')]: {
      transform: 'scale(0.8)',
      marginLeft: spacing(0),
      marginTop: spacing(0.625),
      marginRight: spacing(20),
      width: spacing(23),
      padding: '0 !important',
      textAlign: 'left',
    },
  },
  label: {
    fontSize: spacing(1.75),
    fontFamily: 'Roboto',
    float: 'left',
    marginLeft: spacing(3.25),
    [breakpoints.down('sm')]: {
      marginLeft: spacing(2.5),
    },
  },
}));
