import React, { useRef, useEffect } from 'react';

import { Icon } from '../../icon/Icon';
import { connectControl } from '../connectControl/connectControl';
import { controlBase } from '../controlBase/ControlBase';

import { useOnClickOutside } from 'shared/hooks/useClickOutside/useOnClickOutside';
import { Wrapper, FileName, Input, PencilButton } from './File.styles';
import { FileProps } from './File.types';

export const File: React.FC<FileProps> = ({
  name,
  onBlur,
  onChange,
  onClick,
  onFocus,
  placeholder = 'control.file.select_file',
  value,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(() => {
    if (onBlur) {
      onBlur();
    }
  }, wrapperRef.current);

  const handleOnClick = () => {
    if (onFocus) {
      onFocus();
    }

    if (onClick) {
      onClick();
    }
  };

  const handleOnChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const { files } = event.currentTarget;

    if (files && files.length !== 0 && files[0]) {
      const nextFile = files[0];

      if (onChange) {
        onChange(nextFile);
      }
    }

    if (onBlur) {
      onBlur();
    }
  };

  useEffect(() => {
    if (value) {
      if (onBlur) {
        onBlur();
      }
    }
  }, [onBlur, value]);

  return (
    <Wrapper ref={wrapperRef}>
      <PencilButton onClick={handleOnClick}>
        <Icon icon="Create" />
      </PencilButton>
      <FileName variant="caption" onClick={handleOnClick}>
        {(value && value.name) || placeholder}
      </FileName>
      <Input ref={inputRef} type="file" name={name} onChange={handleOnChange} />
    </Wrapper>
  );
};

const FileControl = controlBase<FileProps>(File);

export const FileField = connectControl<FileProps>(FileControl);
