import { Button, Card, Typography } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';

import { LoginContainer } from '../verifyEmail/VerifyEmail.styles';
import { HeaderSection } from '../headerSection/HeaderSection';
import { InstituteSection } from '../instituteSection/InstituteSection';

import { useStyles } from './StartChat.styles';
import { TextContainer } from 'app/root/intro/graduateSection/GraduateSection.styles';

export const StartChat = () => {
  const styles = useStyles();
  const { formatMessage } = useIntl();

  return (
    <LoginContainer>
      <TextContainer>
        <HeaderSection />
        <Card className={styles.card}>
          <Typography className={styles.subtitle}>
            {formatMessage({ id: 'main_page.chatEmail_page.subtitle' })}
          </Typography>

          <Typography className={styles.description}>
            {formatMessage({ id: 'main_page.chatEmail_page.description' })}
          </Typography>

          <Button
            variant="contained"
            className={styles.buttonLabel}
            onClick={() => {
              window.location.href = 'mailto:talk@stageclip.com';
            }}
          >
            {formatMessage({ id: 'main_page.chatEmail_page.button' })}
          </Button>
        </Card>
        <InstituteSection />
      </TextContainer>
    </LoginContainer>
  );
};
