import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
  header: {
    color: palette.black.main,
    fontSize: spacing(6),
    fontWeight: 'bold',
    marginLeft: 'auto',
    marginRight: 'auto',
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  subheader: {
    color: palette.black.main,
    fontSize: spacing(6),
    fontWeight: 'bold',
    [breakpoints.down('sm')]: {
      display: '28vw',
    },
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileheader: {
    display: 'none',
    color: palette.black.main,
    fontSize: spacing(6),
    fontWeight: 'bold',
    [breakpoints.down('sm')]: {
      display: 'block',
      top: spacing(12.5),
      width: '100%',
      left: spacing(0),
      fontSize: spacing(3.25),
    },
  },
  mobilesubheader: {
    display: 'none',
    width: spacing(37.5),
    color: palette.black.main,
    fontSize: spacing(6),
    fontWeight: 'bold',
    [breakpoints.down('sm')]: {
      display: 'block',
      width: '100%',
      left: spacing(0),
      fontSize: spacing(3.25),
    },
  },
  title: {
    color: palette.gray.dark,
    fontSize: spacing(3.25),
    fontWeight: 'bold',
    [breakpoints.down('sm')]: {
      left: spacing(0),
      fontSize: spacing(2),
      width: '100%',
      top: spacing(23.125),
      color: palette.black.main,
    },
  },
  headertextContainer: {
    width: '100%',
  },
  imageContainer: {
    width: '100%',
    display: 'flex',
    [breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },

  logoImage: {
    marginTop: spacing(2.5),
    width: spacing(13.6125),
    height: spacing(6.725),
    alignContent: 'left',
    [breakpoints.up('md')]: {
      marginLeft: spacing(10),
    },
    [breakpoints.down('sm')]: {
      marginTop: spacing(1.25),
      marginLeft: spacing(0),
    },
  },
}));
