export type SnapchatUrl = string | null;

export enum NameRecordings {
  MarchingOrder = 'MarchingOrder',
  AudioAi = 'AudioAi',
  SelfRecord = 'SelfRecord',
}

export enum ProviderType {
  StageClip = 'StageClip',
  HerffJonesCollege = 'HerffJonesCollege',
  HerffJonesHighSchool = 'HerffJonesHighSchool',
  MarchingOrder = 'MarchingOrder',
}

export type ParticipantDataResponse = {
  deadlineDate: string;
  active: boolean;
  state: string;
  file: string | null;
  fileDurationLimit: number;
  image: string | null;
  message: string | null;
  slide: boolean;
  survey?: string;
  snapchat: boolean;
  snapchatUrl: SnapchatUrl;
  snapcode?: string;
  logo: string | null;
  fullName: string;
  institution?: {
    id?: string;
    instagramAccountName?: string;
    instagramHashtag?: string;
    logoUrl?: string;
    primaryColour?: string;
    secondaryColour?: string;
    name?: string;
    attendanceConfirmation: boolean;
  };
  producerLanguageInfo: any;
  pronunciationName: string;
  languageId: string;
  voiceActorId: string;
  nameRecordings: NameRecordings;
  pronunciationAudio: string;
  providerType: ProviderType;
  attendance?: boolean;
  audioPath?: string | null;
  eventId?: string;
};

export type StudentReviewResponse = {
  url: string;
};
