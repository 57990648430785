import React from 'react';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { createMuiTheme } from '@material-ui/core';

import { Typography } from 'shared/components/typography/Typography';
import { typography } from 'app/core/theme/typography';
import { palette } from 'app/core/theme/palette';
import { TypographyProps } from 'shared/components/typography/Typography.types';

const theme = createMuiTheme({
  palette: palette(),
  typography: {
    ...typography,
    fontFamily: "'Annie Use Your Telescope', cursive;",
    body1: {
      ...typography.body1,
      fontSize: 20,
      fontWeight: 'bold',
    },
  },
});

export const DecorationTypography = ({ children, color = 'primary', ...props }: Omit<TypographyProps, 'variant'>) => {
  return (
    <ThemeProvider theme={theme}>
      <Typography {...props} variant="body1" color={color}>
        {children}
      </Typography>
    </ThemeProvider>
  );
};
