import React, { useRef } from 'react';
import { Grid, Divider } from '@material-ui/core';
import { useField, FormSpy } from 'react-final-form';

import { useStyles } from './DropzoneComponent.styles';
import { DropzoneComponentProps } from './DropzoneComponent.types';
import { AVUserAzureFileUpload } from 'shared/components/controls';
import { useAVUserQueryData } from 'contexts/avUserQueryData/avUserQueryDataContext';
import { VideoRotation } from 'shared/components/videoRotation/VideoRotation';
import { VideoRotationProps } from 'shared/components/videoRotation/VideoRotation.types';
import { SectionMode } from 'shared/components/expandingSection/Section.types';
import { createFetchAVUserUpload } from 'api/actions/avUser/avUser.actions';
import { AvUserFileUploadStreamZoneContainer } from 'shared/components/controls/avUserFileUploadStreamZone/AvUserFileUploadStreamZoneContainer';

const allowedExtensions = ['mp4', 'mov'];

const VideoPreview = (props: VideoRotationProps) => {
  const {
    input: { onChange },
  } = useField('rotation.video');

  return <VideoRotation {...props} onRotate={onChange} />;
};

const previews = {
  video: VideoPreview,
};

export const DropzoneComponent = ({
  validate = [],
  setFileInfo,
  avUserDetails,
  setEventDetails,
  eventDetails,
  fileInfo,
  ...props
}: DropzoneComponentProps) => {
  const classes = useStyles();
  const { avUserId } = useAVUserQueryData();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  return (
    <FormSpy>
      {() => (
        <Grid container className={classes.grid}>
          <Grid lg={5} sm={12}>
            <AVUserAzureFileUpload
              fetchUploadMetaAction={createFetchAVUserUpload({ avUserId, eventId: eventDetails?.id || '' })}
              accept={allowedExtensions}
              maxSize="100 GB"
              maxDuration={2000000}
              validate={validate}
              previews={previews}
              setFileInfo={setFileInfo}
              fileInputRef={fileInputRef}
              previewMode={SectionMode.Opened}
              {...props}
              avUserDetails={avUserDetails}
              setEventDetails={setEventDetails}
              eventDetails={eventDetails}
            />
          </Grid>
          <Grid lg={2}>
            <Divider orientation="vertical" className={classes.divider} />
          </Grid>
          <Grid lg={5} sm={12}>
            <AvUserFileUploadStreamZoneContainer eventDetails={eventDetails} {...props} />
          </Grid>
        </Grid>
      )}
    </FormSpy>
  );
};
