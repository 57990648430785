import React from 'react';

import { IconButton } from 'shared/components/iconButton/IconButton';
import { Icon } from 'shared/components/icon/Icon';
import { formatDuration, getDurationInPercent } from 'shared/utils/duration/duartion';
import { VideoControlsProps } from './VideoControls.types';
import { Controls, ProgressLine, ProgressPoint, Duration } from './VideoControls.styles';

export const VideoControls = ({
  handlePlay,
  handleMute,
  isPlaying,
  isMuted,
  currentTime,
  duration,
}: VideoControlsProps) => {
  return (
    <Controls>
      <IconButton onClick={handlePlay} color="inherit">
        <Icon icon={isPlaying ? 'Pause' : 'PlayArrow'} />
      </IconButton>
      <Duration>{formatDuration(duration)}</Duration>
      <ProgressLine>
        <ProgressPoint style={{ left: `${getDurationInPercent(currentTime, duration)}%` }} />
      </ProgressLine>
      <Duration>{formatDuration(currentTime)}</Duration>
      <IconButton onClick={handleMute} color="inherit">
        <Icon icon={isMuted ? 'VolumeOff' : 'VolumeUp'} />
      </IconButton>
    </Controls>
  );
};
