import { withStyles } from '@material-ui/core';

export const withCommonStyles = withStyles(({ transitions, palette, spacing }) => ({
  root: {
    minHeight: 51,
    borderRadius: 4,
    border: `1px solid ${palette.gray.main}`,
    transition: transitions.create(['border-color']),
    padding: '15px 20px',
    width: '100%',
    'label + &': {
      marginTop: spacing(3),
    },
    '&$focused': {
      borderColor: palette.primary.main,
    },
    '&$error': {
      borderColor: palette.error.main,
    },
    '&$disabled': {
      cursor: 'not-allowed',
    },
  },
  focused: {},
  error: {},
  disabled: {
    cursor: 'not-allowed',
  },
}));
