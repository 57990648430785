import React from 'react';

import { AspectRatio } from '../aspectRatio/AspectRatio';

import { StyledVideo } from './Video.styles';
import { VideoProps } from './Video.types';

export const Video: React.FC<VideoProps> = ({ src, ratio }) => {
  const video = (
    <StyledVideo
      src={src}
      preload="metadata"
      onLoadedMetadata={({ currentTarget }) => {
        // load first frame of the video to create poster on mobile devices
        const videoElement = currentTarget;
        videoElement.currentTime = 0.0;
      }}
      controls
      playsInline
    />
  );

  if (ratio) {
    return <AspectRatio ratio={ratio}>{video}</AspectRatio>;
  }

  return video;
};
