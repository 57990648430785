import { makeStyles } from '@material-ui/core';

import backgroundImg from 'assets/self-registration-background.jpeg';

export const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  header: {
    fontSize: spacing(3),
    color: palette.black.main,
    fontWeight: 800,
    fontFamily: 'Open Sans',
    width: 'auto',
    marginBottom: spacing(1),
    display: 'flex',
    justifyContent: 'flex-start',

    [breakpoints.only('sm')]: {
      fontSize: spacing(3),
      marginBottom: spacing(2),
    },

    // [breakpoints.down('lg')]: {
    //   fontSize: h3.fontSize,
    //   lineHeight: h3.lineHeight,
    // },
  },
  footerContainer: {
    '& footer': {
      position: 'relative',
    },
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },
  cardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: spacing(60),
    maxWidth: '90%',
    overflowY: 'auto',
  },
  card: {
    marginTop: spacing(2),
    background: palette.white.main,
    // borderRadius: spacing(1.5),
    padding: spacing(3, 4.25, 3, 4.25),
    marginLeft: 'auto',
    // border: '1px solid black',
    // maxHeight: '90vh',
    // boxShadow: '1px 2px 7px -1px rgba(0,0,0,0.30)',
    boxShadow: '1px -2px 8px 0px rgba(0,0,0,0.30)',
    marginRight: 'auto',
    textAlign: 'left',
    width: '100%',
    justifyContent: 'center',
    [breakpoints.down('sm')]: {
      padding: spacing(3, 4.25, 2, 4.25),
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    // marginTop: spacing(4),
    backgroundColor: palette.green.dark,
    color: palette.white.main,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: palette.scorpionGray.dark,
    },
    '& .MuiButton-label': {
      fontSize: spacing(3),
      letterSpacing: 'normal',
      fontFamily: 'Open Sans',
      fontWeight: 700,
      lineHeight: '32.68px',
    },
    '&.Mui-disabled': {
      backgroundColor: '#e0e0e0',
    },
  },
  bottomContainer: {
    '& .MuiTypography-body1': {
      fontFamily: 'Inter',
      fontWeight: '400 !important',
      lineHeight: '32.68px',
      fontSize: spacing(1.525),
      color: '#71717A',
      [breakpoints.down('sm')]: {
        lineHeight: '15px',
        // marginTop: spacing(1.375),
      },
    },
  },
  bottomContainerAmazon: {
    '& .MuiTypography-body1': {
      fontFamily: 'Inter',
      fontWeight: '400 !important',
      lineHeight: '32.68px',
      fontSize: spacing(1.525),
      color: '#71717A',
      [breakpoints.down('sm')]: {
        lineHeight: '15px',
        fontSize: spacing(1),
        // marginTop: spacing(1.375),
      },
    },
  },
  checkboxContainer: {
    '& div > .MuiFormLabel-root': {
      display: 'none',
    },
    '& .MuiButtonBase-root': {
      color: palette.green.dark,
      padding: spacing(0.5),
    },
    '& div > .MuiFormHelperText-root': {
      display: 'none',
    },
    '& .MuiFormControlLabel-root': {
      marginRight: '9px',
    },
  },
  snackBar: {
    '& .MuiSnackbarContent-root': {
      backgroundColor: palette.red.light,
    },
  },
  mainContainer: {
    overflow: 'hidden',
    backgroundImage: `url(${backgroundImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    // height: '100vh',
    minHeight: '100vh',
    [breakpoints.down('sm')]: {
      backgroundImage: 'none',
      backgroundColor: '#F9F8F8',
    },
  },
  stepperContainer: {
    marginTop: spacing(2),
  },
  logoImage: {
    maxHeight: spacing(15),
    objectFit: 'contain',
    [breakpoints.up('md')]: {
      marginLeft: spacing(0),
    },
    [breakpoints.down('sm')]: {
      marginTop: spacing(1.25),
      marginLeft: spacing(0),
      maxWidth: '80%',
    },
    textAlign: 'center',
  },
  subTitle: {
    color: '#000000',
    fontWeight: spacing(75),
    fontFamily: 'Open Sans',
    fontSize: spacing(3),
    lineHeight: '43.58px',
    [breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: spacing(1.75),
      fontWeight: spacing(50),
      fontFamily: 'Open Sans',
      lineHeight: '19.07px',
    },
  },
  titleContainer: {
    color: '#000000',
    marginTop: spacing(2),
    fontSize: spacing(4),
    fontWeight: spacing(100),
    fontFamily: 'Open Sans',
    lineHeight: '43.58px',
    [breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: spacing(3),
      fontWeight: spacing(87.5),
      fontFamily: 'Open Sans',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: spacing(3, 0),
  },
  textLabel: {
    color: '#1F2933',
    fontWeight: spacing(75),
    fontFamily: 'Open Sans',
    lineHeight: '21.79px',
    fontSize: spacing(2),
    '& div > .MuiInputLabel-root': {
      width: spacing(43.75),
    },
  },
  textFieldRoot: {
    [breakpoints.down('sm')]: {
      '& .MuiInputLabel-root': {
        width: spacing(43.75),
      },
    },
  },
  emailClipsReadyTitle: {
    fontWeight: 400,
    lineHeight: '21.79px',
    fontFamily: 'Open Sans',
    fontSize: spacing(2),
    color: '#000000',
  },

  recaptcha: {
    marginLeft: spacing(8),
    marginBottom: spacing(1.5),
    transform: 'scale(1.1)',
    '& p': {
      padding: `0 !important`,
      textAlign: 'left',
      width: spacing(25),
    },
    [breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transform: 'scale(0.8)',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: spacing(0.625),
      width: spacing(20),
      padding: '0 !important',
      textAlign: 'center',
    },
  },

  supportText: {
    color: '#000000',
    fontWeight: spacing(0),
    fontFamily: 'Open Sans',
    textAlign: 'center',
    fontSize: spacing(2),
    [breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: spacing(1.75),
      fontWeight: spacing(0),
      fontFamily: 'Open Sans',
      // lineHeight: '19.07px',
      marginTop: spacing(1),
    },
  },
  buttonLoader: {
    display: 'flex',
    justifyContent: 'center',
  },
}));
