import React, { useMemo } from 'react';
import omit from 'lodash/omit';
import pick from 'lodash/pick';

import { FormControl } from './formControl/FormControl';
import { Label } from './label/Label';
import { FormHelperText } from './formHelperText/FormHelperText';
import { FormControlStateProvider } from './provider/ControlBaseContextProvider';
import { withCommonStyles } from './ControlBase.styles';
import { withBorder } from './withBorder/withBorder';
import { withCommonBehavior } from './withCommonBehavior/withCommonBehavior';
import { ControlBaseProps, ControlBaseConfig } from './ControlBase.types';

const formControlProps = [
  'classes',
  'color',
  'component',
  'disabled',
  'error',
  'fullWidth',
  'hiddenLabel',
  'margin',
  'required',
  'size',
  'variant',
  'onFocus',
  'onBlur',
];

const controlProps = [...formControlProps, 'hiddenError', 'helperText', 'label'];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const controlBase = <ControlProps extends any>(
  RawControl: React.ComponentType<ControlProps>,
  config?: ControlBaseConfig,
) => {
  type Props = ControlBaseProps & ControlProps;
  const { inline } = config || { inline: false };

  // @ts-ignore
  const StyledControl = inline ? RawControl : withCommonStyles(withBorder<ControlProps>(RawControl));
  const Control = withCommonBehavior<ControlProps>(StyledControl);

  return ({ name, fullWidth = true, success, label, helperText, hiddenError, ...props }: Props) => {
    const id = useMemo(() => name || Date.now().toString(), [name]);

    const contextValues = {
      success,
      name,
      id,
      hiddenError,
    };

    if (inline) {
      return (
        <div>
          <FormControlStateProvider values={{ ...contextValues, ...pick(props, formControlProps) }}>
            <Label>&nbsp;</Label>
            <Control {...(omit({ id, ...props }, controlProps) as ControlProps)} label={label} />
            <FormHelperText>{helperText || <span>&nbsp;</span>}</FormHelperText>
          </FormControlStateProvider>
        </div>
      );
    }

    return (
      <FormControl {...pick(props, formControlProps)} fullWidth={fullWidth}>
        <FormControlStateProvider values={contextValues}>
          <Label>{label}</Label>
          <Control {...(omit({ id, ...props }, controlProps) as ControlProps)} />
          <FormHelperText>{helperText || <span>&nbsp;</span>}</FormHelperText>
        </FormControlStateProvider>
      </FormControl>
    );
  };
};
