import { isMsBrowser } from 'shared/utils/isMsBrowser/isMsBrowser';

export const getUrlAsFile = async (url: string, filename = 'filename') => {
  const data = await fetch(url)
    .then(response => response.blob())
    .catch(() => new Blob([], { type: 'image/png' }));

  if (isMsBrowser) {
    // MS doesn't support creation files from File constructor
    const extension = data.type.split('/')[1];
    const file = data;
    // @ts-ignore
    file.name = `${filename}.${extension}`;
    // @ts-ignore
    file.lastModifiedDate = new Date();
    // @ts-ignore
    file.lastModified = +new Date();

    return file as File;
  }

  return new File([data], filename, { type: data.type });
};
