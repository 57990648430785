/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import isObject from 'lodash/isObject';

import { useLocale } from 'shared/hooks';
import { prettify } from './prettify/prettify';
import { Translation, TranslationObject } from './useTranslations.types';

const isTranslationString = (value: any): value is string => {
  if (typeof value === 'string') {
    return /^([a-z0-9_]+\.)([a-z0-9_]+\.?)+[^.]$/.test(value);
  }

  return false;
};

const isString = (translated: any): translated is string => typeof translated === 'string';
const isTranslationObject = (value: any): value is TranslationObject =>
  isObject(value) && Object.keys(value).length <= 2 && Object.keys(value).includes('id');

export const useTranslations = (value: Translation, markup = true) => {
  const { formatMessage } = useLocale();

  return useMemo(() => {
    if (isTranslationString(value)) {
      const translated = formatMessage({ id: value });

      return { message: markup && isString(translated) ? prettify(translated) : translated };
    }

    if (isTranslationObject(value)) {
      const { id, values } = value;
      const translated = formatMessage({ id }, values);

      return { message: translated };
    }

    return { message: value };
  }, [value, formatMessage, markup]);
};
