import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useIntl } from 'react-intl';

import { useStyles } from './InstituteSection.styles';
import fiuLogo from 'assets/floridaUni.png';
import birLogo from 'assets/birminghamUni.png';
import oxLogo from 'assets/oxfordUni.png';
import hopLogo from 'assets/hopkinsUni.png';
import { Image } from 'shared/components';

export const InstituteSection = () => {
  const styles = useStyles();
  const { formatMessage } = useIntl();

  return (
    <div className={styles.instituteContainer}>
      <Typography className={styles.textLine}>{formatMessage({ id: 'main_page.upload_page.text' })}</Typography>

      <div className={styles.maincolumn}>
        <div className={styles.subcolumn}>
          <Image alt="" src={fiuLogo} />
        </div>
        <div className={styles.subcolumn}>
          <Image alt="" src={birLogo} />
        </div>
        <div className={styles.subcolumn}>
          <Image alt="" src={oxLogo} />
        </div>
        <div className={styles.subcolumn}>
          <Image alt="" src={hopLogo} />
        </div>
      </div>
    </div>
  );
};
