import React from 'react';
import classNames from 'classnames';

import { ReactComponent as LoaderImage } from 'assets/loader.svg';
import { useStyles } from './Loader.styles';
import { LoaderProps } from './Loader.types';

export const Loader: React.FC<LoaderProps> = ({ fullScreen, withBackground }) => {
  const styles = useStyles();

  const wrapperClassNames = classNames(styles.root, {
    [styles.fullScreen]: fullScreen,
    [styles.withBackground]: withBackground,
  });

  return (
    <div className={wrapperClassNames}>
      <LoaderImage />
    </div>
  );
};
