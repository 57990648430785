import React from 'react';
import { useIntl } from 'react-intl';
import { match as Match } from 'react-router-dom';
import { Location } from 'history';
import { isBefore, parseISO } from 'date-fns';
import MenuItem from '@material-ui/core/MenuItem';
import { SelectProps } from '@material-ui/core';

import { useLocale } from 'shared/hooks';
import { useParticipantData } from 'contexts/participantData/participantDataContext';
import { ExternalLink } from 'shared/components/externalLink/ExternalLink';
import { useParticipantQueryData } from 'contexts/participantQueryData/participantQueryDataContext';
import { NavHashLink } from 'shared/components/routerHashLink/HashLink';
import { Flag, Item, Wrapper } from './Nav.styles';
import { Locale } from 'contexts/locale/localeContext/localeContext.types';

export const FAQ_LINK = 'https://support.stageclip.com/category/15-faq';
const PAGES = ['/', '/email'];

export const Nav = ({
  link: Link,
  select: LanguageSelect,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  link: React.ComponentType<any>;
  select: React.ComponentType<SelectProps>;
}) => {
  const { formatMessage } = useIntl();
  const { participantId, secret } = useParticipantQueryData();
  const participantData = useParticipantData();

  const isParticipantLoaded = !!Object.keys(participantData).length;
  const isBeforeDeadline = isParticipantLoaded ? isBefore(new Date(), parseISO(participantData.deadlineDate)) : false;
  const uploadAvailable = !!participantId && !!secret && isParticipantLoaded && isBeforeDeadline;

  const message = (id: string) => formatMessage({ id });
  const localeContext = useLocale();

  const onLanguageChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    localStorage.setItem('language', event.target.value as Locale);

    return localeContext?.updateLocale(Locale[event.target.value as Locale]);
  };

  return (
    <Wrapper>
      <Item>
        <Link
          component={NavHashLink}
          to={{
            pathname: '/',
            search: uploadAvailable ? `?token=${participantId}:${secret}` : undefined,
            hash: uploadAvailable ? '#upload-form' : undefined,
          }}
          activeClassName="active"
          isActive={(match: Match, { pathname }: Location) => PAGES.includes(pathname)}
        >
          {uploadAvailable ? message('virtual_graduation.nav.upload_video') : message('virtual_graduation.nav.home')}
        </Link>
      </Item>
      <Item>
        <Link component={ExternalLink} href="/about-us" target="_blank">
          {message('virtual_graduation.nav.about_us')}
        </Link>
      </Item>
      <Item>
        <Link component={ExternalLink} href={FAQ_LINK} target="_blank">
          {message('virtual_graduation.nav.faq')}
        </Link>
      </Item>
      <LanguageSelect value={localeContext?.locale} onChange={onLanguageChange} style={{ display: 'none' }}>
        <MenuItem value="en">
          <Flag countryCode="GB" />
          &nbsp;&nbsp;EN-GB
        </MenuItem>
        <MenuItem value="fr">
          <Flag countryCode="FR" />
          &nbsp;&nbsp;FR-CA
        </MenuItem>
      </LanguageSelect>
    </Wrapper>
  );
};
