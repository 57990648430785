import React from 'react';

import { Separator } from 'shared/components/separator/Separator';
import { Typography } from 'shared/components/typography/Typography';
import { RestoreUploadFormSuccessProps } from './RestoreUploadFormSuccess.types';
import { Wrapper, BackButton } from './RestoreUploadFormSuccess.styles';
import { ReactComponent as EmailSent } from 'assets/email_sent.svg';

export const RestoreUploadFormSuccess = ({ goBack }: RestoreUploadFormSuccessProps) => {
  return (
    <Wrapper>
      <EmailSent />
      <Separator height={15} />
      <Typography align="left">
        {{
          id: 'virtual_graduation.email.success.subtitle',
          values: {
            a: msg => <BackButton onClick={goBack}>{msg}</BackButton>,
          },
        }}
      </Typography>
    </Wrapper>
  );
};
