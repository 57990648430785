import React from 'react';
import classNames from 'classnames';
import { InputBaseProps } from '@material-ui/core';

import { controlBase, useFormControlState } from '../controlBase';

import { useTranslations } from 'shared/hooks';
import { EndAdornment } from './endAdornment/EndAdornment';
import { StyledInput, Counter, useStyles } from './Input.styles';
import { TextFieldProps } from './Input.types';
import { EndAdornmentProps } from './endAdornment/EndAdornment.types';

export const Input: React.FC<TextFieldProps> = ({
  classes,
  counter,
  defaultValue,
  disabled,
  error,
  id,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  required,
  success,
  type,
  value,
  maxLength,
  rowsMax,
  multiline,
  rows,
  readonly,
  autoFocus,
}) => {
  const styles = useStyles();

  const inputClassNames = classNames({
    [styles.success]: success,
  });

  const endAdornmentProps: EndAdornmentProps = {
    success,
    error,
  };

  const { message: translatedPlaceholder } = useTranslations(placeholder);

  const inputProps: InputBaseProps = {
    endAdornment: <EndAdornment {...endAdornmentProps} />,
    className: inputClassNames,
    defaultValue,
    disabled,
    id,
    name,
    onBlur,
    onChange,
    onFocus,
    placeholder: translatedPlaceholder as string | undefined,
    required,
    type,
    value,
    classes,
    inputProps: {
      maxLength,
    },
    rowsMax,
    multiline: multiline || !!rowsMax || !!rows,
    rows,
    readOnly: readonly,
    autoFocus,
  };

  return (
    <>
      <StyledInput {...inputProps} />
      {counter && maxLength && (
        <Counter variant="caption">
          {(value || '').length}/{maxLength}
        </Counter>
      )}
    </>
  );
};

export const InputFormControl: React.FC<TextFieldProps> = props => {
  const { success, error, disabled, required, onBlur, onFocus, name, id } = useFormControlState();

  return (
    <Input
      success={success}
      error={error}
      disabled={disabled}
      required={required}
      onBlur={onBlur}
      onFocus={onFocus}
      name={name}
      id={id}
      {...props}
    />
  );
};

export const InputControl = controlBase<TextFieldProps>(InputFormControl);
