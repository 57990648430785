import React from 'react';

import { AspectRatio } from '../aspectRatio/AspectRatio';

import { useTranslations } from 'shared/hooks';
import { Img } from './Image.styled';
import { ImageProps } from './Image.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isString = (alt: any): alt is string => typeof alt === 'string';

export const Image = ({ src, alt, className, ratio }: ImageProps) => {
  const { message } = useTranslations(alt, false);

  if (isString(message)) {
    const content = <Img className={className} src={src} alt={message} />;

    if (typeof ratio !== 'undefined') {
      return <AspectRatio ratio={ratio}>{content}</AspectRatio>;
    }

    return <>{content}</>;
  }

  throw Error('img alt must be a string');
};
