import React, { ComponentType } from 'react';
import { FormControlProps } from '@material-ui/core';
import classNames from 'classnames';

import { StyledFormControl, useStyles } from './FormControl.styles';

export const FormControl: ComponentType<FormControlProps> = ({ className, ...props }) => {
  const styles = useStyles();
  const { disabled } = props;

  const formControlClassNames = classNames(className, {
    [styles.disabled]: disabled,
  });

  return <StyledFormControl {...props} className={formControlClassNames} />;
};
