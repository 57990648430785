import React from 'react';
import { useIntl } from 'react-intl';

import { FAQ_LINK } from 'shared/templates/main/nav/Nav';
import { Wrapper } from './Nav.styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Nav = ({ link: Link }: { link: React.ComponentType<any> }) => {
  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      <Link href="/">{formatMessage({ id: 'virtual_graduation.nav.home' })}</Link>
      <Link href="/about-us">{formatMessage({ id: 'virtual_graduation.nav.about_us' })}</Link>
      <Link href={FAQ_LINK}>{formatMessage({ id: 'virtual_graduation.nav.faq' })}</Link>
    </Wrapper>
  );
};
