import React from 'react';

import { email } from 'shared/validators';
import { Form } from 'shared/components/form/Form';
import { Separator } from 'shared/components/separator/Separator';
import { Button } from 'shared/components/button/Button';
import { RecaptchaField } from 'shared/components/controls/recaptchaField/RecaptchaField';
import { InputField } from 'shared/components/controls/input';
import { useStyles } from './RestoreUploadLinkForm.styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const RestoreUploadLinkForm = ({ onSubmit }: { onSubmit: any }) => {
  const styles = useStyles();

  return (
    <Form
      onSubmit={onSubmit}
      errorSeparator={20}
      render={({ handleSubmit, invalid }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Separator height={15} />
          <InputField
            label="Email"
            name="email"
            fullWidth
            type="email"
            required
            validate={[email()]}
            className={styles.input}
          />
          <Separator height={5} />
          <div className={styles.recaptcha}>
            <RecaptchaField name="token" />
          </div>
          <Separator height={15} />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            disabled={invalid}
            className={styles.button}
          >
            form.submit
          </Button>
        </form>
      )}
    />
  );
};
