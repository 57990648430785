import React from 'react';

import { SimplePage } from 'shared/templates/simplePage/SimplePage';
import { ScrollToTop } from 'shared/components/scrollToTop/ScrollToTop';
import icon from 'assets/icons/email_bg.svg';

export const Unsubscribe = () => {
  return (
    <>
      <ScrollToTop />
      <SimplePage icon={icon} header="virtual_graduation.unsubscribe.title">
        <SimplePage.Content>virtual_graduation.unsubscribe.subtitle</SimplePage.Content>
      </SimplePage>
    </>
  );
};
