import React from 'react';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';

import { GridContainerProps } from './GridContainer.types';
import { useStyles } from './GridContainer.styles';

export const GridContainer: React.FC<GridContainerProps> = ({
  className,
  fullHeight,
  disableVerticalMargins,
  ...props
}) => {
  const styles = useStyles();

  const gridClassNames = classNames(className, {
    [styles.fullHeight]: fullHeight,
    [styles.disableVerticalMargins]: disableVerticalMargins || fullHeight,
  });

  return <Grid container spacing={3} {...props} className={gridClassNames} />;
};
