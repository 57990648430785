import { AxiosInstance } from 'axios';

export type PostActivity = {
  activityTrackClient: AxiosInstance;
  body: Activity;
};

export type Activity = {
  activityCode: ParticipantActivityCode | EventVideoActivityCode;
  email?: string | null | undefined;
  channel: string;
  institution: (string | undefined)[];
  activityType: ActivityTypes;
  userName: string | undefined;
  data: {
    description: string | undefined;
  };
  participantId?: string;
};

export enum ActivityTypes {
  Participant = 'PARTICIPANT',
  EventVideo = 'EVENT_VIDEO',
}

export enum ParticipantActivityCode {
  ProducerParticipantMediaUploaded = 'PRODUCER_PARTICIPANT_MEDIA_UPLOADED',
  ProducerParticipantMessageAdded = 'PRODUCER_PARTICIPANT_MESSAGE_ADDED',
  ProducerParticipantMediaEdited = 'PRODUCER_PARTICIPANT_MEDIA_EDITED',
}

export enum EventVideoActivityCode {
  ProducerVideoUploaded = 'PRODUCER_VIDEO_UPLOADED',
  ProducerVideoUploadFailed = 'PRODUCER_VIDEO_UPLOAD_FAILED',
  ProducerVideoTryToUpload = 'PRODUCER_VIDEO_TRY_TO_UPLOAD',
}
