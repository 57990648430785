import { makeStyles } from '@material-ui/core';

// import { CustomStepperProps } from './selfRegistration/HighSchoolSelfRegistration.types';

export const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  stepperContainer: {
    // backgroundColor: activeStep === 1 ? '#F9F8F8' : 'inherit',
    backgroundColor: 'inherit',
    padding: spacing(0),
    marginTop: spacing(2),
    '& svg': {
      color: palette.gray.light,
    },
    '& .MuiStepIcon-text': {
      fontSize: spacing(2),
    },
    [breakpoints.only('sm')]: {
      backgroundColor: '#F9F8F8',
    },
  },

  activeIcon: {
    '& .MuiStepIcon-root.MuiStepIcon-active': {
      color: `${palette.green.dark} !important`,
      '& text.MuiStepIcon-text': {
        fill: `${palette.white.main} !important`,
      },
    },
    '& .MuiStepIcon-root.MuiStepIcon-completed': {
      color: `${palette.green.dark} !important`,
      // '&:after': {
      //   display: 'none',
      // },
    },
  },
  labelText: {
    color: '#ffffff',
  },
}));
