import React from 'react';
import { Typography } from '@material-ui/core';

import stageClipIcon from 'assets/stageClipIcon.svg';
import { useStyles } from './studentLoginHeader.styles';
import { Image } from 'shared/components';

export const StudentLoginHeader = () => {
  const styles = useStyles();

  const onRedirectSubmit = () => {
    const supportURL = 'https://stageclip.com/student-support/';
    window.open(supportURL, '_blank');
  };

  return (
    <div className={styles.container}>
      <div>
        <Image className={styles.logoImage} alt="" src={stageClipIcon} />
      </div>
      <Typography className={styles.supportContainer} onClick={onRedirectSubmit}>
        Support
      </Typography>
    </div>
  );
};
