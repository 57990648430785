import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
  header: {
    color: palette.black.main,
    lineHeight: spacing(1),
    fontSize: spacing(6),

    fontWeight: 'bold',
  },

  title: {
    color: palette.gray.main,
    lineHeight: spacing(0.5),
    fontSize: spacing(3.25),
    fontWeight: 'bold',
  },

  subtitle: {
    color: palette.black.main,
    lineHeight: spacing(0.2),
    fontSize: spacing(3.25),
    marginBottom: spacing(0.5625),
    fontWeight: 'bold',
    marginTop: spacing(3.5625),
    [breakpoints.down('sm')]: {
      marginLeft: spacing(0),
    },
  },
  description: {
    color: palette.scorpionGray.light,
    textAlign: 'center',
    lineHeight: spacing(0.15),
    fontSize: spacing(2),
    fontFamily: 'Roboto',
    letterSpacing: spacing(0.02),
    marginBottom: spacing(-2.5),
    marginLeft: spacing(1.625),
    marginRight: spacing(1.625),
  },
  label: {
    fontSize: spacing(1.75),
    fontFamily: 'Roboto',
    float: 'left',
    marginLeft: spacing(2.875),
  },
  TextField: {
    height: spacing(4.5),
    width: spacing(37.875),
    float: 'left',
    marginRight: spacing(5.875),
    marginLeft: spacing(2.875),
  },

  buttonLabel: {
    backgroundColor: palette.blue.light,
    color: palette.white.main,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    float: 'right',
    margin: 'auto',
    marginTop: spacing(2.125),
    marginBottom: spacing(4.5),
    marginRight: spacing(4.75),
    borderRadius: spacing(0.5),
    textTransform: 'initial',
    width: spacing(12.5),
    height: spacing(5),
  },

  card: {
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: spacing(3),
    width: spacing(48.5),
    height: spacing(52.75),
    boxShadow: ` ${spacing(0, 0.5, 1, 0)}  ${palette.info.main}`,
    [breakpoints.down('sm')]: {
      marginTop: spacing(3),
      width: spacing(35),
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
}));
