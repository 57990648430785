import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
  stepCard: {
    borderRadius: spacing(1),
    width: '32vw',
    margin: 'auto',
    height: spacing(23.125),
    marginBottom: spacing(2.875),
    [breakpoints.down('sm')]: {
      textAlign: 'center',
      flexDirection: 'column',
      width: '90vw',
      margin: 'auto',
      height: spacing(17.5),
      marginTop: spacing(1.625),
      marginLeft: '5vw',
      marginRight: 'auto',
      marginBottom: spacing(2.5),
    },
  },

  topimage: {
    width: '100%',
    marginBottom: spacing(1.875),
    [breakpoints.down('sm')]: {
      width: '100%',
      height: spacing(25),
    },
  },
  cardTitle: {
    fontSize: spacing(2.625),
    marginBottom: spacing(1.125),
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
  },
  cardParagraph: {
    fontSize: spacing(2.25),
    width: '18vw',
    fontFamily: 'Roboto',
    lineHeight: spacing(0.2),
    color: palette.black.main,
    [breakpoints.down('sm')]: {
      fontSize: spacing(2),
      width: '57vw',
      marginRight: 'auto',
      marginTop: spacing(1.25),
    },
  },
  cardParagraphbottom: {
    fontSize: spacing(2.25),
    width: '20vw',
    fontFamily: 'Roboto',
    lineHeight: spacing(0.2),
    color: palette.black.main,
    [breakpoints.down('sm')]: {
      fontSize: spacing(2),
      width: '57vw',
      marginRight: 'auto',
      marginTop: spacing(1.25),
    },
  },
  cardcolumn: {
    width: '100%',
    textAlign: 'left',
    marginLeft: spacing(6.25),
    marginTop: spacing(5),
    [breakpoints.down('sm')]: {
      marginTop: spacing(1.25),
    },
  },
  imagecolumn: {
    width: spacing(10),
    textAlign: 'center',
    marginTop: spacing(1.25),
  },
  headlinecolumn: {
    width: '100%',
    [breakpoints.down('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'left',
    },
  },
  stepTwoTop: {
    marginLeft: spacing(2.5),
    marginTop: spacing(4.5),
  },
  stepTwoBottom: {
    marginLeft: spacing(2.5),
    marginTop: spacing(4),
  },
  buttonToday: {
    backgroundColor: palette.green.dark,
    color: palette.white.main,
    fontFamily: 'Roboto bold',
    float: 'left',
    fontWeight: 'bold',
    marginBottom: spacing(4),
    borderRadius: spacing(0.5),
    height: spacing(6.5),
    marginTop: spacing(3.125),
    [breakpoints.down('sm')]: {
      float: 'none',
      marginLeft: '5vw',
      marginRight: 'auto',
    },
    '&:hover': {
      backgroundColor: palette.scorpionGray.dark,
    },
  },
  headline: {
    fontSize: spacing(6),
    fontWeight: 'bold',
    textAlign: 'left',
    marginTop: spacing(2),
    lineHeight: spacing(0.15),
    width: spacing(68.625),
    color: palette.white.main,
    [breakpoints.down('sm')]: {
      fontSize: spacing(5.25),
      textAlign: 'left',
      marginLeft: '5vw',
      marginRight: 'auto',
      marginTop: spacing(0),
      width: '90vw',
    },
  },
  para: {
    fontSize: spacing(2),
    fontFamily: 'Roboto',
    textAlign: 'left',
    fontWeight: 'normal',
    lineHeight: spacing(0.1875),
    marginTop: spacing(3.625),
    width: spacing(64.875),
    color: palette.white.main,
    [breakpoints.down('sm')]: {
      marginLeft: '5vw',
      width: '90vw',
    },
  },

  stepContainerInner: {
    backgroundColor: palette.blue.light,
    height: '120vh',
    position: 'relative',
    clipPath: 'polygon(0% 14%, 0% 100%, 100% 86%, 100% 0%)',
    [breakpoints.down('sm')]: {
      height: spacing(156),
      clipPath: 'polygon(0 8%, 100% 5%, 100% 93%, 0% 95%)',
    },
  },
  stepContainerOuter: {
    backgroundColor: palette.info.light,
    height: '136vh',
    clipPath: 'polygon(0% 12%, 0% 100%, 100% 88%, 100% 0%)',
    paddingTop: '4vh',

    [breakpoints.down('sm')]: {
      marginTop: spacing(10),
      height: spacing(170),
      clipPath: 'polygon(0 8%, 100% 5%, 100% 93%, 0% 95%)',
    },
  },
  stepContainerMiddle: {
    backgroundColor: palette.blue.contrastText,
    height: '132vh',
    clipPath: 'polygon(0% 13%, 0% 97%, 100% 84%, 100% 0%)',
    paddingTop: '4vh',
    [breakpoints.down('sm')]: {
      height: spacing(165),
      clipPath: 'polygon(0 8%, 100% 5%, 100% 93%, 0% 95%)',
    },
  },

  title: {
    color: palette.white.main,
    fontSize: spacing(6),

    fontWeight: 'bold',
  },

  columnIch: {
    position: 'absolute',
    left: spacing(0),
    top: spacing(10.625),
    zIndex: spacing(1.25),
    marginLeft: spacing(-10.625),
  },

  raw: {
    display: 'flex',
    flex: 'raw',
    width: '100%',
    [breakpoints.down('sm')]: {
      textAlign: 'center',
      flexDirection: 'column',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  rawNi: {
    display: 'flex',
    flex: 'raw',
    width: '100%',
    [breakpoints.down('sm')]: {
      textAlign: 'center',
      flexDirection: 'column-reverse',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },

  columnMain: {
    width: '100vw',
    [breakpoints.down('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  card: {
    borderRadius: spacing(1.25),
    width: '400vw',
    margin: 'auto',
    [breakpoints.down('sm')]: {
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  cardContent: {
    margin: 'auto',
    [breakpoints.down('sm')]: {
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },

  cardRaw: {
    display: 'flex',
    flex: 'raw',
    width: '100%',
    [breakpoints.down('sm')]: {
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },

  paragraph: {
    fontSize: spacing(2),
    fontFamily: 'Roboto',
    textAlign: 'left',
    marginTop: spacing(2.25),
    fontWeight: 'initial',
    color: palette.gray.dark,
  },
}));
