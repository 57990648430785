import React from 'react';

import { OuterWrapper, InnerWrapper } from './AspectRatio.styles';
import { AspectRatioProps } from './AspectRatio.types';

export const AspectRatio: React.FC<AspectRatioProps> = ({ children, ratio }) => {
  return (
    <OuterWrapper ratio={ratio}>
      <InnerWrapper>{children}</InnerWrapper>
    </OuterWrapper>
  );
};
