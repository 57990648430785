import React from 'react';

import { useFormControlState } from '../useFormControlState/useFormControlState';

import { withCommonBehaviorProps } from './withCommonBehavior.types';

export const withCommonBehavior = <T extends withCommonBehaviorProps>(
  Control: React.ComponentType<Omit<T, keyof withCommonBehaviorProps>>,
) => ({ onFocus, onBlur, ...props }: T) => {
  const { onFocus: formControlOnFocus, onBlur: formControlOnBlur } = useFormControlState();

  const handleOnFocus = () => {
    formControlOnFocus();

    if (onFocus) {
      onFocus();
    }
  };

  const handleOnBlur = () => {
    formControlOnBlur();

    if (onBlur) {
      onBlur();
    }
  };

  return <Control {...props} onFocus={handleOnFocus} onBlur={handleOnBlur} />;
};
