import { updatableContextFactory } from 'shared/utils/contextFactory/updatableContextFactory';
import { ParticipantQueryContext } from './participantQueryDataContext.types';

const [
  ParticipantQueryDataProvider,
  useParticipantQueryDataContext,
  useUpdateParticipantQueryData,
] = updatableContextFactory<ParticipantQueryContext>({} as ParticipantQueryContext);

export const useParticipantQueryData = () => {
  const context = useParticipantQueryDataContext();

  if (context === undefined) {
    throw new Error('useParticipantQueryData must be used within an ParticipantQueryDataProvider');
  }

  return context;
};

export { ParticipantQueryDataProvider, useUpdateParticipantQueryData };
