import { makeStyles, styled, Theme } from '@material-ui/core';

import { GridItem } from '../grid';

import { SocialMediaContainer } from 'app/upload/socialMedia/SocialMediaContainer';
import { Paper } from 'shared/components/paper/Paper';
import { Button } from 'shared/components/button/Button';
import { Typography } from 'shared/components/typography/Typography';
import { SectionMode } from './Section.types';

const padding = 28;
export const StyledPaper = styled(Paper)({
  position: 'relative',
  padding,
});

export const Header = styled('div')({
  padding: `0 ${padding}px`,
});

export const Left = styled(GridItem)(({ theme: { breakpoints } }) => ({
  display: 'flex',
  alignItems: 'center',
  [breakpoints.down('xs')]: {
    flexDirection: 'column',
  },
}));

export const Right = styled(GridItem)(({ theme: { breakpoints } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-end',
  paddingRight: 36,
  [breakpoints.down('xs')]: {
    position: 'absolute',
    bottom: 0,
    alignItems: 'center',

    '&.MuiGrid-item': {
      paddingBottom: padding,
    },
  },
}));

export const SaveButtonWrapper = styled(GridItem)(({ theme: { breakpoints } }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  [breakpoints.down('xs')]: {
    justifyContent: 'center',
  },
}));

export const useStyles = makeStyles<
  Theme,
  { mode: SectionMode; fieldHasValue?: boolean; sectionBottomPadding: number }
>(({ breakpoints }) => ({
  orderCircle: {
    [breakpoints.down('sm')]: {
      marginRight: 8,
    },
    [breakpoints.down('xs')]: {
      margin: 0,
    },
    minWidth: '44px',
    minHeight: '44px',
    backgroundColor: ({ fieldHasValue }) => (fieldHasValue ? '#18a957' : '#212121'),
    borderRadius: '22px',
    marginRight: '20px',
    marginLeft: '24px',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
  },
  sectionPadding: {
    [breakpoints.down('xs')]: {
      paddingBottom: ({ sectionBottomPadding }) => sectionBottomPadding,
    },
  },
}));

export const Subtitle = styled(Typography)(({ theme: { breakpoints } }) => ({
  display: 'flex',
  alignItems: 'center',
  [breakpoints.down('xs')]: {
    display: 'none',
  },
}));

export const ActionButton = styled(Button)(({ theme: { breakpoints } }) => ({
  width: '100px',
  [breakpoints.down('xs')]: {
    width: '210px',
    marginTop: '8px',
  },
}));

export const SaveButton = styled(Button)(({ theme: { breakpoints } }) => ({
  float: 'right',
  width: '210px',
  marginTop: 16,
  [breakpoints.down('xs')]: {
    width: '210px',
    marginTop: '8px',
  },
}));

export const ContentContainer = styled('div')(({ theme: { breakpoints } }) => ({
  [breakpoints.up('sm')]: {
    padding: '30px 30px 0px',
  },
  [breakpoints.up('md')]: {
    padding: '10px 90px 0px',
  },
}));

export const SocialMediaStyled = styled(SocialMediaContainer)(({ theme: { breakpoints } }) => ({
  '&.sm-up': {
    display: 'none',
    [breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  '&.xs-only': {
    display: 'none',
    [breakpoints.only('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      '& a': {
        paddingLeft: 0,
      },
    },
  },
}));
