import React from 'react';

import { ClientContextController } from 'contexts/client';
import { LocaleContextController } from 'contexts/locale';
import { NotificationsProvider } from 'shared/components/notifications/notifications.context';

export const Providers: React.FC = ({ children }) => (
  <LocaleContextController>
    <ClientContextController>
      <NotificationsProvider>{children}</NotificationsProvider>
    </ClientContextController>
  </LocaleContextController>
);
