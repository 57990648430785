import { styled } from '@material-ui/core/styles';

import { Typography } from 'shared/components/typography/Typography';

export const Controls = styled('div')(({ theme: { palette } }) => ({
  position: 'absolute',
  bottom: 4,
  left: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  color: palette.white.main,
  zIndex: 1,
}));

export const ProgressLine = styled('div')(({ theme: { palette } }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  margin: '0 10px',
  '&:after': {
    content: "''",
    display: 'block',
    width: '100%',
    height: 2,
    background: palette.white.main,
  },
}));

const size = 8;
export const ProgressPoint = styled('div')(({ theme: { palette } }) => ({
  width: size,
  height: size,
  borderRadius: '100%',
  background: palette.white.main,
  position: 'relative',
  left: 0,
}));

export const Duration = styled(Typography)({
  whiteSpace: 'nowrap',
});
