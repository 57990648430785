import { withStyles, styled, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Container } from 'shared/components/container/Container';

export const Topic = styled(Typography)(({ theme: { breakpoints, typography, spacing } }) => ({
  ...typography.h4,
  fontWeight: 'normal',
  fontFamily: 'Open Sans',
  [breakpoints.down('sm')]: {
    fontSize: spacing(1.75),
  },
  [breakpoints.up('lg')]: {
    fontSize: spacing(2),
  },
}));

export const StyledContainer = withStyles(({ breakpoints }) => ({
  root: {
    paddingTop: 0,
    paddingBottom: 23,
    [breakpoints.down('sm')]: {
      paddingTop: 5,
      paddingBottom: 0,
    },
  },
}))(Container);

export const UploadMediaStyle = makeStyles(({ breakpoints, palette, spacing }) => ({
  root: {
    paddingTop: '0%',
    paddingBottom: '20%',
    textAlign: 'center',
    flexDirection: 'column',
    [breakpoints.down('md')]: {
      paddingTop: spacing(0.1),
      paddingRight: '5%',
      paddingLeft: '5%',
      width: '100%',
    },
    [breakpoints.up('lg')]: {
      width: '100%',
    },
  },

  availableCard: {
    marginBottom: '3%',
    [breakpoints.up('md')]: {
      paddingBottom: '1%',
    },
  },
  available: {
    flexDirection: 'column',
    [breakpoints.up('md')]: {
      flexDirection: 'row',
      display: 'flex',
      padding: '2%',
    },
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      display: 'flex',
      padding: '3%',
    },
  },
  availableImage: {
    marginRight: '7%',
    backgroundColor: 'black',
    [breakpoints.up('md')]: {
      height: spacing(15),
      width: spacing(21.25),
    },
    [breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  video: {
    objectFit: 'fill',
  },
  content: {
    flexDirection: 'column',
  },
  topic: {
    [breakpoints.up('md')]: {
      height: spacing(4.4),
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
  },
  subTopic: {
    flexDirection: 'column',
    [breakpoints.up('md')]: {
      height: spacing(5),
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
  },
  videoName: {
    width: '1%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  root1: {
    paddingRight: '8%',
    paddingLeft: '8%',
    paddingTop: '2%',
  },
  rootLogo: {
    color: palette.black.dark,
    cursor: 'pointer',
    marginTop: '90%',
    fontSize: spacing(4.25),
  },
  rootText: {
    cursor: 'pointer',
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    fontSize: spacing(4.25),
    color: palette.black.dark,
  },
  eventHeading: {
    marginTop: '10%',
    marginBottom: '1%',
    paddingBottom: '5%',
    fontSize: spacing(3.75),
    fontWeight: 'bolder',
    [breakpoints.down('xs')]: {
      lineHeight: spacing(0.125),
    },
  },
  eventDiv: {
    marginTop: '1%',
    marginBottom: '5%',
    fontSize: spacing(3.75),
    fontWeight: 'bolder',
  },
  formDiv: {
    paddingRight: '0%',
    paddingLeft: '0%',
  },
}));
