import React from 'react';
import { useMutation } from 'react-fetching-library';

import { updateSubscriberData } from 'api/actions/selfRegistration/selfRegistration.actions';
import { SelfRegistrationUpdatePage } from './UpdateHighSchoolSelfRegister';
import { HighSchoolSelfRegisterUpdateContainerProps } from './UpdateHighSchoolSelfRegistration.types';

type patchBody = {
  firstName: string;
  lastName: string;
  email: string;
  subtitle: string;
  eventName: string;
  eventYear: string;
};

export const HighSchoolSelfRegisterUpdateContainer = ({
  activeStep,
  isCollective,
  queryParamKeys,
  instituteLogo,
  isRetail,
  isAllowForAmazonMusic,
  StudentDetails,
  // instituitionEventDetails,
  setParticipantDetails,
  setActiveStep,
}: HighSchoolSelfRegisterUpdateContainerProps) => {
  const { mutate, loading } = useMutation(updateSubscriberData(StudentDetails.id));

  const onSubmit = async (body: patchBody) => {
    const { eventName, eventYear, ...filteredBody } = body;
    const res = await mutate(filteredBody);

    setParticipantDetails(res.payload);
    setActiveStep(2);
  };

  return (
    <SelfRegistrationUpdatePage
      activeStep={activeStep}
      onSubmit={onSubmit}
      instituteLogo={instituteLogo}
      isCollective={isCollective}
      isLoading={loading}
      queryParamKeys={queryParamKeys}
      isRetail={isRetail}
      isAllowForAmazonMusic={isAllowForAmazonMusic}
      StudentDetails={StudentDetails}
      // instituitionEventDetails={instituitionEventDetails}
    />
  );
};
