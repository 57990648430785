/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useMutation } from 'react-fetching-library';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import qs from 'qs';

import { createUploadParticipantData } from 'api/actions/participant/participant.actions';
import { useParticipantData } from 'contexts/participantData/participantDataContext';
import { Upload } from './Upload';
import { handleOnSubmit } from 'shared/utils/handleOnSubmit/handleOnSubmit';
import { CreateUploadParticipantDataBody } from 'api/actions/participant/participant.types';
import { FileInfo } from 'shared/components/controls/azureFileUpload/AzureFileUpload.types';
import { useActivityTrack } from 'shared/hooks/useActivityTrack/useActivityTrack';
import { ActivityTypes, ParticipantActivityCode } from 'api/activityTrack/activityTrack.types';
import { useParticipantQueryData } from 'contexts/participantQueryData/participantQueryDataContext';
import { Users } from './Upload.types';

export const UploadContainer = () => {
  const { mutate } = useMutation(createUploadParticipantData);
  const { editToken } = useParticipantQueryData();
  const { survey, slide } = useParticipantData();
  const { push } = useHistory();
  const { onActivityTrack } = useActivityTrack();
  const { formatMessage } = useIntl();
  const params = qs.parse(window.location.href.split('#')[0]);
  const redirectUrl = params.redirectUrl as string;

  const validate = (values: CreateUploadParticipantDataBody) => {
    if (!values.file && !values.image && !values.message && !values.playNameEdited) {
      if (slide) {
        return {
          file: formatMessage({ id: 'virtual_graduation.upload.validation_error' }),
          message: 'virtual_graduation.upload.validation_error',
          image: formatMessage({ id: 'virtual_graduation.upload.validation_error' }),
        };
      }

      return {
        file: formatMessage({ id: 'validation.required' }),
      };
    }

    return undefined;
  };

  const onSubmit = (fileInfo: FileInfo) =>
    handleOnSubmit<CreateUploadParticipantDataBody & { file: any; image: any }>({
      action: async (data, { getState }) => {
        const {
          message,
          imageURI,
          image,
          fileURI,
          file,
          pronunciationAudio,
          pronunciationAudioURI,
          playNameEdited,
          ...restData
        } = data;

        const errors = validate(data);

        if (errors) {
          // this is required for fix Edge issues with validation and how react-final-form works internally
          return new Promise(resolve => resolve({ error: true, payload: { errors } }));
        }

        const { dirtyFields } = getState();
        const body: CreateUploadParticipantDataBody = restData;

        if (dirtyFields.file) {
          if (file && file instanceof File) {
            body.file = fileURI;
            body.fileInfo = fileInfo;
          }

          if (!file) {
            body.file = null;
          }
        }

        if (dirtyFields.image) {
          if (image && image instanceof File) {
            body.image = imageURI;
          }

          if (!image) {
            body.image = null;
          }
        }

        if (dirtyFields.pronunciationAudioURI) {
          if (pronunciationAudioURI !== undefined) {
            body.pronunciationAudio = pronunciationAudioURI;
          }
        }

        if (dirtyFields.message) {
          body.message = message || null;
        }
        delete body.playNameEdited;

        return mutate(body);
      },
      successCallback: async (response, { getState }) => {
        const { dirtyFields } = getState();
        const description = `${dirtyFields.image ? 'Image' : ''}${
          dirtyFields.image && dirtyFields.message ? ', ' : ''
        }${dirtyFields.message ? 'Message' : ''}${dirtyFields.message && dirtyFields.file ? ', ' : ''}${
          dirtyFields.file ? 'Video' : ''
        }`;

        if (dirtyFields.image || dirtyFields.message || dirtyFields.file) {
          onActivityTrack(
            ActivityTypes.Participant,
            ParticipantActivityCode.ProducerParticipantMediaEdited,
            formatMessage(
              { id: 'activity_track_description.media_edited' },
              { media: description, user: editToken ? Users.Admin : Users.Participant },
            ),
          );
        }

        if (redirectUrl) {
          window.location.href = /^(http|https)/.test(redirectUrl) ? redirectUrl : `http://${redirectUrl}`;
        } else if (survey) {
          window.location.href = survey;
        } else {
          push('/congratulations', { previousUrl: window.location.href });
        }
      },
    });

  return <Upload onSubmit={onSubmit} />;
};
