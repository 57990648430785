import React from 'react';
import { useMutation } from 'react-fetching-library';

import { ConfirmParticipantAttendanceBody } from 'api/actions/participant/participant.types';
import { useParticipantQueryData } from 'contexts/participantQueryData/participantQueryDataContext';
import { Intro } from './Intro';
import { confirmParticipantAttendance } from 'api/actions/participant/participant.actions';
import { IntroContainerProps } from './Intro.types';

export const IntroContainer = ({ refreshParticipant }: IntroContainerProps) => {
  const { participantId } = useParticipantQueryData();

  const { mutate } = useMutation(confirmParticipantAttendance(participantId));

  const handleConfirmationSubmit = async (body: ConfirmParticipantAttendanceBody) => {
    await mutate(body);
    refreshParticipant();
  };

  return <Intro onAttendanceConfirmation={handleConfirmationSubmit} />;
};
