/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import SearchIcon from '@material-ui/icons/Search';
import { Grid, TextField, Typography } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import { useIntl } from 'react-intl';
import moment from 'moment';

import { CollaboratorPages } from '../CollaboratorUpload.types';

import {
  UpcomingEventStyle,
  Cards,
  Title,
  Topic,
  SubTopic,
  SubTopicFail,
  Uploads,
  UploadFail,
  SubTopic1,
} from './UpcomingEvent.style';
import { UpcomingEventProps, EventVideoStatus } from './UpcomingEvent.types';
import uploadIcon from 'assets/icons/upload-icon.svg';
import { Loader } from 'shared/components/loader/Loader';
import { EventInformation } from 'app/avUserRoot/AVUserRoot.types';
import { EventState } from 'api/actions/avUser/avUser.types';

export const UpcomingEvent = ({ setCollaboratorPage, avUserDetails, setEventDetails }: UpcomingEventProps) => {
  const classes = UpcomingEventStyle();
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const [searchTerms, setSearchTerms] = useState('');
  const [filteredAVUserDetails, setFilteredAVUserDetails] = useState<EventInformation[] | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    avUserDetails && setFilteredAVUserDetails(avUserDetails);
  }, [avUserDetails, setFilteredAVUserDetails]);

  useEffect(() => {
    if (!avUserDetails) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [avUserDetails]);

  useEffect(() => {
    if (searchTerms.length) {
      const filteredDetails = avUserDetails?.filter(
        details =>
          details.name?.toLowerCase().includes(searchTerms.toLowerCase()) ||
          details.institution.name.toLowerCase().includes(searchTerms.toLowerCase()),
      );
      filteredDetails && setFilteredAVUserDetails(filteredDetails);
    } else {
      avUserDetails && setFilteredAVUserDetails(avUserDetails);
    }
  }, [searchTerms, avUserDetails, setFilteredAVUserDetails]);

  return (
    <Cards>
      <Card elevation={0}>
        <Title>{formatMessage({ id: 'av_user.upcoming_events.title' })}</Title>
        <div className={classes.searchBarContainer}>
          <TextField
            value={searchTerms}
            variant="outlined"
            placeholder={formatMessage({ id: 'av_user.upcoming_events.search_placeholder' })}
            className={classes.searchBar}
            onChange={({ target: { value } }) => setSearchTerms(value)}
            InputProps={{
              startAdornment: <SearchIcon className={classes.searchIcon} />,
            }}
          />
        </div>

        {filteredAVUserDetails && !!filteredAVUserDetails.length ? (
          filteredAVUserDetails.map(data => (
            <Card elevation={1} className={classes.availableCard} key={data.id}>
              <div className={classes.subTopic1}>
                <SubTopic1>
                  {`${data.institution.name} (${moment(data.startTime).format('DD/MMM/YYYY')} at ${moment(
                    data.startTime,
                  ).format(' hh:mm a')})`}
                </SubTopic1>
              </div>
              <div className={classes.available}>
                <div className={classes.availableImage}>
                  {data.state === EventVideoStatus.Published ? (
                    <img src={uploadIcon} alt="Event" className={classes.image} />
                  ) : (
                    <div className={data.state === EventState.WaitingForAssets ? classes.upload : classes.imageDiv}>
                      <img
                        src={uploadIcon}
                        alt=""
                        className={data.state === EventState.Cancelled ? classes.failedIcon : classes.uploadIcon}
                      />
                    </div>
                  )}
                </div>
                <div className={classes.content}>
                  <div className={classes.side}>
                    <div className={classes.topic}>
                      <Topic>{data.name}</Topic>
                    </div>
                  </div>

                  <div className={classes.subTopic}>
                    {data.state && data.state === EventState.Cancelled ? (
                      <SubTopicFail>{formatMessage({ id: 'av_user.upcoming_events.failed' })}</SubTopicFail>
                    ) : (
                      <SubTopic>{formatMessage({ id: 'av_user.upcoming_events.upload_file' })}</SubTopic>
                    )}
                  </div>
                </div>
                <div className={classes.state}>
                  {data.state && data.state === EventState.Cancelled ? (
                    <UploadFail>{formatMessage({ id: 'av_user.upcoming_events.upload_failed' })}</UploadFail>
                  ) : (
                    <Uploads
                      onClick={() => {
                        setCollaboratorPage(CollaboratorPages.uploadMedia);
                        setEventDetails(data);
                      }}
                    >
                      {formatMessage({ id: 'av_user.upcoming_events.upload' })}
                    </Uploads>
                  )}
                </div>
              </div>
            </Card>
          ))
        ) : (
          <Card elevation={0} className={classes.root}>
            {loading ? (
              <Loader />
            ) : (
              <Grid container className={classes.grid}>
                <Card elevation={1} className={classes.uploads}>
                  <div className={classes.errorDiv}>
                    <ErrorOutline className={classes.ErrorLogo} />
                    <Typography className={classes.uploadContent}>
                      {formatMessage({ id: 'av_user.upcoming_events.no_event' })}
                    </Typography>
                  </div>
                </Card>
              </Grid>
            )}
          </Card>
        )}
      </Card>
    </Cards>
  );
};
