import { styled, makeStyles } from '@material-ui/core';

import { Paper, Container, Icon, Button, Typography } from 'shared/components';

const padding = 28;

export const StyledContainer = styled(Container)(({ theme: { breakpoints } }) => ({
  paddingTop: 72,
  paddingBottom: 236,
  maxWidth: 700,
  [breakpoints.down('sm')]: {
    paddingBottom: 36,
  },
}));

export const StyledPaper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: 30,
  padding,
});

export const TickIcon = styled(Icon)({
  fontSize: 100,
  color: '#227b84',
  margin: `30px 0`,
});

export const ReceivedButton = styled(Button)(({ theme: { breakpoints } }) => ({
  marginTop: 100,
  width: 340,
  [breakpoints.down('sm')]: {
    width: 220,
  },
}));

export const Thanks = styled(Typography)(({ theme: { typography: { h2 } } }) => ({ ...h2 }));

export const Success = styled(Typography)(({ theme: { typography: { h3 } } }) => ({ ...h3 }));

export const Description = styled(Typography)(({ theme: { typography: { h4 } } }) => ({ ...h4 }));

export const useStyles = makeStyles(({ palette }) => ({
  button: {
    color: palette.white.main,
  },
}));
