import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { FaqSection } from '../faqSection/FaqSection';
import { StepSection } from '../stepSection/StepSection';
import { FooterSection } from '../footer/FooterSection';
import { ContactSection } from '../contactSection/ContactSection';
import { InfogradSection } from '../infogradSection/InfogradSection';

import { Button, Image } from 'shared/components';
import { Container, HomeContainer, useStyles, ButtonContainer } from './MainPage.styles';
import { HomePageProps } from './MainPage.types';
import heartImg from 'assets/heart_img.svg';
import mobileImg from 'assets/like_img.svg';
import mobileviewImg from 'assets/mobile1.png';
import likeImg from 'assets/mobile.png';
import bgImage from 'assets/background_img.svg';
import { AppRoute } from 'app/routing/AppRoute.enum';

export const MainPage = ({ titleSize = 40 }: HomePageProps) => {
  const { push } = useHistory();
  const styles = useStyles({ titleSize });
  const { formatMessage } = useIntl();

  return (
    <Container>
      <HomeContainer>
        <div className={styles.home}>
          <Image className={styles.bgImg} alt="" src={bgImage} />

          <h1 className={styles.titletop}>{formatMessage({ id: 'main_page.upload_page.header' })}</h1>
          <h1 className={styles.titlebottom}>{formatMessage({ id: 'main_page.upload_page.title' })}</h1>

          <ButtonContainer>
            <Button variant="contained" className={styles.buttonupload} onClick={() => push(AppRoute.universityEmail)}>
              <span className={styles.mdscreentext}>
                {formatMessage({ id: 'main_page.upload_page.upload_button' })}
              </span>
              <span className={styles.smscreentext}>
                {formatMessage({ id: 'main_page.upload_page.upload_content' })}
              </span>
            </Button>
          </ButtonContainer>
        </div>
        <div className={styles.phoneContainer}>
          <div className={styles.iconSet}>
            <div className={styles.columnHeart}>
              <Image alt="" src={heartImg} />
            </div>
            <div className={styles.columnmobile}>
              <Image className={styles.columnlarge} alt="" src={mobileImg} />
            </div>
            <div className={styles.columnlike}>
              <Image className={styles.columnlike} alt="" src={likeImg} />
            </div>
            <div className={styles.columnmob}>
              <Image className={styles.columnmob} alt="" src={mobileviewImg} />
            </div>
          </div>
        </div>

        <InfogradSection />

        <StepSection />

        <FaqSection />
        <ContactSection />

        <FooterSection />
      </HomeContainer>
    </Container>
  );
};
