import React from 'react';
import { Typography as MaterialTypography } from '@material-ui/core';
import clsx from 'clsx';

import { useTranslations } from 'shared/hooks';
import { useStyles } from './Typography.styles';
import { TypographyProps } from './Typography.types';

export const Typography = ({ children, format = true, className, ...props }: TypographyProps) => {
  const { message } = useTranslations(children);
  const styles = useStyles();

  const classNames = clsx(className, {
    [styles.format]: format,
  });

  return (
    <MaterialTypography className={classNames} {...props}>
      {message}
    </MaterialTypography>
  );
};
