import {
  CreateUploadParticipantDataRequest,
  CreateFetchParticipantData,
  CreateFetchUploadMeta,
  ConfirmParticipantAttendance,
} from './participant.types';

export const createUploadParticipantData: CreateUploadParticipantDataRequest = ({ participantId, ...body }) => ({
  endpoint: `/virtual-graduation/participant/${participantId}`,
  method: 'PATCH',
  body,
});

export const createFetchParticipantData: CreateFetchParticipantData = participantId => ({
  endpoint: `/virtual-graduation/participant/${participantId}`,
  method: 'GET',
});

export const createFetchUploadMeta: CreateFetchUploadMeta = participantId => fileExtension => ({
  endpoint: `/virtual-graduation/participant/${participantId}/upload-meta?ext=${fileExtension}`,
  method: 'GET',
});

export const confirmParticipantAttendance: ConfirmParticipantAttendance = participantId => body => ({
  endpoint: `/virtual-graduation/participant/${participantId}/confirm-attendance`,
  method: 'POST',
  body,
});
