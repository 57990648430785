import { withStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

export const StyledAlert = withStyles({
  root: {
    borderRadius: 16,
  },
  message: {},
  standardWarning: {},
  standardSuccess: {},
})(Alert);
