import { useState, useEffect } from 'react';

type Resource = File | string;

export const useMediaResource = (resource: Resource) => {
  const prepareResource = () => (resource instanceof File ? URL.createObjectURL(resource) : resource);
  const [cache, setCache] = useState(prepareResource);

  useEffect(() => {
    setCache(prepareResource);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resource]);

  return cache;
};
