import React from 'react';
import { InputLabel, InputLabelProps } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const StyledLabel: React.ComponentType<InputLabelProps> = withStyles(({ palette }) => ({
  root: {
    fontSize: '20px',
    fontWeight: 500,
    color: palette.text.primary,
    '&$focused': {
      color: palette.text.primary,
    },
    '&$error': {
      color: palette.text.primary,
    },
  },
  focused: {},
  error: {},
}))(InputLabel);
