import { Button, Card, Typography } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Image } from 'shared/components';
import { useStyles } from './PersonalEmailSuccess.styles';
import doneImg from 'assets/done.svg';
import { AppRoute } from 'app/routing/AppRoute.enum';
import { PersonalEmailProps } from './PersonalEmailSuccess.types';

export const PersonalEmailSuccess = ({ email }: PersonalEmailProps) => {
  const styles = useStyles();
  const { formatMessage } = useIntl();
  const { push } = useHistory();

  return (
    <Card className={styles.card}>
      <Image className={styles.column1} alt="" src={doneImg} />
      <Typography className={styles.subtitle}>
        {formatMessage({ id: 'main_page.personalEmail_page.subtitle' })}
      </Typography>

      <Typography className={styles.description}>
        {formatMessage({ id: 'main_page.successEmail_page.description' })}
      </Typography>
      <Typography className={styles.descriptionemail}>{email}</Typography>

      <Button variant="contained" className={styles.buttonLabel} onClick={() => push(AppRoute.startChat)}>
        {formatMessage({ id: 'main_page.successEmail_page.button' })}
      </Button>
    </Card>
  );
};
