import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { ErrorOutline } from '@material-ui/icons';
import { Card } from '@material-ui/core';

import { StyledFormHelperText } from '../controlBase/formHelperText/FormHelperText.styles';

import { SectionMode } from 'shared/components/expandingSection/Section.types';
import { Image } from 'shared/components/image/Image';
import { Typography } from 'shared/components/typography/Typography';
import { Button } from 'shared/components/button/Button';
import { Loader } from 'shared/components/loader/Loader';
import { useDragAndDropState } from 'shared/hooks/useDragAndDropState/useDragAndDropState';
import { isMobile } from 'shared/utils/isMobile/isMobile';
import { isMsBrowser } from 'shared/utils/isMsBrowser/isMsBrowser';
import { useOnClickOutside } from 'shared/hooks/useClickOutside/useOnClickOutside';
import { defaultExtensions, getExtension, isNotAllowedFile } from './AVUserFileDropZone.utils';
import { getUrlAsFile } from 'shared/utils';
import {
  DropZone,
  Input,
  TextWrapper,
  useStyles,
  Wrapper,
  ImageStyle,
  BorderLinearProgressFail,
} from './AVUserFileDropZone.styles';
import { Error, AVUserFileDropZoneProps, Value } from './AVUserFileDropZone.types';
import uploadIcon from 'assets/dropzone.svg';
import { MediaUploading } from 'app/avUserRoot/collabloratorUpload/uploadMedia/mediaUploading/MediaUploading';

export const AVUserFileDropZone: React.FC<AVUserFileDropZoneProps> = ({
  name,
  onChange,
  accept = defaultExtensions,
  value,
  onFocus,
  onBlur,
  helperText,
  disabled,
  fileInputRef,
  previewMode,
  progress,
  fileDetails,
  setCollaboratorPage,
  reUpload,
  errors,
  avError,
}) => {
  const dragState = useDragAndDropState();
  const wrapperRef = useRef<HTMLInputElement | null>(null);
  const [inArea, setInArea] = useState(false);
  const [storage, setStorage] = useState<File | null>(null);
  const [error, setError] = useState<Error>(null);
  const [loading, setLoading] = useState(false);
  const shouldBlur = useRef(false);
  const { formatMessage } = useIntl();

  useOnClickOutside(() => {
    // https://github.com/final-form/react-final-form/issues/676
    if (shouldBlur.current && onBlur) {
      if (!isMsBrowser) {
        onBlur();
      } else {
        setTimeout(onBlur, 1500);
      }

      shouldBlur.current = false;
    }
  }, wrapperRef.current);

  const drag = disabled ? false : dragState;

  const styles = useStyles();

  useEffect(() => {
    const readUrlAsFile = async (url: string) => {
      const file = await getUrlAsFile(url);

      setStorage(file);
    };

    if (typeof value === 'string') {
      if (value.length > 0) {
        readUrlAsFile(value).finally(() => setLoading(false));

        setLoading(true);
      } else {
        setStorage(null);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleDragEnter = (event: React.DragEvent) => {
    const { items } = event.dataTransfer;

    if (isNotAllowedFile(items, accept)) {
      setError([{ id: 'control.file_drop_zone.not_allowed' }, { formats: accept?.join(', ') }]);
    }

    if (!inArea) {
      setInArea(true);
    }
  };

  const handleDragLeave = () => {
    if (inArea) {
      setInArea(false);

      if (error) {
        setError(null);
      }
    }
  };

  const handleOnChange = (nextValue: Value) => {
    if (onChange) {
      onChange(nextValue);
    }
  };

  const handleDrop = (event: React.DragEvent) => {
    if (event.dataTransfer && event.dataTransfer.files.length !== 0 && event.dataTransfer.files[0] && !error) {
      const nextFile = event.dataTransfer.files[0];
      setStorage(nextFile);

      handleOnChange(nextFile);
    }

    if (inArea) {
      setInArea(false);
      setError(null);
    }

    shouldBlur.current = true;
  };

  const handleButtonClick = () => {
    const input = fileInputRef.current;

    if (input) {
      input.click();

      if (onFocus) {
        onFocus();
      }
    }
  };

  const handleInputOnChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const { files } = event.currentTarget;

    if (files && files.length !== 0 && files[0]) {
      const nextFile = files[0];

      setStorage(nextFile);

      handleOnChange(nextFile);
    }

    shouldBlur.current = true;
  };

  useEffect(() => {
    if (drag) {
      if (inArea) {
        return onFocus && onFocus();
      }

      shouldBlur.current = true;
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inArea]);

  const showImagePreview = !drag && !loading;
  const showDropZoneContent = !storage || drag;

  const dropZoneClassNames = clsx({
    [styles.dropZoneWrapper]: !storage,
    [styles.active]: drag,
    [styles.inArea]: inArea,
    [styles.error]: !!error || !!helperText,
    [styles.disabled]: disabled,
    [styles.dashed]: !storage,
    [styles.preview]: previewMode !== SectionMode.Opened,
  });

  return (
    <Wrapper>
      <div className={styles.subtitle}>{formatMessage({ id: 'av_user.file_dropzone.upload_video_subtitle' })}</div>
      <Card elevation={0} className={styles.cardStyle1}>
        <DropZone
          className={dropZoneClassNames}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          ref={wrapperRef}
          tabIndex={storage ? undefined : -1}
          onClick={storage ? undefined : handleButtonClick}
        >
          {loading && <Loader />}
          {avError === true || avError === 'true' ? (
            <Card elevation={1} className={styles.uploadFail}>
              <div className={styles.errorStyle}>
                <ErrorOutline className={styles.ErrorLogo} />
                <Typography className={styles.uploadContent}>
                  {formatMessage({ id: 'av_user.file_dropzone.upload_fail' })}
                </Typography>
                <BorderLinearProgressFail variant="determinate" value={100} />
                <Button
                  type="button"
                  variant="contained"
                  className={styles.buttonErr}
                  onClick={() => {
                    reUpload();
                  }}
                >
                  {formatMessage({ id: 'av_user.file_dropzone.retry' })}
                </Button>
              </div>
            </Card>
          ) : (
            storage &&
            showImagePreview &&
            (!error && !helperText ? (
              <MediaUploading
                isMediaUploadFailed={false}
                progress={progress}
                fileDetails={fileDetails}
                setCollaboratorPage={setCollaboratorPage}
                errors={errors}
              />
            ) : (
              <MediaUploading isMediaUploadFailed setCollaboratorPage={setCollaboratorPage} errors={errors} />
            ))
          )}

          {showDropZoneContent && (
            <ImageStyle>
              <Image src={uploadIcon} alt="clip_template.variant.upload.title" />
              <TextWrapper>
                {!isMobile && (
                  <>
                    <Typography variant="body1">
                      {drag ? 'control.file_drop_zone.drop_here' : 'av_user.notification_message.notification_message'}
                    </Typography>
                    <Typography variant="body1">
                      {formatMessage({ id: 'av_user.file_dropzone.click_upload' })}
                    </Typography>
                  </>
                )}
                {!drag && (
                  <Button
                    variant="text"
                    className={styles.button}
                    onClick={(event: React.SyntheticEvent) => {
                      event.stopPropagation();
                      handleButtonClick();
                    }}
                  >
                    {formatMessage({ id: 'av_user.file_dropzone.click_to_upload' })}
                  </Button>
                )}
              </TextWrapper>
            </ImageStyle>
          )}
          {(error || helperText) && (
            <StyledFormHelperText role="alert" aria-live="assertive">
              {error ? formatMessage(...error) : helperText || <span>&nbsp;</span>}
            </StyledFormHelperText>
          )}
        </DropZone>
        <Input
          name={name}
          type="file"
          onChange={handleInputOnChange}
          ref={fileInputRef}
          accept={getExtension(accept)}
          required
        />
      </Card>
    </Wrapper>
  );
};
