import qs from 'qs';

import { GetInstitutionData, UseLogin } from './login.types';

export enum ParticipantSourceType {
  Student = 'Student',
  Aluminati = 'Aluminati',
}

export enum EventType {
  NameReading = 'Name Reading',
}

export const useLogin: UseLogin = body => ({
  endpoint: '/participant-verify',
  method: 'POST',
  body: {
    ...body,
    source: ParticipantSourceType.Student,
    eventType: EventType.NameReading,
    eventId: qs.parse(window.location.href.split('?')[1]).id,
  },
});

export const getInstitutionDataByEventId: GetInstitutionData = (eventId: string) => ({
  endpoint: `/participant-verify/event/${eventId}`,
  method: 'GET',
});
