import { InputBase, withStyles, styled, makeStyles } from '@material-ui/core';

import { Typography } from 'shared/components/typography/Typography';

export const StyledInput = withStyles({
  root: {
    width: '100%',
  },
  input: {
    backgroundColor: 'transparent',
    border: 0,
    fontSize: 16,
    width: '100%',
    padding: 0,
  },
  inputAdornedEnd: {},
})(InputBase);

export const Counter = styled(Typography)(({ theme: { palette: { scorpionGray } } }) => ({
  color: scorpionGray.main,
  textAlign: 'right',
  position: 'absolute',
  right: 0,
  bottom: 0,
}));

export const useStyles = makeStyles(({ palette }) => ({
  success: {
    borderColor: palette.primary.main,
  },
}));
