import React from 'react';
import MaterialTooltip, { TooltipProps } from '@material-ui/core/Tooltip';

import { useTranslations } from 'shared/hooks';

export const Tooltip: React.FC<TooltipProps> = ({ title, children, ...props }) => {
  const { message } = useTranslations(title);

  return (
    <MaterialTooltip {...props} title={message}>
      {children}
    </MaterialTooltip>
  );
};
