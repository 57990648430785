import { styled, Link as MaterialLink, Select } from '@material-ui/core';

import { Image } from 'shared/components/image/Image';
import { bold } from 'app/core/theme/typography';

export const FOOTER_GAP = 24;

export const Wrapper = styled('footer')(({ theme: { palette, breakpoints } }) => ({
  backgroundColor: palette.black.dark,
  [breakpoints.up('md')]: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
}));

export const FooterContainer = styled('div')(({ theme: { breakpoints } }) => ({
  padding: '64px 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    justifyContent: 'center',
  },
}));

export const ContainerCopyRightsAndLinks = styled('div')(({ theme: { breakpoints } }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: FOOTER_GAP,
  flex: 4,
  [breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    marginRight: 0,
  },
}));

export const SocialMediaContainer = styled('div')(({ theme: { breakpoints } }) => ({
  '&>*:not(:last-of-type)': {
    marginRight: FOOTER_GAP,
  },

  [breakpoints.down('sm')]: {
    marginLeft: '0',
    marginBottom: 2 * FOOTER_GAP,
  },
  flex: 1,
}));

export const StageClipLogo = styled(Image)(({ theme: { breakpoints } }) => ({
  [breakpoints.down('sm')]: {
    marginLeft: 0,
    marginBottom: FOOTER_GAP,
    width: 92,
    height: 51,
  },

  flex: 1,
}));

export const LinksWrapper = styled('div')(({ theme: { breakpoints } }) => ({
  display: 'flex',
  [breakpoints.down('sm')]: {
    flexDirection: 'column',
    marginBottom: 2 * FOOTER_GAP,
  },
}));

export const Link = styled(MaterialLink)(({ theme: { breakpoints, palette } }) => ({
  fontSize: 16,
  fontWeight: bold,
  color: palette.white.main,
  whiteSpace: 'nowrap',

  [breakpoints.down('sm')]: {
    textAlign: 'center',
    textTransform: 'unset',
  },

  '&:not(:last-of-type)': {
    [breakpoints.down('sm')]: {
      marginBottom: FOOTER_GAP,
      marginRight: 0,
    },
    marginRight: FOOTER_GAP,
  },

  '&.active': {
    color: palette.primary.main,
  },
}));

export const LanguageSelect = styled(Select)(({ theme: { palette } }) => ({
  '&.MuiInputBase-root': {
    color: palette.white.main,
    '& > .MuiSelect-icon': {
      color: palette.white.main,
    },
  },
  marginLeft: '24px',
  fontSize: '14px',
}));
