import React, { createContext, useEffect, useState } from 'react';
import { useQuery } from 'react-fetching-library';
import { hotjar } from 'react-hotjar';
import { Redirect } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { useAVUserQueryData } from 'contexts/avUserQueryData/avUserQueryDataContext';
import { Loader } from 'shared/components/loader/Loader';
import { CollaboratorUploadContainer } from 'app/avUserRoot/collabloratorUpload/CollaboratorUploadContainer';
import { AVUserDataResponse } from 'app/avUserRoot/AVUserRoot.types';
import { getAVUserData } from 'api/actions/avUser/avUser.actions';
import { useUpdateAVUserData } from 'contexts/avUserData/avUserDataContext';
import { useUpdateNotifications } from 'shared/components/notifications/notifications.context';

export const SectionsContext = createContext<
  { openedSection: number; setOpenedSection: React.Dispatch<React.SetStateAction<number>> } | undefined
>(undefined);

export const AvUserRoot = () => {
  const { avUserId } = useAVUserQueryData();
  const updateAVUserData = useUpdateAVUserData();
  const { loading, error, payload } = useQuery<AVUserDataResponse>(getAVUserData(avUserId));
  const [openedSection, setOpenedSection] = useState(0);
  const updateNotifications = useUpdateNotifications();
  const { formatMessage } = useIntl();

  window.addEventListener('online', () => {
    updateNotifications([
      {
        id: '200',
        message: formatMessage({ id: 'av_user.notification_message.online' }),
        severity: 'success',
        open: true,
      },
    ]);
  });
  window.addEventListener('offline', () => {
    updateNotifications([
      {
        id: '500',
        message: formatMessage({ id: 'av_user.notification_message.offline' }),
        severity: 'error',
        open: true,
      },
    ]);
  });

  useEffect(() => {
    if (process.env.REACT_APP_HOTJAR_ID) {
      hotjar.initialize(+process.env.REACT_APP_HOTJAR_ID, 6);
    }
  }, []);

  useEffect(() => {
    if (!loading && !error && !!payload) {
      updateAVUserData(payload);
    }
  }, [error, loading, payload, updateAVUserData]);

  if (loading) {
    return <Loader fullScreen withBackground />;
  }

  if (error || !payload) {
    return <Redirect to="/something-went-wrong" />;
  }

  return (
    <SectionsContext.Provider value={{ openedSection, setOpenedSection }}>
      <main>
        <CollaboratorUploadContainer />
      </main>
    </SectionsContext.Provider>
  );
};
