import React from 'react';
import { styled } from '@material-ui/core';

import { AspectRatioProps } from './AspectRatio.types';

export const OuterWrapper: React.ComponentType<Pick<AspectRatioProps, 'ratio'>> = styled('div')({
  position: 'relative',
  width: '100%',
  height: 0,
  paddingBottom: props => `${(1 / props.ratio) * 100}%`,
});

export const InnerWrapper = styled('div')({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
});
