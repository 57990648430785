import { Card, Typography } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';

import { EmailValidateForm } from '../verifyEmail/emailValidateForm/EmailValidateForm';

import { useStyles } from './PersonalEmail.styles';
import { SecondEmailProps } from './PersonalEmail.types';

export const PersonalEmail = ({ onSubmit }: SecondEmailProps) => {
  const styles = useStyles();
  const { formatMessage } = useIntl();

  return (
    <Card className={styles.card}>
      <Typography className={styles.subtitle}>
        {formatMessage({ id: 'main_page.secondEmail_page.subtitle' })}
      </Typography>

      <Typography className={styles.description}>
        {formatMessage({ id: 'main_page.secondEmail_page.description' })}
      </Typography>

      <EmailValidateForm onSubmit={onSubmit} label={formatMessage({ id: 'main_page.secondEmail_page.label' })} />
    </Card>
  );
};
