import React, { useState, useEffect } from 'react';
import loadImage from 'blueimp-load-image';

import { BlobImage } from 'shared/components/blobImage/BlobImage';
import { hash } from 'shared/utils/hash/hash';
import { BlobImageRotationProps } from './BlobImageRotation.types';

type angle = 0 | 90 | 180 | 270;
const orientations = [1, 6, 3, 8];
const angles: angle[] = [0, 90, 180, 270];

const getRotation = async (file: File | Blob) => {
  return new Promise(resolve => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadImage.parseMetaData(file).then((data: any) => {
      const orientation = (data.exif && data.exif.get('Orientation')) || 1;

      resolve(orientation);
    });
  });
};

export const BlobImageRotation = ({
  src,
  onRotate,
  onCrop,
  disabled,
  scale,
  rotation,
  resetRotation,
  sectionMode,
  isError,
  ...props
}: BlobImageRotationProps) => {
  // TODO - remove this state
  const [orientationIndex, setOrientationIndex] = useState(0);
  const angle = angles[rotation];
  const [resize, setResize] = useState(1);

  useEffect(() => {
    const initializeAngle = async () => {
      const orientation = await getRotation(src);

      setOrientationIndex(orientations.findIndex(value => value === orientation));
    };

    initializeAngle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(src)]);

  useEffect(() => {
    if (onRotate) {
      onRotate(angle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rotation]);

  useEffect(() => {
    if (onRotate) {
      onRotate(angles[orientationIndex]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orientationIndex]);

  return (
    <BlobImage
      {...props}
      src={src}
      orientation={angle}
      onCrop={onCrop}
      resize={resize}
      resetRotation={resetRotation}
      resetResize={() => setResize(1)}
      scale={scale}
      rotation={rotation}
      sectionMode={sectionMode}
      isError={isError}
    />
  );
};
