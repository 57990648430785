export const defaultExtensions = ['jpg', 'jpeg', 'png', 'gif'];

export const getExtension = (extensions: string[]) => extensions.reduce((acc, next) => `${acc},.${next}`, '');

const types: { [key: string]: string } = {
  'video/quicktime': 'mov',
  'image/jpeg': 'jpg',
};

export const isNotAllowedFile = (items: DataTransferItemList, accept: string[]) => {
  const files = Array.from(items);

  return files.some(({ type }) => {
    const extension = types[type] || type.substring(type.indexOf('/') + 1, type.length);

    return !accept.includes(extension);
  });
};
