import React from 'react';

import { useFormControlState } from '../useFormControlState/useFormControlState';

import { useTranslations } from 'shared/hooks';
import { StyledLabel } from './Label.styles';

export const Label: React.FC = ({ children }) => {
  const { message } = useTranslations(children);
  const { disabled, required, id, hiddenLabel } = useFormControlState();

  if (hiddenLabel) {
    return null;
  }

  return (
    <StyledLabel htmlFor={id} shrink required={required} disabled={disabled}>
      {message}
    </StyledLabel>
  );
};
