import React from 'react';

import { GraduateSection } from 'app/root/intro/graduateSection/GraduateSection';
import { StyledContainer } from 'app/root/intro/Intro.styles';
import { NotYouBar } from 'app/notYouBar/NotYouBar';
import { useParticipantQueryData } from 'contexts/participantQueryData/participantQueryDataContext';
import { useParticipantData } from 'contexts/participantData/participantDataContext';
import { Typography } from 'shared/components/typography/Typography';
import { Separator } from 'shared/components/separator/Separator';
import { Button } from 'shared/components/button/Button';
import { HashLink } from 'shared/components/routerHashLink/HashLink';
import { DateText } from './Uploaded.styles';
import { formattedDate } from 'shared/utils';
import { ReactComponent as ThankYou } from 'assets/thank_you.svg';

export const Uploaded = () => {
  const participantData = useParticipantData();
  const { participantId, secret } = useParticipantQueryData();

  const canEdit = !!Object.keys(participantData).length;

  return (
    <StyledContainer>
      <NotYouBar />
      <GraduateSection
        title="virtual_graduation.uploaded.header"
        description1={
          <div>
            <Typography>virtual_graduation.uploaded.intro1</Typography>
            <Typography>virtual_graduation.uploaded.intro2</Typography>
          </div>
        }
        description2={
          canEdit && (
            <>
              <Typography>
                {{
                  id: 'virtual_graduation.uploaded.edit_info',
                  values: {
                    date: <DateText>{formattedDate(participantData.deadlineDate)}</DateText>,
                  },
                }}
              </Typography>
              <Separator height={30} />
              <Button
                component={HashLink}
                variant="contained"
                color="primary"
                disabled={!participantData.active}
                to={`/?token=${participantId}:${secret}&edit=true#upload-form`}
              >
                virtual_graduation.uploaded.edit_upload
              </Button>
            </>
          )
        }
        image={<ThankYou />}
      />
    </StyledContainer>
  );
};
