import { styled } from '@material-ui/core/styles';

export const Wrapper = styled('ul')({
  display: 'flex',
  margin: 0,
  padding: 0,
  '& :not(:last-of-type)': {
    marginRight: 18,
  },
});

export const Item = styled('li')({
  listStyleType: 'none',
  textAlign: 'center',
});
