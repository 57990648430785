import React from 'react';

import { Separator } from 'shared/components/separator/Separator';
import { Header } from './SimplePageHeader.styles';
import { SimplePageHeaderProps } from './SimplePageHeader.types';

export const SimplePageHeader = ({ children }: SimplePageHeaderProps) => {
  return (
    <>
      <Header variant="h2">{children}</Header>
      <Separator height={15} />
    </>
  );
};
