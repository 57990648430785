import React from 'react';
import * as Icons from '@material-ui/icons';

import { IconProps } from './Icon.types';

export const Icon = ({ icon, className }: IconProps) => {
  const SvgIcon = Icons[icon];

  return <SvgIcon className={className} data-testid={icon} />;
};
