import { styled, makeStyles } from '@material-ui/core';

import { Typography } from 'shared/components/typography/Typography';

export const LoginContainer = styled('div')(({ theme: { breakpoints } }) => ({
  width: '100%',
  margin: 'auto',
  flexDirection: 'column',
  textAlign: 'center',
  [breakpoints.down('sm')]: {
    padding: 0,
    width: 'unset',
    textAlign: 'center',
    flexDirection: 'column',
  },
}));

export const useStyles = makeStyles(({ palette, spacing }) => ({
  textLine: {
    margin: ` ${spacing(1.875, 0, 4.25, 0)}`,
    fontSize: spacing(4.375),
    fontWeight: spacing(125),
    color: palette.gray.dark,
  },

  column4: {
    width: '100%',
    display: 'flex',
    textAlign: 'center',
  },
  column5: {
    margin: 'auto',
  },
}));

export const Description = styled(Typography)(({ theme: { breakpoints, typography, spacing } }) => ({
  marginBottom: spacing(1),
  [breakpoints.down('sm')]: {
    ...typography.h4,
  },
}));

export const TextContainer = styled('div')({});
