import { ComponentType } from 'react';
import { InputAdornment as MaterialInputAdornment, InputAdornmentProps } from '@material-ui/core';
import { Theme, withStyles, makeStyles } from '@material-ui/core/styles';

export const StyledInputAdornment: ComponentType<InputAdornmentProps> = withStyles(({ palette }: Theme) => ({
  root: {
    color: palette.text.primary,
  },
  positionEnd: {},
}))(MaterialInputAdornment);

export const useStyles = makeStyles(({ palette }) => ({
  error: {
    color: palette.error.main,
  },
  success: {
    color: palette.primary.main,
  },
}));
