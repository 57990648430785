import React from 'react';

import { Container } from 'shared/components/container/Container';
import { Typography } from 'shared/components/typography/Typography';
import { Wrapper } from './FooterSection.styles';

export const FooterSection = () => {
  return (
    <Wrapper>
      <Container>
        <Typography variant="h3" align="center">
          virtual_graduation.about_us.footer
        </Typography>
      </Container>
    </Wrapper>
  );
};
