import React from 'react';

import { NotYouBar } from '../notYouBar/NotYouBar';

import { GraduateSection } from 'app/root/intro/graduateSection/GraduateSection';
import { ReactComponent as Sorry } from 'assets/sorry.svg';
import { StyledContainer } from './Expired.styles';

export const Expired = () => {
  return (
    <StyledContainer>
      <NotYouBar />
      <GraduateSection
        title="virtual_graduation.expired.header"
        description1="virtual_graduation.expired.content"
        image={<Sorry />}
      />
    </StyledContainer>
  );
};
