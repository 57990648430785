import React from 'react';

import { DescriptionSection } from './descriptionSection/DescriptionSection';
import { RightColumn } from './rightColumn/RightColumn';
import { FooterSection } from './footerSection/FooterSection';
import { StyledContainer, Wrapper } from './AboutUs.styles';

export const AboutUs = () => {
  return (
    <>
      <StyledContainer>
        <Wrapper>
          <DescriptionSection />
          <RightColumn />
        </Wrapper>
      </StyledContainer>
      <FooterSection />
    </>
  );
};
