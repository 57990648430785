// eslint-disable-next-line import/order
import React from 'react';

import { Button, Typography } from '@material-ui/core';
import { length } from 'redux-form-validators';
import moment from 'moment';

import { useStyles } from '../HighSchoolSelfRegistration.styles';

import { useLocale } from 'shared/hooks/useLocale/useLocale';
import { StudentLoginHeader } from 'app/studentLoginPage/studentLoginHeader/studentLoginHeader';
// import { MainHeading } from 'app/avUserRoot/collabloratorUpload/uploadMedia/eventInformations/EventInformation.styles';
import { InputField } from 'shared/components/controls/input/InputField';
import { Form } from 'shared/components/form/Form';
import { email } from 'shared/validators';
import { RecaptchaField } from 'shared/components/controls/recaptchaField/RecaptchaField';
import { SelfRegistrationUpdateProps } from './UpdateHighSchoolSelfRegistration.types';
import { Loader } from 'shared/components';

export const SelfRegistrationUpdatePage = ({
  onSubmit,
  instituteLogo,
  isLoading,
  StudentDetails,
}: SelfRegistrationUpdateProps) => {
  const styles = useStyles({});
  const { formatMessage } = useLocale();

  const initialValues = {};

  return (
    <>
      <div className={styles.mainContainer}>
        <StudentLoginHeader />
        <div className={styles.imgContainer}>
          {instituteLogo && <img src={instituteLogo} alt="institute logo" className={styles.logoImage} />}
        </div>
        <div className={styles.container}>
          <div className={styles.cardWrapper}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography className={styles.titleContainer}>
                {formatMessage({ id: 'highSchool.register_update' })}
              </Typography>
              <Typography className={styles.subTitle}>
                {formatMessage({ id: 'highSchool.register_welcome_subtitle' })}
              </Typography>
              {/* <Typography className={styles.supportText}>
                <div>
                  If you have already registered to receive a clip and need to make a change to your submission, please{' '}
                  <a href="https://stageclip.com/student-support/" target="_blank" rel="noopener noreferrer">
                    contact us.{' '}
                  </a>
                  Do not submit a second registration, as this may delay delivery of your clip.
                </div>
              </Typography> */}
            </div>
            <div className={styles.card}>
              <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                errorSeparator={2}
                render={({ handleSubmit, invalid }) => (
                  <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
                    {/* <MainHeading className={styles.header}>
                      {formatMessage({ id: 'highSchool.register_title' })}
                    </MainHeading> */}

                    <InputField
                      label={formatMessage({ id: 'highSchool.graduate_event_year' })}
                      type="text"
                      size="small"
                      name="eventYear"
                      defaultValue={`${moment(`${StudentDetails.eventData.startDateOnly}`).format('YYYY')}`}
                      fullWidth
                      placeholder="Enter your first name as announced"
                      className={styles.textLabel}
                      classes={{ root: styles.textFieldRoot }}
                      disabled
                    />

                    <InputField
                      label={formatMessage({ id: 'highSchool.graduate_event_name' })}
                      type="text"
                      size="small"
                      name="eventName"
                      defaultValue={`${moment(
                        `${StudentDetails.eventData.startDateOnly} ${StudentDetails.eventData.startTimeOnly}`,
                      ).format('dddd, Do MMMM - h:mma')} (${StudentDetails.eventData.name})`}
                      fullWidth
                      placeholder="Enter your first name as announced"
                      className={styles.textLabel}
                      classes={{ root: styles.textFieldRoot }}
                      disabled
                    />
                    <InputField
                      label={formatMessage({ id: 'highSchool.graduate_first_name' })}
                      type="text"
                      size="small"
                      name="firstName"
                      defaultValue={StudentDetails?.firstName}
                      fullWidth
                      placeholder="Enter your first name as announced"
                      required
                      validate={[length({ max: 50 })]}
                      className={styles.textLabel}
                      classes={{ root: styles.textFieldRoot }}
                    />
                    <InputField
                      label={formatMessage({ id: 'highSchool.graduate_last_name' })}
                      type="text"
                      size="small"
                      name="lastName"
                      defaultValue={StudentDetails?.lastName}
                      fullWidth
                      placeholder="Enter your last name as announced"
                      required
                      validate={[length({ max: 50 })]}
                      className={styles.textLabel}
                      classes={{ root: styles.textFieldRoot }}
                    />
                    <InputField
                      label={formatMessage({ id: 'highSchool.graduate_email' })}
                      type="email"
                      size="small"
                      name="email"
                      defaultValue={StudentDetails?.email}
                      fullWidth
                      placeholder="Enter your email"
                      validate={[email()]}
                      required
                      className={styles.textLabel}
                    />
                    <InputField
                      label="Degree title or course name"
                      type="text"
                      size="small"
                      name="subtitle"
                      defaultValue={StudentDetails?.subtitle}
                      fullWidth
                      placeholder="Enter details (optional)"
                      validate={[length({ max: 90 })]}
                      className={styles.textLabel}
                    />

                    <div className={styles.recaptcha}>
                      <RecaptchaField name="token" />
                    </div>

                    {isLoading ? (
                      <div className={styles.buttonLoader}>
                        <Loader />
                      </div>
                    ) : (
                      <Button
                        type="submit"
                        size="large"
                        className={styles.buttonContainer}
                        disabled={isLoading || invalid}
                      >
                        {formatMessage({ id: 'highSchool.register_update_button' })}
                      </Button>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        marginTop: '16px',
                      }}
                    >
                      {/* <Typography className={styles.emailClipsReadyTitle}>
                        {formatMessage({ id: 'highSchool.register_subtitle' })}
                      </Typography>
                      <div className={styles.checkboxContainer}>
                        <CheckboxField
                          name="isAllowForMarketing"
                          size="small"
                          label={formatMessage({ id: 'highSchool.register_promotion_subtitle' })}
                          className={clsx(
                            isAllowForAmazonMusic ? styles.bottomContainerAmazon : styles.bottomContainer,
                          )}
                        />
                      </div> */}
                    </div>
                  </form>
                )}
              />
            </div>
          </div>
        </div>
      </div>
      {/* //   )} */}
    </>
  );
};
