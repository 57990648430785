import React from 'react';
import { Form as FinalForm } from 'react-final-form';

import { Separator, PageAlert } from 'shared/components';
import { Props } from './Form.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Form: <T extends any>(props: Props<T>) => React.ReactElement = ({
  errorSeparator = 68,
  subscription,
  ...props
}) => {
  return (
    <FinalForm
      {...props}
      subscription={{
        ...subscription,
        submitError: true,
        hasSubmitErrors: true,
        dirtyFieldsSinceLastSubmit: true,
        invalid: true,
      }}
      render={formProps => {
        const { submitError, hasSubmitErrors, dirtySinceLastSubmit } = formProps;

        const showError = !dirtySinceLastSubmit && hasSubmitErrors && submitError;

        return (
          <>
            {showError && (
              <>
                <PageAlert severity="error">{submitError}</PageAlert>
                <Separator height={errorSeparator} />
              </>
            )}
            {props.render(formProps)}
          </>
        );
      }}
    />
  );
};
