import React from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';

import { UploadMediaSteps } from '../uploadMediaSteps/UploadMediaSteps';

import { MainHeading, SubHeading, Date } from './EventInformation.styles';
import { EventInformationProps } from './EventInformation.typs';
import { EventState } from 'api/actions/avUser/avUser.types';

export const EventInformation = ({ eventDetails }: EventInformationProps) => {
  const { formatMessage } = useIntl();

  return (
    <>
      {eventDetails?.state === EventState.WaitingForAssets ? <UploadMediaSteps /> : null}
      <MainHeading>{formatMessage({ id: 'av_user.upload_media.event_information.upload_media_content' })}</MainHeading>
      <SubHeading>{eventDetails?.name}</SubHeading>
      <Date>
        {`${moment(eventDetails?.startTime).format('DD/MMM/YYYY')} at ${moment(eventDetails?.startTime).format(
          ' hh:mm a',
        )}`}
      </Date>
      {eventDetails?.liveAndHybridEvent && (
        <Date>
          ({eventDetails?.liveAndHybridEvent.address}, {eventDetails?.liveAndHybridEvent.location})
        </Date>
      )}
    </>
  );
};
