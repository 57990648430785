import React, { useState } from 'react';
import { useMutation } from 'react-fetching-library';

import { LoginContainer } from '../verifyEmail/VerifyEmail.styles';
import { HeaderSection } from '../headerSection/HeaderSection';
import { InstituteSection } from '../instituteSection/InstituteSection';

import { handleOnSubmit } from 'shared/utils/handleOnSubmit/handleOnSubmit';
import { InstitutionEmail } from './InstitutionEmail';
import { createSendEmail } from 'api/actions/email/email.actions';
import { TextContainer } from 'app/root/intro/graduateSection/GraduateSection.styles';
import { InstitutionEmailSuccess } from './institutionEmailSuccess/InstitutionEmailSuccess';

export const InstitutionEmailContainer = () => {
  const { mutate } = useMutation(createSendEmail);
  const [isSuccess, setisSuccess] = useState(false);
  const [value, setValue] = useState('');

  const handleSuccessCallback = () => {
    setisSuccess(true);
  };

  const onSubmit = handleOnSubmit({
    action: values => {
      setValue(values.email);

      return mutate(values);
    },
    successCallback: () => handleSuccessCallback(),
  });

  return (
    <LoginContainer>
      <TextContainer>
        <HeaderSection />
        {isSuccess ? <InstitutionEmailSuccess email={value} /> : <InstitutionEmail onSubmit={onSubmit} />}
        <InstituteSection />
      </TextContainer>
    </LoginContainer>
  );
};
