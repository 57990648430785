import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
  header: {
    color: palette.black.main,
    lineHeight: spacing(9),
    fontSize: spacing(6),
    fontWeight: 'bold',
  },

  title: {
    color: palette.gray.main,
    lineHeight: spacing(1),
    fontSize: spacing(3.25),
    fontWeight: 'bold',
  },

  subtitle: {
    color: palette.black.main,
    lineHeight: spacing(0.2),
    fontSize: spacing(3.25),

    fontWeight: 'bold',
  },
  description: {
    color: palette.scorpionGray.light,
    fontSize: spacing(2),
    fontFamily: 'Roboto',
    letterSpacing: spacing(0.018),
  },
  descriptionlower: {
    color: palette.scorpionGray.light,
    fontSize: spacing(2),
    fontFamily: 'Roboto',
    letterSpacing: spacing(0.018),
    width: '100%',
  },
  label: {
    fontSize: spacing(2),
    fontFamily: 'roboto-regular',
    float: 'left',
  },
  TextField: {
    height: spacing(0.5),
    float: 'left',
  },

  button5: {
    backgroundColor: palette.blue.light,
    color: palette.white.main,
    fontFamily: 'Roboto bold',
    height: spacing(5),
    fontSize: spacing(2),
    margin: 'auto',
    marginTop: spacing(5),
    marginBottom: spacing(1.25),
    textTransform: 'initial',
    borderRadius: spacing(0.5),
    [breakpoints.down('sm')]: {
      marginLeft: spacing(0),
    },
  },

  column1: {
    marginTop: spacing(3.75),
  },

  card: {
    width: spacing(48.5),
    margin: 'auto',
    height: spacing(50),
    marginLeft: 'auto',
    marginRight: 'auto',
    boxShadow: ` ${spacing(0, 0.5, 1, 0)}  ${palette.info.main}`,
    [breakpoints.down('sm')]: {
      marginTop: spacing(3),
    },
    [breakpoints.down('xs')]: {
      width: '90vw',
      marginTop: spacing(3),
    },
  },
  question: {
    width: '100%',
  },
  tryanother: {
    fontSize: spacing(2),
    color: palette.green.dark,
    width: '100%',
    fontWeight: 'bolder',
    marginBottom: spacing(2.5),
    [breakpoints.down('sm')]: {
      width: '100%',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));
