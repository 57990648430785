import React from 'react';

import { SocialMedia } from './SocialMedia';
import { useParticipantData } from 'contexts/participantData/participantDataContext';
import { SocialMediaContainerProps } from './SocialMedia.types';

export const SocialMediaContainer = ({ className }: SocialMediaContainerProps) => {
  const { institution } = useParticipantData();

  if (!institution?.instagramAccountName) {
    return null;
  }

  return <SocialMedia instagramAccountName={institution?.instagramAccountName} className={className} />;
};
