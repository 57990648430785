import React from 'react';
import { FormControlLabel, FormControlLabelProps } from '@material-ui/core';

import { useTranslations } from 'shared/hooks';

export const OptionFormControl: React.FC<FormControlLabelProps> = ({ label, ...props }) => {
  const { message } = useTranslations(label);

  return <FormControlLabel {...props} label={message} />;
};
