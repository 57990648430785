import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  divSize: {
    width: '100vw',
    height: '100vh',
  },
  videoSize: {
    width: '100%',
    height: '100%',
  },
}));
