/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { FormSpy, useField } from 'react-final-form';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

import { useTextToSpeech } from '../../textToSpeech/useTextToSpeech';

import { SectionMode } from 'shared/components/expandingSection/Section.types';
import { GridContainer } from 'shared/components/grid';
import { useParticipantData } from 'contexts/participantData/participantDataContext';
import { Button, Icon } from 'shared/components';
import { StyledButton } from 'shared/components/button/Button.styles';
import { ActionButton, Buttons, Left, Right, useStyles } from './PlayName.styles';
import { InputField, SelectField } from 'shared/components/controls';
import { ExpandingSection } from 'shared/components/expandingSection/Section';
import { SlideProps } from './PlayName.types';

export const PlayName = ({ order }: SlideProps) => {
  const { fullName, producerLanguageInfo } = useParticipantData();
  const { translateTextToSpeech, playing, stopPlayer } = useTextToSpeech();
  const { formatMessage } = useIntl();
  const [sectionMode, setSectionMode] = useState<SectionMode>(SectionMode.Closed);
  const [editMode, setEditMode] = useState(false);
  const buttonRef = useRef();
  const styles = useStyles();

  const {
    input: { onChange: playNameOnChange },
  } = useField<string>('playNameEdited');

  useEffect(() => {
    if (sectionMode === SectionMode.Edited) {
      playNameOnChange(true);
    }
  }, [playNameOnChange, sectionMode]);

  if (!producerLanguageInfo?.length) {
    return null;
  }

  const play = () => {
    // @ts-ignore
    // eslint-disable-next-line no-unused-expressions
    buttonRef?.current?.click();
    setEditMode(true);
  };

  return (
    <ExpandingSection
      title="virtual_graduation.upload.play_name.title"
      subtitle="virtual_graduation.upload.play_name.subtitle"
      order={order}
      buttonName={
        <Buttons>
          <ActionButton
            onClick={play}
            color="primary"
            variant="contained"
            disabled={playing}
            aria-label={formatMessage({ id: 'virtual_graduation.upload.play_name.aria_label.play' })}
          >
            <Icon icon="PlayArrow" /> Play
          </ActionButton>
          <ActionButton
            className={clsx({ [styles.hidden]: !editMode })}
            onClick={() => setSectionMode(SectionMode.Opened)}
            color="primary"
            variant="contained"
            aria-label={formatMessage({ id: 'virtual_graduation.upload.play_name.aria_label.edit' })}
          >
            Edit
          </ActionButton>
        </Buttons>
      }
      fieldHasValue={sectionMode === SectionMode.Edited}
      mode={sectionMode}
      setSectionMode={setSectionMode}
      sectionBottomPadding={180}
      showContent
      ariaLabelSaveButton={formatMessage({ id: 'virtual_graduation.upload.play_name.aria_label.save' })}
    >
      <GridContainer spacing={5}>
        <FormSpy>
          {({ values }) => {
            const languages = producerLanguageInfo?.map((val: any) => ({
              value: val.languageId,
              label: formatMessage({ id: `virtual_graduation.upload.play_name.language.${val.languageId}` }),
            }));
            const languageVoices = producerLanguageInfo?.find(
              (language: any) => language.languageId === values?.languageId,
            )?.voiceActors;

            return (
              <>
                <div
                  className={clsx(
                    [SectionMode.Closed, SectionMode.Edited].includes(sectionMode) ? styles.hidden : styles.content,
                  )}
                >
                  <Left xs={12} md={6}>
                    <SelectField name="languageId" label="Language" options={languages || []} />
                    <SelectField
                      name="voiceActorId"
                      label="Voice actor"
                      options={languageVoices?.map((el: any) => ({ value: el.id, label: el.name })) || []}
                      defaultValue={languageVoices?.length ? languageVoices[0].id : ''}
                    />
                    <InputField
                      name="pronunciationName"
                      label="Change your phonetic spelling if your name isn’t pronounced correctly. (This will not change the way your name is displayed).
                      Capitalization can affect automated name reading. For best results, use title casing (e.g. Bob Hope)."
                      variant="outlined"
                      defaultValue={fullName}
                    />
                  </Left>
                  <Right xs={12} md={6}>
                    {!playing ? (
                      <Button
                        disabled={playing}
                        color="primary"
                        variant="outlined"
                        ref={buttonRef}
                        onClick={() =>
                          translateTextToSpeech({
                            textToSpeech: values.pronunciationName,
                            language: values.languageId,
                            voiceActor: values.voiceActorId,
                          })
                        }
                        className={styles.audioButton}
                        aria-label={formatMessage({ id: 'virtual_graduation.upload.play_name.aria_label.play' })}
                      >
                        <Icon icon="PlayArrow" /> Play
                      </Button>
                    ) : (
                      <StyledButton
                        onClick={stopPlayer}
                        variant="contained"
                        className={styles.audioButton}
                        aria-label={formatMessage({ id: 'virtual_graduation.upload.play_name.aria_label.stop' })}
                      >
                        <Icon icon="Stop" /> Stop
                      </StyledButton>
                    )}
                  </Right>
                </div>
              </>
            );
          }}
        </FormSpy>
      </GridContainer>
      {/* <Separator height={25} /> */}
      {/* <Typography variant="h4">virtual_graduation.upload.photo.playName.title</Typography> */}
      {/* <InputField */}
      {/*  name="message" */}
      {/*  counter */}
      {/*  maxLength={150} */}
      {/*  label="virtual_graduation.upload.message.label" */}
      {/*  rows={3} */}
      {/*  validate={validate} */}
      {/*  validateFields={[]} */}
      {/* /> */}
    </ExpandingSection>
  );
};
