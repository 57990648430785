import React from 'react';
import clsx from 'clsx';

import {
  Container,
  GraduationLogoContainer,
  GraduationLogoBox,
  ClientLogoContainer,
  GraduationLogo,
  useStyles,
  Hashtag,
} from './LogoSection.styles';
import clientLogoBackground from 'assets/screen.gif';
import { useParticipantData } from 'contexts/participantData/participantDataContext';
import { Image } from 'shared/components/image/Image';

export const LogoSection = ({ hideOnSM = false }) => {
  const { logo, institution } = useParticipantData();
  const styles = useStyles();

  const classNames = clsx({
    [styles.hideOnSM]: hideOnSM,
  });

  return (
    <Container className={classNames}>
      <GraduationLogoContainer>
        <GraduationLogoBox>
          <div>
            <ClientLogoContainer>
              {(institution?.logoUrl || logo) && (
                <Image alt="university logo" src={institution?.logoUrl || logo || ''} />
              )}
              <Hashtag>
                {institution?.instagramHashtag && institution?.instagramHashtag.length <= 12
                  ? `#${institution.instagramHashtag.toUpperCase()}`
                  : ''}
              </Hashtag>
            </ClientLogoContainer>
          </div>
          <GraduationLogo alt="Graduate" src={clientLogoBackground} />
        </GraduationLogoBox>
      </GraduationLogoContainer>
    </Container>
  );
};
