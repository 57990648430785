import { Button, Slider, styled, withStyles } from '@material-ui/core';

import { GridItem } from 'shared/components/grid';
import { Icon, Typography } from 'shared/components';

export const Left = styled(GridItem)(({ theme: { breakpoints } }) => ({
  flexDirection: 'column',
  display: 'flex',
  alignItems: 'start',
  [breakpoints.down('xs')]: {
    flexDirection: 'column',
  },
}));

export const Right = styled(GridItem)({
  flexDirection: 'column',
  display: 'flex',
  alignItems: 'center',
  padding: '12px 100px',
});

export const PrettoSlider = withStyles(({ palette }) => ({
  root: {
    color: palette.primary.main,
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
  },
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
}))(Slider);

export const IconButton = styled(Button)(({ theme: { palette } }) => ({
  width: '40px',
  height: '40px',
  background: 'white',
  color: palette.primary.main,
  minWidth: '40px',
  boxShadow: '0 2px 4px 0 rgba(48, 49, 51, 0.1), 0 0 1px 0 rgba(48, 49, 51, 0.05)',
}));

export const Scale = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export const Buttons = styled('div')(({ theme: { breakpoints } }) => ({
  '& > button': {
    marginRight: 10,
    '& svg': {
      marginRight: 8,
    },
  },

  [breakpoints.up('lg')]: {
    '& > button': {
      minWidth: 130,
    },
  },

  [breakpoints.down('xs')]: {
    display: 'flex',
    justifyContent: 'space-around',
    '& > button': {
      minWidth: 130,
      marginRight: 0,
    },
  },
}));

export const PhotoDetails = styled('div')(({ theme: { breakpoints } }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-around',
  padding: '10px 30px',
  [breakpoints.down('sm')]: {
    padding: '10px 0',
  },
}));

export const Details = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const DetailsIcon = styled(Icon)(({ theme: { palette } }) => ({
  display: 'flex',
  alignItems: 'center',
  color: palette.primary.main,
  marginRight: '8px',
}));

export const EnhancementButtons = styled('div')({
  paddingTop: '16px',
  '& > button:not(:last-of-type)': {
    marginRight: '14px',
  },
});

export const SliderWrapper = styled(GridItem)(({ theme: { breakpoints } }) => ({
  [breakpoints.down('xs')]: {
    display: 'none',
  },
}));

export const EditHeader = styled(Typography)(({ theme: { typography: { h4 } } }) => ({ ...h4 }));
