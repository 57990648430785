import { styled, withStyles } from '@material-ui/core';

import { Typography } from 'shared/components';

export const Wrapper = styled('div')(({ theme: { palette } }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '50px',
  backgroundColor: palette.black.dark,
}));

export const Text = withStyles(({ palette }) => ({
  root: {
    fontWeight: 'bold',
    color: palette.white.main,
    '& .MuiLink-underlineHover': {
      color: palette.white.main,
    },
  },
}))(Typography);
