import React, { useEffect, useState } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder';
import { Button } from '@material-ui/core';
import { useField } from 'react-final-form';
import { RHAP_UI } from 'react-h5-audio-player';

import { SectionMode } from 'shared/components/expandingSection/Section.types';
import { Icon } from 'shared/components';
import { useAzureUpload } from 'shared/hooks/useAzureUpload/useAzureUpload';
import { createFetchUploadMeta } from 'api/actions/participant/participant.actions';
import { useParticipantQueryData } from 'contexts/participantQueryData/participantQueryDataContext';
import { InputField } from 'shared/components/controls/input';
import { useParticipantData } from 'contexts/participantData/participantDataContext';
import { ExpandingSection } from 'shared/components/expandingSection/Section';
import { SlideProps } from './RecordName.types';
import {
  Left,
  Right,
  Wrapper,
  RecordNameContainer,
  RecordButton,
  RecordNameLabel,
  AudioPlayerStyled,
} from './RecordName.styles';
import 'react-h5-audio-player/lib/styles.css';

export const RecordName = ({ order, fieldHasValue }: SlideProps) => {
  const [blob, setBlob] = useState();
  const { startRecording, stopRecording, mediaBlobUrl, status, clearBlobUrl } = useReactMediaRecorder({
    audio: true,
    onStop: (_, _blob) => setBlob(_blob),
  });
  const { fullName } = useParticipantData();
  const { participantId } = useParticipantQueryData();
  const [sectionMode, setSectionMode] = useState<SectionMode>(SectionMode.Closed);
  const { upload, fileURI } = useAzureUpload({
    fetchUploadMetaAction: createFetchUploadMeta(participantId),
  });

  const {
    input: { onChange: pronunciationAudioChange, value: pronunciationAudio },
  } = useField<string>('pronunciationAudio', {
    subscription: { value: true, valid: true },
  });

  const {
    input: { onChange: pronunciationAudioURIChange, value: pronunciationAudioURI },
  } = useField<string>('pronunciationAudioURI');

  useEffect(() => {
    pronunciationAudioURIChange(fileURI);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileURI]);

  useEffect(() => {
    if (blob) {
      upload(new File([blob], `${participantId}.wav`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blob]);

  const handleDelete = () => {
    pronunciationAudioURIChange(null);
    pronunciationAudioChange(null);
    clearBlobUrl();
  };

  return (
    <ExpandingSection
      title="virtual_graduation.upload.record_name.title"
      subtitle="virtual_graduation.upload.record_name.subtitle"
      order={order}
      buttonName="Edit"
      fieldHasValue={fieldHasValue}
      mode={sectionMode}
      setSectionMode={setSectionMode}
    >
      {sectionMode === SectionMode.Opened && (
        <Wrapper spacing={5}>
          <Left xs={12} md={6}>
            <InputField name="pronunciationName" label="Phonetic name" variant="outlined" defaultValue={fullName} />
          </Left>
          <Right xs={12} md={6}>
            <RecordNameLabel>Record name</RecordNameLabel>
            <RecordNameContainer style={{ width: '100%', height: '130px' }}>
              {!pronunciationAudioURI && !pronunciationAudio && !mediaBlobUrl && (
                <div>
                  {status !== 'recording' && (
                    <RecordButton aria-label="delete" color="primary" onClick={startRecording}>
                      <Icon icon="MicNone" />
                    </RecordButton>
                  )}
                  {status === 'recording' && (
                    <RecordButton aria-label="delete" color="primary" onClick={stopRecording}>
                      <Icon icon="Stop" />
                    </RecordButton>
                  )}
                </div>
              )}
              {(!!pronunciationAudioURI || pronunciationAudio || mediaBlobUrl) && (
                <>
                  {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                  {/* <audio controls src={mediaBlobUrl || pronunciationAudio || ''} /> */}
                  <AudioPlayerStyled
                    src={mediaBlobUrl || pronunciationAudioURI || pronunciationAudio || ''}
                    showJumpControls={false}
                    customAdditionalControls={[RHAP_UI.MAIN_CONTROLS]}
                    customVolumeControls={[
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={handleDelete}
                        // disabled={disabled}
                      >
                        <Icon icon="Delete" /> Delete
                      </Button>,
                    ]}
                    customControlsSection={[
                      RHAP_UI.MAIN_CONTROLS,
                      <Button variant="contained" color="secondary" size="small" onClick={handleDelete}>
                        <Icon icon="Delete" /> Delete
                      </Button>,
                    ]}
                  />
                </>
              )}
            </RecordNameContainer>
          </Right>
        </Wrapper>
      )}
    </ExpandingSection>
  );
};
