import React from 'react';
import ReactDOM from 'react-dom';
import 'blueimp-canvas-to-blob';
import AudioRecorder from 'audio-recorder-polyfill';

import { init as initSentry } from 'shared/utils/sentry/sentry';
import App from './app/App';

import 'index.css';

const render = () => {
  ReactDOM.render(<App />, document.getElementById('root'));
};

initSentry();

render();

// @ts-ignore
if (!window.MediaRecorder) {
  // @ts-ignore
  window.MediaRecorder = AudioRecorder;
}

if (module.hot) {
  module.hot.accept('app/App', () => {
    render();
  });
}
