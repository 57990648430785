import React from 'react';
import { useIntl } from 'react-intl';

import { useParticipantData } from 'contexts/participantData/participantDataContext';
import { Button, Icon, Typography } from 'shared/components';
import { NotYouBar } from 'app/notYouBar/NotYouBar';
import { GraduateSection } from './graduateSection/GraduateSection';
import { LogoSection } from 'app/root/intro/logoSection/LogoSection';
import { StyledContainer, useStyles } from './Intro.styles';
import { Link } from './graduateSection/GraduateSection.styles';
import { IntroProps } from './Intro.types';

export const Intro = ({ onAttendanceConfirmation }: IntroProps) => {
  const { formatMessage } = useIntl();
  const { attendance, institution } = useParticipantData();
  const styles = useStyles();

  const hasDeclaredAttendance = institution?.attendanceConfirmation && attendance === null;
  const hasNotDeclaredAttendance = institution?.attendanceConfirmation && attendance !== null;

  return (
    <StyledContainer>
      <NotYouBar />
      <GraduateSection
        title={(() => {
          if (!institution?.attendanceConfirmation) {
            return 'virtual_graduation.upload.graduate_section.title';
          }

          if (hasNotDeclaredAttendance) {
            return `virtual_graduation.upload.graduate_section.attendance.title${attendance ? '_yes' : '_no'}`;
          }

          return 'virtual_graduation.upload.graduate_section.attendance.title';
        })()}
        titleSize={hasNotDeclaredAttendance ? 40 : 80}
        description1={
          hasDeclaredAttendance || hasNotDeclaredAttendance ? (
            ''
          ) : (
            <>
              <Typography>virtual_graduation.upload.graduate_section.information_text.part_1</Typography>
              <Typography>virtual_graduation.upload.graduate_section.information_text.part_2</Typography>
            </>
          )
        }
        description2={
          hasDeclaredAttendance ? (
            <div className={styles.buttons}>
              <Button
                variant="contained"
                className={styles.noButton}
                onClick={() => onAttendanceConfirmation({ confirmed: false })}
              >
                <Icon icon="Close" />
                {formatMessage({ id: 'virtual_graduation.upload.graduate_section.attendance.button_no' })}
              </Button>
              <Button
                variant="contained"
                className={styles.yesButton}
                onClick={() => onAttendanceConfirmation({ confirmed: true })}
              >
                <Icon icon="Check" />
                {formatMessage({ id: 'virtual_graduation.upload.graduate_section.attendance.button_yes' })}
              </Button>
            </div>
          ) : (
            <Link href="#upload-form" underline="none" color="primary">
              {formatMessage({ id: 'virtual_graduation.upload.graduate_section.upload_button' })}
            </Link>
          )
        }
        image={<LogoSection />}
      />
    </StyledContainer>
  );
};
