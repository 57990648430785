import makeStyles from '@material-ui/core/styles/makeStyles';
import styled from '@material-ui/core/styles/styled';

export const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  minHeight: '240px',
});

export const DropZone = styled('div')(({ theme: { breakpoints } }) => ({
  alignItems: 'center',
  borderRadius: 8,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  textAlign: 'center',
  boxSizing: 'content-box',
  position: 'relative',
  '& > img': {
    maxHeight: '170px',
    [breakpoints.down('xs')]: {
      maxHeight: '280px',
    },
    objectFit: 'contain',
  },
  '& > video': {
    maxHeight: '50vh',
  },
}));

export const TextWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Input = styled('input')({
  display: 'none',
  opacity: 0,
});

export const useStyles = makeStyles(({ palette, breakpoints }) => ({
  active: {
    '& > *': {
      pointerEvents: 'none',
    },
  },
  inArea: {
    borderColor: palette.green.main,
  },
  error: {
    border: `2px dashed ${palette.error.main}`,
    padding: '10px 18px',
  },
  disabled: {
    '&, & *': {
      'pointer-events': 'none',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > button': {
      marginLeft: 10,
    },
  },
  dropZoneWrapper: {
    outlineColor: palette.green.main,
    '&:hover': {
      cursor: 'pointer',
      borderColor: palette.green.main,
    },
  },
  dashed: {
    border: `2px dashed ${palette.gray.main}`,
  },
  preview: {
    [breakpoints.up('sm')]: {
      alignItems: 'start',
    },
  },
  musicDisclaimer: {
    padding: '10px 0',
    maxWidth: '90%',
  },
  secondLimit: {
    paddingBottom: 10,
  },
}));
