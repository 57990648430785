import React, { useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useField, useFormState } from 'react-final-form';
import { useIntl } from 'react-intl';

import { RecaptchaFieldProps } from './RecaptchaField.types';
import { ErrorMessage } from './RecaptchaField.styles';

export const RecaptchaField = ({ name }: RecaptchaFieldProps) => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const { formatMessage } = useIntl();

  const {
    input: { onChange, value },
  } = useField(name, {
    validate: inputValue => {
      if (!inputValue) {
        return 'validation.recaptcha';
      }

      return undefined;
    },
  });

  const { invalid, errors, pristine } = useFormState({
    subscription: { invalid: true, errors: true, pristine: true },
  });

  const handleOnChange = (token: string | null) => {
    if (token) {
      onChange(token);
    }
  };

  const resetCaptcha = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }

    onChange('');
  };

  useEffect(() => {
    if (invalid && value) {
      resetCaptcha();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invalid]);

  return (
    <>
      <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY || ''} onChange={handleOnChange} />
      {!pristine && errors?.token && <ErrorMessage> {formatMessage({ id: errors.token })}</ErrorMessage>}
    </>
  );
};
