import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette, transitions }) => ({
  icon: {
    height: 32,
    width: 32,
    transition: transitions.create(['transform'], { duration: transitions.duration.shorter }),
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  inverse: {
    '& path.bg': {
      fill: palette.black.dark,
    },
    '& path': {
      fill: palette.white.main,
    },
  },
}));
