import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import flatten from 'flat';

import { LocaleContextProvider } from '../localeContextProvider/LocaleContextProvider';
import { Locale, UpdateLocale } from '../localeContext/localeContext.types';

import enTranslations from 'locale/en.json';
import frTranslations from 'locale/fr-ca.json';
import { LocaleContextControllerProps, LocaleData } from './LocaleContextController.types';

const defaultLocaleData: LocaleData = {
  en: flatten<object, { [key: string]: string }>(enTranslations),
  fr: flatten<object, { [key: string]: string }>(frTranslations),
};

export const LocaleContextController: React.FC<LocaleContextControllerProps> = ({
  children,
  localeData = defaultLocaleData,
}) => {
  const localStorageLanguage = localStorage.getItem('language');
  const [locale, setLocale] = useState<Locale>((localStorageLanguage as Locale) || Locale.en);
  const messages = localeData[locale];

  const updateLocale: UpdateLocale = nextLocale => setLocale(nextLocale);

  return (
    <IntlProvider locale={locale} messages={messages}>
      <LocaleContextProvider updateLocale={updateLocale}>{children}</LocaleContextProvider>
    </IntlProvider>
  );
};
