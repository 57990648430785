import React, { useEffect } from 'react';
import { useLocation, RouteProps, Route } from 'react-router-dom';
import { parse } from 'query-string';

import { useAVUserQueryData, useUpdateAVUserQueryData } from 'contexts/avUserQueryData/avUserQueryDataContext';
import { Loader } from 'shared/components/loader/Loader';

const parseQueryData = (search: string) => {
  const { token } = parse(search, { parseBooleans: true }) as {
    token?: string;
  };

  const [avUserId, secret] = (typeof token === 'string' ? token : '').split(':');

  return { avUserId, secret };
};
export const AVUserAuthorizedRoute: React.FC<RouteProps> = props => {
  const { search } = useLocation();
  const queryData = parseQueryData(search);
  const avUserData = useAVUserQueryData();
  const updateAVUserQueryData = useUpdateAVUserQueryData();

  const { avUserId, secret } = queryData;

  useEffect(() => {
    if (avUserId && secret) {
      updateAVUserQueryData({ avUserId, ...queryData });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...Object.values(queryData), updateAVUserQueryData]);

  if (!avUserData.secret || !avUserData.avUserId) {
    return <Loader fullScreen />;
  }

  return <Route {...props} />;
};
