import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

import { StyledFormHelperText } from '../controlBase/formHelperText/FormHelperText.styles';

import { SectionMode } from 'shared/components/expandingSection/Section.types';
import { BlobVideo } from 'shared/components/blobVideo/BlobVideo';
import { Image } from 'shared/components/image/Image';
import { Typography } from 'shared/components/typography/Typography';
import { Button } from 'shared/components/button/Button';
import { Loader } from 'shared/components/loader/Loader';
import { BlobImage } from 'shared/components/blobImage/BlobImage';
import { BlobPlayer } from 'shared/components/blobPlayer/BlobPlayer';
import { useDragAndDropState } from 'shared/hooks/useDragAndDropState/useDragAndDropState';
import { isMobile } from 'shared/utils/isMobile/isMobile';
import { isMsBrowser } from 'shared/utils/isMsBrowser/isMsBrowser';
import { useOnClickOutside } from 'shared/hooks/useClickOutside/useOnClickOutside';
import { defaultExtensions, getExtension, isNotAllowedFile } from './FileDropZone.utils';
import { getFileType, getUrlAsFile } from 'shared/utils';
import { DropZone, Input, TextWrapper, useStyles, Wrapper } from './FileDropZone.styles';
import { Error, FileDropZoneProps, Value } from './FileDropZone.types';
import uploadIcon from 'assets/icons/upload_bg.svg';

const defaultPreviews = {
  image: BlobImage,
  video: BlobVideo,
  audio: ({ src }: { src: File; disabled?: boolean }) => (
    <BlobPlayer src={src} title={src.name === 'filename' ? '' : src.name} />
  ),
};

export const FileDropZone: React.FC<FileDropZoneProps> = ({
  name,
  onChange,
  accept = defaultExtensions,
  value,
  onFocus,
  onBlur,
  helperText,
  previews = {},
  ratio,
  disabled,
  fileInputRef,
  rotation,
  resetRotation,
  previewMode,
  scale,
  type: sourceType,
}) => {
  const dragState = useDragAndDropState();
  const wrapperRef = useRef<HTMLInputElement | null>(null);
  const [inArea, setInArea] = useState(false);
  const [storage, setStorage] = useState<File | null>(null);
  const [error, setError] = useState<Error>(null);
  const [loading, setLoading] = useState(false);
  const shouldBlur = useRef(false);
  const { formatMessage } = useIntl();
  useOnClickOutside(() => {
    // https://github.com/final-form/react-final-form/issues/676
    if (shouldBlur.current && onBlur) {
      if (!isMsBrowser) {
        onBlur();
      } else {
        setTimeout(onBlur, 1500);
      }

      shouldBlur.current = false;
    }
  }, wrapperRef.current);

  const drag = disabled ? false : dragState;

  const styles = useStyles();

  const previewOptions = {
    ...defaultPreviews,
    ...previews,
  };

  useEffect(() => {
    const readUrlAsFile = async (url: string) => {
      const file = await getUrlAsFile(url);

      setStorage(file);
    };

    if (typeof value === 'string') {
      if (value.length > 0) {
        readUrlAsFile(value).finally(() => setLoading(false));

        setLoading(true);
      } else {
        setStorage(null);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleDragEnter = (event: React.DragEvent) => {
    const { items } = event.dataTransfer;

    if (isNotAllowedFile(items, accept)) {
      setError([{ id: 'control.file_drop_zone.not_allowed' }, { formats: accept?.join(', ') }]);
    }

    if (!inArea) {
      setInArea(true);
    }
  };

  const handleDragLeave = () => {
    if (inArea) {
      setInArea(false);

      if (error) {
        setError(null);
      }
    }
  };

  const handleOnChange = (nextValue: Value) => {
    if (onChange) {
      onChange(nextValue);
    }
  };

  const handleDrop = (event: React.DragEvent) => {
    if (event.dataTransfer && event.dataTransfer.files.length !== 0 && event.dataTransfer.files[0] && !error) {
      const nextFile = event.dataTransfer.files[0];
      setStorage(nextFile);

      handleOnChange(nextFile);
    }

    if (inArea) {
      setInArea(false);
      setError(null);
    }

    shouldBlur.current = true;
  };

  const handleButtonClick = () => {
    const input = fileInputRef.current;

    if (input) {
      input.click();

      if (onFocus) {
        onFocus();
      }
    }
  };

  const handleInputOnChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const { files } = event.currentTarget;

    if (files && files.length !== 0 && files[0]) {
      const nextFile = files[0];

      setStorage(nextFile);

      handleOnChange(nextFile);
    }

    shouldBlur.current = true;
  };

  useEffect(() => {
    if (drag) {
      if (inArea) {
        return onFocus && onFocus();
      }

      shouldBlur.current = true;
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inArea]);

  const showImagePreview = !drag && !loading;
  const showDropZoneContent = !storage || drag;
  const type = storage ? getFileType(storage) : null;
  const Preview = (type && previewOptions[type]) || 'div';

  const dropZoneClassNames = clsx({
    [styles.dropZoneWrapper]: !storage,
    [styles.active]: drag,
    [styles.inArea]: inArea,
    [styles.error]: !!error || !!helperText,
    [styles.disabled]: disabled,
    [styles.dashed]: !storage,
    [styles.preview]: previewMode !== SectionMode.Opened,
  });

  return (
    <Wrapper>
      <DropZone
        className={dropZoneClassNames}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        ref={wrapperRef}
        tabIndex={storage ? undefined : -1}
        onClick={storage ? undefined : handleButtonClick}
      >
        {loading && <Loader />}
        {storage && showImagePreview && (
          <Preview
            alt="new logo"
            src={storage}
            ratio={ratio}
            disabled={disabled}
            scale={scale}
            rotation={rotation}
            resetRotation={resetRotation}
            sectionMode={previewMode}
            isError={!!(error || helperText)}
          />
        )}

        {showDropZoneContent && (
          <>
            {sourceType === 'video' && (
              <div className={styles.musicDisclaimer}>Do not use music in your video or it will be rejected</div>
            )}
            <Image src={uploadIcon} alt="clip_template.variant.upload.title" />
            <TextWrapper>
              {!isMobile && (
                <Typography variant="body1">
                  {drag ? 'control.file_drop_zone.drop_here' : 'control.file_drop_zone.text'}
                </Typography>
              )}
              {!drag && (
                <Button
                  variant="text"
                  color="primary"
                  onClick={(event: React.SyntheticEvent) => {
                    event.stopPropagation();
                    handleButtonClick();
                  }}
                >
                  control.file_drop_zone.browse
                </Button>
              )}
            </TextWrapper>
            {sourceType === 'video' && <div className={styles.secondLimit}>10 second limit</div>}
          </>
        )}
        {(error || helperText) && (
          <StyledFormHelperText role="alert" aria-live="assertive">
            {error ? formatMessage(...error) : helperText || <span>&nbsp;</span>}
          </StyledFormHelperText>
        )}
      </DropZone>
      <Input name={name} type="file" onChange={handleInputOnChange} ref={fileInputRef} accept={getExtension(accept)} />
    </Wrapper>
  );
};
