import React from 'react';
import { useIntl } from 'react-intl';

import { Button, Form, Typography } from 'shared/components';
import { InputField } from 'shared/components/controls';
import { useStyles } from './studentLoginForm.styles';
import { StudentLoginFormProps } from './studentLoginForm.types';
import { email, studentIdRequired } from 'shared/validators';

export const StudentLoginForm = ({ onSubmit }: StudentLoginFormProps) => {
  const styles = useStyles();
  const { formatMessage } = useIntl();

  return (
    <div className={styles.formContainer}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Typography className={styles.labelTop}>{formatMessage({ id: 'av_user.student_login.email' })}</Typography>

            <InputField
              size="small"
              name="email"
              fullWidth
              type="email"
              placeholder="Enter your email"
              validate={[email()]}
              className={styles.textField}
              classes={{ root: styles.textFieldRoot }}
            />

            <Typography className={styles.studentID}>
              {formatMessage({ id: 'av_user.student_login.student_id' })}
            </Typography>
            <InputField
              size="small"
              name="studentId"
              fullWidth
              placeholder="Enter your student id"
              type="text"
              className={styles.textField}
              classes={{ root: styles.textFieldRoot }}
              validate={[studentIdRequired()]}
            />

            <Button variant="contained" color="primary" fullWidth type="submit" className={styles.buttonNext}>
              {formatMessage({ id: 'av_user.student_login.button_login' })}
            </Button>
          </form>
        )}
      />
    </div>
  );
};
