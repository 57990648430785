import { styled } from '@material-ui/core';

import { Container } from 'shared/components/container/Container';

export const StyledContainer = styled(Container)({
  // 239px -  footer height + header
  minHeight: 'calc(100vh - 239px)',
  paddingTop: 50,
  paddingBottom: 50,
  display: 'flex',
  flexDirection: 'column',
});

export const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});
