import React, { useState, useEffect } from 'react';

import { Video } from '../video/Video';

import { hash } from 'shared/utils/hash/hash';
import { BlobVideoProps } from './BlobVideo.types';

export const BlobVideo = ({ src, ...props }: BlobVideoProps) => {
  const [blob, setBlob] = useState();

  useEffect(() => {
    setBlob(URL.createObjectURL(src));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash(src)]);

  return <Video {...props} src={blob} />;
};
