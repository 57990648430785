import React from 'react';
import { useFormControl } from '@material-ui/core';

import { FormControlContext } from '../context/ControlBaseContext';

import { FormControlStateProviderProps } from './ControlBaseContextProvider.types';

export const FormControlStateProvider: React.FC<FormControlStateProviderProps> = ({ children, values }) => {
  const state = useFormControl();

  return <FormControlContext.Provider value={{ ...state, ...values }}>{children}</FormControlContext.Provider>;
};
