import React from 'react';

import { Image } from 'shared/components/image/Image';
import { Container } from './RightColumn.styles';
import clipLogo from 'assets/clip-logo.png';

export const RightColumn = () => {
  return (
    <Container>
      <Image alt="clip" src={clipLogo} />
    </Container>
  );
};
