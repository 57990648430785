import { withStyles, Select } from '@material-ui/core';

export const StyledSelect = withStyles({
  root: {
    backgroundColor: 'transparent',
    border: 0,
    width: '100%',
    padding: 0,
  },
})(Select);
