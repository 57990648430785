import { Button, Card, Typography } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';

import { useStyles } from './ContactSection.styles';

export const ContactSection = () => {
  const styles = useStyles();
  const { formatMessage } = useIntl();

  return (
    <Card className={styles.contactCard}>
      <div>
        <div className={styles.cardTopic}>{formatMessage({ id: 'main_page.contactSection_page.topic' })}</div>
        <div>
          <Typography className={styles.cardParagraph}>
            {formatMessage({ id: 'main_page.contactSection_page.cardPara' })}
          </Typography>
          <Button
            variant="contained"
            className={styles.buttonContact}
            onClick={() => {
              window.location.href = 'mailto:talk@stageclip.com';
            }}
          >
            {formatMessage({ id: 'main_page.contactSection_page.buttonText' })}
          </Button>
        </div>
      </div>
    </Card>
  );
};
