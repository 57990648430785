import {
  LinearProgress,
  withStyles,
  FormHelperText as MaterialFormHelperText,
  FormHelperTextProps,
} from '@material-ui/core';
import React from 'react';

export const StyledFormHelperText: React.ComponentType<FormHelperTextProps> = withStyles(({ typography, palette }) => ({
  root: {
    ...typography.caption,
    color: palette.error.main,
    padding: '0 15px',
  },
}))(MaterialFormHelperText);

export const StyledLinearProgress = withStyles(({ palette }) => ({
  root: {
    width: '100%',
    height: 20,
    backgroundColor: palette.green.light,
    borderRadius: 10,
    margin: '20px 0',
  },
  bar: {
    borderRadius: 20,
    backgroundColor: palette.green.main,
  },
}))(LinearProgress);
