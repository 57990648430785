import { Dispatch, SetStateAction } from 'react';

import { EventInformation } from '../AVUserRoot.types';

export enum CollaboratorPages {
  upcomingEvent = 'upcomingEvent',
  uploadMedia = 'uploadMedia',
  confirmMedia = 'confirmMedia',
  confirmLinkMedia = 'cofirmLinkMedia',
}
export type CollaboratorUploadProps = {
  avUserDetails: EventInformation[] | undefined;
  setEventDetails: (eventDetails: EventInformation) => void;
  eventDetails: EventInformation | null;
  setVideoFileInfo: (videoFileInfo: VideoFileInfo | null) => void;
  videoFileInfo: VideoFileInfo | null;
  setCollaboratorPage: (collaboratorPage: CollaboratorPages) => void;
  collaboratorPage: CollaboratorPages;
  handleEventVideos: () => void;
  isDisable: boolean;
  setIsDisable: Dispatch<SetStateAction<boolean>>;
  success: boolean;
  isMediaUploadFailed: boolean;
};

export type VideoFileInfo = {
  fileUrl: string;
  videoUrl: string;
  fileName: string;
  duration: string;
  size: string;
  fileInfo?: FileInfo;
};

export type FileInfo = {
  brands: string[];
  mime: string;
};
