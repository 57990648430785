import { Card, Typography } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import stepOne from 'assets/stepOne.svg';
import stepTwo from 'assets/stepTwo.svg';
import { useStyles } from './StepSection.styles';
import { Button, Image } from 'shared/components';
import bgImage from 'assets/stepBg.svg';
import { AppRoute } from 'app/routing/AppRoute.enum';

export const StepSection = () => {
  const styles = useStyles();
  const { formatMessage } = useIntl();
  const { push } = useHistory();

  return (
    <div className={styles.stepContainerOuter}>
      <div className={styles.stepContainerMiddle}>
        <div className={styles.stepContainerInner}>
          <Image className={styles.topimage} alt="" src={bgImage} />
          <div className={styles.rawNi}>
            <div className={styles.columnMain}>
              <Card className={styles.stepCard}>
                <div className={styles.cardContent}>
                  <div className={styles.cardRaw}>
                    <div className={styles.imagecolumn}>
                      <Image className={styles.stepTwoTop} alt="" src={stepOne} />
                    </div>
                    <div className={styles.cardcolumn}>
                      <Typography className={styles.cardTitle}>
                        {formatMessage({ id: 'main_page.upload_page.step1_topic' })}
                      </Typography>

                      <Typography className={styles.cardParagraph}>
                        {formatMessage({ id: 'main_page.upload_page.stepOne_paragraph' })}
                      </Typography>
                    </div>
                  </div>
                </div>
              </Card>
              <Card className={styles.stepCard}>
                <div className={styles.cardContent}>
                  <div className={styles.cardRaw}>
                    <div className={styles.imagecolumn}>
                      <Image className={styles.stepTwoBottom} alt="" src={stepTwo} />
                    </div>
                    <div className={styles.cardcolumn}>
                      <Typography className={styles.cardTitle}>
                        {formatMessage({ id: 'main_page.upload_page.step2_topic' })}
                      </Typography>
                      <Typography className={styles.cardParagraphbottom}>
                        {formatMessage({ id: 'main_page.upload_page.stepTwo_paragraph' })}
                      </Typography>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            <div className={styles.headlinecolumn}>
              <div className={styles.headline}>{formatMessage({ id: 'main_page.upload_page.headline' })}</div>
              <div className={styles.para}>{formatMessage({ id: 'main_page.upload_page.para' })}</div>
              <Button variant="contained" className={styles.buttonToday} onClick={() => push(AppRoute.universityEmail)}>
                {formatMessage({ id: 'main_page.upload_page.today_button' })}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
