/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { useLocation } from 'react-router-dom';

import { useStyles } from './VideoPreview.styles';

export const VideoPreview = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const url = search.substring(5);

  return (
    <div className={classes.divSize}>
      <video key={url} controls className={classes.videoSize} autoPlay>
        <source src={url} type="video/mp4" />
      </video>
    </div>
  );
};
