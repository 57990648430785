import { ParticipantDataResponse } from 'app/root/Root.types';
import { updatableContextFactory } from 'shared/utils/contextFactory/updatableContextFactory';

const [ParticipantDataProvider, useParticipantDataContext, useUpdateParticipantData] = updatableContextFactory<
  ParticipantDataResponse
>({} as ParticipantDataResponse);

export const useParticipantData = () => {
  const context = useParticipantDataContext();

  if (context === undefined) {
    throw new Error('useParticipantData must be used within an ParticipantDataProvider');
  }

  return context;
};

export { ParticipantDataProvider, useUpdateParticipantData };
