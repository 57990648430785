import React, { useRef, useEffect, useState } from 'react';

import { useMediaResource } from 'shared/hooks/useMediaResource/useMediaResource';
import { VideoControls } from 'shared/components/videoControls/VideoControls';
import { useWindowResize } from 'shared/hooks/useWindowResize/useWindowResize';
import { Typography } from 'shared/components/typography/Typography';
import { VideoRotationProps } from './VideoRotation.types';
import { Wrapper, Video, VideoPlaceholder } from './VideoRotation.styles';

/*
0 -> original
1 -> 90CW
2 -> 180CW
3 -> 270CW
*/
const angles = [0, 90, 180, 270];

export const VideoRotation = ({ src, onRotate, rotation }: VideoRotationProps) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const videoPlaceholderRef = useRef<HTMLDivElement | null>(null);
  const [error, setError] = useState(false);
  const source = useMediaResource(src);
  // const [loaded, setLoaded] = useState(false);

  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [duration, setDuration] = useState<number | string>('--:--');
  const [currentTime, setCurrentTime] = useState<number | string>('--:--');

  // TODO - remove this state
  // const [orientationIndex, setOrientationIndex] = useState(0);
  const angle = angles[rotation];

  const getVideoElement = () => {
    if (videoRef.current && videoRef.current instanceof HTMLVideoElement) {
      return videoRef.current;
    }

    return undefined;
  };

  const updateDimensions = () => {
    if (videoRef.current && videoPlaceholderRef.current && videoRef.current.parentElement) {
      const orientation = parseInt(videoPlaceholderRef.current.getAttribute('data-orientation') || '0', 10);
      const switchDimensions = orientation === 1 || orientation === 3;

      const parentWidth = parseInt(getComputedStyle(videoRef.current.parentElement).width.replace('px', ''), 10);
      const { videoWidth: rawVideoWidth, videoHeight: rawVideoHeight } = videoRef.current;
      const isHorizontal = rawVideoWidth > rawVideoHeight;

      const videoWidth = switchDimensions && isHorizontal ? rawVideoHeight : rawVideoWidth;
      const videoHeight = switchDimensions && isHorizontal ? rawVideoWidth : rawVideoHeight;
      const maxSpace = Math.min(parentWidth, switchDimensions ? videoHeight : videoWidth);
      const scale = maxSpace / (switchDimensions ? videoHeight : videoWidth) || 1;

      const w = rawVideoWidth * scale;
      const h = rawVideoHeight * scale;

      const width = switchDimensions ? h : w;
      const height = switchDimensions ? w : h;

      videoPlaceholderRef.current.style.width = `${width}px`;
      videoPlaceholderRef.current.style.height = `${height}px`;

      videoRef.current.style.width = `${w}px`;
      videoRef.current.style.height = `${h}px`;
    }
  };

  useWindowResize(updateDimensions, [videoRef.current]);

  useEffect(() => {
    updateDimensions();

    if (onRotate) {
      onRotate(angle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rotation]);

  const setVideoDuration = () => {
    const video = getVideoElement();

    if (video) {
      // create poster from first frame for mobile devices
      video.currentTime = 0.0;
      setDuration(video.duration);
    }
  };

  const handlePlay = () => {
    const video = getVideoElement();

    if (video) {
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    }
  };

  const handleMute = () => {
    const video = getVideoElement();

    if (video) {
      if (video.muted) {
        video.muted = false;
        setIsMuted(false);
      } else {
        video.muted = true;
        setIsMuted(true);
      }
    }
  };

  const handleCanPlay = () => {
    updateDimensions();
    // setLoaded(true);
  };

  useEffect(() => {
    // TODO - think about reseting rotation on new source
    // setOrientationIndex(0);
    // setLoaded(false);
    setError(false);
  }, [source]);

  const handleTimeUpdate = (e: React.SyntheticEvent<HTMLAudioElement>) => setCurrentTime(e.currentTarget.currentTime);

  if (error) {
    return <Typography color="error">error.cannot_display_preview</Typography>;
  }

  return (
    <Wrapper>
      <Video
        ref={videoRef}
        src={source}
        style={{ transform: `rotate(${angle}deg)` }}
        muted={isMuted}
        onLoadedMetadata={setVideoDuration}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        onTimeUpdate={handleTimeUpdate}
        preload="metadata"
        playsInline
        onCanPlay={handleCanPlay}
        onError={() => setError(true)}
      />
      <VideoPlaceholder ref={videoPlaceholderRef} data-orientation={rotation}>
        <VideoControls
          duration={duration}
          currentTime={currentTime}
          handleMute={handleMute}
          handlePlay={handlePlay}
          isMuted={isMuted}
          isPlaying={isPlaying}
        />
      </VideoPlaceholder>
    </Wrapper>
  );
};
