import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
  contactCard: {
    borderRadius: spacing(1),
    width: '70vw',
    height: spacing(32.125),
    backgroundColor: palette.black.light,
    marginLeft: 'auto',
    marginTop: spacing(27.5),
    marginRight: 'auto',
    marginBottom: spacing(21.625),
    [breakpoints.down('sm')]: {
      padding: spacing(0),
      width: '100%',
      textAlign: 'center',
      height: spacing(54.625),
      marginLeft: spacing(0),
      marginRight: spacing(0),
      marginTop: spacing(3.75),
      marginBottom: spacing(-0.125),
      borderRadius: spacing(0),
    },
  },
  cardTopic: {
    fontSize: spacing(4.25),
    fontWeight: 'bold',
    marginBottom: spacing(3),
    color: palette.white.main,
    marginTop: spacing(4.375),
    [breakpoints.down('sm')]: {
      marginBottom: spacing(12.5),
      marginTop: spacing(8),
    },
  },
  buttonContact: {
    backgroundColor: palette.green.dark,
    color: palette.white.main,
    fontFamily: 'Roboto',
    marginTop: spacing(3.125),
    marginBottom: spacing(4.375),
    borderRadius: spacing(0.5),
    height: spacing(6),
    textTransform: 'initial',
    '&:hover': {
      backgroundColor: palette.scorpionGray.dark,
    },
  },
  cardParagraph: {
    fontSize: spacing(2),
    fontFamily: 'Roboto',
    color: palette.gray.contrastText,
    fontWeight: 'initial',
    textTransform: 'initial',
    marginTop: spacing(1),
    marginLeft: spacing(14.5),
    marginRight: spacing(14.5),
    [breakpoints.down('sm')]: {
      width: '90vw',
      marginLeft: '3vw',
    },
  },
}));
