import {
  ConfirmParticipantCollectiveData,
  CreateFetchInstitutionData,
  CreateFetchInstitutionEventData,
  CreateFetchParticipantData,
  CreateFetchSubscriberData,
  PostSubscriberData,
  SendEmailForSubscripe,
} from './selfRegistration.types';

export const createFetchInstitutionData: CreateFetchInstitutionData = (eventId: string) => ({
  endpoint: `/participant-subscribe/${eventId}`,
  method: 'GET',
});

export const createFetchInstitutionEventData: CreateFetchInstitutionEventData = (
  domain: string,
  partOfInstitutionId: string,
) => ({
  endpoint: `/participant-subscribe/events/${domain}/${partOfInstitutionId}`,
  method: 'GET',
});

export const createParticipantOnboarding: CreateFetchParticipantData = (eventId: string) => body => ({
  endpoint: `/participant-subscribe/${eventId}/participant`,
  method: 'POST',
  body,
});

export const confirmParticipantApproveCollectiveUI: ConfirmParticipantCollectiveData = (
  participantId: string,
) => body => ({
  endpoint: `/participant-subscribe/allow-donation/${participantId}`,
  method: 'POST',
  body,
});

export const sendEmailForSubscripeParticipant: SendEmailForSubscripe = (participantId: string) => ({
  endpoint: `/participant-subscribe/send-email/${participantId}`,
  method: 'POST',
});

export const createFetchSubscriberData: CreateFetchSubscriberData = (participantId: string) => ({
  endpoint: `/participant-subscribe/participants/${participantId}`,
  method: 'GET',
});

export const updateSubscriberData: PostSubscriberData = (participantId: string) => body => ({
  endpoint: `/participant-subscribe/participants/${participantId}`,
  method: 'PATCH',
  body,
});
