import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@material-ui/core';

import { Wrapper, Text } from './NotYouBar.styles';

export const NotYouBar = () => {
  return (
    <Wrapper>
      <Text variant="body1" align="center">
        {{
          id: 'virtual_graduation.upload.not_you',
          values: {
            a: (msg: string) => (
              <Link component={RouterLink} to="/">
                {msg}
              </Link>
            ),
          },
        }}
      </Text>
    </Wrapper>
  );
};
