import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing, palette }) => ({
  grid1: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '1%',
    paddingBottom: '2%',
  },
  fontColored: {
    color: palette.green.dark,
    fontSize: spacing(2.6),
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
  },
  fontColorless: {
    color: palette.black.main,
    fontSize: spacing(2.6),
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
  },
  colorlessCircle: {
    marginTop: '15%',
    display: 'flex',
    width: spacing(3),
    height: spacing(3),
    backgroundColor: palette.gray.main,
    borderRadius: '50%',
  },
  colorCircle: {
    marginTop: '15%',
    display: 'flex',
    width: spacing(3),
    height: spacing(3),
    backgroundColor: palette.green.dark,
    borderRadius: '50%',
    gap: spacing(2),
  },
  text: {
    color: 'white',
    margin: 'auto',
  },
}));
