import { styled, Link as MaterialLink, makeStyles, Theme } from '@material-ui/core';

import { Typography } from 'shared/components/typography/Typography';

export const Container = styled('div')(({ theme: { breakpoints, spacing } }) => ({
  width: '100%',
  textAlign: 'center',
  flexDirection: 'column',
  height: 'auto',
  [breakpoints.down('sm')]: {
    padding: spacing(0),
    textAlign: 'center',
    flexDirection: 'column',
  },
}));

export const HomeContainer = styled('div')(({ theme: { breakpoints, spacing } }) => ({
  [breakpoints.down('sm')]: {
    padding: spacing(0),
    width: '100%',
    textAlign: 'center',
    flexDirection: 'column',
  },
}));

export const useStyles = makeStyles<Theme, { titleSize: number }>(({ palette, breakpoints, spacing }) => ({
  bgImg: {
    backgroundSize: 'cover',
    width: '100%',
    marginBottom: '10px',
    marginTop: spacing(-13.75),
    [breakpoints.down('sm')]: {
      width: '100%',
      objectFit: 'contain',
      marginTop: spacing(0),
    },
  },
  home: {
    height: '135vh',
    backgroundColor: palette.blue.light,
    zIndex: spacing(-0.25),
    clipPath: 'polygon(100% 0%, 100% 100%, 50% 90%, 0 99%, 0 0)',
    [breakpoints.down('md')]: {
      height: spacing(116.125),
    },
    [breakpoints.down('sm')]: {
      height: spacing(91.875),
    },
    [breakpoints.down('xs')]: {
      height: spacing(80),
    },
  },
  header: {
    color: palette.white.main,
    fontSize: spacing(4.25),
  },

  titletop: {
    color: palette.white.main,
    fontSize: spacing(6),
    fontWeight: spacing(87.5),
    fontFamily: 'Open Sans',
    lineHeight: spacing(0.125),
    [breakpoints.down('sm')]: {
      margin: 'auto',
      fontSize: spacing(3.25),
      width: spacing(37.5),
      lineHeight: spacing(0.15),
    },
  },
  titlebottom: {
    color: palette.white.main,
    fontSize: spacing(6),
    fontWeight: spacing(87.5),
    fontFamily: 'Open Sans',
    lineHeight: spacing(0.125),
    [breakpoints.down('sm')]: {
      margin: 'auto',
      fontSize: spacing(3.25),
      width: spacing(37.5),
      lineHeight: spacing(0.15),
    },
  },

  textLine: {
    marginTop: spacing(1.875),
    marginBottom: spacing(4.25),
    fontSize: spacing(4.375),
    fontWeight: spacing(125),
    color: palette.gray.dark,
  },

  subtitle: {
    color: palette.white.main,
    fontSize: spacing(2),
    fontFamily: 'Roboto',
  },

  buttonfind: {
    backgroundColor: palette.black.light,
    color: palette.white.main,
    fontFamily: 'Roboto bold',
    fontWeight: 'bold',
    position: 'absolute',
    top: '1vh',
    marginBottom: spacing(4),
    width: spacing(25),
    borderRadius: spacing(0.5),
    display: 'none',
    [breakpoints.down('sm')]: {
      display: 'inline',
    },
  },
  buttonupload: {
    backgroundColor: palette.green.dark,
    color: palette.white.main,
    fontFamily: 'Roboto bold',
    fontWeight: 'bold',
    height: spacing(5.25),
    width: spacing(29.625),
    marginTop: spacing(2.5),
    marginBottom: spacing(3.75),
    borderRadius: spacing(0.5),
    [breakpoints.down('sm')]: {
      position: 'absolute',
      width: spacing(25),
    },
    [breakpoints.up('sm')]: {
      display: 'inline',
    },
    '&:hover': {
      backgroundColor: palette.scorpionGray.dark,
    },
  },

  iconContainer: {
    backgroundColor: 'transparent',
  },

  iconSet: {
    width: spacing(81.25),
    height: spacing(75),
    position: 'absolute',
    left: spacing(0),
    right: spacing(0),
    marginLeft: 'auto',
    marginRight: 'auto',
    top: '70vh',
    [breakpoints.down('md')]: {
      top: '73vh',
    },
    [breakpoints.down('sm')]: {
      width: spacing(40),
      height: spacing(37.5),
      top: spacing(53.75),
    },
    [breakpoints.down('xs')]: {
      width: spacing(40),
      height: spacing(37.5),
      top: spacing(42.5),
    },
  },

  columnHeart: {
    position: 'absolute',
    left: spacing(3.75),
    top: spacing(12.5),
    width: spacing(26.25),
    height: spacing(26.25),
    zIndex: spacing(1.25),
    [breakpoints.down('sm')]: {
      width: spacing(14),
      height: spacing(9),
      zIndex: spacing(0.125),
      top: spacing(1.25),
      left: spacing(0),
    },
  },

  columnmobile: {
    position: 'absolute',
    right: spacing(3.75),
    top: spacing(32.5),
    width: spacing(26.25),
    height: spacing(26.25),
    zIndex: spacing(1.25),
    [breakpoints.down('sm')]: {
      top: spacing(17.5),
      width: spacing(11.25),
      height: spacing(7.5),
      zIndex: spacing(0.125),
      rigth: spacing(0),
      left: spacing(27.5),
    },
  },

  columnlike: {
    display: 'inline',
    marginRight: spacing(8.125),
    position: 'relative',
    width: spacing(41.875),
    height: spacing(68.75),
    marginLeft: spacing(5),
    zIndex: spacing(0.25),
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  columnmob: {
    display: 'none',
    zIndex: spacing(0.25),
    [breakpoints.down('sm')]: {
      display: 'inline',
      position: 'relative',
      width: spacing(26),
      height: spacing(42.75),
      marginRight: spacing(2.5),
    },
  },
  card: {
    position: 'absolute',
    borderRadius: spacing(1.25),
    width: spacing(30),
    marginBottom: '100vh',
    height: spacing(23.875),
    top: '90vh',
    right: '10vw',
    [breakpoints.down('md')]: {
      display: 'none',
    },
  },
  value: {
    fontSize: spacing(4.25),
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  passage: {
    fontSize: spacing(2),
    fontFamily: 'Roboto',
    textAlign: 'left',
    marginLeft: spacing(4),
    marginBottom: spacing(2.5),
  },
  arrow: {
    float: 'right',
    marginLeft: spacing(2.5),
  },
  cardRaw: {
    display: 'flex',
    flex: 'raw',
    width: '100%',
    marginTop: spacing(4.75),
    marginLeft: spacing(3.625),
  },
  topic: {
    fontSize: spacing(4.25),
    textAlign: 'left',
  },
  list: {
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontSize: spacing(2),
    fontWeight: 'initial',
    color: palette.gray.dark,
  },
  paragraph: {
    fontSize: spacing(2),
    fontFamily: 'Roboto',
    textAlign: 'left',
    marginTop: spacing(2.25),
    textTransform: 'initial',
    fontWeight: 'initial',
    color: palette.gray.dark,
  },
  stepCard: {
    borderRadius: spacing(1),
    width: spacing(57),
    margin: 'auto',
    height: spacing(23.125),
    marginTop: spacing(2.875),
  },
  cardTitle: {
    fontSize: spacing(2.625),
    fontWeight: 'bold',
  },
  cardParagraph: {
    fontSize: spacing(2),
    fontFamily: 'Roboto',
    color: palette.gray.contrastText,
    fontWeight: 'initial',
    textTransform: 'initial',
    marginTop: spacing(1),
    marginLeft: spacing(14.5),
    marginRight: spacing(14.5),
  },
  column8: {
    width: '100%',
    textAlign: 'left',
    marginLeft: spacing(6.25),
    marginTop: spacing(5),
  },
  column9: {
    width: spacing(10),
    textAlign: 'center',
    marginTop: spacing(2.75),
  },
  column10: {
    margin: 'auto',
    width: spacing(68.75),
  },
  stepTwo: {
    marginLeft: spacing(2.5),
    marginTop: spacing(5),
  },
  buttonToday: {
    backgroundColor: palette.black.light,
    color: palette.white.main,
    fontFamily: 'Roboto bold',
    float: 'left',
    marginBottom: spacing(4),
    borderRadius: spacing(0.5),
    height: spacing(5),
    marginTop: spacing(3.125),
  },
  headline: {
    fontSize: spacing(6),
    fontWeight: 'bold',
    textAlign: 'left',
    color: palette.white.main,
  },
  para: {
    fontSize: spacing(2),
    fontFamily: 'Roboto',
    textAlign: 'left',
    fontWeight: 'initial',
    marginTop: spacing(3.625),
    color: palette.white.main,
  },
  stepContainer: {
    backgroundColor: palette.blue.dark,
    height: spacing(56.25),
  },
  buttonContact: {
    backgroundColor: palette.green.dark,
    color: palette.white.main,
    fontFamily: 'Roboto bold',
    marginTop: spacing(3.125),
    marginBottom: spacing(4.375),
    borderRadius: spacing(0.5),
    height: spacing(6),
  },
  phoneContainer: {
    height: '10vh',
    marginBottom: '20vh',
    [breakpoints.down('sm')]: {
      marginBottom: spacing(0),
      height: '7vh',
    },
  },

  mdscreentext: {
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  smscreentext: {
    display: 'none',
    [breakpoints.down('sm')]: {
      display: 'inline',
    },
  },
}));

export const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
});

export const Description = styled(Typography)(({ theme: { breakpoints, typography, spacing } }) => ({
  marginBottom: spacing(1),
  [breakpoints.down('sm')]: {
    ...typography.h4,
  },
}));

export const InformationText = styled(Typography)(({ theme: { breakpoints, spacing } }) => ({
  marginBottom: spacing(5),
  marginTop: spacing(2.5),
  [breakpoints.down('sm')]: {
    whiteSpace: 'unset',
    textAlign: 'center',
  },
}));

export const Link = styled(MaterialLink)(({ theme: { palette, breakpoints, typography, spacing } }) => ({
  ...typography.button,
  textTransform: 'initial',
  backgroundColor: palette.primary.main,
  color: palette.white.main,
  fontWeight: 'bold',
  paddingTop: spacing(1.25),
  paddingLeft: spacing(6.75),
  marginTop: spacing(12.5),
  borderRadius: spacing(0.5),
  [breakpoints.down('sm')]: {
    paddingTop: spacing(1.25),
    paddingLeft: spacing(4.625),
  },
}));

export const LogoContainer = styled('div')(({ theme: { breakpoints, spacing } }) => ({
  justifyContent: 'flex-end',
  flex: spacing(0.5),
  margin: 'auto',

  [breakpoints.only('sm')]: {
    '& svg': {
      maxWidth: spacing(50),
      maxHeight: spacing(37.5),
    },
  },
  [breakpoints.down('sm')]: {
    '& svg': {
      maxWidth: spacing(37.5),
      maxHeight: spacing(37.5),
    },
    justifyContent: 'center',
  },
}));

export const Name = styled(Typography)(({ theme: { typography: { h3 }, spacing } }) => ({
  ...h3,
  fontWeight: spacing(25),
}));
