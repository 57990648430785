import React from 'react';
import { useIntl } from 'react-intl';

import stageClipIcon from 'assets/stageClipIcon.svg';
import { useStyles } from './HeaderSection.styles';
import { Typography, Image } from 'shared/components';

export const HeaderSection = () => {
  const styles = useStyles();
  const { formatMessage } = useIntl();

  return (
    <>
      <div className={styles.imageContainer}>
        <Image className={styles.logoImage} alt="" src={stageClipIcon} />
      </div>
      <div className={styles.headertextContainer}>
        <Typography className={styles.header}>{formatMessage({ id: 'main_page.headerSection_page.Login' })}</Typography>
        <Typography className={styles.subheader}>
          {formatMessage({ id: 'main_page.headerSection_page.sup' })}
        </Typography>
        <Typography className={styles.mobileheader}>
          {formatMessage({ id: 'main_page.headerSection_page.logintosc' })}
        </Typography>
        <Typography className={styles.mobilesubheader}>
          {formatMessage({ id: 'main_page.headerSection_page.uploadp' })}
        </Typography>
        <Typography className={styles.title}>{formatMessage({ id: 'main_page.headerSection_page.myoh' })}</Typography>
      </div>
    </>
  );
};
