import { Button } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { useStyles } from './InfogradSection.styles';
import { Typography } from 'shared/components';
import { AppRoute } from 'app/routing/AppRoute.enum';

export const InfogradSection = () => {
  const styles = useStyles();
  const { formatMessage } = useIntl();
  const { push } = useHistory();

  return (
    <div className={styles.row}>
      <div className={styles.column}>
        <iframe className={styles.video} title="video" src="https://www.youtube.com/embed/AV60mdNy1p4" />
      </div>
      <div className={styles.column}>
        <Typography className={styles.topic}>
          {formatMessage({ id: 'main_page.infogradSection_page.inforgrad' })}
        </Typography>
        <Typography className={styles.content}>
          {formatMessage({ id: 'main_page.infogradSection_page.para' })}
        </Typography>
        <Button variant="contained" className={styles.buttonupload} onClick={() => push(AppRoute.universityEmail)}>
          {formatMessage({ id: 'main_page.infogradSection_page.button' })}
        </Button>
      </div>
    </div>
  );
};
