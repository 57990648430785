import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  message: {
    // TODO - Try to remove !important
    marginTop: '0 !important',
    '& textarea:read-only': {
      cursor: 'default',
    },
    '& MuiTypography-root': {
      color: 'red',
    },
  },
});
