import React from 'react';

import { ExternalLink } from 'shared/components/externalLink/ExternalLink';
import vector from 'assets/arrows/arrow-snapcode.svg';
import { StyledContainer, Text, Vector, SnapcodeImage } from './Snapcode.styles';
import { SnapcodeProps } from './Snapcode.types';

export const Snapcode = ({ snapchatUrl, snapcode }: SnapcodeProps) => {
  return (
    <StyledContainer>
      <Text>virtual_graduation.upload.snapcode.text</Text>
      <Vector alt="vector" src={vector} />
      <ExternalLink href={snapchatUrl} target="_blank">
        <SnapcodeImage alt="snapcode" src={snapcode} />
      </ExternalLink>
    </StyledContainer>
  );
};
