import { Button, Card, Typography } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Image } from 'shared/components';
import { useStyles } from './InstitutionEmailSuccess.styles';
import doneImg from 'assets/done.svg';
import { AppRoute } from 'app/routing/AppRoute.enum';
import { InstitutionalEmailProps } from './InstitutionEmailSuccesstypes';

export const InstitutionEmailSuccess = ({ email }: InstitutionalEmailProps) => {
  const styles = useStyles();
  const { formatMessage } = useIntl();
  const { push } = useHistory();

  return (
    <Card className={styles.card}>
      <Image className={styles.column1} alt="" src={doneImg} />
      <Typography className={styles.subtitle}>
        {formatMessage({ id: 'main_page.personalEmail_page.subtitle' })}
      </Typography>

      <Typography className={styles.description}>
        {formatMessage({ id: 'main_page.personalEmail_page.descriptionQ' })}
      </Typography>
      <Typography className={styles.descriptionlower}>{email}</Typography>

      <Button
        variant="contained"
        className={styles.button5}
        onClick={() => {
          window.location.href = 'mailto:';
        }}
      >
        {formatMessage({ id: 'main_page.personalEmail_page.button' })}
      </Button>
      <Typography className={styles.question}>
        {formatMessage({ id: 'main_page.personalEmail_page.question' })}
      </Typography>
      <Typography className={styles.tryanother} onClick={() => push(AppRoute.personalEmail)}>
        {formatMessage({ id: 'main_page.personalEmail_page.tryanother' })}
      </Typography>
    </Card>
  );
};
