/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react';
import { useQuery } from 'react-fetching-library';
import { Snackbar } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import { createFetchSubscriberData } from 'api/actions/selfRegistration/selfRegistration.actions';
import { useStyles } from './HighSchoolSelfRegistration.styles';
import { HighSchoolSelfRegisterUpdateContainer } from './selfRegistration/upadateSelfRegistration/UpdateHighSchoolRegisterContainer';
import { StudentDetails } from './selfRegistration/upadateSelfRegistration/UpdateHighSchoolSelfRegistration.types';
import { Loader } from 'shared/components';
import { CompleteSelfRegistrationContainer } from './completeSelfRegistration/CompleteSelfRegistrationContainer';

export type EventIdParamType = { participantId: string };

export const SelfRegistrationEditContainer = () => {
  const styles = useStyles();
  const id = useParams<EventIdParamType>();
  const [isProhibitedEmailModalOpen, setIsProhibitedEmailModalOpen] = useState<React.ReactNode | null>(null);
  const [activeStep, setActiveStep] = useState(0);

  const [participantDetails, setParticipantDetails] = useState({
    id: '',
    wooCommercePermalink: '',
  });

  const { payload: studentDetails, loading: studentLoading } = useQuery<StudentDetails>(
    createFetchSubscriberData(id.participantId),
  );

  if (studentLoading) {
    return (
      <div>
        <Loader fullScreen />
      </div>
    );
  }

  const handleSnackBarClose = () => {
    setIsProhibitedEmailModalOpen(null);
  };

  return (
    <>
      {activeStep === 0 && (
        <HighSchoolSelfRegisterUpdateContainer
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          isCollective={false}
          isRetail={false}
          instituteLogo={studentDetails?.instituionLogo}
          setSnackbarMessage={(message: React.ReactNode) => setIsProhibitedEmailModalOpen(message)}
          queryParamKeys="instituitionId"
          isAllowForAmazonMusic={false}
          StudentDetails={studentDetails!}
          // instituitionEventDetails={institutionData!}
          setParticipantDetails={setParticipantDetails}
        />
      )}
      {activeStep === 2 && (
        <CompleteSelfRegistrationContainer
          activeStep={activeStep}
          participantWooCommercePermalink={participantDetails.wooCommercePermalink}
          setActiveStep={setActiveStep}
          instituteLogo={studentDetails?.instituionLogo}
          isCollective={false}
          isRetail={false}
          queryParamKeys=""
          isAllowForAmazonMusic={false}
          isRegisterFlow={false}
        />
      )}
      <Snackbar
        open={!!isProhibitedEmailModalOpen}
        onClose={handleSnackBarClose}
        message={isProhibitedEmailModalOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        className={styles.snackBar}
        autoHideDuration={10000}
      />
    </>
  );
};
