import React from 'react';
import { useIntl } from 'react-intl';

import { PageAlert } from 'shared/components';
import { MailLink } from 'shared/components/mailLink/MailLink';
import { StyledContainer } from './Alert.styles';

export const Alert = () => {
  const { formatMessage } = useIntl();

  const email = formatMessage({ id: 'static.support_email' });

  return (
    <StyledContainer>
      <PageAlert severity="warning">
        {formatMessage(
          {
            id: 'virtual_graduation.upload.alert',
          },
          { email: <MailLink mailto={email}>{email}</MailLink> },
        )}
      </PageAlert>
    </StyledContainer>
  );
};
