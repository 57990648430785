import { PaletteOptions } from '@material-ui/core/styles/createPalette';

const green = '#458606';
const lightGreen = '#89d044';
const blue = '#2baadf';
const red = '#b00020';
const black = '#373737';
const darkBlack = '#212121';
export const white = '#ffffff';
const gray = '#979797';
const darkGrey = '#595959';
const lightGray = '#d8d8d8';
const bodyBackground = '#fff';
export const yellow = '#ffdc6c';
const darkBlue = '#1C2541';
export const lightGreenBackground = '#E5ECE0';
export const pelorous = '#32abb8';
const scorpionGray = '#595959';
const darkGreen = '#18a957';
const redLight = '#df1642';
const greenButton = '#e9fcf1';
const babyRed = '#f0e3e6';
const lightBlack = '#0D1C28';
const lightBlue = '#163043';
const fillGray = '#e6e6e6';
const fillBlue = '#2d6187';
const backgroundblue = '#add8e6';
const backgroundlightblue = '#214865';
const lighgray = 'rgba(0, 0, 0, 0.54)';
const hovergreen = '#5dc389';
export const darkBlueLight = '#163043';

export const palette = (primaryColour = pelorous): PaletteOptions => ({
  green: {
    main: green,
    light: lightGreen,
    dark: darkGreen,
    contrastText: greenButton,
  },
  red: {
    main: red,
    light: redLight,
    dark: babyRed,
  },
  black: {
    main: black,
    dark: darkBlack,
    light: lightBlack,
  },

  white: {
    main: white,
  },
  gray: {
    main: gray,
    light: lightGray,
    dark: darkGrey,
    contrastText: fillGray,
  },
  blue: {
    main: blue,
    dark: darkBlue,
    light: lightBlue,
    contrastText: fillBlue,
  },
  primary: {
    main: primaryColour,
    light: primaryColour,
  },
  secondary: {
    main: darkBlack,
  },
  error: {
    main: red,
  },
  success: {
    main: green,
  },
  warning: {
    main: yellow,
  },
  info: {
    main: backgroundblue,
    light: backgroundlightblue,
  },
  text: {
    primary: black,
    secondary: white,
  },
  background: {
    default: bodyBackground,
  },
  scorpionGray: {
    main: scorpionGray,
    light: lighgray,
    dark: hovergreen,
  },
});
