import React, { useEffect } from 'react';
import { useLocation, RouteProps, Redirect, Route } from 'react-router-dom';
import { parse } from 'query-string';

import {
  useParticipantQueryData,
  useUpdateParticipantQueryData,
} from 'contexts/participantQueryData/participantQueryDataContext';
import { Loader } from 'shared/components/loader/Loader';

const parseQueryData = (search: string) => {
  const { token, edit = false, editToken } = parse(search, { parseBooleans: true }) as {
    token?: string;
    edit?: boolean;
    editToken?: string;
  };

  const [participantId, secret] = (typeof token === 'string' ? token : '').split(':');

  return { participantId, secret, edit, editToken };
};
export const AuthorizedRoute: React.FC<RouteProps> = props => {
  const { search } = useLocation();
  const queryData = parseQueryData(search);
  const participantData = useParticipantQueryData();
  const updateParticipantQueryData = useUpdateParticipantQueryData();

  const { participantId, secret } = queryData;

  useEffect(() => {
    if (participantId && secret) {
      updateParticipantQueryData({ participantId, ...queryData });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...Object.values(queryData), updateParticipantQueryData]);

  if (!participantId || !secret) {
    return <Redirect to="/email" />;
  }

  if (!participantData.secret || !participantData.participantId) {
    return <Loader fullScreen />;
  }

  return <Route {...props} />;
};
