import { TextFieldProps } from '@material-ui/core';

import { connectControl } from '../connectControl/connectControl';
import { ConnectControlProps } from '../connectControl/connectControl.types';

import { length } from 'shared/validators';
import { TextField } from './TextField';

function decorateProps(props: ConnectControlProps<TextFieldProps>): ConnectControlProps<TextFieldProps> {
  const { validate = [], maxLength } = props;

  if (maxLength) {
    validate.push(length({ max: maxLength }));
  }

  return {
    ...props,
    validate,
  };
}

export const ConnectedTextField = connectControl<TextFieldProps, string>(TextField, decorateProps);
