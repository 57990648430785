import React, { createContext, useContext, useState, Dispatch, SetStateAction } from 'react';

export function updatableContextFactory<Data>(defaultValue?: Data) {
  type ContextData = Data | null | undefined;
  const dataContext = createContext<ContextData>(null);
  const updateContext = createContext<Dispatch<SetStateAction<ContextData>> | undefined>(undefined);

  const useContextData = () => {
    const c = useContext(dataContext);

    if (c === null) {
      throw new Error('updatableContextFactory => useContextData must be inside a Provider');
    }

    return c;
  };

  const useUpdateContextData = () => {
    const c = useContext(updateContext);

    if (!c) {
      throw new Error('updatableContextFactory => useUpdateContextData must be inside a Provider');
    }

    return c;
  };

  const Provider: React.FC = props => {
    const [state, update] = useState<ContextData>(defaultValue);

    return (
      <updateContext.Provider value={update}>
        <dataContext.Provider value={state} {...props} />
      </updateContext.Provider>
    );
  };

  return [Provider, useContextData, useUpdateContextData] as const;
}
