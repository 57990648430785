import { useRef, useReducer, useState } from 'react';
import { useClient } from 'react-fetching-library';
import { BlockBlobClient } from '@azure/storage-blob';
import filesize from 'filesize';
import { useIntl } from 'react-intl';

import { useActivityTrack } from '../useActivityTrack/useActivityTrack';

import { useIsMounted } from 'shared/hooks/useIsMounted/useIsMounted';
import * as Sentry from 'shared/utils/sentry/sentry';
import { reducer, reducerInitialState } from './useAzureUpload.reducer';
import { setProgress, setFileUri, setError, setLoading, setVideoUri } from './useAzureUpload.actions';
import { Config, UploadMeta } from './useAzureUpload.types';
import { ActivityTypes, EventVideoActivityCode } from 'api/activityTrack/activityTrack.types';

const isAbortControllerAvailable = 'AbortController' in window;

export const useAzureUpload = ({ fetchUploadMetaAction, institutionName }: Config) => {
  const [state, dispatch] = useReducer(reducer, reducerInitialState);
  const isMounted = useIsMounted();
  const controller = useRef<AbortController | null>();
  const client = useClient();
  const [fileName, setFileName] = useState('');
  const [fileSize, setFileSize] = useState('');
  const [videoBlobFile, setVideoBlobFile] = useState<File>();
  const { onActivityTrack } = useActivityTrack();
  const { formatMessage } = useIntl();

  const upload = async (file: File) => {
    dispatch(setLoading());

    if (controller.current) {
      controller.current.abort();
    }

    controller.current = isAbortControllerAvailable ? new AbortController() : undefined;

    const fileExtension = file?.name?.substr(file.name.lastIndexOf('.') + 1).toLowerCase();
    const fileNameWithoutExtension = file?.name.replace(`.${fileExtension}`, '');

    const uploadMetaResponse = await client.query<UploadMeta>(
      fetchUploadMetaAction(fileExtension, fileNameWithoutExtension),
    );
    const videoFileName = file.name;
    const videoFileSize = filesize(file.size);
    setFileName(videoFileName);
    setFileSize(videoFileSize);
    setVideoBlobFile(file);

    if (institutionName) {
      onActivityTrack(
        ActivityTypes.EventVideo,
        EventVideoActivityCode.ProducerVideoTryToUpload,
        formatMessage({ id: 'av_user.activity_track_description.video_uploading' }, { media: fileName }),
        institutionName || '',
      );
    }
    try {
      if (uploadMetaResponse.payload && !uploadMetaResponse.error) {
        const uri = uploadMetaResponse.payload.fileURI;
        const blobClient = new BlockBlobClient(`${uri}?${uploadMetaResponse.payload.token}`);
        dispatch(setVideoUri(`${uri}?${uploadMetaResponse.payload.token}`));

        await blobClient.uploadBrowserData(file, {
          onProgress: transferProgress => dispatch(setProgress((transferProgress.loadedBytes / file.size) * 100)),
          blobHTTPHeaders: {
            blobContentType: file.type,
          },
          abortSignal: controller.current ? controller.current.signal : undefined,
        });

        if (isMounted.current) {
          dispatch(setFileUri(uri));
        }
      } else {
        throw uploadMetaResponse;
      }
    } catch (uploadError) {
      dispatch(setError('true'));
      onActivityTrack(
        ActivityTypes.EventVideo,
        EventVideoActivityCode.ProducerVideoUploadFailed,
        formatMessage({ id: 'av_user.activity_track_description.video_upload_error' }, { media: fileName }),
        institutionName || '',
      );

      if (uploadError.name !== 'AbortError') {
        Sentry.withScope(() => {
          Sentry.captureException(uploadError.errorObject.message || uploadError.payload.errors.ext || 'Upload error');
        });
      }
    }
  };

  return {
    upload,
    videoBlobFile,
    fileName,
    fileSize,
    ...state,
  };
};
