import React from 'react';

import { Container, Title, DescriptionContainer, Description } from './DescriptionSection.styles';

export const DescriptionSection = () => {
  return (
    <Container>
      <Title variant="h1">virtual_graduation.about_us.title</Title>
      <DescriptionContainer>
        <Description>virtual_graduation.about_us.description_1</Description>
        <Description>virtual_graduation.about_us.description_2</Description>
        <Description>virtual_graduation.about_us.description_3</Description>
      </DescriptionContainer>
    </Container>
  );
};
