import { styled, IconButton } from '@material-ui/core';

import { Typography } from 'shared/components/typography/Typography';

export const Wrapper = styled('div')({
  display: 'inline-flex',
  alignItems: 'center',
  overflow: 'hidden',
  height: '100%',
  width: '100%',
});

export const FileName = styled(Typography)({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: 'calc(100% - 48px)',
  cursor: 'pointer',
  position: 'relative',
  marginLeft: 35,
});

export const Input = styled('input')({
  display: 'none',
  opacity: 0,
});

export const PencilButton = styled(IconButton)({
  position: 'absolute',
  left: 6,
  cursor: 'pointer',
});
