import React from 'react';

import { GridContainer } from '../grid';

import { Left, Right, StyledPaper } from './Section.styles';
import { Separator } from 'shared/components/separator/Separator';
import { SectionProps } from './Section.types';

export const Section: React.FC<SectionProps> = ({ left, right }) => {
  return (
    <>
      <Separator height={15} />
      <StyledPaper>
        <GridContainer justify="center">
          <Left xs={12} md={10}>
            {left}
          </Left>
          <Right xs={12} md={2}>
            {right}
          </Right>
        </GridContainer>
      </StyledPaper>
    </>
  );
};
