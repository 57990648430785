import { Actions, SET_ERROR, SET_LOADING, SET_FILE_URI, SET_PROGRESS, SET_VIDEO_URI } from './useAzureUpload.actions';

type FileURI = string | undefined;
type Error = false | string;

type State = {
  progress: number;
  loading: boolean;
  fileURI: FileURI;
  videoURI: FileURI;
  error: Error;
};

export const reducerInitialState: State = {
  loading: false,
  progress: 0,
  error: false,
  fileURI: undefined,
  videoURI: undefined,
};

export const reducer = (state: State, action: Actions) => {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...reducerInitialState,
        error: action.payload,
      };
    case SET_LOADING:
      return {
        ...reducerInitialState,
        loading: true,
      };
    case SET_FILE_URI:
      return {
        ...state,
        loading: false,
        fileURI: action.payload,
      };
    case SET_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };
    case SET_VIDEO_URI:
      return {
        ...state,
        loading: false,
        videoURI: action.payload,
      };
    default:
      return state;
  }
};
