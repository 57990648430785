import * as React from 'react';
import { AppBar, Toolbar, Grid } from '@material-ui/core';

import { HeaderStyle } from './Header.style';
import Banner from 'assets/Banner.jpg';
import BannerMobile from 'assets/Banner-mobile.jpg';
import BannerXs from 'assets/Banner-mobile-xs.jpg';
import clippingPlatformLogo from 'assets/clipping_platform_logo.png';

export const Header = () => {
  const classes = HeaderStyle();

  return (
    <>
      <div className={classes.divRoot}>
        <AppBar elevation={1} position="static" className={classes.root}>
          <Toolbar className={classes.toolBar}>
            <img src={clippingPlatformLogo} alt="logo" className={classes.logo} />
          </Toolbar>
        </AppBar>
      </div>
      <Grid container sm={12} xs={12}>
        <Grid container>
          <img src={Banner} alt="HeaderImage" className={classes.headerImage} />
          <img src={BannerMobile} alt="HeaderImage" className={classes.headerImageMobile} />
          <img src={BannerXs} alt="HeaderImage" className={classes.headerImagexs} />
        </Grid>
      </Grid>
    </>
  );
};
