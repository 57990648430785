import { TextField, withStyles } from '@material-ui/core';

export const StyledTextField = withStyles(({ palette, spacing }) => ({
  root: {
    width: '100%',
    marginBottom: spacing(4),
    '& label': {
      color: palette.gray.main,
    },
    '& .MuiInput-underline.Mui-disabled:before': {
      borderBottomStyle: 'solid',
    },
  },
}))(TextField);
