import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from '@material-ui/core';

import stageClipIcon from 'assets/stageClipIcon.svg';
import { useStyles } from './FooterSection.styles';
import { Typography, Image } from 'shared/components';

export const FooterSection = () => {
  const styles = useStyles();
  const { formatMessage } = useIntl();

  return (
    <div className={styles.stepContainer}>
      <div className={styles.raw}>
        <div className={styles.column}>
          <Image className={styles.columnImg} alt="" src={stageClipIcon} />
          <Typography className={styles.cardTopic}>
            {formatMessage({ id: 'main_page.footerSection_page.cardTopic' })}
          </Typography>
        </div>
        <div className={styles.links}>
          <div className={styles.categoryLeft}>
            <Link href="https://producer.stageclip.com/login" className={styles.link} target="_blank">
              {formatMessage({ id: 'main_page.footerSection_page.clientlogin' })}
            </Link>

            <Link href="https://stageclip.com/privacy-policy/" className={styles.link} target="_blank">
              {formatMessage({ id: 'main_page.footerSection_page.privacypolicy' })}
            </Link>

            <Link href="https://stageclip.com/terms-conditions/" className={styles.link} target="_blank">
              {formatMessage({ id: 'main_page.footerSection_page.tandcond' })}
            </Link>

            <Link href="https://stageclip.com/cookie-statement/" className={styles.link} target="_blank">
              {formatMessage({ id: 'main_page.footerSection_page.cookies' })}
            </Link>

            <Link href="https://stageclip.com/contact-us/" className={styles.link} target="_blank">
              {formatMessage({ id: 'main_page.footerSection_page.contactus' })}
            </Link>
          </div>
          <div className={styles.categoryRight}>
            <Link href="https://stageclip.com/copyright-infringement" className={styles.link} target="_blank">
              {formatMessage({ id: 'main_page.footerSection_page.copyright' })}
            </Link>

            <Link
              href="https://stageclip.com/uk-modern-slavery-act-2015-statement-mar-21/"
              className={styles.link}
              target="_blank"
            >
              {formatMessage({ id: 'main_page.footerSection_page.modernslavery' })}
            </Link>

            <Link href="https://stageclip.com/institutions" className={styles.link} target="_blank">
              {formatMessage({ id: 'main_page.footerSection_page.resourcesinst' })}
            </Link>

            <Link href="https://stageclip.com/graduates" className={styles.link} target="_blank">
              {formatMessage({ id: 'main_page.footerSection_page.resourcesgrad' })}
            </Link>

            <Link href="https://stageclip.com/fundraising" className={styles.link} target="_blank">
              {formatMessage({ id: 'main_page.footerSection_page.fundforschools' })}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
