import { styled } from '@material-ui/core';

import { Typography } from 'shared/components/typography/Typography';

export const Container = styled('div')(({ theme: { breakpoints } }) => ({
  flex: 1,
  minWidth: 506,
  [breakpoints.down('sm')]: {
    marginBottom: 24,
    minWidth: 'auto',
  },
}));

export const Title = styled(Typography)(({ theme: { breakpoints, typography } }) => ({
  marginBottom: 24,
  [breakpoints.down('sm')]: {
    ...typography.h3,
  },
}));

export const DescriptionContainer = styled('div')({
  '& :not(:last-child)': {
    marginBottom: 24,
  },
});

export const Description = styled(Typography)(({ theme: { breakpoints } }) => ({
  [breakpoints.down('sm')]: {
    whiteSpace: 'normal',
  },
}));
