/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import {
  AudioConfig,
  SpeechConfig,
  SpeechSynthesizer,
  SpeakerAudioDestination,
  AudioOutputStream,
} from 'microsoft-cognitiveservices-speech-sdk';

import { TextToSpeechFormProps } from './TextToSpeech.types';

const pipe = (...fns: any[]) => (x: any) => fns.reduce((v, f) => f(v), x);

let player: SpeakerAudioDestination = new SpeakerAudioDestination();

const encodeSpecialCharacter = ({ textToSpeech, ...rest }: TextToSpeechFormProps) => ({
  textToSpeech: textToSpeech.replace(/&/g, '&amp;'),
  ...rest,
});

const getXML = ({ language, voiceActor, textToSpeech }: TextToSpeechFormProps) =>
  `<speak version="1.0" xmlns="" xml:lang="${language}">
    <voice name="${voiceActor}">
    <lexicon uri="https://clippingplatformprod.blob.core.windows.net/public/lexicon.xml"/>
    <prosody rate="0.85" pitch="-10Hz">
      ${textToSpeech}
    </prosody>
    </voice>
  </speak>`;

const parseXmlToDom = (xmlString: string) => {
  const parser = new DOMParser();

  return parser.parseFromString(xmlString, 'application/xml');
};

const domToString = (dom: XMLDocument) => {
  const serializer = new XMLSerializer();

  return serializer.serializeToString(dom);
};

const xmlToString = (form: TextToSpeechFormProps) =>
  pipe(encodeSpecialCharacter, getXML, parseXmlToDom, domToString)(form);

const synthesizeSsml = (ssml: string) => (synthesizer: SpeechSynthesizer) => (callback: Function) => {
  synthesizer.speakSsmlAsync(
    ssml,
    result => {
      callback(result);
      synthesizer.close();
    },
    error => {
      console.error(error);
      synthesizer.close();
    },
  );
};

const speakSsmlAsync = (ssml: string) => (synthesizer: SpeechSynthesizer) => {
  synthesizeSsml(ssml)(synthesizer)(console.log);
};

const createAnchorElement = (data: any) => {
  const dateTime = new Date().toISOString();
  const blob = new Blob([data.audioData]);
  const a = document.createElement('a');

  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = `text_to_speech-${dateTime}.mp3`;
  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();
  a.remove();
};

const printFile = (ssml: string) => (synthesizer: SpeechSynthesizer) => (setDownloading: Function) => {
  synthesizeSsml(ssml)(synthesizer)((result: any) => {
    setDownloading(false);
    createAnchorElement(result);
  });
};

const getFile = (ssml: string) => (synthesizer: SpeechSynthesizer) => (setSpeech: Function) => (name: string) => (
  id: string,
) => {
  synthesizeSsml(ssml)(synthesizer)((result: any) => {
    const blob = new Blob([result.audioData]);
    setSpeech({ blob, name, id });
  });
};

const initializeSpeechSynthesizer = (speechConfig: SpeechConfig) => (audioConfig: AudioConfig) =>
  new SpeechSynthesizer(speechConfig, audioConfig);

export const useTextToSpeech = () => {
  const [playing, setPlaying] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [speech, setSpeech] = useState();

  player.onAudioEnd = () => {
    setPlaying(false);
  };

  const stopPlayer = () => {
    player.pause();
    setPlaying(false);
  };

  const translate = (audioConfig: any) => {
    const speechConfig = SpeechConfig.fromSubscription('106c5401b06d4cf0bffb612766b638d4', 'westeurope');

    return initializeSpeechSynthesizer(speechConfig)(audioConfig);
  };

  const translateTextToSpeech = (form: TextToSpeechFormProps) => {
    if (!form.textToSpeech.trim()) {
      return;
    }
    setPlaying(true);
    player = new SpeakerAudioDestination();
    const ssml = xmlToString(form);
    const synthesizer = pipe(AudioConfig.fromSpeakerOutput, translate)(player);
    speakSsmlAsync(ssml)(synthesizer);
  };

  const downloadSpeech = (form: TextToSpeechFormProps) => {
    if (!form.textToSpeech.trim()) {
      return;
    }
    setDownloading(true);
    const ssml = xmlToString(form);
    const synthesizer = pipe(AudioOutputStream.createPullStream, AudioConfig.fromStreamOutput, translate)(null);
    printFile(ssml)(synthesizer)(setDownloading);
  };

  const getSpeech = (form: TextToSpeechFormProps, id: string) => {
    if (!form.textToSpeech.trim()) {
      return;
    }
    const ssml = xmlToString(form);
    const synthesizer = pipe(AudioOutputStream.createPullStream, AudioConfig.fromStreamOutput, translate)(null);
    getFile(ssml)(synthesizer)(setSpeech)(form.textToSpeech)(id);
  };

  return {
    translateTextToSpeech,
    playing,
    stopPlayer,
    downloadSpeech,
    downloading,
    speech,
    getSpeech,
  };
};
