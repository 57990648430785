import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { SectionMode } from 'shared/components/expandingSection/Section.types';
import { ExpandingSection } from 'shared/components/expandingSection/Section';
import { InputField } from 'shared/components/controls';
import { SlideProps } from './Message.types';
import { useStyles } from './Message.styles';
import { emojiRegex, MESSAGE_INPUT_NAME, removeEmoji } from './Message.utils';

export const Message = ({ validate, order, fieldHasValue }: SlideProps) => {
  const { formatMessage } = useIntl();
  const [sectionMode, setSectionMode] = useState<SectionMode>(SectionMode.Closed);
  const styles = useStyles();

  const handleParseMessage = useCallback(
    (value: string | null | undefined) => (value?.match(emojiRegex) ? removeEmoji(value) : value),
    [],
  );

  return (
    <ExpandingSection
      title="virtual_graduation.upload.message.title"
      subtitle="virtual_graduation.upload.message.subtitle"
      order={order}
      buttonName={fieldHasValue ? 'Edit' : 'Write'}
      fieldHasValue={fieldHasValue}
      mode={sectionMode}
      setSectionMode={setSectionMode}
      ariaLabelledby={MESSAGE_INPUT_NAME}
      ariaLabelSaveButton={formatMessage({ id: 'virtual_graduation.upload.message.aria_label.save' })}
    >
      <InputField
        name={MESSAGE_INPUT_NAME}
        counter
        maxLength={150}
        rows={3}
        validate={validate}
        validateFields={[]}
        readonly={sectionMode !== SectionMode.Opened}
        className={styles.message}
        parse={handleParseMessage}
      />
    </ExpandingSection>
  );
};
