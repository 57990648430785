import { connectControl } from '../connectControl/connectControl';
import { ConnectControlProps } from '../connectControl/connectControl.types';

import { InputControl } from './Input';
import { length, email, required as requiredValidator } from 'shared/validators';
import { TextFieldProps } from './Input.types';

const getValidators = (props: ConnectControlProps<TextFieldProps>) => {
  const { validate = [], maxLength, type, required } = props;

  const validators = [...validate];

  if (maxLength) {
    validators.push(length({ max: maxLength }));
  }

  if (type === 'email') {
    validators.push(email());
  }

  if (required) {
    validators.push(requiredValidator());
  }

  return { validate: validators };
};

// @ts-ignore
export const InputField = connectControl<TextFieldProps, string | number>(InputControl, props => ({
  ...props,
  ...getValidators(props),
}));
