import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { FormSpy } from 'react-final-form';
import { isAfter, parseISO } from 'date-fns';
import { useIntl } from 'react-intl';
import { ArrowBackIos } from '@material-ui/icons';

import { CollaboratorPages } from '../CollaboratorUpload.types';

import { UploadMediaStyle, StyledContainer } from './UploadMedia.style';
import { UploadMediaProps } from './UploadMedia.types';
import { DropzoneComponent } from './dropzoneComponent/DropzoneComponent';
import { EventInformation } from './eventInformations/EventInformation';
import { Form } from 'shared/components/form/Form';
import { AVUserDataBody, EventState } from 'api/actions/avUser/avUser.types';
import { useParticipantData } from 'contexts/participantData/participantDataContext';
import { EventDetails } from './eventDetails/EventDetails';

export const UploadMedia = ({
  onSubmit,
  eventDetails,
  avUserDetails,
  setEventDetails,
  setCollaboratorPage,
  isDisable,
  setIsDisable,
  ...props
}: UploadMediaProps) => {
  const { file, deadlineDate } = useParticipantData();
  const classes = UploadMediaStyle();
  const [rotation, setRotation] = useState(0);
  const [fileInfo, setFileInfo] = useState();
  const { formatMessage } = useIntl();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validateDeadline = useCallback(() => {
    if (isAfter(new Date(), parseISO(deadlineDate))) {
      return formatMessage({ id: 'validation.upload_forbidden' });
    }

    return undefined;
  }, [deadlineDate, formatMessage]);

  return (
    <>
      <Grid container spacing={0} className={classes.root1}>
        <Card elevation={0}>
          <CardContent>
            <Grid container spacing={3} direction="row">
              <Grid item xs={2} direction="column">
                <ArrowBackIos
                  onClick={() => {
                    setIsDisable(false);
                    setCollaboratorPage(CollaboratorPages.upcomingEvent);
                  }}
                  className={classes.rootLogo}
                />
              </Grid>
              <Grid item xs={10} direction="column">
                <Typography
                  className={classes.rootText}
                  onClick={() => {
                    setIsDisable(false);
                    setCollaboratorPage(CollaboratorPages.upcomingEvent);
                  }}
                >
                  {formatMessage({ id: 'av_user.upload_media.events' })}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <StyledContainer>
        <Card elevation={0} className={classes.root}>
          <EventInformation eventDetails={eventDetails} />
          <div className={classes.formDiv}>
            <Form<AVUserDataBody>
              onSubmit={() => onSubmit(fileInfo)}
              subscription={{}}
              initialValues={{
                file,
              }}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} id="upload-form">
                  {eventDetails && eventDetails.state !== EventState.Cancelled ? (
                    <FormSpy>
                      {() => (
                        <DropzoneComponent
                          name="file"
                          maxSize="100 GB"
                          maxDuration={2000000}
                          validate={[validateDeadline]}
                          rotation={rotation}
                          setFileInfo={setFileInfo}
                          resetRotation={() => setRotation(0)}
                          type="video"
                          avUserDetails={avUserDetails}
                          eventDetails={eventDetails}
                          {...props}
                          setEventDetails={setEventDetails}
                          setCollaboratorPage={setCollaboratorPage}
                          fileInfo={fileInfo}
                        />
                      )}
                    </FormSpy>
                  ) : null}
                </form>
              )}
            />
          </div>
          {eventDetails?.eventVideos.length !== 0 ? (
            <>
              <h1 className={classes.eventHeading}>{formatMessage({ id: 'av_user.upload_media.title' })}</h1>
              <div className={classes.eventDiv}>
                {eventDetails?.eventVideos &&
                  eventDetails?.eventVideos.map(({ name, url, createdAt, id, data }) => (
                    <EventDetails key={id} name={name} url={url} createdAt={createdAt} data={data} />
                  ))}
              </div>
            </>
          ) : null}
        </Card>
      </StyledContainer>
    </>
  );
};
