import React from 'react';

import { OptionFormControl } from '../optionFormControl/OptionFormControl';
import { connectControl } from '../connectControl/connectControl';
import { controlBase } from '../controlBase/ControlBase';

import { StyledCheckbox } from './Checkbox.styles';
import { CheckboxProps } from './Checkbox.types';

export const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  classes,
  onChange,
  value,
  label,
  disabled,
  size,
  onFocus,
  onBlur,
  className,
}) => {
  return (
    <OptionFormControl
      label={label}
      classes={classes}
      className={className}
      control={
        <StyledCheckbox
          checked={checked}
          onChange={onChange}
          value={value}
          disabled={disabled}
          inputProps={{ checked }}
          size={size}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      }
    />
  );
};

const CheckboxControl = controlBase<CheckboxProps>(Checkbox, { inline: true });

export const CheckboxField = connectControl<CheckboxProps>(
  // @ts-ignore
  CheckboxControl,
  // @ts-ignore
  props =>
    ({
      type: 'checkbox',
      ...props,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any),
);
