import { styled, withStyles } from '@material-ui/core';

import { Container } from 'shared/components/container/Container';
import { DecorationTypography } from 'shared/components/decorationTypography/DecorationTypography';
import { Image } from 'shared/components/image/Image';

const SNAPCODE_SIZE = 150;

export const StyledContainer = withStyles(({ breakpoints }) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 72,

    [breakpoints.down('sm')]: {
      paddingTop: 36,
    },
  },
}))(Container);

export const Text = styled(DecorationTypography)(({ theme: { palette } }) => ({ color: palette.black.main }));

export const Vector = styled(Image)(({ theme: { breakpoints } }) => ({
  margin: '0 48px',
  [breakpoints.down('sm')]: {
    margin: '0 24px',
  },
}));

export const SnapcodeImage = styled(Image)({
  maxHeight: SNAPCODE_SIZE,
  maxWidth: SNAPCODE_SIZE,
});
