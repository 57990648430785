import { Checkbox, withStyles, fade } from '@material-ui/core';

export const StyledCheckbox = withStyles(({ palette }) => ({
  root: {
    color: palette.green.light,
    '&$checked': {
      color: palette.green.main,
    },
    '&:not($checked) svg': {
      fill: palette.gray.light,
    },
    '&:hover, &$checked:hover': {
      backgroundColor: fade(palette.green.main, 0.08),
    },
  },
  checked: {},
  disabled: {
    opacity: 0.5,
  },
}))(Checkbox);
