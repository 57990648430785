import React from 'react';
import { useIntl } from 'react-intl';

import { ActionButton, ButtonWrapper } from './Terms.styles';
import { acceptance } from 'shared/validators';
import { Section } from 'shared/components/section/Section';
import { ExternalLink } from 'shared/components/externalLink/ExternalLink';
import { CheckboxField } from 'shared/components/controls';

export const Terms = () => {
  const { formatMessage } = useIntl();

  const terms = formatMessage(
    {
      id: 'virtual_graduation.upload.terms',
    },
    {
      a: (text: string) => (
        <ExternalLink href="https://stageclip.com/privacy-policy/" target="_blank">
          {text}
        </ExternalLink>
      ),
    },
  );

  return (
    <Section
      left={
        <CheckboxField
          name="terms"
          size="medium"
          validate={[acceptance({ message: { id: 'virtual_graduation.upload.terms_validation' } })]}
          label={terms}
        />
      }
      right={
        <ButtonWrapper>
          <ActionButton color="primary" variant="contained" type="submit">
            Submit
          </ActionButton>
        </ButtonWrapper>
      }
    />
  );
};
