import { CollaboratorPages, CollaboratorUploadProps } from '../CollaboratorUpload.types';

export type UpcomingEventProps = Pick<CollaboratorUploadProps, 'avUserDetails' | 'setEventDetails'> & {
  setCollaboratorPage: (collaboratorPage: CollaboratorPages) => void;
};

export enum EventVideoStatus {
  Preview = 'preview',
  SentToPublication = 'sent-to-publication',
  Publishing = 'publishing',
  Published = 'published',
  Uploaded = 'uploaded',
  Deleted = 'deleted',
}
