import React from 'react';
import classNames from 'classnames';

import { StyledInputAdornment, useStyles } from './InputAdornment.styles';
import { InputAdornmentProps } from './InputAdornment.types';

export const InputAdornment = ({ error, success, ...restProps }: InputAdornmentProps) => {
  const styles = useStyles();

  const adornmentClassNames = classNames({
    [styles.error]: error,
    [styles.success]: success,
  });

  return <StyledInputAdornment {...restProps} className={adornmentClassNames} />;
};
