import { makeStyles, withStyles } from '@material-ui/core';

import { Container } from 'shared/components/container/Container';

export const StyledContainer = withStyles(({ breakpoints }) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 140,
    paddingBottom: 170,

    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingTop: 100,
    },
  },
}))(Container);

export const useStyles = makeStyles(({ breakpoints, palette: { white, green, red } }) => ({
  buttons: {
    display: 'flex',
    [breakpoints.down('sm')]: {
      flexDirection: 'row',
      justifyContent: 'center',
      '& button:first-child': {
        marginRight: 30,
      },
    },
    [breakpoints.up('md')]: {
      '& button:not(:last-child)': {
        marginRight: 40,
      },
    },
  },
  noButton: {
    background: red.main,
    color: white.main,
    width: 100,
  },
  yesButton: {
    background: green.main,
    color: white.main,
    width: 100,
  },
}));
