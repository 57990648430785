import { styled } from '@material-ui/core/styles';

export const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  // height: '100%',
  width: '100%',
});

export const Video = styled('video')({
  maxWidth: '100%',
  position: 'absolute',
});

export const VideoPlaceholder = styled('div')({
  position: 'relative',
});
