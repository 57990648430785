import { Card, Typography } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';

import { EmailValidateForm } from '../verifyEmail/emailValidateForm/EmailValidateForm';

import { useStyles } from './InstitutionEmail.styles';
import { FirstEmailProps } from './InstitutionEmail.types';

export const InstitutionEmail = ({ onSubmit }: FirstEmailProps) => {
  const styles = useStyles();
  const { formatMessage } = useIntl();

  return (
    <Card className={styles.card}>
      <Typography className={styles.subtitle}>{formatMessage({ id: 'main_page.firstEmail_page.subtitle' })}</Typography>

      <EmailValidateForm onSubmit={onSubmit} label={formatMessage({ id: 'main_page.firstEmail_page.label' })} />
    </Card>
  );
};
