import uniqueId from 'lodash/uniqueId';

import { updatableContextFactory } from 'shared/utils/contextFactory/updatableContextFactory';
import { Notification } from './Notifications.types';

const [NotificationsProvider, useNotificationsContext, useUpdateNotifications] = updatableContextFactory<
  Notification[]
>([]);

const useNotifications = () => {
  const data = useNotificationsContext();

  if (typeof data === 'undefined') {
    throw new Error('useNotifications must be used within an NotificationsProvider');
  }

  return data;
};

const usePushNotification = () => {
  const update = useUpdateNotifications();

  return (notification: Omit<Notification, 'open' | 'id'>) => {
    const newNotification = {
      ...notification,
      open: true,
      id: uniqueId('notification_'),
    };

    update(prev => {
      return [...(prev || []), newNotification];
    });
  };
};

export { NotificationsProvider, useNotifications, useUpdateNotifications, usePushNotification };
