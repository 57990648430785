import { styled, Typography } from '@material-ui/core';

export const MainHeading = styled(Typography)(({ theme: { breakpoints, spacing, palette } }) => ({
  fontFamily: 'Open Sans',
  color: palette.black.main,
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  [breakpoints.down('sm')]: {
    fontSize: spacing(3.13),
  },
  [breakpoints.up('lg')]: {
    fontSize: spacing(3.75),
  },
}));

export const SubHeading = styled(Typography)(({ theme: { breakpoints, spacing, palette } }) => ({
  paddingBottom: '1%',
  fontFamily: 'Open Sans',
  fontWeight: 'bold',
  color: palette.black.dark,
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  [breakpoints.down('sm')]: {
    fontSize: spacing(3.75),
  },
  [breakpoints.up('lg')]: {
    fontSize: spacing(4.25),
  },
}));

export const Date = styled(Typography)(({ theme: { breakpoints, spacing, palette } }) => ({
  fontFamily: 'Open Sans',
  fontWeight: 'bold',
  color: palette.black.light,
  display: 'flex',
  gap: spacing(1.25),
  alignContent: 'center',
  justifyContent: 'center',
  [breakpoints.down('sm')]: {
    fontSize: spacing(2),
  },
  [breakpoints.up('lg')]: {
    fontSize: spacing(2.6),
  },
}));
