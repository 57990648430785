import { makeStyles } from '@material-ui/core/styles';

import { darkBlueLight } from 'app/core/theme/palette';

export const useStyles = makeStyles(({ breakpoints, spacing, palette, typography: { body1 } }) => ({
  root: {
    marginTop: spacing(2),
    '& [class*="dropzoneWrapper"]': {
      marginBottom: 0,
      marginRight: 0,
    },
  },

  container: {
    flexDirection: 'column',
    paddingLeft: spacing(0),
    paddingRight: spacing(0),
    textAlign: 'center',
    position: 'relative',
    width: '100%',
    '&:hover': {
      borderColor: palette.green.main,
    },
  },
  buttonContainer: {
    display: 'flex',
  },

  row: {
    fontSize: body1.fontSize,
    width: '100%',
    marginBottom: spacing(3),
    marginTop: spacing(10),
    '& root': {
      display: 'none',
    },
  },
  textFieldRoot: {
    display: 'block !important',
    '& label': {
      display: 'none',
      marginTop: '0 !important',
    },
    '& p': {
      marginTop: spacing(-2.5),
      marginLeft: spacing(-1.625),
    },
  },

  actionCard: {
    display: 'flex',
    width: '48%',
    marginBottom: '12px',
  },
  button: {
    textTransform: 'initial',
    fontWeight: 'bold',
    boxShadow: 'none',
    border: 'none',
    background: palette.green.dark,
    color: palette.white.main,
    marginTop: spacing(0.5),
    '&:hover': {
      backgroundColor: palette.scorpionGray.dark,
    },
  },
  label: {
    marginBottom: spacing(-3),
    display: 'flex',
  },
  subtitle: {
    justifyContent: 'center',
    textAlign: 'center',
    padding: spacing(0, 2, 4),
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    fontSize: spacing(2.25),
    color: palette.black.main,
    [breakpoints.down('xs')]: {
      marginTop: spacing(3),
    },
  },

  infoText: {
    fontSize: spacing(1.8),
    color: palette.white.main,
    textAlign: 'left',
  },
  infoicon: {
    color: palette.white.main,
    marginRight: spacing(1),
  },
  infoContainer: {
    backgroundColor: `${darkBlueLight} !important`,
    BorderColor: darkBlueLight,
    borderRadius: spacing(1),
    padding: spacing(1),
    marginBottom: spacing(2),
    display: 'flex',
    [breakpoints.down('xs')]: {
      maxHeight: spacing(35),
    },
  },
  cardStyle1: {
    width: '100%',
    '& .MuiCardContent-root': {
      padding: spacing(0),
    },
  },
}));
