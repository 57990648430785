/* eslint-disable no-nested-ternary */
import React from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-fetching-library';

import { VerifyEmailSteps } from '../Email.types';

import { VerifyEmailContainerProps } from './VerifyEmail.types';
import { VerifyEmail } from './VerifyEmail';
import { createSendEmail } from 'api/actions/email/email.actions';
import { handleOnSubmit } from 'shared/utils/handleOnSubmit/handleOnSubmit';

export const VerifyEmailContainer = ({ setStep, step }: VerifyEmailContainerProps) => {
  const { formatMessage } = useIntl();
  const { mutate } = useMutation(createSendEmail);

  const handleSuccessCallback = () => {
    if (step === VerifyEmailSteps.InstitutionEmail) {
      setStep(VerifyEmailSteps.FirstTryEmailSuccess);
    } else if (step === VerifyEmailSteps.SecondEmail) {
      setStep(VerifyEmailSteps.SecondTryEmailSuccess);
    }
  };

  const onSubmit = handleOnSubmit({
    action: values => mutate(values),
    successCallback: () => handleSuccessCallback(),
  });

  return (
    <VerifyEmail
      header={formatMessage({ id: 'main_page.verify_page.header' })}
      title={formatMessage({ id: 'main_page.upload_page.title' })}
      textLine={formatMessage({ id: 'main_page.verify_page.text' })}
      setStep={setStep}
      step={step}
      onSubmit={onSubmit}
    />
  );
};
