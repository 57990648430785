import { Card, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { useIntl } from 'react-intl';

import { EventDetailStyles, Topic } from './EventDetails.styles';
import { EventDetailsProps } from './EventDetails.types';
import { Button } from 'shared/components/button/Button';
import { AppRoute } from 'app/routing/AppRoute.enum';

export const EventDetails = ({ createdAt, data, name, url }: EventDetailsProps) => {
  const classes = EventDetailStyles();
  const { formatMessage } = useIntl();

  return (
    <Card elevation={1} className={classes.availableCard}>
      <div className={classes.available}>
        <div className={classes.availableImage}>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video
            className={classes.video}
            key={url}
            width="100%"
            height="100%"
            controlsList="nodownload"
            disablePictureInPicture
            playsInline
          >
            <source src={url} type="video/mp4" />
          </video>
        </div>

        <div className={classes.content}>
          <div className={classes.subTopic}>
            <Grid item xs={12} md={12} lg={12} direction="column">
              <Typography className={classes.videoText}>
                <Typography className={classes.videoSize}>
                  {formatMessage({ id: 'av_user.upload_media.video_name' })}
                </Typography>
                <Typography className={classes.videoName}>{name.substring(0, 80)}</Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12} direction="column">
              <Typography className={classes.videoText}>
                <Typography className={classes.videoSize1}>
                  {formatMessage({ id: 'av_user.upload_media.created_at' })}
                </Typography>
                <Typography className={classes.videoName}>
                  {`${moment(createdAt).format('DD/MMM/YYYY')} at ${moment(createdAt).format(' hh:mm a')}`}
                </Typography>
              </Typography>
            </Grid>
            {data && (
              <>
                <Grid item xs={12} md={12} lg={12} direction="column">
                  <Typography className={classes.videoText}>
                    <Typography className={classes.videoSize2}>
                      {formatMessage({ id: 'av_user.upload_media.video_size' })}
                    </Typography>
                    <Typography className={classes.videoName}>{data.size || ''}</Typography>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12} direction="column">
                  <Typography className={classes.videoText}>
                    <Typography className={classes.videoSize3}>
                      {formatMessage({ id: 'av_user.upload_media.video_duration' })}
                    </Typography>
                    <Typography className={classes.videoName}> {data.length || ''}</Typography>
                  </Typography>
                </Grid>
              </>
            )}
            <Topic>
              <Button
                variant="text"
                className={classes.button}
                type="button"
                onClick={() => {
                  window.open(`${AppRoute.videoPreview}/?url=${url}`);
                }}
              >
                {formatMessage({ id: 'av_user.upload_media.play_video' })}
              </Button>
            </Topic>
          </div>
        </div>
      </div>
    </Card>
  );
};
