import { Container, styled } from '@material-ui/core';

import { Typography } from 'shared/components';

export const StyledContainer = styled(Container)(({ theme: { breakpoints } }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 72,
  paddingBottom: 72,
  [breakpoints.down('sm')]: {
    paddingTop: 36,
    paddingBottom: 36,
  },
}));

export const Wrapper = styled('div')(({ theme: { breakpoints } }) => ({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: '20px',
  '& > div': {
    flex: '1',
    margin: '10px',
  },
  [breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

export const Title = styled(Typography)(({ theme: { breakpoints, typography } }) => ({
  ...typography.h2,
  display: 'flex',
  flexDirection: 'column',
  [breakpoints.down('sm')]: {
    ...typography.h3,
  },
}));

export const Subtitle = styled(Typography)(({ theme: { breakpoints, typography } }) => ({
  ...typography.h3,
  display: 'flex',
  flexDirection: 'column',
  [breakpoints.down('sm')]: {
    ...typography.h4,
  },
}));

export const ControlWrapper = styled('div')(({ theme: { breakpoints } }) => ({
  [breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const Header = styled('div')({
  marginBottom: 24,
});

export const Section = styled('div')(({ theme: { typography } }) => ({
  ...typography.body1,
  '& ul': {
    marginTop: '5px',
  },
}));

export const Buttons = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

export const FileInput = styled('input')({
  display: 'none',
});
