import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
  title: {
    color: palette.black.main,
    fontSize: spacing(6),
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    [breakpoints.down('sm')]: {
      position: 'absolute',
      left: '5vw',
      marginBottom: spacing(1.25),
    },
  },
  cardSet: {
    marginTop: spacing(6),
    [breakpoints.down('sm')]: {
      position: 'relative',
      marginTop: spacing(3),
      top: spacing(3.75),
    },
  },
  card: {
    borderRadius: spacing(1),
    width: '25vw',
    height: '30vh',
    boxShadow: ` ${spacing(0, 0.5, 1, 0)}  ${palette.info.main}`,
    backgroundColor: palette.white.main,
    marginBottom: spacing(6.5),
    marginLeft: 'auto',

    [breakpoints.down('md')]: {
      height: '45vh',
    },
    [breakpoints.down('sm')]: {
      marginRight: 'auto',
      marginBottom: '5vh',
      width: '90vw',
      marginTop: spacing(3),
      height: '40vh',
    },
  },
  cardTwo: {
    borderRadius: spacing(1),
    width: '30vw',
    height: spacing(36.625),
    marginBottom: spacing(6.5),
    marginLeft: 'auto',
    marginRight: 'auto',
    boxShadow: ` ${spacing(0, 0.5, 1, 0)}  ${palette.info.main}`,
    backgroundColor: palette.white.main,

    [breakpoints.down('md')]: {
      height: '50vh',
    },
    [breakpoints.down('sm')]: {
      borderRadius: spacing(1),
      width: '90vw',
      height: '40vh',
      boxShadow: ` ${spacing(0, 0.5, 1, 0)}  ${palette.info.main}`,
      backgroundColor: palette.white.main,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '5vh',
    },
  },
  cardThree: {
    borderRadius: spacing(1),
    width: '25vw',
    height: '30vh',
    boxShadow: ` ${spacing(0, 0.5, 1, 0)}  ${palette.info.main}`,
    backgroundColor: palette.white.main,
    marginBottom: spacing(6.5),
    marginRight: 'auto',

    [breakpoints.down('md')]: {
      height: '45vh',
    },
    [breakpoints.down('sm')]: {
      marginLeft: 'auto',
      marginBottom: '5vh',
      width: '90vw',
      height: '40vh',
    },
  },
  cardContent: {
    margin: 'auto',
  },
  cardRaw: {
    display: 'flex',
    flex: 'raw',
    width: '100%',
  },
  value: {
    fontSize: spacing(3.25),
    fontWeight: 'bold',
    marginLeft: spacing(3),
    marginTop: spacing(3),
    fontFamily: 'Open Sans',
    textAlign: 'left',
  },
  passage: {
    fontSize: spacing(2),
    fontFamily: 'Roboto',
    color: palette.gray.dark,
    marginTop: spacing(1),
    marginLeft: spacing(3),
    textAlign: 'left',
    width: '22vw',
    [breakpoints.down('sm')]: {
      width: '80vw',
    },
    [breakpoints.down('xs')]: {
      width: '80vw',
    },
  },
  valueTwo: {
    fontSize: spacing(3.25),
    fontWeight: 'bold',
    marginLeft: spacing(5),
    marginTop: spacing(5.875),
    textAlign: 'left',
    fontFamily: 'Open Sans',
    [breakpoints.down('sm')]: {
      marginLeft: spacing(3),
      marginTop: spacing(3),
    },
  },
  passageTwo: {
    fontSize: spacing(2),
    fontFamily: 'Roboto',
    color: palette.gray.dark,
    marginTop: spacing(2.375),
    marginLeft: spacing(5),
    marginRight: spacing(5),
    textAlign: 'left',
    width: '25vw',
    [breakpoints.down('sm')]: {
      marginLeft: spacing(2.875),
      marginRight: 'auto',
      width: '80vw',
    },
    [breakpoints.down('xs')]: {
      width: '80vw',
    },
  },
  raw: {
    display: 'flex',
    flex: 'raw',
    width: '100%',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  column: {
    flex: 'column',
    width: '100%',
    [breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  talkstageclip: {
    color: palette.green.dark,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));
