import { styled, Link as MaterialLink, makeStyles, Grid, Select, withStyles } from '@material-ui/core';

import { Container } from 'shared/components/container/Container';
import { Image } from 'shared/components/image/Image';

export const HeaderContainer = withStyles(() => ({
  root: {
    height: 90,
    paddingTop: 66,
  },
}))(Container);

export const Logo = styled(Image)({
  maxHeight: 100,
  maxWidth: 120,
});

export const Link = styled(MaterialLink)(({ theme: { breakpoints, palette } }) => ({
  fontSize: 12,
  fontWeight: 600,
  textTransform: 'uppercase',
  color: palette.blue.dark,

  [breakpoints.down('sm')]: {
    fontSize: 24,
    textAlign: 'center',
    textTransform: 'unset',
    marginBottom: 32,
  },

  '&:not(:last-of-type)': {
    [breakpoints.down('sm')]: {
      marginRight: 0,
    },
    marginRight: 24,
  },

  '&.active': {
    color: palette.green.main,
  },
}));

export const ContainerGrid = styled(Grid)({
  height: '100%',
});

export const useStyles = makeStyles({
  socialMedia: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  nav: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
});

export const LanguageSelect = styled(Select)(({ theme: { breakpoints, palette } }) => ({
  '&.MuiInputBase-root': {
    color: palette.blue.dark,
  },
  marginLeft: '24px',
  fontSize: '12px',
  [breakpoints.down('sm')]: {
    marginLeft: '12px',
    fontSize: '18px',
  },
}));
