import React from 'react';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';

import { email } from 'shared/validators';
import { Form } from 'shared/components/form/Form';
import { Button } from 'shared/components/button/Button';
import { RecaptchaField } from 'shared/components/controls/recaptchaField/RecaptchaField';
import { InputField } from 'shared/components/controls/input';
import { useStyles } from './EmailValidateForm.styles';
import { EmailValidateFormProps } from './EmailValidateForm.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EmailValidateForm = ({ onSubmit, label }: EmailValidateFormProps) => {
  const styles = useStyles();
  const { formatMessage } = useIntl();

  return (
    <div className={styles.evalidateform}>
      <Form
        onSubmit={onSubmit}
        errorSeparator={20}
        render={({ handleSubmit, invalid }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Typography className={styles.label}>{label}</Typography>

            <InputField
              size="small"
              name="email"
              fullWidth
              type="email"
              validate={[email()]}
              className={styles.textField}
              classes={{ root: styles.textFieldRoot }}
            />

            <div className={styles.recaptcha}>
              <RecaptchaField name="token" />
            </div>

            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              disabled={invalid}
              className={styles.buttonNext}
            >
              {formatMessage({ id: 'main_page.emailValidate.send_button' })}
            </Button>
          </form>
        )}
      />
    </div>
  );
};
