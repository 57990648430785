import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  logoImage: {
    position: 'absolute',
    left: spacing(9),
    width: spacing(25),
    height: spacing(6.75),
    top: spacing(2.25),
    [breakpoints.down('sm')]: {
      left: spacing(0),
      width: spacing(18.75),
    },
  },
  container: {
    width: '100%',
    height: spacing(11),
    position: 'relative',
    backgroundColor: palette.white.main,
    display: 'flex',
    boxShadow: ` ${spacing(0, 0.1, 1, 0)}  ${palette.scorpionGray.main}`,
    justifyContent: 'space-between',
  },
  button: {
    position: 'absolute',
    right: spacing(9.875),
    fontFamily: 'Roboto bold',
    backgroundColor: palette.white.main,
    color: palette.green.main,
    fontWeight: 'bold',
    boxShadow: 'none',
    border: palette.green.main,
    borderStyle: 'solid',
    height: spacing(5),
    width: spacing(16.75),
    marginTop: spacing(3),
    justifyContent: 'right',
    fontSize: spacing(1.5),
  },
  supportContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Open Sans',
    fontWeight: spacing(87.5),
    fontSize: spacing(2.5),
    lineHeight: '27.24px',
    paddingRight: spacing(4),
    cursor: 'pointer',
    [breakpoints.down('sm')]: {
      paddingRight: spacing(2),
    },
  },
}));
