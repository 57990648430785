import { styled } from '@material-ui/core';

import { Button } from 'shared/components';

export const ActionButton = styled(Button)(({ theme: { breakpoints } }) => ({
  width: '100px',
  [breakpoints.down('xs')]: {
    width: '210px',
    marginTop: '8px',
  },
}));

export const ButtonWrapper = styled('div')(({ theme: { breakpoints } }) => ({
  [breakpoints.down('xs')]: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}));
