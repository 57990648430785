import { Area } from './BlobImage.types';

const getRadianAngle = (degreeValue: number) => {
  return (degreeValue * Math.PI) / 180;
};

const translateCanvasToCentralLocation = (ctx: CanvasRenderingContext2D, safeArea: number, rotation: number) => {
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);
};

const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image2 = new Image();
    image2.addEventListener('load', () => resolve(image2));
    image2.addEventListener('error', error => reject(error));
    image2.src = url;
  });

async function initialize(imageSrc: string) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  return { image, canvas, ctx };
}

export const useCreateImage = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getCroppedImg = async (imageSrc: any, pixelCrop: Area, rotation = 0) => {
    if (!imageSrc || !pixelCrop) {
      return;
    }
    const { image, canvas, ctx } = await initialize(imageSrc);

    if (!ctx) return;

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    translateCanvasToCentralLocation(ctx, safeArea, rotation);

    // draw rotated image and store data.
    ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
    );

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    // eslint-disable-next-line consistent-return
    return new Promise(resolve => {
      canvas.toBlob(file => {
        resolve(URL.createObjectURL(file));
      }, 'image/jpeg');
    });
  };

  return { getCroppedImg };
};
