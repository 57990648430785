import format from 'date-fns/format';

export const formatDuration = (duration: string | number) => {
  if (typeof duration === 'string') {
    return duration;
  }

  return format(new Date(0, 0, 0, 0, 0, duration), 'mm:ss');
};

export const getDurationInPercent = (currentTime: string | number, duration: string | number) => {
  if (typeof currentTime === 'string' || typeof duration === 'string') {
    return 0;
  }

  return (currentTime / duration) * 100;
};
