import React from 'react';
import classNames from 'classnames';

import { useFormControlState } from '../useFormControlState/useFormControlState';

import { useTranslations } from 'shared/hooks';
import { StyledFormHelperText, useStyles } from './FormHelperText.styles';

export const FormHelperText: React.FC = ({ children }) => {
  const styles = useStyles();
  const { message } = useTranslations(children);
  const { success, hiddenError } = useFormControlState();

  if (hiddenError) {
    return null;
  }

  const formHelperTextClassNames = classNames({
    [styles.success]: success,
  });

  return <StyledFormHelperText className={formHelperTextClassNames}>{message}</StyledFormHelperText>;
};
