import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
  instituteContainer: {
    bottom: spacing(0),
    width: '100%',
    textAlign: 'center',
    marginTop: spacing(5),
    [breakpoints.down('xs')]: {
      position: 'static',
      marginTop: spacing(5),
    },
  },
  textLine: {
    fontSize: spacing(4.375),
    fontWeight: spacing(125),
    color: palette.scorpionGray.main,
    marginBottom: spacing(2.5),
    [breakpoints.down('sm')]: {
      fontSize: spacing(2.625),
    },
  },
  maincolumn: {
    width: '100%',
    display: 'flex',
    textAlign: 'center',
    marginBottom: spacing(1),
    [breakpoints.down('sm')]: {
      margin: spacing(0),
      padding: spacing(0),
      display: 'block',
    },
  },
  subcolumn: {
    margin: 'auto',

    [breakpoints.down('sm')]: {
      margin: spacing(0),
      width: '100%',
      marginTop: spacing(3),
    },
  },
}));
