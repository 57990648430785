import { parseUrl, stringifyUrl } from 'query-string';

import { Action } from 'api/types';

export const requestSecretInterceptor = (secret: string, editToken?: string) => () => async (action: Action) => {
  const { url, query } = parseUrl(action.endpoint);

  return {
    ...action,
    endpoint: stringifyUrl({ url, query: { ...query, s: secret, editToken } }),
  };
};
