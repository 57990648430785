import { styled } from '@material-ui/core/styles';
import { Select } from '@material-ui/core';
// @ts-ignore
import ReactCountryFlag from 'react-country-flag';

export const Wrapper = styled('ul')(({ theme: { breakpoints } }) => ({
  display: 'flex',
  margin: 0,
  padding: 0,

  '& li:not(:last-child)': {
    marginRight: '24px',
  },

  [breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'center',
    '& li:not(:last-child)': {
      marginBottom: '24px',
    },
  },
}));

export const Item = styled('li')({
  listStyleType: 'none',
  textAlign: 'center',
});

export const SelectWrapper = styled(Select)({
  marginLeft: '24px',
  fontSize: '12px',
});

export const Flag = styled(ReactCountryFlag)({
  fontSize: '1.4em',
});
