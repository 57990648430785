import { styled } from '@material-ui/core';

import { Container } from 'shared/components';

export const StyledContainer = styled(Container)(({ theme: { breakpoints } }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: 80,
  paddingBottom: 170,

  [breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
  },
}));
