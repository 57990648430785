import { useEffect, useState, useRef } from 'react';

const START_EVENT = 'dragenter';
const END_EVENT = 'dragleave';

const CANCEL_EVENTS = ['dragover', 'drop'];

export const useDragAndDropState = () => {
  const [drag, setDrag] = useState(false);
  const state = useRef(false);
  const counter = useRef(0);

  const handleStartEvent = () => {
    if (!state.current) {
      setDrag(true);
    }

    counter.current += 1;
  };

  const handleEndEvent = () => {
    counter.current -= 1;

    if (state.current && counter.current === 0) {
      setDrag(false);
    }
  };

  const cancelEvent = (event: Event) => {
    event.preventDefault();

    if (event.type === 'drop') {
      setDrag(false);
      counter.current = 0;
    }
  };

  useEffect(() => {
    if (drag !== state.current) {
      state.current = drag;
    }
  }, [drag]);

  useEffect(() => {
    window.addEventListener(START_EVENT, handleStartEvent, false);
    window.addEventListener(END_EVENT, handleEndEvent, false);
    CANCEL_EVENTS.forEach(type => window.addEventListener(type, cancelEvent, false));

    return () => {
      window.removeEventListener(START_EVENT, handleStartEvent, false);
      window.removeEventListener(END_EVENT, handleEndEvent, false);
      CANCEL_EVENTS.forEach(type => window.removeEventListener(type, cancelEvent, false));
    };
  }, []);

  return drag;
};
