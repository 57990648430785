// eslint-disable-next-line import/order
import React, { useState } from 'react';
import { MainPage } from 'app/email/mainPage/MainPage';
import { VerifyEmailSteps } from './Email.types';
import { VerifyEmailContainer } from './verifyEmail/VerifyEmailContainer';

export const Email = () => {
  const [step, setStep] = useState<VerifyEmailSteps>(VerifyEmailSteps.Home);

  return (
    <>
      {step === VerifyEmailSteps.Home ? (
        <MainPage setStep={setStep} />
      ) : (
        <VerifyEmailContainer setStep={setStep} step={step} />
      )}
    </>
  );
};
