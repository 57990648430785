import { makeStyles, withStyles, FormControl } from '@material-ui/core';

export const StyledFormControl = withStyles(({ spacing }) => ({
  root: {
    '& label + *': {
      marginTop: spacing(3),
    },
  },
}))(FormControl);

export const useStyles = makeStyles({
  disabled: {
    opacity: 0.5,
  },
});
