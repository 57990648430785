import React, { useState } from 'react';
import { useMutation } from 'react-fetching-library';

import { LoginContainer, TextContainer } from '../verifyEmail/VerifyEmail.styles';
import { HeaderSection } from '../headerSection/HeaderSection';
import { InstituteSection } from '../instituteSection/InstituteSection';

import { handleOnSubmit } from 'shared/utils/handleOnSubmit/handleOnSubmit';
import { createSendEmail } from 'api/actions/email/email.actions';
import { PersonalEmail } from './PersonalEmail';
import { PersonalEmailSuccess } from './personalEmailSuccess/PersonalEmailSuccess';

export const PersonalEmailContainer = () => {
  const { mutate } = useMutation(createSendEmail);
  const [isSuccess, setisSuccess] = useState(false);
  const [value, setValue] = useState('');

  const handleSuccessCallback = () => {
    setisSuccess(true);
  };

  const onSubmit = handleOnSubmit({
    action: values => {
      setValue(values.email);

      return mutate(values);
    },
    successCallback: () => handleSuccessCallback(),
  });

  return (
    <LoginContainer>
      <TextContainer>
        <HeaderSection />
        {isSuccess ? <PersonalEmailSuccess email={value} /> : <PersonalEmail onSubmit={onSubmit} />}
        <InstituteSection />
      </TextContainer>
    </LoginContainer>
  );
};
