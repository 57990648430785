import React, { useCallback, FocusEvent, useMemo } from 'react';
import { useField } from 'react-final-form';
import { FieldState } from 'final-form';
import { combine } from 'redux-form-validators';
import omit from 'lodash/omit';
import pick from 'lodash/pick';

import { ConnectControlProps } from './connectControl.types';

const FieldProps = [
  'afterSubmit',
  'beforeSubmit',
  'component',
  'data',
  'defaultValue',
  'format',
  'formatOnBlur',
  'initialValue',
  'isEqual',
  'parse',
  'render',
  'subscription',
  'validate',
  'validateFields',
  'value',
  'type',
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const connectControl = <ComponentProps extends {}, FieldValue = any>(
  Control: React.ComponentType<ComponentProps>,
  overrideProps: (
    props: ConnectControlProps<ComponentProps, FieldValue>,
  ) => ConnectControlProps<ComponentProps, FieldValue> = props => props,
) => (props: ConnectControlProps<ComponentProps, FieldValue>) => {
  const newProps = overrideProps(props);

  const { name, validate = [], onFocus, onBlur, validateFields, ...restProps } = newProps;

  const validators = useMemo(() => validate, [validate]);

  const {
    input,
    meta: { error, dirty, touched, submitError, submitting, dirtySinceLastSubmit },
  } = useField(name, {
    ...pick(restProps, FieldProps),
    validate: (value: FieldValue, allValues: object, meta?: FieldState<FieldValue>) =>
      combine(...validators)(value, allValues, meta),
    validateFields,
  });

  const handleFocus = useCallback(
    (event: FocusEvent<HTMLElement>) => {
      if (onFocus) {
        onFocus();
      }

      input.onFocus(event);
    },
    [input, onFocus],
  );

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLElement>) => {
      if (onBlur) {
        onBlur();
      }

      input.onBlur(event);
    },
    [input, onBlur],
  );

  const hasError = (dirty || touched) && (!!error || (!!submitError && !dirtySinceLastSubmit));
  const errorMessage = hasError ? error || submitError : undefined;

  return (
    <Control
      {...(omit(restProps, FieldProps) as ComponentProps)}
      {...input}
      onFocus={handleFocus}
      onBlur={handleBlur}
      error={hasError}
      helperText={errorMessage}
      disabled={restProps.disabled || submitting}
    />
  );
};
