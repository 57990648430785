import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { flow } from 'lodash';
import convert from 'color-convert';

import { useParticipantData } from 'contexts/participantData/participantDataContext';
import { typography } from './typography';
import { palette, pelorous, white } from './palette';

const defaultTheme = createMuiTheme();

function enhanceColour(colour: string) {
  const hslColor = convert.hex.hsl(colour);

  if (hslColor[2] > 90) {
    const enhancedColor = convert.hsl.hex([hslColor[0], hslColor[1], 60]);

    return `#${enhancedColor}`;
  }

  return colour;
}

const getColour = (institution: { primaryColour: string; secondaryColour: string }) => {
  if (!institution) return pelorous;

  if (institution.primaryColour !== white) {
    return enhanceColour(institution.primaryColour);
  }

  if (institution.secondaryColour !== white) {
    return enhanceColour(institution.secondaryColour);
  }

  return pelorous;
};

const theme = (colour?: string): ThemeOptions => ({
  palette: palette(colour),
  typography,
  props: {
    MuiTypography: {
      variantMapping: {
        body1: 'div',
        subtitle1: 'div',
        subtitle2: 'div',
      },
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        a: {
          textDecoration: 'none',
          '&.active': {
            pointerEvents: 'none',
            cursor: 'arrow',
          },
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
    MuiContainer: {
      root: {
        [defaultTheme.breakpoints.down('xs')]: {
          width: '100%',
        },
        [defaultTheme.breakpoints.up('sm')]: {
          // medium: 960px or larger
          width: 540,
        },
        [defaultTheme.breakpoints.up('md')]: {
          // medium: 960px or larger
          width: 920,
        },
        [defaultTheme.breakpoints.up('lg')]: {
          // large: 1280px or larger
          width: 1140,
        },
        [defaultTheme.breakpoints.up('xl')]: {
          // extra-large: 1920px or larger
          width: 1320,
        },
      },
    },
  },
});

export const Theme: React.FC = ({ children }) => {
  const { institution } = useParticipantData();

  const dynamicallyCreatedTheme = flow(getColour, theme, createMuiTheme)(institution);

  return (
    <ThemeProvider theme={dynamicallyCreatedTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
