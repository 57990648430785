import React from 'react';
import { IconButton as MaterialIconButton } from '@material-ui/core';

import { Tooltip } from 'shared/components/tooltip/Tooltip';
import { IconButtonProps } from './IconButton.types';

export const IconButton = ({ children, tooltip, ...props }: IconButtonProps) => {
  const button = <MaterialIconButton {...props}>{children}</MaterialIconButton>;

  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        <span>{button}</span>
      </Tooltip>
    );
  }

  return button;
};
