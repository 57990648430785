import { styled } from '@material-ui/core/styles';

export const Wrapper = styled('div')(({ theme: { breakpoints } }) => ({
  textAlign: 'center',
  maxWidth: '80%',

  [breakpoints.down('sm')]: {
    maxWidth: 'none',
  },
}));
export const BackButton = styled('span')(({ theme: { palette } }) => ({
  color: palette.green.main,
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
}));
