import React, { Component, ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { SimplePage } from 'shared/templates/simplePage/SimplePage';
import { Header } from 'shared/templates/main/header/Header';
import { Footer } from 'shared/templates/main/footer/Footer';
import { Nav } from './nav/Nav';
import { LocaleContextController } from 'contexts/locale';
import * as Sentry from 'shared/utils/sentry/sentry';
import { MailLink } from 'shared/components/mailLink/MailLink';
// import { Link as HeaderLink } from 'shared/templates/main/header/Header.styles';
import { Link as FooterLink } from 'shared/templates/main/footer/Footer.styles';

const Content = () => {
  const { formatMessage } = useIntl();
  const email = formatMessage({ id: 'static.support_email' });

  return (
    <>
      {formatMessage(
        {
          id: 'virtual_graduation.error_boundary.content',
        },
        { email: <MailLink mailto={email}>{email}</MailLink> },
      )}
    </>
  );
};

export class ErrorBoundary extends Component<{ children: ReactElement }, { hasError?: boolean }> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(props: any) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: { componentStack: string }) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <LocaleContextController>
          <Header />
          {/* <Header> */}
          {/* <Nav link={HeaderLink} /> */}
          {/* </Header> */}
          <SimplePage header="virtual_graduation.something_went_wrong.title">
            <SimplePage.Content>
              <Content />
            </SimplePage.Content>
          </SimplePage>
          <Footer>
            <Nav link={FooterLink} />
          </Footer>
        </LocaleContextController>
      );
    }

    return children;
  }
}
