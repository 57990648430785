/* eslint-disable no-nested-ternary */
import React from 'react';

import { VerifyEmailSteps } from '../Email.types';
import { InstituteSection } from '../instituteSection/InstituteSection';
import { HeaderSection } from '../headerSection/HeaderSection';
import { InstitutionEmail } from '../institutionEmail/InstitutionEmail';
import { PersonalEmail } from '../personalEmail/PersonalEmail';
import { InstitutionEmailSuccess } from '../institutionEmail/institutionEmailSuccess/InstitutionEmailSuccess';
import { StartChat } from '../startChat/StartChat';
import { PersonalEmailSuccess } from '../personalEmail/personalEmailSuccess/PersonalEmailSuccess';

import { VerifyEmailProps } from './VerifyEmail.types';
import { LoginContainer, TextContainer } from './VerifyEmail.styles';

export const VerifyEmail = ({ step, onSubmit }: VerifyEmailProps) => {
  return (
    <LoginContainer>
      <TextContainer>
        <HeaderSection />
        {step === VerifyEmailSteps.InstitutionEmail ? (
          <InstitutionEmail onSubmit={onSubmit} />
        ) : step === VerifyEmailSteps.FirstTryEmailSuccess ? (
          <InstitutionEmailSuccess email={step} />
        ) : step === VerifyEmailSteps.SecondEmail ? (
          <PersonalEmail onSubmit={onSubmit} />
        ) : step === VerifyEmailSteps.SecondTryEmailSuccess ? (
          <PersonalEmailSuccess email={step} />
        ) : (
          step === VerifyEmailSteps.StartChat && <StartChat />
        )}

        <InstituteSection />
      </TextContainer>
    </LoginContainer>
  );
};
