import React from 'react';

import { SocialMedia } from '../socialMedia/SocialMedia';
import { Nav } from '../nav/Nav';

import { Container } from 'shared/components/container/Container';
import { CopyRight } from './copyRight/CopyRight';
import {
  Wrapper,
  FooterContainer,
  ContainerCopyRightsAndLinks,
  LinksWrapper,
  SocialMediaContainer,
  Link,
  LanguageSelect,
} from './Footer.styles';

const defaultNav = <Nav link={Link} select={LanguageSelect} />;

export const Footer = ({ children = defaultNav }) => {
  return (
    <Wrapper>
      <Container>
        <FooterContainer>
          <ContainerCopyRightsAndLinks>
            <CopyRight />
            <LinksWrapper>{children}</LinksWrapper>
          </ContainerCopyRightsAndLinks>
          <SocialMediaContainer>
            <SocialMedia inverse />
          </SocialMediaContainer>
          {/* <StageClipLogo alt="StageClip" src={stageClipLogo} /> */}
        </FooterContainer>
      </Container>
    </Wrapper>
  );
};
