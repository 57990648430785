import React from 'react';

import { ExternalLink } from 'shared/components/externalLink/ExternalLink';
import { StyledContainer } from './SocialMedia.styles';
import { SocialMediaProps } from './SocialMedia.types';
import { Icon } from 'shared/components';

export const SocialMedia = ({ instagramAccountName, className }: SocialMediaProps) => {
  return (
    <StyledContainer className={className}>
      <ExternalLink href={`https://instagram.com/${instagramAccountName}`} target="_blank">
        <Icon icon="Instagram" />
      </ExternalLink>
    </StyledContainer>
  );
};
