import React from 'react';
import { useLocation } from 'react-router-dom';

import { FooterText, LogoImage, LogoWrapper } from '../upload/Upload.styles';

import { ReactComponent as SuccessIcon } from 'assets/success.svg';
import logo from 'assets/clipping_platform_logo.png';
import { StyledPaper, StyledContainer, Thanks, Success, Description, useStyles } from './Congratulations.styles';
import { Button, GridContainer, GridItem, Separator } from 'shared/components';

export const Congratulations = () => {
  const styles = useStyles();
  const { state } = useLocation();

  const handleBack = () => {
    window.location.href = state.previousUrl;
  };

  return (
    <StyledContainer>
      <StyledPaper>
        <Thanks variant="h1">Thank you</Thanks>
        <Separator height={30} />
        <SuccessIcon />
        <Separator height={20} />
        <Success role="status" aria-live="polite">
          Success!
        </Success>
        <Description>We have received your content</Description>
        <Separator height={30} />
        <Button variant="contained" color="primary" className={styles.button} onClick={handleBack}>
          Review/Edit
        </Button>
      </StyledPaper>

      <Separator height={30} />
      <GridContainer justify="center">
        <GridItem xs={12} md={8}>
          <FooterText variant="body2">virtual_graduation.upload.disclaimer</FooterText>
        </GridItem>
        <GridItem xs={12} md={8}>
          <Separator height={50} />
          <FooterText variant="body2">Powered by</FooterText>
        </GridItem>
        <LogoWrapper xs={12} md={8}>
          <LogoImage src={logo} alt="" />
        </LogoWrapper>
      </GridContainer>
    </StyledContainer>
  );
};
