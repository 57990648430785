import { makeStyles, styled } from '@material-ui/core';
import AudioPlayer from 'react-h5-audio-player';

import { IconButton } from 'shared/components/iconButton/IconButton';
import { StyledPaper } from 'shared/components/expandingSection/Section.styles';
import { Button, GridContainer } from 'shared/components';
import { GridItem } from 'shared/components/grid';

export const Wrapper = styled(GridContainer)({
  paddingTop: 20,
});

export const RecordNameContainer = styled(StyledPaper)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: 20,
});

export const Left = styled(GridItem)(({ theme: { breakpoints } }) => ({
  flexDirection: 'column',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [breakpoints.down('xs')]: {
    flexDirection: 'column',
  },
}));

export const Right = styled(GridItem)({
  flexDirection: 'column',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const ActionButton = styled(Button)(({ theme: { breakpoints } }) => ({
  width: '100px',
  marginBottom: '10px',
  [breakpoints.down('xs')]: {
    width: '210px',
    marginTop: '8px',
  },
}));

export const Buttons = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const useStyles = makeStyles(({ breakpoints }) => ({
  hidden: {
    display: 'none',
  },
  content: {
    display: 'flex',
    flex: 1,
    margin: 20,
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  audioButton: {
    [breakpoints.down('sm')]: {
      width: 210,
    },
  },
}));

export const RecordButton = styled(IconButton)({
  width: 84,
  height: 84,
  backgroundColor: '#df1642',
  color: 'white',
  //  TODO - pozmieniac kolorki
  '&:hover': {
    backgroundColor: 'rgb(223, 22, 66, 0.75)',
  },
});

export const RecordNameLabel = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
  fontSize: 15,
  fontWeight: 100,
  marginBottom: 10,
});

export const AudioPlayerStyled = styled(AudioPlayer)({
  '&.rhap_container': {
    boxShadow: 'none',
  },
  '& .rhap_progress-indicator': {
    background: '#28abb9',
  },
  '& .rhap_progress-filled': {
    background: '#227b84',
  },
  '& .rhap_controls-section': {
    display: 'flex',
    justifyContent: 'space-between',
  },
});
