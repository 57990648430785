import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

const convertDate = (date: Date | string) => {
  if (typeof date === 'string') {
    return parseISO(date);
  }

  return date;
};

export const formattedDate = (date: Date | string, formatString = 'MMM. d y h:mm a') =>
  format(convertDate(date), formatString);
