import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
  header: {
    color: palette.black.main,
    lineHeight: spacing(1),
    fontSize: spacing(6),

    fontWeight: 'bold',
    marginTop: spacing(3.75),
  },

  title: {
    color: palette.gray.main,
    lineHeight: spacing(0.5),
    fontSize: spacing(3.25),

    fontWeight: 'bold',
  },

  subtitle: {
    color: palette.black.main,
    lineHeight: spacing(0.1),
    fontSize: spacing(3.25),
    fontWeight: 'bold',
    marginTop: spacing(3.5),
    width: spacing(26.25),
    marginLeft: spacing(12.5),
    [breakpoints.down('sm')]: {
      fontSize: spacing(2.625),
      marginLeft: spacing(4.375),
    },
  },
  description: {
    color: palette.black.main,
    lineHeight: spacing(3),
    fontSize: spacing(2),
    fontFamily: 'Roboto',
    letterSpacing: spacing(0.1),
    margin: spacing(1),
    marginTop: spacing(1.1875),
  },
  label: {
    fontSize: spacing(1.75),
    fontFamily: 'Roboto',
    float: 'left',
    marginLeft: spacing(3.25),
    marginTop: spacing(4.125),
    [breakpoints.down('sm')]: {
      top: spacing(1.25),
    },
  },
  TextField: {
    height: spacing(4.5),
    float: 'left',
    marginRight: spacing(6.875),
    marginLeft: spacing(3.25),
    width: spacing(42.5),
    marginTop: spacing(0),
  },

  buttonNext: {
    backgroundColor: palette.blue.light,
    color: palette.white.main,
    fontFamily: 'Roboto bold',
    fontWeight: 'bold',
    float: 'right',
    margin: 'auto',
    marginTop: spacing(10.125),
    marginBottom: spacing(6.875),
    marginRight: spacing(4.75),
    borderRadius: spacing(0.5),
    textTransform: 'initial',
    width: spacing(12.5),
    height: spacing(5),
  },

  card: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: spacing(3),
    width: spacing(48.5),
    height: spacing(45),
    boxShadow: ` ${spacing(0, 0.5, 1, 0)}  ${palette.info.main}`,
    [breakpoints.down('sm')]: {
      marginTop: spacing(3),
      width: spacing(35),
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
}));
