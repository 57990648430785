import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  grid: {
    justifyContent: 'center',
    paddingTop: '10%',
  },
  divider: {
    margin: 'auto',
  },
}));
