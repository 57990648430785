import React, { useContext } from 'react';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

import { GridContainer } from '../grid';

import { SectionsContext } from 'app/root/Root';
import { Typography } from 'shared/components/typography/Typography';
import { Separator } from 'shared/components/separator/Separator';
import {
  ActionButton,
  ContentContainer,
  Header,
  Left,
  Right,
  SaveButton,
  SaveButtonWrapper,
  SocialMediaStyled,
  StyledPaper,
  Subtitle,
  useStyles,
} from './Section.styles';
import { SectionMode, SectionProps } from './Section.types';
import { useActivityTrack } from 'shared/hooks/useActivityTrack/useActivityTrack';
import { ActivityTypes, ParticipantActivityCode } from 'api/activityTrack/activityTrack.types';

import { Icon } from '..';

export const ExpandingSection: React.FC<SectionProps> = ({
  children,
  title,
  subtitle,
  order,
  mode = SectionMode.Closed,
  setSectionMode,
  buttonName,
  fieldHasValue,
  isError,
  sectionBottomPadding = 80,
  showSocialIcons = false,
  ariaLabelledby,
  ariaLabelSaveButton = '',
  showContent = false,
}) => {
  const { formatMessage } = useIntl();
  const styles = useStyles({ mode, fieldHasValue, sectionBottomPadding });
  // TODO - check if still necessary
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { setOpenedSection } = useContext(SectionsContext)!;
  const { onActivityTrack } = useActivityTrack();

  const handleSave = () => {
    if (setSectionMode) {
      setSectionMode(SectionMode.Edited);
      const description =
        ariaLabelledby === 'message'
          ? formatMessage({ id: 'activity_track_description.message_saved' })
          : formatMessage({ id: 'activity_track_description.media_uploaded' }, { media: `${ariaLabelledby}` });

      if (fieldHasValue) {
        onActivityTrack(
          ActivityTypes.Participant,
          ariaLabelledby === 'message'
            ? ParticipantActivityCode.ProducerParticipantMessageAdded
            : ParticipantActivityCode.ProducerParticipantMediaUploaded,
          description.charAt(0).toUpperCase() + description.slice(1),
        );
      }
    }

    setOpenedSection(val => val + 1);
  };

  const open = () => {
    setOpenedSection(order);

    if (setSectionMode) {
      setSectionMode(SectionMode.Opened);
    }
  };

  return (
    <>
      <Separator height={15} />
      <StyledPaper className={clsx({ [styles.sectionPadding]: mode !== SectionMode.Opened })}>
        {(title || subtitle) && (
          <div>
            <GridContainer justify="center" className={styles.wrapper}>
              <Left xs={12} sm={8} md={10}>
                {fieldHasValue ? (
                  <div className={styles.orderCircle}>
                    <Icon icon="Check" />
                  </div>
                ) : (
                  <div className={styles.orderCircle}>{order}</div>
                )}
                <div>
                  {!!title && <Typography variant="h3">{title}</Typography>}
                  {!!subtitle && (
                    <Subtitle variant="subtitle2" aria-labelledby={ariaLabelledby}>
                      {subtitle}
                    </Subtitle>
                  )}
                  {showSocialIcons && mode === SectionMode.Opened && <SocialMediaStyled className="xs-only" />}
                </div>
              </Left>
              <Right xs={12} sm={4} md={2}>
                {/* {openedSection !== order && ( */}
                {[SectionMode.Closed, SectionMode.Edited].includes(mode) && (
                  <>
                    {React.isValidElement(buttonName) ? (
                      buttonName
                    ) : (
                      <ActionButton
                        color="primary"
                        variant="contained"
                        onClick={open}
                        aria-label={formatMessage({ id: title })}
                      >
                        {buttonName}
                      </ActionButton>
                    )}
                  </>
                )}
                {showSocialIcons && mode === SectionMode.Opened && <SocialMediaStyled className="sm-up" />}
              </Right>
              <Header />
            </GridContainer>
          </div>
        )}
        <>
          {((mode === SectionMode.Closed && showContent) ||
            ((mode === SectionMode.Closed || mode === SectionMode.Edited) && fieldHasValue) ||
            mode === SectionMode.Opened) && <ContentContainer>{children}</ContentContainer>}
          {mode === SectionMode.Opened && (
            <GridContainer>
              <SaveButtonWrapper xs={12}>
                <SaveButton
                  color="primary"
                  variant="contained"
                  onClick={handleSave}
                  disabled={isError}
                  aria-label={ariaLabelSaveButton}
                >
                  Save
                </SaveButton>
              </SaveButtonWrapper>
            </GridContainer>
          )}
        </>
      </StyledPaper>
    </>
  );
};
