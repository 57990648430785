import { styled } from '@material-ui/core';

import { ReactComponent as EmailReject } from 'assets/email_reject.svg';
import { Container } from 'shared/components/container/Container';

export const StyledContainer = styled(Container)(({ theme: { breakpoints } }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: 80,
  paddingBottom: 170,

  [breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const EmailRejectImage = styled(EmailReject)({
  width: 400,
  height: 400,
});
