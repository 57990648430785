import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  fullHeight: {
    display: 'flex',
    flex: 1,
  },
  disableVerticalMargins: {
    marginTop: 0,
    marginBottom: 0,
  },
});
