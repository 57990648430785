/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { FormSpy } from 'react-final-form';
import XLSX from 'xlsx';
import JSZip from 'jszip';

import { Button, Form, Separator } from 'shared/components';
import { InputField, SelectField } from 'shared/components/controls';
import { StyledButton } from 'shared/components/button/Button.styles';
import {
  StyledContainer,
  Title,
  ControlWrapper,
  Wrapper,
  Subtitle,
  Header,
  Section,
  Buttons,
  FileInput,
} from './TextToSpeech.styles';
import { Icon } from 'shared/components/icon/Icon';
import { TextToSpeechFormProps, TextToSpeechProps, Voice } from './TextToSpeech.types';
import { useTextToSpeech } from './useTextToSpeech';
import { useDownloadFile } from './useDownloadFile';

const languages = [
  { value: 'en-US', label: 'English (US)' },
  { value: 'en-GB', label: 'English (UK)' },
  { value: 'en-IE', label: 'English (Ireland)' },
  { value: 'es-ES', label: 'Spanish' },
  { value: 'ga-IE', label: 'Irish' },
  { value: 'fr-CA', label: 'French (Canada)' },
  { value: 'sv-SE', label: 'Swedish' },
  { value: 'yue-CN', label: 'Chinese (Cantonese, Simplified)' },
  { value: 'zh-HK', label: 'Chinese (Cantonese, Traditional)' },
  { value: 'zh-CN', label: 'Chinese (Mandarin, Simplified)' },
  { value: 'zh-TW', label: 'Chinese (Taiwanese Mandarin, Traditional)' },
  { value: 'it-IT', label: 'Italian (Italy)' },
  { value: 'ar-AE', label: 'Arabic (United Arab Emirates)' },
];

const voicesUnsupported = ['fr-CA-JeanNeural', 'en-GB-LibbyNeural', 'en-US-JennyNeural'];

const initialValues = {
  textToSpeech: `James Matthew Baillie, Master of Research, Byzantine Studies
Yulia Chmelenko, Master of Research, Cuneiform and Ancient Near Eastern Studies
Emily Victoria Goddard, Master of Research, Archaeology
Marianna Hadjivassiliou, Master of Research, Modern Greek Studies
Moayad Hanoush, Master of Research, Medieval History
Stephen Charles Hudson, Master of Arts. COLLEGE OF ARTS AND LAW, School of History and Cultures`,
  language: 'en-US',
  // voiceActor: 'en-US-GuyNeural'
};

export const TextToSpeech = ({ token, voices, getVoices }: TextToSpeechProps) => {
  const [rowsCount, setRowsCount] = useState(0);
  const [files, setFiles] = useState<any[]>([]);
  const [generatingZip, setGeneratingZip] = useState(false);
  const { downloadFile } = useDownloadFile();
  const fileRef = useRef<HTMLInputElement>(null);
  const [key, setKey] = useState('');
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const {
    translateTextToSpeech,
    playing,
    stopPlayer,
    downloadSpeech,
    downloading,
    getSpeech,
    speech,
  } = useTextToSpeech();

  const handleFileClick = () => {
    // eslint-disable-next-line no-unused-expressions
    fileRef?.current?.click();
  };

  const uploadXlsx = (values: TextToSpeechFormProps, event: any) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }
    setGeneratingZip(true);
    const reader = new FileReader();

    reader.onload = (e: any) => {
      let data = e.target.result;
      data = new Uint8Array(data);
      const workbook = XLSX.read(data, { type: 'array' });
      const excelSheets: any = [];
      workbook.SheetNames.forEach(sheetName => {
        const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
        excelSheets.push(...sheetData);
      });
      setRowsCount(excelSheets.length);

      excelSheets.forEach((row: string[], i: number) => {
        setTimeout(() => {
          getSpeech({ ...values, textToSpeech: row[0] }, row[1]);
        }, i * 500);
      });
    };
    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    if (token) {
      getVoices(token);
    }
  }, [getVoices, token]);

  useEffect(() => {
    if (!speech || rowsCount <= files.length) return;

    setFiles((prevFiles: any) => {
      const filesCopy = [...prevFiles];
      filesCopy.push(speech);

      return filesCopy;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [speech]);

  useEffect(() => {
    setUploadPercentage(Math.floor((files.length / rowsCount) * 100));

    if (rowsCount && rowsCount === files.length) {
      const zip = new JSZip();
      files.forEach((file: { id: string; blob: Blob }) => {
        zip.file(`${file.id}.mp3`, file.blob);
      });

      zip.generateAsync({ type: 'blob' }).then((blob: Blob) => {
        downloadFile('text_to_speech_files.zip', blob);
        setGeneratingZip(false);
        setFiles([]);
        setKey(Math.random().toString(36));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  return (
    <StyledContainer>
      <Header>
        <Title>Get name calling right every time!</Title>
        <Subtitle>Participants hear their name and approve or edit.</Subtitle>
      </Header>
      <Form<TextToSpeechFormProps>
        initialValues={initialValues}
        onSubmit={translateTextToSpeech}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Wrapper>
              <div>
                <InputField
                  name="textToSpeech"
                  label="Press play to listen or type your own text here"
                  variant="outlined"
                  multiline
                  rows="20"
                />
              </div>
              <ControlWrapper>
                <SelectField name="language" label="Language" options={languages} />
                <FormSpy>
                  {({ values }: { values: TextToSpeechFormProps }) => {
                    const languageVoices = voices?.filter(
                      (voice: Voice) =>
                        voice.Locale === values?.language &&
                        voice.VoiceType === 'Neural' &&
                        !voicesUnsupported.includes(voice.ShortName),
                    );
                    const initialVoice = languageVoices?.find(el => el.ShortName === 'en-US-GuyNeural');

                    return (
                      <>
                        <SelectField
                          name="voiceActor"
                          label="Voice actor"
                          options={
                            voices
                              ? languageVoices.map((voice: any) => ({
                                  value: voice.ShortName,
                                  label: `${voice.DisplayName}`,
                                }))
                              : []
                          }
                          defaultValue={
                            initialVoice?.ShortName || (languageVoices?.length ? languageVoices[0].ShortName : '')
                          }
                          disabled={!languageVoices?.length}
                        />

                        <Buttons>
                          {!playing ? (
                            <Button type="submit" disabled={playing} color="primary" variant="contained">
                              <Icon icon="PlayArrow" /> Play
                            </Button>
                          ) : (
                            <StyledButton onClick={stopPlayer} variant="contained">
                              <Icon icon="Stop" /> Stop
                            </StyledButton>
                          )}
                          <Separator width={10} />

                          <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => downloadSpeech(values)}
                            disabled={downloading}
                          >
                            <Icon icon="GetApp" /> Download
                          </Button>

                          <Separator width={10} />

                          <FileInput
                            name="xlsx"
                            type="file"
                            onChange={file1 => uploadXlsx(values, file1)}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            ref={fileRef}
                            style={{ display: 'none' }}
                            key={key}
                          />

                          <Button
                            color="primary"
                            variant="contained"
                            onClick={handleFileClick}
                            disabled={generatingZip}
                          >
                            <Icon icon="ArrowUpward" /> Upload {uploadPercentage ? `${uploadPercentage}%` : ''}
                          </Button>
                        </Buttons>
                      </>
                    );
                  }}
                </FormSpy>
              </ControlWrapper>
            </Wrapper>
          </form>
        )}
      />
      <Section>
        How does it work?
        <ul>
          <li>Graduate can hear their name and approve/edit</li>
          <li>Name recording is in your moderation area with other content</li>
        </ul>
      </Section>
    </StyledContainer>
  );
};
