import { LinearProgress } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const BorderLinearProgress = withStyles(({ palette }) => ({
  root: {
    height: 10,
    borderRadius: 12,
    paddingRight: '30%',
    paddingLeft: '30%',
    marginRight: '20%',
    marginLeft: '20%',
  },
  colorPrimary: {
    backgroundColor: palette.gray.light,
  },
  bar: {
    borderRadius: 12,
    backgroundColor: palette.green.dark,
  },
}))(LinearProgress);

export const BorderLinearProgressFail = withStyles(({ palette }) => ({
  root: {
    height: 10,
    borderRadius: 12,
    paddingRight: '30%',
    paddingLeft: '30%',
  },
  colorPrimary: {
    backgroundColor: palette.red.main,
  },
  bar: {
    borderRadius: 12,
    backgroundColor: palette.red.main,
  },
}))(LinearProgress);

export const useStyles = makeStyles(({ breakpoints, spacing, palette }) => ({
  button: {
    borderRadius: 5,
    backgroundColor: palette.red.main,
    color: 'black',
    fontFamily: 'Opan Sans',
    fontSize: spacing(2),
    fontWeight: 'bold',
  },
  root: {
    paddingRight: '0%',
    paddingLeft: '0%',
    paddingTop: '5%',
    paddingBottom: '5%',
    textAlign: 'center',
    width: '100%',
  },

  uploadFail: {
    backgroundColor: palette.red.dark,
    [breakpoints.down('sm')]: {
      paddingRight: '10%',
      paddingLeft: '10%',
    },
    [breakpoints.up('md')]: {
      paddingRight: '30%',
      paddingLeft: '30%',
    },
  },
  upload: {
    backgroundColor: palette.green.contrastText,
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  grid: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '1%',
    [breakpoints.down('md')]: {
      width: '100%',
    },
    [breakpoints.up('lg')]: {
      width: '100%',
    },
  },
  ErrorLogo: {
    height: '30%',
    width: '30%',
    color: palette.red.main,
    paddingBottom: '5%',
    paddingTop: '10%',
  },
  uploadContent: {
    fontFamily: 'Open Sans',
    fontSize: spacing(2),
    paddingBottom: '5%',
    color: palette.black.main,
  },
  videoInfo: {
    color: palette.green.dark,
    paddingTop: '3%',
    paddingBottom: '5%',
    fontFamily: 'sans-serif',
    fontSize: spacing(1.7),
    padding: spacing(0, 12),
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  uploadLogo: {
    height: '30%',
    width: '30%',
    color: palette.red.light,
    paddingBottom: '3%',
    paddingTop: '5%',
    paddingRight: '10%',
    paddingLeft: '10%',
  },
  divStyle: {
    paddingBottom: '10%',
  },
}));
