import React from 'react';
import { useMutation } from 'react-fetching-library';

import {
  createParticipantOnboarding,
  sendEmailForSubscripeParticipant,
} from 'api/actions/selfRegistration/selfRegistration.actions';
import { CreateParticipantBody } from 'api/actions/selfRegistration/selfRegistration.types';
import { SelfRegistrationPage } from './HighSchoolSelfRegistration';
import { HighSchoolSelfRegisterContainerProps } from './HighSchoolSelfRegistration.types';
import { useLocale } from 'shared/hooks';

export const HighSchoolSelfRegisterContainer = ({
  activeStep,
  setActiveStep,
  eventId,
  setParticipantDetails,
  isCollective,
  setSnackbarMessage,
  queryParamKeys,
  instituteLogo,
  isRetail,
  isAllowForAmazonMusic,
}: HighSchoolSelfRegisterContainerProps) => {
  const { formatMessage } = useLocale();

  let participantId = '';

  const { mutate, loading } = useMutation(createParticipantOnboarding(eventId));

  const { mutate: sendEmail } = useMutation(sendEmailForSubscripeParticipant);

  const onSubmit = async (body: CreateParticipantBody) => {
    const res = await mutate(body);

    participantId = res.payload.id;

    if (res.error) {
      setSnackbarMessage(res.payload.errors.email.replace('"email"', `"${body.email}"`));

      return;
    }

    if (!isCollective && res.payload.id) {
      await sendEmail(participantId);
    }

    if (res?.payload?.wooCommercePermalink) {
      setParticipantDetails({ id: res?.payload?.id, wooCommercePermalink: res.payload.wooCommercePermalink });
    }

    const blockedDomains = ['yahoomail', 'yopmail'];
    const checkEmail = blockedDomains.some(i => res?.payload.email.includes(i));

    if (isCollective && checkEmail) {
      setActiveStep(activeStep + 2);
      setSnackbarMessage(
        <div>
          <div>One More Step!</div>
          <div>
            We need to verify you. Please complete this {res?.payload.email}{' '}
            <a href="https://stageclip.com/student-support/" target="_blank" rel="noopener noreferrer">
              form
            </a>
          </div>
          <div>{formatMessage({ id: 'highSchool.register_welcome' })}</div>
        </div>,
      );
    } else if ((isCollective && isRetail) || (isAllowForAmazonMusic && isRetail)) {
      setActiveStep(activeStep + 2);
    } else if (isAllowForAmazonMusic || isCollective) {
      setActiveStep(activeStep + 1);
    } else if (!isCollective || !isAllowForAmazonMusic) {
      setActiveStep(activeStep + 2);
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  return (
    <SelfRegistrationPage
      activeStep={activeStep}
      onSubmit={onSubmit}
      instituteLogo={instituteLogo}
      isCollective={isCollective}
      isLoading={loading}
      queryParamKeys={queryParamKeys}
      isRetail={isRetail}
      isAllowForAmazonMusic={isAllowForAmazonMusic}
    />
  );
};
