import React from 'react';
import { TextFieldProps } from '@material-ui/core';

import { useTranslations } from 'shared/hooks';
import { StyledTextField } from './TextField.styles';

export const TextField: React.FC<TextFieldProps> = ({ label, placeholder, ...props }) => {
  const { message: translatedLabel } = useTranslations(label);
  const { message: translatedPlaceholder } = useTranslations(placeholder);

  return <StyledTextField label={translatedLabel} placeholder={translatedPlaceholder as string} {...props} />;
};
