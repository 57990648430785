import React from 'react';

import { ScrollToTop } from 'shared/components/scrollToTop/ScrollToTop';
import { Header } from './header/Header';
import { Footer } from './footer/Footer';
import { Wrapper } from './MainTamplate.styles';

export const MainTemplate: React.FC = ({ children }) => {
  return (
    <Wrapper>
      <ScrollToTop />
      <Header />
      {children}
      <Footer />
    </Wrapper>
  );
};
