import React from 'react';
import { MenuItem, InputBase } from '@material-ui/core';

import { controlBase } from '../controlBase';
import { connectControl } from '../connectControl/connectControl';

import { StyledSelect } from './Select.styles';
import { SelectProps, Option } from './Select.types';

export const SelectBase: React.FC<SelectProps> = ({ options, value = '', ...props }) => {
  const { id } = props;

  return (
    <StyledSelect {...props} value={value} labelId={id} input={<InputBase />} fullWidth>
      {options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

export const Select = controlBase<SelectProps>(SelectBase);
export const SelectField = connectControl<SelectProps, Option>(Select);
