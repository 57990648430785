import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette, spacing }) => ({
  formContainer: {
    width: '100%',
  },
  labelTop: {
    marginTop: spacing(3.75),
    fontFamily: 'Roboto',
    fontSize: spacing(2),
  },
  studentID: {
    fontFamily: 'Roboto',
    fontSize: spacing(2),
  },
  labelbottom: {
    marginTop: spacing(2.75),
  },
  textField: {
    // margin: spacing(0),
    // padding: spacing(0),
    justifyContent: 'flesx-start',
  },
  buttonNext: {
    backgroundColor: palette.green.dark,
    color: palette.white.main,
    marginBottom: spacing(3),
    '&:hover': {
      backgroundColor: palette.green.dark,
    },
  },
  textFieldRoot: {
    '& label+*': {
      marginTop: '0 !important',
    },
  },
  snackBar: {
    '& .MuiSnackbarContent-root': {
      backgroundColor: palette.red.light,
    },
  },
}));
