export const useDownloadFile = () => {
  const downloadFile = (fileName: string, blob: Blob) => {
    const a = document.createElement('a');

    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove();
  };

  return {
    downloadFile,
  };
};
