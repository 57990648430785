import { styled, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const Topic = styled(Typography)(({ theme: { breakpoints, typography, spacing } }) => ({
  ...typography.h4,
  fontWeight: 'normal',
  fontFamily: 'Open Sans',
  [breakpoints.down('sm')]: {
    fontSize: spacing(1.75),
  },
  [breakpoints.up('lg')]: {
    fontSize: spacing(2),
  },
  [breakpoints.down('md')]: {
    height: spacing(5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const EventDetailStyles = makeStyles(({ breakpoints, spacing, palette }) => ({
  availableCard: {
    marginBottom: '3%',
    [breakpoints.up('md')]: {
      paddingBottom: '1%',
    },
  },
  available: {
    flexDirection: 'column',
    [breakpoints.up('md')]: {
      flexDirection: 'row',
      display: 'flex',
      padding: '2%',
    },
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      display: 'flex',
      padding: '3%',
    },
  },
  availableImage: {
    marginRight: '3%',
    backgroundColor: palette.black.dark,
    [breakpoints.up('md')]: {
      height: spacing(19),
      width: spacing(35.5),
    },
    [breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  button: {
    color: palette.green.main,
    backgroundColor: palette.green.contrastText,
    padding: spacing(1, 3, 1, 3),
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    [breakpoints.down('md')]: {
      height: spacing(5),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  video: {
    objectFit: 'fill',
  },
  content: {
    flexDirection: 'column',
    width: '100%',
  },
  subTopic: {
    flexDirection: 'column',
    [breakpoints.up('md')]: {
      height: spacing(5),
      display: 'flex',
      alignItems: 'strech',
      justifyContent: 'flex-start',
    },
  },
  videoText: {
    display: 'flex',
    fontSize: spacing(1.75),
    fontFamily: 'Open Sans',
    marginLeft: spacing(1),

    gap: spacing(1.25),
    width: '100%',
    [breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
    [breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  videoName: {
    width: '100%',

    [breakpoints.up('md')]: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
  },
  videoSize: {
    fontWeight: 'bold',
    [breakpoints.up('lg')]: {
      width: '14%',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    [breakpoints.down('md')]: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  videoSize1: {
    fontWeight: 'bold',
    [breakpoints.up('md')]: {
      width: '12%',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
  },
  videoSize2: {
    fontWeight: 'bold',
    [breakpoints.up('md')]: {
      width: '12%',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
  },
  videoSize3: {
    fontWeight: 'bold',
    [breakpoints.up('lg')]: {
      width: '17%',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
  },
}));
