import { useContext } from 'react';

import { FormControlContext } from '../context/ControlBaseContext';

export const useFormControlState = () => {
  const context = useContext(FormControlContext);

  if (context === undefined) {
    throw new Error('useFormControlState must be used within an FormControlContext');
  }

  return context;
};
