import React from 'react';

import { SocialMediaIcon } from './icon/SocialMediaIcon';
import { Wrapper, Item } from './SocialMedia.styles';
import { SocialMediaProps } from './SocialMedia.types';
import { ReactComponent as Instagram } from 'assets/twitter.svg';
import { ReactComponent as Facebook } from 'assets/facebook.svg';
import { ReactComponent as Youtube } from 'assets/youtube.svg';

export const SocialMedia = ({ inverse = false }: SocialMediaProps) => (
  <Wrapper>
    <Item>
      <SocialMediaIcon icon={Instagram} inverse={inverse} href="https://twitter.com/stageclip" />
    </Item>
    <Item>
      <SocialMediaIcon icon={Facebook} inverse={inverse} href="https://www.facebook.com/stageclip" />
    </Item>
    <Item>
      <SocialMediaIcon
        icon={Youtube}
        inverse={inverse}
        href="https://www.youtube.com/channel/UCNzUbhuEJXhyNoqEedWUmaw"
      />
    </Item>
  </Wrapper>
);
