import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette, spacing, breakpoints, typography: { fontWeightBold, h3 } }) => ({
  header: {
    fontSize: spacing(4.25),
    color: palette.black.main,
    fontWeight: spacing(87.5),
    fontFamily: 'Open Sans',
    width: 'auto',
    marginBottom: spacing(4),

    [breakpoints.only('sm')]: {
      fontSize: spacing(3),
      marginBottom: spacing(2),
    },

    [breakpoints.down('lg')]: {
      fontSize: h3.fontSize,
      lineHeight: h3.lineHeight,
    },
  },

  studentNameWrapper: {
    textAlign: 'center',
  },

  link: {
    fontWeight: fontWeightBold,
    display: 'inline-block',
    marginTop: spacing(1),
  },
  footerContainer: {
    '& footer': {
      position: 'relative',
    },
  },
  container: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: spacing(3, 0),
  },
  rootText: {
    fontSize: spacing(4),
    fontWeight: spacing(87.5),
    fontFamily: 'Open Sans',
    textAlign: 'center',
    lineHeight: '43.58px',
    marginTop: spacing(4),
    color: '#000000',
    [breakpoints.down('sm')]: {
      fontSize: spacing(3),
      margin: spacing(2),
    },
  },
  rootTextAmazon: {
    fontSize: spacing(3),
    fontWeight: spacing(87.5),
    fontFamily: 'Open Sans',
    textAlign: 'center',
    lineHeight: '32.68px',
    marginTop: spacing(4),
    color: '#000000',
    [breakpoints.down('sm')]: {
      fontSize: spacing(3),
      margin: spacing(2),
    },
  },
  rootTextSub: {
    fontSize: spacing(4.25),
    fontWeight: spacing(87.5),
    fontFamily: 'Open Sans',
    textAlign: 'center',
    [breakpoints.down('sm')]: {
      fontSize: spacing(2.5),
    },
  },
  background: {
    position: 'relative',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: '96%',
    backgroundSize: '100%',
    backgroundImage: ({ background }: { background?: string }) => `url(${background}), ${palette.blue}`,
  },
  cardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: spacing(60),
    maxWidth: '90%',
    overflowY: 'auto',
  },
  card: {
    marginTop: spacing(2),
    background: palette.white.main,
    // borderRadius: spacing(1.5),
    padding: spacing(3),
    maxHeight: '90vh',
    width: '100%',
    justifyContent: 'center',
  },
  cardAmazon: {
    marginTop: spacing(2),
    background: palette.white.main,
    // borderRadius: spacing(1.5),
    padding: spacing(4),
    maxHeight: '90vh',
    width: '100%',
    justifyContent: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: spacing(2),
    backgroundColor: palette.green.dark,
    color: palette.white.main,
    textTransform: 'capitalize',
    borderRadius: spacing(0.625),
    padding: spacing(1.5, 2, 1.5, 2),
    '&:hover': {
      backgroundColor: palette.scorpionGray.dark,
    },
    '& .MuiButton-label': {
      letterSpacing: '-0.48px',
      fontFamily: 'Open Sans',
      fontWeight: 700,
      lineHeight: '32.68px',
      fontSize: spacing(3),
    },
  },
  bottomContainer: {
    fontSize: spacing(2.25),
    textAlign: 'center',
    fontFamily: 'Open Sans',
    fontWeight: spacing(50),
    lineHeight: '24.51px',
    color: '#505050',
    letterSpacing: '1px',
  },
  buttonSecondContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: spacing(2),
    backgroundColor: palette.white.dark,
    // color: palette.gray.dark,
    textTransform: 'initial',
    cursor: 'pointer',
    letterSpacing: '1px',
    fontFamily: 'Open Sans',
    fontWeight: 400,
    lineHeight: '21.79px',
    fontSize: spacing(2),
    color: '#505050',
    // '& .MuiButton-label': {
    //   letterSpacing: '1px',
    //   fontFamily: 'Open Sans',
    //   fontWeight: 400,
    //   lineHeight: '21.79px',
    //   fontSize: spacing(2),
    //   color: '#505050',
    // },
  },
  logoImage: {
    maxHeight: spacing(15),
    objectFit: 'contain',
    [breakpoints.up('md')]: {
      marginLeft: spacing(0),
    },
    [breakpoints.down('sm')]: {
      marginTop: spacing(1.25),
      marginLeft: spacing(0),
      maxWidth: '75%',
    },
    textAlign: 'center',
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainContainer: {
    backgroundColor: '#F9F8F8',
    minHeight: '100vh',
    [breakpoints.down('sm')]: {
      backgroundImage: 'none',
      backgroundColor: '#F9F8F8',
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  amazonCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '8px',
  },
  amazonTitle: {
    letterSpacing: '1px',
    fontFamily: 'Open Sans',
    fontWeight: 800,
    lineHeight: '14.98px',
    fontSize: '11px',
    color: '#505050',
    marginTop: spacing(2),
    [breakpoints.up('sm')]: {
      marginTop: spacing(2),
    },
  },
  amazonDescription: {
    letterSpacing: '1px',
    fontFamily: 'Open Sans',
    fontWeight: 600,
    lineHeight: '14.98px',
    fontSize: spacing(1.375),
    color: '#505050',
    marginBottom: spacing(2),
    [breakpoints.up('sm')]: {
      marginTop: spacing(0.5),
    },
    [breakpoints.down('xs')]: {
      marginTop: spacing(0.5),
    },
  },
  amazonDescription1: {
    letterSpacing: '1px',
    fontFamily: 'Open Sans',
    fontWeight: 600,
    lineHeight: '14.98px',
    fontSize: spacing(1.375),
    color: '#505050',
    marginBottom: spacing(2),
    [breakpoints.up('sm')]: {
      marginTop: spacing(-1),
    },
    [breakpoints.down('xs')]: {
      marginTop: spacing(-1),
    },
  },
  amazonDescription2: {
    letterSpacing: '1px',
    fontFamily: 'Open Sans',
    fontWeight: 600,
    lineHeight: '14.98px',
    fontSize: spacing(1.375),
    color: '#505050',
    marginBottom: spacing(2),
    [breakpoints.up('sm')]: {
      marginTop: spacing(-1),
    },
    [breakpoints.down('xs')]: {
      marginTop: spacing(-1),
    },
  },
  amazonDescription3: {
    letterSpacing: '1px',
    fontFamily: 'Open Sans',
    fontWeight: 600,
    lineHeight: '14.98px',
    fontSize: spacing(1.375),
    color: '#505050',
    marginBottom: spacing(2),
    [breakpoints.up('sm')]: {
      marginTop: spacing(-1),
    },
    [breakpoints.down('xs')]: {
      marginTop: spacing(-1),
    },
  },
  amazonButtonSecondContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: spacing(0.5),
    backgroundColor: palette.white.dark,
    cursor: 'pointer',
    fontFamily: 'Open Sans',
    fontWeight: 400,
    lineHeight: '14.98px',
    fontSize: spacing(1.5),
    color: '#050505',
    textDecoration: 'underline',
  },
  amazonLogoImage: {
    display: 'flex',
    justifyContent: 'center',
    justifyItems: 'center',
    maxHeight: spacing(15),
    objectFit: 'contain',
    marginTop: spacing(1.5),
    [breakpoints.up('md')]: {
      marginLeft: spacing(0),
    },
    [breakpoints.down('sm')]: {
      marginTop: spacing(1.25),
      marginLeft: spacing(0),
    },
    textAlign: 'center',
  },
}));
