import { Button, Container, Card, CardContent } from '@material-ui/core';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Info } from '@material-ui/icons';

import { Form } from 'shared/components/form/Form';
import { url } from 'shared/validators';
import { InputField } from 'shared/components/controls/input';
import { useStyles } from './AvUserFileUploadStream.style';
import { AvUserFileUploadStreamZoneSubmitProps } from './AvUserFileUploadStreamZone.type';
import { CollaboratorPages } from 'app/avUserRoot/collabloratorUpload/CollaboratorUpload.types';
import { Typography } from 'shared/components/typography/Typography';
import { AvUserFileUploadLinkLoadingContainer } from './avUserFileUploadLinkLoading/AvUserFileUploadLinkLoadingContainer';

export const AvUserFileUploadStreamZone = ({
  onSubmit,
  id,
  setCollaboratorPage,
  setVideoFileInfo,
}: AvUserFileUploadStreamZoneSubmitProps) => {
  const styles = useStyles();
  const { formatMessage } = useIntl();
  const [success, setsuccess] = useState(false);
  const [stop, setstop] = useState(false);

  return (
    <Container className={styles.container}>
      <div className={styles.subtitle}>
        {formatMessage({ id: 'av_user.file_uploadstream.upload_video_stream_subtitle' })}
      </div>

      <Card elevation={0} className={styles.cardStyle1}>
        <CardContent>
          {id && !success && !stop ? (
            <AvUserFileUploadLinkLoadingContainer
              jobid={id}
              setSuccess={setsuccess}
              setVideoFileInfo={setVideoFileInfo}
              setstop={setstop}
            />
          ) : (
            <>
              <div className={styles.infoContainer}>
                <Info type="alert" className={styles.infoicon} />
                <div className={styles.infoText}>
                  {formatMessage({ id: 'av_user.file_uploadstream.upload_stream_info_label' })}
                </div>
              </div>
              <Form
                onSubmit={onSubmit}
                errorSeparator={2}
                render={({ handleSubmit }) => (
                  <form className={styles.root} onSubmit={handleSubmit} noValidate>
                    <div>
                      <Typography className={styles.label}>
                        {formatMessage({ id: 'av_user.file_uploadstream.upload_stream_info_text' })}
                      </Typography>
                      <InputField
                        variant="outlined"
                        name="videoUrl"
                        placeholder={formatMessage({ id: 'av_user.file_uploadstream.upload_stream_info_placeholder' })}
                        required
                        validate={[url()]}
                        className={styles.row}
                        classes={{ root: styles.textFieldRoot }}
                      />
                    </div>
                    <div className={styles.buttonContainer}>
                      <Button type="submit" variant="contained" className={styles.button}>
                        {formatMessage({ id: 'av_user.file_uploadstream.Upload' })}
                      </Button>
                    </div>
                  </form>
                )}
              />
            </>
          )}
          {success && setCollaboratorPage(CollaboratorPages.confirmLinkMedia)}
        </CardContent>
      </Card>
    </Container>
  );
};
