type Config = {
  behavior?: ScrollBehavior;
  timeout?: number;
  offsetX?: number;
  offsetY?: number;
};
export const scroll = (config?: Config) => {
  const { behavior = 'smooth', timeout = 800, offsetX = 0, offsetY = 0 } = config || {};

  setTimeout(() => {
    try {
      window.scrollTo({ left: offsetX, top: offsetY, behavior });
    } catch (e) {
      window.scrollTo(offsetX, offsetY);
    }
  }, timeout);
};
